import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class CashManagementService {
  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }

  /**
   * Check if opening balance status
   * @returns Array
   */
  public checkOpeningBalanceStatus():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/cash-mgnt/check-opening-bal-status`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Post opening balance
   * @param formData
   * @returns array
   */
  saveOpeningBalance(formData:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/cash-mgnt/post-opening-bal`;

    return this.http.post(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get balance C/F
   */
  getBalanceCf():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/cash-mgnt/get-balance-cf`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Close day
   */
  closeDay():Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/cash-mgnt/close-day`;

    return this.http.post(apiURL, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Receive cash
   * @param formData
   * @returns array
   */
  receiveCash(formData:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/cash-mgnt/post-cash-receipt`;

    return this.http.post(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Cash payment
   * @param formData
   * @returns array
   */
  cashPayment(formData:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/cash-mgnt/post-cash-payment`;

    return this.http.post(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get all cash transactions
   * @returns Array
   */
  public fetchTransactions():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/cash-mgnt/get-transactions`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

   /**
   * Filter cash transactions by date
   */
   filterByDate(startDate:any,endDate:any): Observable<ApiResponse>{
    const formData = new FormData();
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);

    let apiURL:string=`${this.baseURL}/v1/cash-mgnt/filter-transactions-by-date`;
    
    return this.http.post<ApiResponse>(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }
}
