import { Component, OnInit } from '@angular/core';
import { StockService } from 'src/app/services/stock/stock.service';

@Component({
  selector: 'app-stock-at-cfs',
  templateUrl: './stock-at-cfs.component.html',
  styleUrls: ['./stock-at-cfs.component.css']
})
export class StockAtCfsComponent implements OnInit{
  public errorMessage: string | null = null;
  vehicles: any[] = [];
  searchText: string = '';
  public isInProgress: boolean = false;
  rowCount: number=0;

  constructor(
    private stockService:StockService,
  ){}

  ngOnInit(): void {
    this.isInProgress=true;
    this.fetchStockAtCFS();
  }

  fetchStockAtCFS():void{
    this.stockService.getStockAtCFS()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicles = response.data;
          this.rowCount = this.vehicles.length;
          this.isInProgress=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.rowCount =0;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }
}
