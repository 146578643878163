<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
            
            </div><!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item active">Refunded Commitment Fees</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
        <div class="row">
            <div class="col-md-8">
                <form [formGroup]="form" (ngSubmit)="handleSubmit()">
                    <div class="row">
                        <div class="col-md-1">
                            <label>From:<span style="color:red;">*</span></label>
                        </div>
                        <div class="col-md-3">
                            <input type="date" formControlName="date_from" class="form-control" value="">
                            <span *ngIf="submitted && form.get('date_from')?.hasError('required')" style="color:red"> <strong>Please enter or select start date!</strong></span>
                        </div>
                        <div class="col-md-1">
                            <label>To:<span style="color:red;">*</span></label>
                        </div>
                        <div class="col-md-3">
                            <input type="date" formControlName="date_to" class="form-control" value="">
                            <span *ngIf="submitted && form.get('date_to')?.hasError('required')" style="color:red"> <strong>Please enter or select end date!</strong></span>
                        </div>
                        <div class="col-md-2">
                            <button type="submit" class="btn btn-success"><i class="fa fa-filter" aria-hidden="true">&nbsp;</i>Filter</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-4">
                <input type="text" class="form-control" placeholder="Type Chassis Number,Customer Name or Phone Number to search..." (input)="onSearchTextChange($event)">
            </div>
        </div>
    </div>
    </section>
      <!-- /.content-header -->
    <section class="content">
      <div class="container-fluid">
      <div class="card">
          <div class="card-header">
            <h3 class="card-title">Refunded Commitment Fees</h3>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
               <!--Error messages-->
               <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
               </div>
            <table id="example1" class="table table-bordered table-hover">
              <thead>
              <tr>
                  <th>Make/Model</th>
                  <th>Chassis No.</th>
                  <th>Customer Name</th>
                  <th>Phone Number</th>
                  <th>Amount</th>
                  <th>Date Refunded</th>
                  <th></th>
              </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let refunded_fee of refunded_fees | searchRefunds: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                      <td>{{ refunded_fee.make }} {{ refunded_fee.model }}</td>
                      <td>{{ refunded_fee.chassis_no }}</td>
                      <td>{{ refunded_fee.first_name }} {{ refunded_fee.middle_name }} {{ refunded_fee.surname }}</td>
                      <td>{{ refunded_fee.phone_no }}</td>
                      <td>{{refunded_fee.amount| currency:' '}}</td>
                      <td>{{refunded_fee.date_refunded| date: 'dd-MM-yyyy' }}</td>
                      <td>
                        <a routerLink="/view-commitment-fee-refund-attachments/{{refunded_fee.refund_id}}" class="btn btn-default btn-sm" role="button"><i class="fa fa-paperclip" aria-hidden="true">&nbsp;</i>View Attachments</a>
                      </td>
                  </tr>
              </tbody>
            </table>
            <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </section>