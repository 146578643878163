  <!--Loading Spinner-->
  <div *ngIf="isLoading">
    <app-spinner></app-spinner>
  </div>
  
  <!-- Content Header (Page header) -->
  <section class="content-header">
      <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
             
              <div class="col-sm-6">
                
              </div><!-- /.col -->
             
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                  <li class="breadcrumb-item active">Delete Entry</li>
                </ol>
              </div><!-- /.col -->
            </div><!-- /.row -->
          </div><!-- /.container-fluid -->
      </div>
    
      <div class="row">
        <div class="col-md-8">
            <div class="row">
               
            </div>
        </div>
        <div class="col-md-4">
          <div class="row">
              <div class="col">
                  <input type="text" class="form-control" placeholder="Search by Make, Model, Chassis No., Entry No., Document Ref No." (input)="onSearchTextChange($event)">
              </div>
          </div>
        </div>
      </div>
      </section>
        <!-- /.content-header -->
      <section class="content">
        <div class="container-fluid">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Delete Entry</h3>
              <!--Error Message-->
              <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
              </div>
              <!--Success Message-->
              <div class="alert alert-success" role="alert" *ngIf="successMessage">
                {{successMessage}}
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
              <table id="example1" class="table table-bordered table-hover">
                <thead>
                  <tr>
                      <th>BL No.</th>
                      <th>Make</th>
                      <th>Model</th>
                      <th>Chassis No.</th>
                      <th>Entry No.</th>
                      <th>Entry Date</th>
                      <th>Document Ref No.</th>
                      <th></th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let vehicle of deletableEntries | searchEditableEntries: searchText| paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }" [ngClass]="{'highlight_paid': vehicle.bank_acc_id!==null}">
                      <td>{{vehicle.bl_no}}</td>
                      <td>{{vehicle.make_name}}</td>
                      <td>{{vehicle.model_name}}</td>
                      <td>{{vehicle.chassis_no}}</td>
                      <td>{{vehicle.entry_no}}</td>
                      <td>{{vehicle.entry_date| date:'dd-MM-yyyy'}}</td>
                      <td>{{vehicle.doc_ref_no}}</td>
                      <td *ngIf="vehicle.duty_count <= 1"><a (click)="deleteEntry(vehicle.entry_id,vehicle.entry_no)" class="btn btn-danger btn-sm" role="button" aria-pressed="true"><i
                        class="fa fa-trash" aria-hidden="true">&nbsp;</i>Delete</a></td>
                      <td *ngIf="vehicle.duty_count > 1"><a routerLink="/transactions/delete-addtl-duty/{{vehicle.vehicle_id}}" class="btn btn-warning btn-sm" role="button" aria-pressed="true"><i
                            class="fa fa-list-ul" aria-hidden="true">&nbsp;</i>Manage</a></td>
                    </tr>
                </tbody>
              </table>
              <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </section>