import { Component, OnInit } from '@angular/core';
import { PortChargesService } from 'src/app/services/payments/port-charges.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-add-port-charges-select-bl',
  templateUrl: './add-port-charges-select-bl.component.html',
  styleUrls: ['./add-port-charges-select-bl.component.css']
})
export class AddPortChargesSelectBlComponent implements OnInit {
  page: number = 1;
  count: number = 0;

  bills_of_lading: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  searchText: string = '';

  constructor(private portChargesService: PortChargesService){}

  ngOnInit(): void {
    this.getBillsOflading();
  }

  getBillsOflading(): void {
    this.portChargesService.getBLs().subscribe((response: ApiResponse) =>this.bills_of_lading = response.data);
  }
  
  pageChangeEvent(event: number){
    this.page = event;
    this.getBillsOflading();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }
}
