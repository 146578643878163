import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { DeliveryNotesService } from 'src/app/services/sales-management/delivery-notes.service';

@Component({
  selector: 'app-print-delivery-note',
  templateUrl: './print-delivery-note.component.html',
  styleUrls: ['./print-delivery-note.component.css']
})
export class PrintDeliveryNoteComponent implements OnInit{
  public deliveryId: string | null = null;
  public deliveryData:any;
  public delivery_note_items:any[] = [];
  public errorMessage: string | null = null;
  isInProgress: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private deliveryNotesService:DeliveryNotesService
  ){}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.deliveryId = param['get']('delivery_id')
    })

    if(this.deliveryId){
      this.isInProgress=true;
      this.fetchDeliveryData(this.deliveryId);
    }else{
      //Navigate back to delivery notes list with error
      this.globalService.setGlobalErrorMessage("Unable to select delivery note for printing. Please try again!");
      this.router.navigate(['sales/delivery-notes']);
    }
  }
  /**
   * Get delivery note data
   * @param deliveryId 
   */
  fetchDeliveryData(deliveryId: string): void {
    this.deliveryNotesService.getDeliveryInfo(deliveryId)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
          this.globalService.setGlobalErrorMessage(this.errorMessage);
          this.router.navigate(['sales/delivery-notes']);
        }else{
          this.deliveryData = response;
          if(this.deliveryData.status==='no_data' || this.deliveryData.status.length===0){
            this.globalService.setGlobalErrorMessage(this.deliveryData.message);
            this.router.navigate(['sales/delivery-notes']);
          }else{
            this.deliveryData = response;
            this.fetchDeliveryNoteItems(deliveryId);
          }
        }
      },
      error: (error) => {
        //Navigate back to delivery notes list with error
        this.globalService.setGlobalErrorMessage(error);
        this.router.navigate(['sales/delivery-notes']);
      }
    });
  }

  fetchDeliveryNoteItems(deliveryId: string):void{
    this.deliveryNotesService.getDeliveryNoteItems(deliveryId)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.delivery_note_items = response.data;
          this.isInProgress=false;
        }else if(response.status==='no_data'){
          this.globalService.setGlobalErrorMessage(response.message);
          this.router.navigate(['sales/delivery-notes']);
        }else{
          this.globalService.setGlobalErrorMessage(response.message);
          this.router.navigate(['sales/delivery-notes']);
        }
      },
      error: (error) => {
        this.globalService.setGlobalErrorMessage(error);
        this.router.navigate(['sales/delivery-notes']);
      }
    });
  }
}
