import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchRefunds'
})
export class SearchRefundsPipe implements PipeTransform {

  transform(refundableFeesData: any, searchText: string){
    if (!searchText) {
      return refundableFeesData; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return refundableFeesData.filter((item: any) =>
      (item.chassis_no && item.chassis_no.toLowerCase().includes(searchText)) ||
      (item.first_name && item.first_name.toLowerCase().includes(searchText)) ||
      (item.middle_name && item.middle_name.toLowerCase().includes(searchText)) ||
      (item.surname && item.surname.toLowerCase().includes(searchText)) ||
      (item.phone_no && item.phone_no.toLowerCase().includes(searchText))
    );
  }

}
