import { Component, OnInit } from '@angular/core';
import { AgentService } from 'src/app/services/agents/agent.service';

@Component({
  selector: 'app-agent-invoices',
  templateUrl: './agent-invoices.component.html',
  styleUrls: ['./agent-invoices.component.css']
})
export class AgentInvoicesComponent implements OnInit{
  invoices: any[] = [];
  public isLoading: boolean = false;
  public errorMessage: string | null = null;
  searchText: string = '';
  page: number = 1;
  count: number = 0;

  constructor(
    private agentService:AgentService
  ){}

  ngOnInit(): void {
    this. getInvoices();
  }

  getInvoices(): void {
    this.agentService.getOpenAgentInvoices()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.isLoading = false;
          this.invoices = response.data
        }else{
          this.isLoading = false;
          this.errorMessage=response.message;
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.errorMessage=error;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.getInvoices();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }
}
