<!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Edit Location</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/settings/locations-list"><i class="fa fa-list-ul"></i>&nbsp;View Locations</a></li>
               <li class="breadcrumb-item active">Edit Location</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
         <h3 class="card-title">Edit Location</h3>
         <!--Error Message-->
         <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            <span [innerHTML]="errorMessage"></span>
          </div>
          <!--Success Message-->
          <div class="alert alert-success" role="alert" *ngIf="successMessage">
            {{successMessage}}
          </div>
       </div>
       <!-- /.card-header -->
        <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="handleSubmit()">
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Country:<span style="color:red;">*</span></label>
                            <select formControlName="country_id" class="form-control" [(ngModel)]="locationData.data.country_id">
                                <option [value]="country.id" *ngFor="let country of countries">{{country.country_name}}</option>
                            </select>
                            <span *ngIf="submitted && form.get('country_id')?.hasError('required')" style="color:red"> <strong>Please select a country!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                       <div class="form-group">
                           <label>Location:<span style="color:red;">*</span></label>
                           <input type="text" formControlName="location_name" class="form-control" placeholder="Location Name" [(ngModel)]="locationData.data.location_name" value="">
                           <span *ngIf="submitted && form.get('location_name')?.hasError('required')" style="color:red"> <strong>Please enter location!</strong></span>
                       </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>BL:<span style="color:red;">*</span></label>
                            <ng-select [(ngModel)]="selectedBL" [ngModelOptions]="{standalone: true}">
                                <ng-option *ngFor="let bl of billsOfLading" [value]="bl.bl_no">{{bl.bl_no}}</ng-option>
                            </ng-select>

                            <span *ngIf="submitted && form.get('bl_no')?.hasError('required')" style="color:red"> <strong>Please select BL!</strong></span>
                        </div>
                    </div>
               </div>
              
               <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Processing...
                </button>
                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Save</button>
                </ng-template>
            </form>
        </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>