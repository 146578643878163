<!--Loading Spinner-->
<div *ngIf="isLoading">
    <app-spinner></app-spinner>
  </div>
<!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Vehicle Expense Details</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a href="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a href="/stock-overview/all-stock"><i class="fa fa-list-ul"></i>&nbsp;All Available Stock</a></li>
               <li class="breadcrumb-item active">Vehicle Expense Details</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
         <h3 class="card-title">Vehicle Details</h3>
         <!--Error messages here-->
       </div>
       <!-- /.card-header -->
       <div class="card-body">
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Make:</label>
                        <input type="text" name="make" class="form-control" placeholder="Make" value="{{vehicle.data.make}}" readonly>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Model:</label>
                        <input type="text" name="model" class="form-control" placeholder="Model" value="{{vehicle.data.model}}" readonly>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Chassis No.:</label>
                        <input type="text" name="chassis_no" class="form-control" placeholder="Chassis No." value="{{vehicle.data.chassis_no}}" readonly>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Engine CC:</label>
                        <input type="text" name="engine_cc" class="form-control" placeholder="Engine CC" value="{{vehicle.data.engine_cc}}" readonly>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Fuel:</label>
                        <input type="text" name="fuel" class="form-control" placeholder="Fuel" value="{{vehicle.data.fuel}}" readonly>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Colour:</label>
                        <input type="text" name="colour" class="form-control" placeholder="Colour" value="{{vehicle.data.colour}}" readonly>
                    </div>
                </div>
            </div>
            <h3 class="card-title">Expense Details</h3>
            <table class="table">
                <tr>
                    <th>Paid On</th>
                    <th>Description</th>
                    <th>Amount</th>
                </tr>
                <tr *ngFor="let expense of expenses">
                    <td>{{expense.pmt_dt| date: 'dd-MM-yyyy'}}</td>
                    <td>{{expense.txn_desc}}</td>
                    <td>{{expense.amt_pd| currency:'Kshs '}}</td>
                </tr>
            </table>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>