import { Component, OnInit } from '@angular/core';
import { BillsOfLadingService } from 'src/app/services/bills-of-lading/bills-of-lading.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-print-qr-codes',
  templateUrl: './print-qr-codes.component.html',
  styleUrls: ['./print-qr-codes.component.css']
})
export class PrintQrCodesComponent implements OnInit{
  public errorMessage: string | null = null;
  vehicles: any[] = [];
  public bl_no: string | null = null;

  constructor(
    private billsOfLadingService: BillsOfLadingService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ){}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.bl_no = param['get']('bl_no')
    })

    if(this.bl_no){
      this.fetchVehicles(this.bl_no);
    }else{
      //Navigate back to list with error
      this.errorMessage="Unable to select BL for QR Code printing!";
      this.router.navigate(['bl-list']);
    }

   
  }

  fetchVehicles(bl_no: string): void {
    this.billsOfLadingService.getVehicles(bl_no)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicles = response.data;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  printPage(): void {
    window.print(); // Open the browser print preview dialog
  }

}
