<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>

<div class="overlay">
    <a routerLink="/port-charges-invoices" class="close-btn" role="button">Close</a>

    <div class="d-flex justify-content-center align-items-center h-100">
        <img [src]="fileURL">
    </div>
</div>