import { Component, OnInit } from '@angular/core';
import { CommitmentFeeRefundsService } from 'src/app/services/sales-management/commitment-fee-refunds.service';

@Component({
  selector: 'app-commitment-fee-refunds',
  templateUrl: './commitment-fee-refunds.component.html',
  styleUrls: ['./commitment-fee-refunds.component.css']
})
export class CommitmentFeeRefundsComponent implements OnInit{
  searchText: string = '';
  page: number = 1;
  count: number = 0;
  public errorMessage: string | null = null;
  public isProcessing: boolean = false;
  public isLoading: boolean = false;
  public refundable_fees: any[] = [];
  
  constructor(
    private commitmentFeeRefundsService: CommitmentFeeRefundsService,
  ){
    
  }

  ngOnInit(): void {
    this.isLoading=true;
    this.fetchRefundableCommitmentFees();
  }

  fetchRefundableCommitmentFees(): void {
    this.commitmentFeeRefundsService.getRefundableCommitmentFees()
    .subscribe({ 
        next: (response) => {
          if(response.status==='success'){
            this.isLoading = false;
            this.refundable_fees = response.data
          }else{
            this.isLoading = false;
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.errorMessage=error;
        }
      });
  }

  onSearchTextChange(event: Event):void {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  pageChangeEvent(event: number){
    this.page = event;
  }
}
