import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';
import { ApiResponse } from 'src/app/models/IApiResponse'

@Injectable({
  providedIn: 'root'
})
export class AssignUnitsToBlService {

  private baseURL = environment.apiURL;

  private headers = new HttpHeaders({ 'Content-Type': 'application/json','Accept': 'application/json' });

  constructor(private http: HttpClient, private errorHandler: ConfigErrorHandler) { }

  /**
   * Get units pending BL assignment
   * @returns Array
   */
  getUnitsPendingBLAssignment(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/suppliers/assign-units-to-bl`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get active & incomplete bills of lading
   */
  getActiveBLs(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/suppliers/get-active-bls`;
    
    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Assign units to BL
   * @param bl_no 
   * @param selectedVehicles 
   * @returns 
   */
  assignUnitsToBL(bl_no: string, selectedVehicles: any[]): Observable<any> {

    let apiURL:string=`${this.baseURL}/v1/suppliers/assign-units-to-bl`;

    const data = {
      bl_no: bl_no,
      selected_vehicles: selectedVehicles
    };

    return this.http.post<any>(apiURL, data);
  }

  /**
   * Unassign unit(s)
   * @param bl_id
   * @param bl_no
   * @param selectedVehicles 
   * @returns 
   */
  unassignUnit(bl_id: string,bl_no:string,selectedVehicles: any[]): Observable<any> {

    let apiURL:string=`${this.baseURL}/v1/suppliers/unassign-units`;

    const data = {
      bl_id: bl_id,
      bl_no: bl_no,
      selected_vehicles: selectedVehicles
    };

    return this.http.post<any>(apiURL, data);
  }

  /**
   * Delete Unit
   * @param vehicleId 
   * @returns array
   */
  deleteUnit(vehicleId: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/suppliers/delete-unit/${vehicleId}`;

    return this.http.delete(apiURL, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
