import { Component, OnInit } from '@angular/core';
import { PortChargesService } from 'src/app/services/payments/port-charges.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-port-charges-invoices',
  templateUrl: './port-charges-invoices.component.html',
  styleUrls: ['./port-charges-invoices.component.css']
})
export class PortChargesInvoicesComponent implements OnInit{
  public userPermissions: string[] = [];
  invoices: any[] = [];
  public isLoading: boolean = false;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public isProcessing: boolean = false;
  searchText: string = '';
  page: number = 1;
  count: number = 0;

  constructor(
    private portChargesService:PortChargesService,
    private authService: AuthService,
  ){}

  ngOnInit(): void {
    this.getInvoices();
    this.userPermissions=this.authService.getPermissions();
  }

  getInvoices(): void {
    this.portChargesService.getOpenInvoices()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.isLoading = false;
          this.invoices = response.data
        }else{
          this.isLoading = false;
          this.errorMessage=response.message;
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.errorMessage=error;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.getInvoices();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  deleteInvoice(invoice_no:string,invoice_id:string){
    let prompt = "Are you sure you want to delete invoice with invoice number "+invoice_no;
    if (confirm(prompt) == true) {
      this.isProcessing=true;

      this.portChargesService.deleteInvoice(invoice_id,invoice_no)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.isProcessing=false;
            this.successMessage=response.message;
            this.errorMessage=''; //Clear error message incase it's available
           
            //Reload invoices
            this.getInvoices();
          }else if(response.status==='error'){
            this.isProcessing=false;
            this.errorMessage=response.message;
          }else{
            this.isProcessing=false;
            this.errorMessage=response.message;
          }
        },
        error: (error) => {
          this.isProcessing=false;
          this.errorMessage=error;
        }
      });
    }
  }
}
