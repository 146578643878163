import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchColour'
})
export class SearchColourPipe implements PipeTransform {

  transform(colours: any, searchText: string){
    
    if (!searchText) {
      return colours; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return colours.filter((colours: any) => colours.colour_name.toLowerCase().includes(searchText));
  }
}
