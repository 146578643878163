<!--Loading Spinner-->
<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
           
            <div class="col-sm-6">
              <a routerLink="/integrations/create-app" class="btn btn-success pull-left" class="btn btn-success"><i
                class="fa fa-plus-circle" aria-hidden="true">&nbsp;</i>New App</a>
            </div><!-- /.col -->
           
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item active">Manage Apps</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
</section>
<!-- /.content-header -->
<section class="content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Manage Apps</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <!--Error Message-->
                <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                  {{errorMessage}}
                </div>
                <!--Success Message-->
                <div class="alert alert-success" role="alert" *ngIf="successMessage">
                  {{successMessage}}
                </div>
                <table id="example1" class="table table-bordered table-hover">
                    <thead>
                    <tr>
                        <th>App Name</th>
                        <th>Created On</th>
                        <th>Updated On</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let app of apps | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                            <td>{{app.name}}</td>
                            <td>{{app.created_at | date: 'dd-MM-yyyy'}}</td>
                            <td>{{app.updated_at | date: 'dd-MM-yyyy'}}</td>
                            <td>
                                <div class="dropdown">
                                    <button class="btn btn-default dropdown-toggle" type="button"
                                        data-toggle="dropdown">Action
                                        <span class="caret"></span></button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" routerLink="/integrations/access-tokens/{{app.id}}"><i class="fa fa-satellite-dish"></i>&nbsp;Access Tokens</a></li>
                                        <li><a class="dropdown-item" href="#" (click)="deleteApp($event,app.id)"><i class="fa fa-trash"></i>&nbsp;Delete</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
            </div>
            <!-- /.card-body -->
        </div>
    </div>
</section>