<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
           
            </div><!-- /.col -->
           
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/view-vehicle-reservations/{{vehicle_id}}"><i class="fas fa-angle-double-left"></i>&nbsp;Back</a></li>
                <li class="breadcrumb-item active">View Commitment Fee Payments</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    </section>
    <!-- /.content-header -->
    <section class="content">
      <div class="container-fluid">
      <div class="card">
          <div class="card-header">
            <h3 class="card-title">View Commitment Fee Payments</h3>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <!--Error Message-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
            </div>
            <!--Success Message-->
            <div class="alert alert-success" role="alert" *ngIf="successMessage">
            {{successMessage}}
            </div>
            <table id="example1" class="table table-bordered table-hover">
              <thead>
                <tr>
                    <th>Date</th>
                    <th>Amount Paid</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let payment of payments">
                    <td>{{payment.pmt_date | date: 'dd-MM-yyyy'}}</td>
                    <td>{{payment.amount| currency:'Kshs '}}</td>
                  </tr>
              </tbody>
            </table>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </section>