import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';

@Injectable({
    providedIn: 'root'
  })
  export class PermissionGuard implements CanActivate {
  
    constructor(private permissionsService: PermissionsService, private router: Router) {}
  
    canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  
      const permissions = next.data['permission'] as string[];

      if (permissions) {
        // Check if any of the permissions are granted
        const hasPermission = this.permissionsService.hasAnyPermission(permissions);
        if (hasPermission) {
          return true; // User has at least one of the required permissions
        } else {
          // User doesn't have permission, redirect to unauthorized
          return this.router.parseUrl('/unauthorized');
        }
      } else {
       // User doesn't have permission, redirect to unauthorized
       return this.router.parseUrl('/unauthorized');
      }
    }
  }