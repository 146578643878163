import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';
import { IBillOfLading } from 'src/app/models/IBillOfLading';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Injectable({
  providedIn: 'root'
})
export class BillsOfLadingService {

  private baseURL = environment.apiURL;

  private headers = new HttpHeaders({ 'Content-Type': 'application/json','Accept': 'application/json' });

  constructor(private httpClient: HttpClient, private errorHandler: ConfigErrorHandler) { }

  /**
   * Get all bills of lading
   */
  getAllBLs(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/billsoflading`;
    
    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Create new Bill of lading
   * @param billOfLanding 
   * @returns 
   */
  public createBL(billOfLanding: any,attachment:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/billsoflading`;

    const formData = new FormData();
    formData.append("bl_no", billOfLanding.bl_no);
    formData.append("no_of_units", billOfLanding.no_of_units);
    formData.append("vessel", billOfLanding.vessel);
    formData.append("consignee_id", billOfLanding.consignee_id);
    formData.append("cfs_id", billOfLanding.cfs_id);
    formData.append("bl_date", billOfLanding.bl_date);
    formData.append("attachment", attachment);

    return this.httpClient.post(apiURL, formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Edit BL
   * @param bl_data 
   * @param bl_id 
   * @returns array
   */
  editBL(bl_data:any,bl_id: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/billsoflading/${bl_id}`;

    return this.httpClient.put(apiURL,bl_data, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

/**
 * Get single BL for editing
 * @param bl_id 
 */
  getBL(bl_id: string):Observable<IBillOfLading>{

    let apiURL:string=`${this.baseURL}/v1/billsoflading/${bl_id}/edit`;

    return this.httpClient.get<IBillOfLading>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

/**
 * View BL
 * @param bl_id 
 */
  viewBL(bl_id: string):Observable<IBillOfLading>{

    let apiURL:string=`${this.baseURL}/v1/billsoflading/${bl_id}`;

    return this.httpClient.get<IBillOfLading>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

/**
 * Get D.O & Payment status
 * @param bl_id 
 */
  getDO_RadiationPaymentStatus(bl_id: string):Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-do-rad-pmt-status/${bl_id}`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

   /**
   * Delete BL
   * @param bl_id 
   * @param bl_no 
   * @returns array
   */
   deleteBL(bl_id: string|null,bl_no: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/billsoflading/${bl_id}/${bl_no}`;

    return this.httpClient.delete(apiURL, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }


  /**
   * Assign BL(s) to agent
   * @param agentId 
   * @param selectedBLs 
   * @returns 
   */
  assignBLstoAgent(agentId: string, selectedBLs: any[]): Observable<any> {

    let apiURL:string=`${this.baseURL}/v1/assign-bl-to-agent`;

    const data = {
      agentId: agentId,
      selectedBLs: selectedBLs
    };

    return this.httpClient.post<any>(apiURL, data);
  }

/**
 * Get vehicles associated with a partcular BL
 * @param bl_no 
 */
  getVehicles(bl_no: string):Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/vehicles-by-bl/${bl_no}`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
 * Get vehicles associated with a partcular BL
 * for printing
 * @param bl_no 
 */
  getVehiclesForPrinting(bl_no: string):Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/print-bl/get-vehicles-by-bl/${bl_no}`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Update BL (Adding vehicle details)
   * @param vehicle_data 
   * @param bl_id 
   * @returns array
   */
  updateBL(vehicle_data:any,jevic_attachment:any,logbook_attachment: any,logbook_trans_attachment: any,vehicle_photos:any,other_documents:any,bl_id: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/update-bl/${bl_id}`;

    const formData = new FormData();
    formData.append("make_id", vehicle_data.make_id);
    formData.append("model_id", vehicle_data.model_id);
    formData.append("grade_id", vehicle_data.grade_id);
    formData.append("chassis_no", vehicle_data.chassis_no);
    formData.append("colour", vehicle_data.colour);
    formData.append("yr_of_mfg", vehicle_data.yr_of_mfg);
    formData.append("engine_cc", vehicle_data.engine_cc);
    formData.append("drive", vehicle_data.drive);
    formData.append("fuel", vehicle_data.fuel);
    formData.append("mileage", vehicle_data.mileage);
    formData.append("engine_no", vehicle_data.engine_no);
    formData.append("transmission", vehicle_data.transmission);
    formData.append("body_type", vehicle_data.body_type);
    formData.append("weight", vehicle_data.weight);
    formData.append("cbm", vehicle_data.cbm);
    formData.append("reg_no", vehicle_data.reg_no);
    formData.append("bl_no", vehicle_data.bl_no);
    formData.append("branch_id", vehicle_data.branch_id);
    formData.append("condition", vehicle_data.condition);
    formData.append("no_of_doors", vehicle_data.no_of_doors);
    formData.append("no_of_seats", vehicle_data.no_of_seats);
    formData.append("jevic_attachment", jevic_attachment);
    formData.append("logbook_attachment", logbook_attachment);
    formData.append("logbook_trans_attachment", logbook_trans_attachment);

    other_documents.forEach((file: string | Blob) => {
      formData.append('other_documents[]', file);
    });

    vehicle_photos.forEach((file: string | Blob) => {
      formData.append('vehicle_photos[]', file);
    });

    return this.httpClient.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get BLs pending Vessel ETA update
   */
  getBLPendingVesselETA_Update(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-bls-pending-vessel-eta-update`;
    
    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Update Vessel ETA
   * @param bl_id
   * @param vessel_eta
   * @returns 
   */
  updateVesselETA(bl_id:string, vessel_eta: any[]): Observable<any> {

    let apiURL:string=`${this.baseURL}/v1/update-vessel-eta`;

    const data = {
      bl_id:bl_id,
      vessel_eta: vessel_eta
    };

    return this.httpClient.post<any>(apiURL, data);
  }

  /**
   * Get total units in all uncleared bLs
   */
  getUnclearedTotalUnits():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/incomplete-bls/get-total-units`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

   /**
   * Get total units added in all uncleared bLs
   */
   getUnclearedTotalUnitsAdded():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/incomplete-bls/get-total-units-added`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Get total units in all cleared bLs
   */
  getClearedTotalUnits():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/complete-bls/get-total-units`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Get total units added in all cleared bLs
   */
  getClearedTotalUnitsAdded():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/complete-bls/get-total-units-added`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Get completed bills of lading
   */
  getCompletedBLs(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-completed-bls`;
    
    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Upload attachment
   */
  uploadAttachment(attachment:any,bl_no: string):Observable<any>{
    const formData = new FormData();
    formData.append("bl_no", bl_no);
    formData.append("attachment", attachment);
   
    let apiURL:string=`${this.baseURL}/v1/upload-bl-attachment`;

    return this.httpClient.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Remove unit drom BL
   * @param bl_id
   * @param bl_no
   * @returns 
   */
  removeUnit(bl_id: string,bl_no:string,vehicle_id:string): Observable<any> {

    let apiURL:string=`${this.baseURL}/v1/remove-unit-from-bl`;

    const data = {
      bl_id: bl_id,
      bl_no: bl_no,
      vehicle_id:vehicle_id
    };

    return this.httpClient.post<any>(apiURL, data);
  }
}
