import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { ConsigneeService } from 'src/app/services/consignees/consignee.service';

@Component({
  selector: 'app-add-consignee',
  templateUrl: './add-consignee.component.html',
  styleUrls: ['./add-consignee.component.css']
})
export class AddConsigneeComponent {
  form!: FormGroup;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  isProcessing: boolean = false;
  submitted: boolean = false;

  constructor(
    private consigneeService:ConsigneeService,
    private formBuilder: FormBuilder,
    private router: Router,
    private globalService: GlobalService,
  ){
    this.form = this.formBuilder.group({
      consignee_name: ['', Validators.required],
    });
  }

  handleSubmit():void{
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      const formData = {
        ...this.form.value,
      };

      this.consigneeService.saveConsignee(formData).subscribe(response => {
        if(response.status==='success'){

          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalSuccessMessage(response.message);
         
          // Redirect back to makes list
          this.router.navigate(['settings/consignees-list']);
        }else if(response.status==='error'){
          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalErrorMessage(response.message);
        }else{
          this.submitted=false;
          this.isProcessing = false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
        }
      },
      error => {
        this.isProcessing = false;
        this.errorMessage=error;
      });
    }else{
      this.isProcessing = false;
    }
  }

}
