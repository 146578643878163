import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchPortChargesInvoices'
})
export class SearchPortChargesInvoicesPipe implements PipeTransform {

  transform(invoiceData: any, searchText: string) {
    if (!searchText) {
      return invoiceData; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return invoiceData.filter((item: any) =>
      (item.bl_no && item.bl_no.toLowerCase().includes(searchText)) ||
      (item.agent && item.agent.toLowerCase().includes(searchText)) ||
      (item.invoice_no && item.invoice_no.toLowerCase().includes(searchText))
    );
  }

}
