 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Delete Additional Duty</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a href="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/transactions/delete-entry"><i class="fa fa-list-ul"></i>&nbsp;Delete Entry</a></li>
               <li class="breadcrumb-item active">Delete Additional Duty</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
    </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
         <h3 class="card-title">Delete Additional Duty</h3>
         <!--Error messages-->
         <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
          </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
           <form>
               <div class="row clearfix">
                    <div class="col-md-4">
                       <div class="form-group">
                           <label>Make:</label>
                           <input type="text" name="make" class="form-control" placeholder="Make" value="{{ entry?.data?.make }}" readonly>
                       </div>
                    </div>
                    <div class="col-md-4">
                       <div class="form-group">
                           <label>Model:</label>
                           <input type="text" name="model" class="form-control" placeholder="Model" value="{{ entry?.data?.model }}" readonly>
                       </div>
                    </div>
                    <div class="col-md-4">
                       <div class="form-group">
                           <label>Chassis No.</label>
                           <input type="text" name="chassis_no" class="form-control" placeholder="Chassis No." value="{{entry?.data?.chassis_no}}" readonly>
                        </div>
                    </div>
               </div>
               <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Colour:</label>
                            <input type="text" name="colour" class="form-control" placeholder="Colour" value="{{ entry?.data?.colour }}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Entry No.:</label>
                            <input type="text" name="entry_no" class="form-control" placeholder="Entry No." value="{{entry?.data?.entry_no}}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Entry Date:</label>
                            <input type="text" name="entry_date" class="form-control" placeholder="Entry Date" value="{{ entry?.data?.entry_date | date:'dd-MM-yyyy' }}" readonly>
                        </div>
                    </div>
                </div>
               
           </form>

           <table id="example1" class="table table-bordered table-hover">
            <thead>
                <tr>
                    <th>Document Ref No.</th>
                    <th>Ref. Number</th>
                    <th>Duty Amount</th>
                    <th>Payment Type</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let duty of duties">
                    <td>{{ duty.doc_ref_no }}</td>
                    <td>{{ duty.pmt_ref_no }}</td>
                    <td>{{ duty.amount_paid | currency:' ' }}</td>
                    <td>{{ duty.pmt_type }}</td>
                    <td  *ngIf="duty.bank_acc_id === null">
                        <a (click)="deleteAdditionalDuty(duty.txn_id,duty.doc_ref_no,duty.pmt_type)" class="btn btn-danger btn-sm" role="button" aria-pressed="true"><i
                        class="fa fa-trash" aria-hidden="true">&nbsp;</i>Delete</a>
                    </td>
                    <td *ngIf="duty.bank_acc_id !== null"></td>
                </tr>
            </tbody>
          </table>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>