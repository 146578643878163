<!--Loading Spinner-->
<div *ngIf="isInProgress">
    <app-spinner></app-spinner>
  </div>
  
  <!-- Content Header (Page header) -->
  <section class="content-header">
      <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
             
              <div class="col-sm-6">
               
              </div><!-- /.col -->
             
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                  <li class="breadcrumb-item active">Stock En route</li>
                </ol>
              </div><!-- /.col -->
            </div><!-- /.row -->
          </div><!-- /.container-fluid -->
      </div><!-- /.content-header -->
      <div class="row">
        <div class="col-md-8">
            <div class="row">
               
            </div>
        </div>
        <div class="col-md-4">
          <div class="row">
              <div class="col">
                  <input type="text" class="form-control" placeholder="Type here to search..." (input)="onSearchTextChange($event)">
              </div>
          </div>
        </div>
      </div>
      </section>
      <section class="content">
        <div class="container-fluid">
        <div class="card">
            <div class="card-header">
              <h3 class="card-title">Stock En route &nbsp;&nbsp;&nbsp;Count: {{rowCount}}</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body" style="overflow-x: auto; overflow-y: auto; white-space: nowrap; height: 650px;">
                <!--Error Message-->
                <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                  {{errorMessage}}
                </div>
              <table id="example1" class="table table-bordered table-hover table-striped">
                <thead>
                  <tr>
                      <th>BL No.</th>
                      <th>Make</th>
                      <th>Model</th>
                      <th>Chassis No.</th>
                      <th>Engine CC</th>
                      <th>Engine No.</th>
                      <th>Transmission</th>
                      <th>Fuel</th>
                      <th>Colour</th>
                      <th>Month Year</th>
                      <th>Body Type</th>
                      <th>Mileage</th>
                      <th>Vessel</th>
                      <th>CFS</th>
                      <th>Agency</th>
                      <th>Company</th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let vehicle of vehicles | searchStock: searchText">
                      <td>{{vehicle.bl_no}}</td>
                      <td>{{vehicle.make}}</td>
                      <td>{{vehicle.model}}</td>
                      <td><a routerLink="/view-vehicle/{{vehicle.vehicle_id}}">{{vehicle.chassis_no}}</a></td>
                      <td>{{vehicle.engine_cc}}</td>
                      <td>{{vehicle.engine_no}}</td>
                      <td>{{vehicle.transm}}</td>
                      <td>{{vehicle.fuel}}</td>
                      <td>{{vehicle.colour}}</td>
                      <td>{{vehicle.month_yr}}</td>
                      <td>{{vehicle.body_type}}</td>
                      <td>{{vehicle.mileage}}</td>
                      <td>{{vehicle.vessel}}</td>
                      <td>{{vehicle.cfs_name}}</td>
                      <td>{{vehicle.agency}}</td>
                      <td>{{vehicle.company}}</td>
                    </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </section>