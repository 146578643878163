<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>

<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">

            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/view-refunded-commitment-fees"><i class="nav-icon fas fa-angle-double-left"></i>&nbsp;Back</a></li>
                <li class="breadcrumb-item active">View Refund Attachment(s)</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
</section>
<!-- /.content-header -->
<section class="content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">View Refund Attachment(s)</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <!--Error messages here-->
                <table id="example1" class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>File Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let attachment of attachments">
                            <td>{{attachment.filename}}</td>
                            <td *ngIf="attachment.file_type==='image'"><a routerLink="/preview-attachment/{{attachment.id}}/{{attachment.refund_id}}" class="btn btn-default btn-sm" role="button">Preview</a></td>
                            <td *ngIf="attachment.file_type==='pdf'"><a target="_blank" href="{{attachment.file_path}}" class="btn btn-default btn-sm" role="button">Preview</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- /.card-body -->
        </div>
    </div>
</section>