 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Add Entry</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a href="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/imported-vehicles"><i class="fa fa-list-ul"></i>&nbsp;Imported Cars</a></li>
               <li class="breadcrumb-item active">Add Entry</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
    </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
         <h3 class="card-title">Add Entry</h3>
         <!--Error messages-->
         <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            <span [innerHTML]="errorMessage"></span>
          </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
           <form [formGroup]="form" (ngSubmit)="handleSubmit()">
               <div class="row clearfix">
                    <div class="col-md-4">
                       <div class="form-group">
                           <label>Make:</label>
                           <input type="text" name="make" class="form-control" placeholder="Make" value="{{ vehicle?.data?.make }}" readonly>
                       </div>
                    </div>
                    <div class="col-md-4">
                       <div class="form-group">
                           <label>Model:</label>
                           <input type="text" name="model" class="form-control" placeholder="Model" value="{{ vehicle?.data?.model }}" readonly>
                       </div>
                    </div>
                    <div class="col-md-4">
                       <div class="form-group">
                           <label>Chassis No.</label>
                           <input type="text" formControlName="chassis_no" class="form-control" placeholder="Chassis No." [(ngModel)]="vehicle.data.chassis_no" readonly>

                           <span *ngIf="submitted && form.get('chassis_no')?.hasError('required')" style="color:red"> <strong>Chasis No. is required!</strong></span>
                        </div>
                    </div>
               </div>
               <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Colour:</label>
                            <input type="text" name="colour" class="form-control" placeholder="Colour" value="{{ vehicle?.data?.colour }}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Entry No.:<span style="color:red;">*</span></label>
                            <input type="text"  formControlName="entry_no" class="form-control" placeholder="Entry No." value="" [(ngModel)]="entryNo">

                            <span *ngIf="submitted && form.get('entry_no')?.hasError('required')" style="color:red"> <strong>Please enter entry number!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Entry Date:<span style="color:red;">*</span></label>
                            <input type="date" formControlName="entry_date" class="form-control" placeholder="Entry Date" value="">

                            <span *ngIf="submitted && form.get('entry_date')?.hasError('required')" style="color:red"> <strong>Please enter or select entry date!</strong></span>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Duty Amount:<span style="color:red;">*</span></label>
                            <input type="text" formControlName="duty_amount"  class="form-control" placeholder="Duty Amount" value="">

                            <span *ngIf="submitted && form.get('duty_amount')?.hasError('required')" style="color:red"> <strong>Please enter duty amount!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Attach Entry (PDF Only):<span style="color:red;">*</span></label>
                            <div class="input-group mb-3">
                                <div class="custom-file">
                                  <input type="file" formControlName="entry_attachment" accept="application/pdf" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onEntryFileSelected($event)">
                                  <label class="custom-file-label" for="inputGroupFile01">{{ entryFileName }}</label>
                                </div>
                            </div>

                            <span *ngIf="submitted && form.get('entry_attachment')?.hasError('required')" style="color:red"> <strong>Please attach Entry!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Attach Payment Slip (PDF Only):<span style="color:red;">*</span></label>
                            <div class="input-group mb-3">
                                <div class="custom-file">
                                  <input type="file" formControlName="pmt_slip_attachment" accept="application/pdf" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onPaymentSlipFileSelected($event)">
                                  <label class="custom-file-label" for="inputGroupFile01">{{ pmtSlipFileName }}</label>
                                </div>
                            </div>

                            <span *ngIf="submitted && form.get('pmt_slip_attachment')?.hasError('required')" style="color:red"> <strong>Please attach Payment Slip!</strong></span>
                        </div>
                    </div>
                </div>
                <div class="separator">Payment Details</div>
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Document Reference:<span style="color:red;">*</span></label>
                            <input type="text" formControlName="doc_ref_no" [(ngModel)]="docRefNo" class="form-control" placeholder="Document Reference Number" value="" (click)="copyEntryNo()">

                            <span *ngIf="submitted && form.get('doc_ref_no')?.hasError('required')" style="color:red"> <strong>Please enter Document Reference Number!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Payment Ref. Number:<span style="color:red;">*</span></label>
                            <input type="text" formControlName="payment_ref_no" class="form-control" placeholder="Payment Reference Number" value="">

                            <span *ngIf="submitted && form.get('payment_ref_no')?.hasError('required')" style="color:red"> <strong>Please enter Payment Reference Number!</strong></span>
                        </div>
                    </div>
                </div>

                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>
            
                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Save</button>
                </ng-template>
           </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>