<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>New CFS</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/settings/cfs-list"><i class="fa fa-list-ul"></i>&nbsp;View CFSes</a></li>
               <li class="breadcrumb-item active">New CFS</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
          <h3 class="card-title">New CFS</h3>
          <!--Error Message-->
          <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            <span [innerHTML]="errorMessage"></span>
          </div>
          <!--Success Message-->
          <div class="alert alert-success" role="alert" *ngIf="successMessage">
            {{successMessage}}
          </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
           <form [formGroup]="form" (ngSubmit)="handleSubmit()">
                <div class="row clearfix">
                   <div class="col-md-4">
                       <div class="form-group">
                           <label>Company Name:<span style="color:red;">*</span></label>
                           <input type="text" formControlName="name" class="form-control" placeholder="Company Name" value="">
                           <span *ngIf="submitted && form.get('name')?.hasError('required')" style="color:red"> <strong>This field is required!</strong></span>
                       </div>
                   </div>
                   <div class="col-md-4">
                    <div class="form-group">
                        <label>Phone Number:<span style="color:red;">*</span></label>
                        <input type="text" formControlName="phone_no" class="form-control" placeholder="Phone Number" value="">
                        <span *ngIf="submitted && form.get('phone_no')?.hasError('required')" style="color:red"> <strong>This field is required!</strong></span>
                    </div>
                </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Email:<span style="color:red;">*</span></label>
                            <input type="text" formControlName="email" class="form-control" placeholder="Email" value="">
                            <span *ngIf="submitted && form.get('email')?.hasError('required')" style="color:red"> <strong>This field is required!</strong></span>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                   <div class="col-md-4">
                       <div class="form-group">
                           <label>Location:</label>
                           <input type="text" formControlName="location" class="form-control" placeholder="Location" value="">
                       </div>
                   </div>
                   <div class="col-md-4">
                       <div class="form-group">
                           <label>Contact Person:</label>
                           <input type="text" formControlName="contact_person" class="form-control" placeholder="Contact Person" value="">
                       </div>
                   </div>
                </div>

                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>
            
                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Save</button>
                </ng-template>
           </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>