import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';
import { ApiResponse } from 'src/app/models/IApiResponse'

@Injectable({
  providedIn: 'root'
})
export class DeliveryNotesService {

  private baseURL = environment.apiURL;

  private headers = new HttpHeaders({ 'Content-Type': 'application/json','Accept': 'application/json' });

  constructor(private httpClient: HttpClient, private errorHandler: ConfigErrorHandler) { }

  /**
   * View delivery notes
   * @returns Array
   */
  getDeliveryNotes(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/delivery-notes`;

    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get delivery note info
   * @param delivery_id 
   */
  getDeliveryInfo(delivery_uuid: string){

    let apiURL:string=`${this.baseURL}/v1/delivery-note-info/${delivery_uuid}`;

    return this.httpClient.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Get delivery note items
   * @param delivery_id 
   * @returns Array
   */
   getDeliveryNoteItems(delivery_uuid: string): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/delivery-note-items/${delivery_uuid}`;

    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get customer info
   * @param delivery_id 
   */
  getCustomerInfo(id: string){

    let apiURL:string=`${this.baseURL}/v1/delivery-note/get-customer-info/${id}`;

    return this.httpClient.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Edit customer info
   * @param customerName
   * @param customerAddress
   * @returns array
   */
  updateCustomerInfo(customerName:string,customerAddress:string,id: string):Observable<any>{
  
    const formData = {
      customer_name: customerName,
      customer_address: customerAddress
    };

    let apiURL:string=`${this.baseURL}/v1/delivery-note/update-customer-info/${id}`;

    return this.httpClient.put(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
