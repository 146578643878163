 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Enter Radiation & IDF Payments</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/do-rad-payment/select-bl"><i class="fa fa-list-ul"></i>&nbsp;Select BL</a></li>
               <li class="breadcrumb-item active">Enter Radiation & IDF Payments</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
            <h3 class="card-title">Enter Radiation & IDF Payments</h3>
            <!--Error message-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
            </div>
            <!--Warning message-->
            <div class="alert alert-warning" role="alert" *ngIf="warningMessage">
                {{warningMessage}}
            </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
           <form [formGroup]="paymentForm" (ngSubmit)="handleSubmit()">
               <div class="row clearfix">
                   <div class="col-md-4">
                       <div class="form-group">
                           <label>BL No.:</label>
                           <input #bl_no type="text" formControlName="bl_no" class="form-control" placeholder="BL No." [value]="this.billOfLading.data.bl_no" readonly>
                       </div>
                   </div>
                   <div class="col-md-4">
                       <div class="form-group">
                           <label>Consignee:</label>
                           <input type="text" name="consignee_name" class="form-control" placeholder="Consignee" [value]="this.billOfLading.data.consignee_name" readonly>
                       </div>
                   </div>
                   <div class="col-md-4">
                       <div class="form-group">
                           <label>No. of Units:</label>
                           <input type="text" #no_of_units name="no_of_units" class="form-control" placeholder="No. of Units" [value]="this.billOfLading.data.no_of_units" readonly>
                       </div>
                   </div>
               </div>
               <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Agent:</label>
                            <input type="text" name="agent_name" class="form-control" placeholder="Agent" [value]="this.billOfLading.data.agent_name" readonly>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <input type="hidden" #rad_pmt_status name="rad_pmt_status" [value]="this.billOfLading.data.do_rad_paid">
                </div>
                <table id="example1" class="table table-bordered table-hover">
                    <thead>
                        <ng-container *ngIf="this.billOfLading.data.do_rad_paid==0">
                            <tr>
                                <th>Make</th>
                                <th>Model</th>
                                <th>Chassis No.</th>
                                <th>D.O Amount Paid</th>
                                <th>
                                    Radiation Amount Paid&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="checkbox" [formControl]="applyToAllRadControl">&nbsp;&nbsp;<i>Apply to all</i>
                                </th>
                                <th>
                                    IDF Amount Paid&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="checkbox" [formControl]="applyToAllIdfControl">&nbsp;&nbsp;<i>Apply to all</i>
                                </th>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="this.billOfLading.data.do_rad_paid==2;">
                            <tr>
                                <th>Make</th>
                                <th>Model</th>
                                <th>Chassis No.</th>
                                <th>Radiation Amount Paid</th>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="this.billOfLading.data.do_rad_paid==3">
                            <tr>
                                <th>Make</th>
                                <th>Model</th>
                                <th>Chassis No.</th>
                                <th>IDF Amount Paid</th>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="this.billOfLading.data.do_rad_paid==4">
                            <tr>
                                <th>Make</th>
                                <th>Model</th>
                                <th>Chassis No.</th>
                                <th>Radiation Amount Paid</th>
                                <th>IDF Amount Paid</th>
                            </tr>
                        </ng-container>
                    </thead>
                    <tbody formArrayName="vehicles">
                        <ng-container *ngIf="this.billOfLading.data.do_rad_paid==0">
                            <tr *ngFor="let x of vehiclesArray.controls; let i = index;" [formGroupName]="i">
                                    <td hidden><input type="text" formControlName="vehicle_id"></td>
                                    <td>{{ x?.get('make')?.value  }}</td>
                                    <td>{{ x?.get('model')?.value  }}</td>
                                    <td><input type="text" formControlName="chassis_no" class="form-control" readonly></td>
                                    <td><input type="text" formControlName="do_amount" class="form-control" [(ngModel)]="doAmountPaidPerUnit" readonly></td>
                                    <td>
                                        <input type="text" formControlName="rad_amount" class="form-control" value="">
                                        <span *ngIf="submitted && paymentForm.get('rad_amount')?.hasError('required')" style="color:red"> <strong>Radiation amount paid is required!</strong></span>
                                    </td>
                                    <td>
                                        <input type="text" formControlName="idf_amount" class="form-control" value="">
                                        <span *ngIf="submitted && paymentForm.get('idf_amount')?.hasError('required')" style="color:red"> <strong>IDF amount paid is required!</strong></span>
                                    </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="this.billOfLading.data.do_rad_paid==2">
                            <tr *ngFor="let x of vehiclesArray.controls; let i = index;" [formGroupName]="i">
                                <td hidden><input type="text" formControlName="vehicle_id"></td>
                                <td>{{ x?.get('make')?.value  }}</td>
                                <td>{{ x?.get('model')?.value  }}</td>
                                <td><input type="text" formControlName="chassis_no" class="form-control" readonly></td>
                                <td>
                                    <input type="text" formControlName="rad_amount" class="form-control" value="">
                                    <span *ngIf="submitted && paymentForm.get('rad_amount')?.hasError('required')" style="color:red"> <strong>Radiation amount paid is required!</strong></span>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="this.billOfLading.data.do_rad_paid==3">
                            <tr *ngFor="let x of vehiclesArray.controls; let i = index;" [formGroupName]="i">
                                <td hidden><input type="text" formControlName="vehicle_id"></td>
                                <td>{{ x?.get('make')?.value  }}</td>
                                <td>{{ x?.get('model')?.value  }}</td>
                                <td><input type="text" formControlName="chassis_no" class="form-control" readonly></td>
                                <td>
                                    <input type="text" formControlName="idf_amount" class="form-control" value="">
                                    <span *ngIf="submitted && paymentForm.get('idf_amount')?.hasError('required')" style="color:red"> <strong>IDF amount paid is required!</strong></span>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="this.billOfLading.data.do_rad_paid==4">
                            <tr *ngFor="let x of vehiclesArray.controls; let i = index;" [formGroupName]="i">
                                <td hidden><input type="text" formControlName="vehicle_id"></td>
                                <td>{{ x?.get('make')?.value  }}</td>
                                <td>{{ x?.get('model')?.value  }}</td>
                                <td><input type="text" formControlName="chassis_no" class="form-control" readonly></td>
                                <td>
                                    <input type="text" formControlName="rad_amount" class="form-control" value="" *ngIf="x?.get('rad_paid')?.value==0">
                                    <span *ngIf="submitted && paymentForm.get('rad_amount')?.hasError('required')" style="color:red"> <strong>Radiation amount paid is required!</strong></span>
                                </td>
                                <td>
                                    <input type="text" formControlName="idf_amount" class="form-control" value="" *ngIf="x?.get('idf_paid')?.value==0">
                                    <span *ngIf="submitted && paymentForm.get('idf_amount')?.hasError('required')" style="color:red"> <strong>IDF amount paid is required!</strong></span>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>

                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Save</button>
                </ng-template>
           </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>