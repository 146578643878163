<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
           
            </div><!-- /.col -->
           
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/view-vehicle-reservations/{{vehicle_id}}"><i class="fas fa-angle-double-left"></i>&nbsp;Back</a></li>
                <li class="breadcrumb-item active">Add Commitment Fee Payment</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    </section>
    <!-- /.content-header -->
    <section class="content">
      <div class="container-fluid">
      <div class="card">
          <div class="card-header">
            <h3 class="card-title">Add Commitment Fee Payment</h3>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <!--Error Message-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
            </div>
            <!--Success Message-->
            <div class="alert alert-success" role="alert" *ngIf="successMessage">
            {{successMessage}}
            </div>

            <form [formGroup]="form" (ngSubmit)="handleSubmit()">
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Amount:<span style="color:red;">*</span></label>
                            <input type="text" formControlName="amount_paid" class="form-control" value="" placeholder="Amount Paid">

                            <span *ngIf="submitted && form.get('amount_paid')?.hasError('required')" style="color:red"> <strong>Please select the type of deal!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Payment Date:<span style="color:red;">*</span></label>
                            <input type="date" formControlName="payment_date" class="form-control" value="" placeholder="Payment Date">

                            <span *ngIf="submitted && form.get('payment_date')?.hasError('required')" style="color:red"> <strong>Please select the type of deal!</strong></span>
                        </div>
                    </div>
                </div>

                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                    </button>
                    <ng-template #showSaveBtn>
                        <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Save</button>
                    </ng-template>
            </form>
            &nbsp;&nbsp;
            <table id="example1" class="table table-bordered table-hover">
              <thead>
                <tr>
                    <th>Date</th>
                    <th>Amount Paid</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let payment of payments">
                    <td>{{payment.pmt_date | date: 'dd-MM-yyyy'}}</td>
                    <td>{{payment.amount| currency:'Kshs '}}</td>
                  </tr>
              </tbody>
            </table>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </section>