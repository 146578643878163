<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
           
            <div class="col-sm-6"></div><!-- /.col -->
           
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item active">Paid Port Charges Invoices</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <div class="row">
      <div class="col-md-8"></div>
      <div class="col-md-4">
        <div class="row">
            <div class="col">
                <input type="text" class="form-control" placeholder="Type Invoice No., BL No. or Agent here to search..." (input)="onSearchTextChange($event)">
            </div>
        </div>
      </div>
    </div>
    </section>
      <!-- /.content-header -->
    <section class="content">
      <div class="container-fluid">
      <div class="card">
          <div class="card-header">
            <h3 class="card-title">Paid Port Charges Invoices</h3>
          </div>
          <!-- /.card-header -->
          <div class="card-body" style="overflow-x: auto; white-space: nowrap;">
               <!--Error messages here-->
            <table id="example1" class="table table-bordered table-hover">
              <thead>
                <tr>
                    <th>BL No.</th>
                    <th>No. Of Units</th>
                    <th>Consignee</th>
                    <th>Agent</th>
                    <th>Invoice No.</th>
                    <th>Amount USD</th>
                    <th>Amount KES</th>
                    <th></th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let invoice of invoices | searchPortChargesInvoices: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                        <td>{{invoice.bl_no}}</td>
                        <td>{{invoice.no_of_units}}</td>
                        <td>{{invoice.consignee}}</td>
                        <td>{{invoice.agent}}</td>
                        <td>{{invoice.invoice_no}}</td>
                        <td>{{invoice.amount_usd|currency:' '}}</td>
                        <td>{{invoice.amount_kes|currency:' '}}</td>
                        <td>
                            <div class="dropdown">
                                <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">Action<span class="caret"></span></button>
                                <ul class="dropdown-menu">
                                    <li *ngIf="userPermissions.includes('edit-port-chgs-inv')"><a class="dropdown-item" routerLink="/transactions/edit-port-charges-invoice/{{invoice.invoice_id}}">Edit</a></li>
                                    <li *ngIf="invoice.file_type==='image'"><a class="dropdown-item" routerLink="/preview-port-charges-invoice/{{invoice.invoice_id}}">View Attachment</a></li>
                                    <li *ngIf="invoice.file_type==='pdf'"><a class="dropdown-item" target="_blank" href="{{invoice.attachment}}">View Attachment</a></li>
                                </ul>
                            </div>
                        </td>
                  </tr>
              </tbody>
            </table>
            <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </section>