import { Component, OnInit } from '@angular/core';
import { BranchService } from 'src/app/services/branches/branch.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';

@Component({
  selector: 'app-print-stock-qr-codes',
  templateUrl: './print-stock-qr-codes.component.html',
  styleUrls: ['./print-stock-qr-codes.component.css']
})
export class PrintStockQrCodesComponent implements OnInit{
  public errorMessage: string | null = null;
  vehicles: any[] = [];
  public branch_id: string | null = null;
  public isLoading: boolean = false;

  constructor(
    private branchService: BranchService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
  ){}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.branch_id = param['get']('branch_id')
    })

    if(this.branch_id){
      this.isLoading=true;
      this.fetchVehicles(this.branch_id);
    }else{
      //Navigate back to list with error
      const error="Unable to select branch for QR Code printing!";
      this.globalService.setGlobalErrorMessage(error);
      this.router.navigate(['settings/branches-list']);
    }
  }

  fetchVehicles(branch_id: string): void {
    this.branchService.getChassisNumbersByBranch(branch_id)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicles = response.data;
          this.isLoading=false;
        }else if(response.status==='no_data'){
          this.isLoading=false;
           this.errorMessage=response.message;
        }else{
          this.isLoading=false;
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.errorMessage=error;
      }
    });
  }

  printPage(): void {
    window.print(); // Open the browser print preview dialog
  }
}
