import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/shared/global.service';
import { AgentService } from 'src/app/services/agents/agent.service';

@Component({
  selector: 'app-edit-agent',
  templateUrl: './edit-agent.component.html',
  styleUrls: ['./edit-agent.component.css']
})
export class EditAgentComponent implements OnInit{
  form!: FormGroup;
  public agentId: string | null = null;
  public agentData:any;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  isProcessing: boolean = false;
  submitted: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private globalService:GlobalService,
    private agentService:AgentService
  ){
    this.form = this.formBuilder.group({
      company_name: ['', Validators.required],
      phone_no: ['', Validators.required],
      email: ['', ],
      location: ['', ],
      contact_person: ['',Validators.required],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.agentId = param['get']('agent_id')
    })

    if(this.agentId){
      this.fetchAgentData(this.agentId);
    }else{
      //Navigate back to agents list with error
      this.globalService.setGlobalErrorMessage("Unable to select agent record for update!");
      this.router.navigate(['settings/agents-list']);
    }
  }

  /**
   * Get user data
   * @param userId 
   */
  fetchAgentData(agentId: string): void {
    this.agentService.getAgentData(agentId)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.agentData = response;
          if(this.agentData.status==='no_data' || this.agentData.status.length===0){
            this.errorMessage=this.agentData.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  handleSubmit():void{
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      const formData = {
        ...this.form.value,
      };

      this.agentService.updateAgent(formData,this.agentId).subscribe(response => {
        if(response.status==='success'){

          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalSuccessMessage(response.message);
         
          // Redirect back to agents list
          this.router.navigate(['settings/agents-list']);
        }else if(response.status==='error'){
          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalErrorMessage(response.message);
        }else{
          this.submitted=false;
          this.isProcessing = false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
        }
      },
      error => {
        this.isProcessing = false;
        this.errorMessage=error;
      });
    }else{
      this.isProcessing = false;
    }
  }
}
