import { Component } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  public username: string ="";
  public password: string ="";
  public error: string ="";
  public isProcessing: boolean = false;

  constructor(private auth: AuthService, private router: Router) { 
    //Navigate to dashboard if already logged in
    if(this.auth.isLoggedIn){
      this.router.navigate(['/']);
    }
   
    //Show errors if any
    let error_msg=sessionStorage.getItem('error');
    if(error_msg!==null){
      this.error=error_msg;
      sessionStorage.removeItem("error");
    }
  }

  public submit() {
    this.isProcessing = true;

    this.auth.login(this.username, this.password)
      .pipe(first())
      .subscribe({
        next: (response) => {
          if(response){
            window.location.href = '/';
          }else{
            this.error = 'Authentication failure!'
            this.isProcessing = false;
          }
        },
        error: (error) => {
          this.error = 'Authentication failure!'
          this.isProcessing = false;
        }
      });
  }
}
