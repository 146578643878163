import { Component, OnInit } from '@angular/core';
import { AgentService } from 'src/app/services/agents/agent.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-agents-list',
  templateUrl: './agents-list.component.html',
  styleUrls: ['./agents-list.component.css']
})
export class AgentsListComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  agents: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;

  constructor(
    private agentService:AgentService,
  ){}

  ngOnInit(): void {
    this.fetchAgents();
  }

  fetchAgents():void{
    this.agentService.getAllAgents().subscribe((response: ApiResponse) =>this.agents = response.data);
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fetchAgents();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  deleteAgent(event: Event,agentId:string): void{
    event.preventDefault();
  }

}
