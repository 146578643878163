import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { VehiclesService } from 'src/app/services/vehicles/vehicles.service';
import { GlobalService } from 'src/app/services/shared/global.service';

@Component({
  selector: 'app-add-other-charges',
  templateUrl: './add-other-charges.component.html',
  styleUrls: ['./add-other-charges.component.css']
})
export class AddOtherChargesComponent implements OnInit{
  public isLoading: boolean = false;
  isProcessing: boolean = false;
  disallowAddingRow: boolean = false;
  public vehicle_id: string | null = null;
  public errorMessage: string | null = null;
  form!: FormGroup;
  vehicle:any;
  submitted: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    private formBuilder: FormBuilder,
    private vehiclesService:VehiclesService,
    private globalService: GlobalService,
  ){
    this.form = this.formBuilder.group({
      payment_date: ['', Validators.required],
      payment_mode: ['', Validators.required],
      payment_ref: ['', Validators.required],
      payments: this.formBuilder.array([])
    });
  }

  ngOnInit(): void {
    this.isLoading=true;

    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.vehicle_id = param['get']('vehicle_id')
    })

    if(this.vehicle_id){
      this.getVehicleData(this.vehicle_id);
    }else{
      //Navigate back to vehicle list with error
      this.globalService.setGlobalErrorMessage("Unable to select vehicle, please try again!");
      this.router.navigate(['vehicles']);
    }

    // Add one default row
    this.addPaymentRow();
  }

  /**
   * Get single vehicle info
   * @param vehicle_id 
   */
  getVehicleData(vehicle_id: string): void {
    this.vehiclesService.getVehicle(vehicle_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.isLoading=false;
          this.vehicle = response;
          
          if(this.vehicle.status==='no_data' || this.vehicle.status.length===0){
            this.errorMessage=this.vehicle.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

   /**
   * Get payments FormArray
   */
  get payments(): FormArray {
    return this.form.get('payments') as FormArray;
  }

  /**
   * Add a new row to the payments table
   */
  addPaymentRow(): void {
    const paymentForm = this.formBuilder.group({
      txn_desc: ['', Validators.required],
      amount: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]]
    });
  
    this.payments.push(paymentForm);
  }

  /**
   * Remove a row from the payments table
   * @param index - index of the row to remove
   */
  removePaymentRow(index: number): void {
    this.payments.removeAt(index);
  }

  handleSubmit(): void{
    this.submitted = true;

    if (this.form.valid) {
      this.isProcessing = true;
      this.disallowAddingRow = true;

      const line_items=this.form.value.payments

      const payload = {
        vehicle_id: this.vehicle_id,
        payment_date:this.form.value.payment_date,
        payment_mode:this.form.value.payment_mode,
        payment_ref:this.form.value.payment_ref,
        line_items: line_items.map((lines_item:any) => ({
          txn_desc: lines_item.txn_desc,
          amount: lines_item.amount,
        }))
      };
    
      this.vehiclesService.addOtherCharges(payload).subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.isProcessing = false;
            this.globalService.setGlobalSuccessMessage(response.message);
            this.router.navigate(['/vehicles']);
          }else{
            this.errorMessage=response.message;
            this.isProcessing=false;
          }
        },
        error: (error) => {
          this.isProcessing = false;
          this.errorMessage = "Error: "+error.error.message;
        }
      });
    }
  }
}
