import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchConsignee'
})
export class SearchConsigneePipe implements PipeTransform {

  transform(consignees: any, searchText: string){
    
    if (!searchText) {
      return consignees; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return consignees.filter((consignees: any) => consignees.consignee_name.toLowerCase().includes(searchText));
  }

}
