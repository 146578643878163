 <!--Loading Spinner-->
<div *ngIf="isProcessing">
  <app-spinner></app-spinner>
</div>
 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Unpaid Duties</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item active">Unpaid Duties</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   <h5>Filters</h5>
   <hr>
  <div class="row">
    <div class="col-md-8">
        <div class="row">
          <div class="col">
            <div class="col-md-4">
              <form class="form-inline">
                <div class="form-group">
                    <label>Consignee:</label>&nbsp;
                    <select name="consignee_id"  class="form-control" (change)="onSelectChange($event.target)">
                        <option value="">All</option>
                        <option [value]="consignee.id" *ngFor="let consignee of consignees">{{ consignee.consignee_name }}</option>
                    </select>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label>Agent:</label>&nbsp;
                    <select name="agents" class="form-control" (change)="onAgentSelectChange($event.target)">
                      <option value="">All</option>
                      <option [value]="agent.id" *ngFor="let agent of agents">{{ agent.company_name }}</option>
                    </select>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div>
    <div class="col-md-4">
      <div class="row">
          <div class="col">
              <input type="text" class="form-control" placeholder="Search by Ref. Number, BL Number, Chassis Number or Doc Ref. Number" (input)="onSearchTextChange($event)">
          </div>
      </div>
    </div>
  </div>
    <hr>
    
  </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
      <h5>Payment</h5>
      <hr>
      <form [formGroup]="dutyPayment" (ngSubmit)="handleSubmit()">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
              <label>Amount to pay:</label>
              <input type="text" class="form-control" placeholder="Amount Paid" [(ngModel)]="amountToPay" [ngModelOptions]="{standalone: true}" readonly>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
              <label>Payment Date:<span style="color:red;">*</span></label>
              <input type="date" formControlName="payment_date" class="form-control" placeholder="Payment Date" value="">

              <span *ngIf="submitted && dutyPayment.get('payment_date')?.hasError('required')" style="color:red"> <strong>Please enter or select payment date!</strong></span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Bank:<span style="color:red;">*</span></label>
            <select formControlName="bank_account" class="form-control">
                <option value="">Select</option>
                <option [value]="bank_account.id" *ngFor="let bank_account of bank_accounts">{{ bank_account.bank_name }}</option>
            </select>

            <span *ngIf="submitted && dutyPayment.get('bank_account')?.hasError('required')" style="color:red"> <strong>Please select a bank account!</strong></span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group" style="margin-top: 30px;">
            <button type="submit" class="btn btn-outline-success"><i class="fas fa-money-check" aria-hidden="true">&nbsp;</i>Pay Selected</button>
          </div>
        </div>
      </div>
      <div class="card">
          <div class="card-header">
            <h3 class="card-title">Select duties to pay</h3>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <!--Error Message-->
            <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="errorMessage">
              <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
              {{errorMessage}}
            </div>
            <!--Warning Message-->
            <div class="alert alert-warning alert-dismissible" role="alert" *ngIf="warningMessage">
              <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
              {{warningMessage}}
            </div>
             <!--Success Message-->
             <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage">
              <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
              {{successMessage}}
            </div>
            <table id="example1" class="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th></th>
                        <th>Document Reference</th>
                        <th>Entry Date</th>
                        <th>BL No.</th>
                        <th>Make</th>
                        <th>Model</th>
                        <th>Chassis No.</th>
                        <th>Ref. Number</th>
                        <th>Duty Amount</th>
                        <th>Agent</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let duty of unpaid_duties | searchDutyPayment: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                        <td><input type="checkbox" (change)="checkboxChange($event, duty.txn_id,duty.amount_paid)" class="form-control"></td>
                        <td>{{ duty.doc_ref_no }}</td>
                        <td>{{ duty.entry_date| date: 'dd-MM-yyyy' }}</td>
                        <td>{{ duty.bl_no }}</td>
                        <td>{{ duty.make }}</td>
                        <td>{{ duty.model }}</td>
                        <td>{{ duty.chassis_no }}</td>
                        <td>{{ duty.pmt_ref_no }}</td>
                        <td>{{ duty.amount_paid| currency:'Kshs ' }}</td>
                        <td>{{ duty.agent_name }}</td>
                        <td>
                            <button type="button" class="btn btn-success btn-sm" (click)="viewEntryAndPaymentSlip(duty.entry_id,duty.txn_id)"><i
                              class="fas fa-paperclip" aria-hidden="true">&nbsp;</i>View Entry</button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                      <td colspan="9"><b>Total</b></td>
                      <td><b>{{unpaidDuty.total_unpaid_duty| currency:'Kshs '}}</b></td>
                    </tr>
                </tfoot>
            </table>
            <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
          </div>
          <!-- /.card-body -->
      </div>
    </form>
   </div>
   </section>