import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-print-qr-code',
  templateUrl: './print-qr-code.component.html',
  styleUrls: ['./print-qr-code.component.css']
})
export class PrintQrCodeComponent implements OnInit{
  public errorMessage: string | null = null;
  public chassis_no: string | null = null;

  constructor(
    private activatedRoute: ActivatedRoute,
  ){}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.chassis_no = param['get']('chassis_no');
    });

    if(!this.chassis_no){
      this.errorMessage="Unable to select BL for QR Code printing!";
    }
  }

  printPage(): void {
    window.print(); // Open the browser print preview dialog
  }
}
