import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { VehicleReservationService } from 'src/app/services/sales-management/vehicle-reservation.service';

@Component({
  selector: 'app-view-commitment-fee-payments',
  templateUrl: './view-commitment-fee-payments.component.html',
  styleUrls: ['./view-commitment-fee-payments.component.css']
})
export class ViewCommitmentFeePaymentsComponent implements OnInit{
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public reservation_id: string | null = null;
  public vehicle_id: string | null = null;
  public payments: any[] = [];
  public isLoading: boolean = false;
  public isProcessing: boolean = false;
  submitted: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private vehicleReservationService:VehicleReservationService,
  ){
  
  }

  ngOnInit(): void {
    
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.reservation_id = param['get']('reservation_id')
      this.vehicle_id = param['get']('vehicle_id')
    })

    if(this.reservation_id){
      this.fetchCommitmentFeePayments(this.reservation_id);
    }else{ 
      //Navigate back to vehicle reservation records with error
      this.globalService.setGlobalErrorMessage("Unable to load commitment fee payments!");
      this.router.navigate(['view-vehicle-reservations',this.vehicle_id]);
    }
  }

  fetchCommitmentFeePayments(reservation_id:string): void {
    this.vehicleReservationService.getCommitmentFeePayments(reservation_id)
    .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.isLoading = false;
            this.payments = response.data
          }else{
            this.isLoading = false;
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.errorMessage=error;
        }
      });
  }
}
