import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivityLogsService } from 'src/app/services/activity-logs/activity-logs.service';
import { AgentService } from 'src/app/services/agents/agent.service';
import { UserService } from 'src/app/services/users/user.service';
import { GlobalService } from 'src/app/services/shared/global.service';

@Component({
  selector: 'app-activity-logs',
  templateUrl: './activity-logs.component.html',
  styleUrls: ['./activity-logs.component.css']
})
export class ActivityLogsComponent implements OnInit{
  form!: FormGroup;
  submitted: boolean = false;
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  activity_logs: any[] = [];
  agents: any[] = [];
  users: any[] = [];
  public userId:string='';
  public agentId:string='';
  public userType:string='';
  public sysModule:string='';
  public isProcessing: boolean = false;
  public userTypeIsInternal: boolean = false;
  public userTypeIsAgent: boolean = false;

  constructor(
    private activityLogsService:ActivityLogsService,
    private agentService: AgentService,
    private userService:UserService,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
  ){
    this.form = this.formBuilder.group({
      date_from: ['', Validators.required],
      date_to: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.isProcessing=true;
    this.fetchActivityLogs();
  }

  fetchActivityLogs():void {
    this.activityLogsService.getActivityLogs()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.activity_logs = response.data
          this.isProcessing=false;
        }else if(response.status==='no_data'){
          this.activity_logs = response.data
          this.globalService.setGlobalWarningMessage(response.message);
           this.isProcessing=false;
        }else{
          this.isProcessing=false;
          const errorMessage="Sorry, a problem was encountered while loading activity logs. Please refresh the page and try again!";
          this.globalService.setGlobalErrorMessage(errorMessage);
        }
      },
      error: (error) => {
        this.isProcessing=false;
        this.globalService.setGlobalErrorMessage(error);
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onUserTypeSelectChange(target: EventTarget|null){
    if (target instanceof HTMLSelectElement) {
      this.userType = target.value;

      if(this.userType!==undefined){
        if(this.userType==="1"){
          this.userTypeIsInternal=true;
          this.userTypeIsAgent=false;
          this.isProcessing=true;
          this.filterActivityLogsByUserType(1);
          this.getUsers();
        }else if(this.userType==="2"){
          this.userTypeIsAgent=true;
          this.userTypeIsInternal=false;
          this.isProcessing=true;
          this.filterActivityLogsByUserType(2);
          this.getAgents();
        }else{
          this.userTypeIsAgent=false;
          this.userTypeIsInternal=false;
          this.fetchActivityLogs();
        }
      }else{
        const warningMessage="Invalid user type selected. Please try again!";
        this.globalService.setGlobalWarningMessage(warningMessage);
      }
    }
  }

  getUsers(): void{
    this.userService.getAllUsers()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.users = response.data;
          this.isProcessing=false;
        }else if(response.status==='no_data'){
          this.users=[];
          this.globalService.setGlobalWarningMessage(response.message);
           this.isProcessing=false;
        }else{
          this.isProcessing=false;
          const errorMessage="Sorry, a problem was encountered while loading users. Please refresh the page and try again!";
          this.globalService.setGlobalErrorMessage(errorMessage);
        }
      },
      error: (error) => {
        this.isProcessing=false;
        this.globalService.setGlobalErrorMessage(error);
      }
    });
  }

  getAgents(): void{
    this.agentService.getAllAgents()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.agents = response.data;
          this.isProcessing=false;
        }else if(response.status==='no_data'){
          this.agents=[];
          this.globalService.setGlobalWarningMessage(response.message);
           this.isProcessing=false;
        }else{
          this.isProcessing=false;
          this.globalService.setGlobalErrorMessage("Sorry, a problem was encountered while loading agents. Please try again!");
        }
      },
      error: (error) => {
        this.isProcessing=false;
        this.globalService.setGlobalErrorMessage(error);
      }
    });
  }

  /**
   * Filter activity logs by user
   * @param userId 
   */
  onUserSelectChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {
      this.userId = target.value;

      if(this.userId!==undefined){
        this.isProcessing=true
        this.filterActivityLogsByUserOrAgent(this.userType,Number(this.userId));
        this.page = 1; // Reset to page 1 after applying filter
      }else{
        const warningMessage="Invalid user selected. Please try again!";
        this.globalService.setGlobalWarningMessage(warningMessage);
      }
    }
  }

  /**
   * Filter activity logs by agent
   * @param agentId 
   */
  onAgentSelectChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {
      this.agentId = target.value;

      if(this.agentId!==undefined){
        this.isProcessing=true
        this.filterActivityLogsByUserOrAgent(this.userType,Number(this.agentId));
        this.page = 1; // Reset to page 1 after applying filter
      }else{
        const warningMessage="Invalid agent selected. Please try again!";
        this.globalService.setGlobalWarningMessage(warningMessage);
      }
    }
  }

  /**
   * Filter activity logs by module
   * @param sysModule 
   */
  onModuleSelectChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {
      this.sysModule = target.value;

      if(this.sysModule!==undefined){
        this.isProcessing=true
        this.filterActivityLogsByModule(this.userType,this.userId,this.sysModule);
        this.page = 1; // Reset to page 1 after applying filter
      }else{
        const warningMessage="Invalid module selected. Please try again!";
        this.globalService.setGlobalWarningMessage(warningMessage);
      }
    }
  }

  filterActivityLogsByUserType(userType: number):void {
    this.activityLogsService.filterByUserType(userType)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.activity_logs = response.data;
        }else if(response.status==='no_data'){
           this.activity_logs=[];
           this.globalService.setGlobalWarningMessage(response.message);
        }else{
          const errorMessage="Sorry, a problem was encountered while filtering activity logs by user type. Please try again!";
          this.globalService.setGlobalErrorMessage(errorMessage);
        }
      },
      error: (error) => {
        this.globalService.setGlobalErrorMessage(error);
      }
    });
  }

  filterActivityLogsByUserOrAgent(userType: any,userId: number):void {
    this.activityLogsService.filterByUserOrAgent(userType,userId)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.isProcessing=false;
          this.activity_logs = response.data;
        }else if(response.status==='no_data'){
          this.isProcessing=false;
           this.activity_logs=[];
           this.globalService.setGlobalWarningMessage(response.message);
        }else{
          this.isProcessing=false;
          const errorMessage="Sorry, a problem was encountered while filtering activity logs. Please try again!";
          this.globalService.setGlobalErrorMessage(errorMessage);
        }
      },
      error: (error) => {
        this.isProcessing=false;
        this.globalService.setGlobalErrorMessage(error);
      }
    });
  }

  filterActivityLogsByModule(userType: any,userId: any,sysModule:string):void {
    this.activityLogsService.filterByModule(userType,userId,sysModule)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.isProcessing=false;
          this.activity_logs = response.data;
        }else if(response.status==='no_data'){
          this.isProcessing=false;
           this.activity_logs=[];
           this.globalService.setGlobalWarningMessage(response.message);
        }else{
          this.isProcessing=false;
          const errorMessage="Sorry, a problem was encountered while filtering activity logs. Please try again!";
          this.globalService.setGlobalErrorMessage(errorMessage);
        }
      },
      error: (error) => {
        this.isProcessing=false;
        this.globalService.setGlobalErrorMessage(error);
      }
    });
  }

  filterByDate(){
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      this.activityLogsService.filterByDate(this.form.value.date_from,this.form.value.date_to,this.userType,this.userId,this.sysModule)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.isProcessing=false;
            this.activity_logs = response.data;
            this.page = 1; // Reset to page 1 after applying filter
          }else if(response.status==='no_data'){
            this.isProcessing=false;
            this.activity_logs=[];
            this.globalService.setGlobalWarningMessage(response.message);
          }else{
            this.isProcessing=false;
            const errorMessage="Sorry, a problem was encountered while filtering activity logs. Please try again!";
            this.globalService.setGlobalErrorMessage(errorMessage);
          }
        },
        error: (error) => {
          this.isProcessing=false;
          this.globalService.setGlobalErrorMessage(error);
        }
      });
    }else{
      this.isProcessing = false;
    }
  }
}
