import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private httpClient: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }

  /**
   * Get users
   * @returns Array
   */
  getAllUsers(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/users`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get roles available for assignment
   * @returns Array
   */
  getAssignableRoles(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/assignable-roles`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Create new user
   * @param user_data 
   * @returns array
   */
  saveUser(user_data:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/users`;

    return this.httpClient.post(apiURL,user_data, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
 * Get user record
 * @param userId 
 */
  getUserData(userId: string){

    let apiURL:string=`${this.baseURL}/v1/users/${userId}`;

    return this.httpClient.get(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Edit User
   * @param user_data 
   * @param userId 
   * @returns array
   */
  updateUser(user_data:any,userId: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/users/${userId}`;

    return this.httpClient.put(apiURL,user_data, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Delete User
   * @param userId 
   * @returns array
   */
  deleteUser(userId: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/users/${userId}`;

    return this.httpClient.delete(apiURL, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Change user password
   * @param formData
   * @returns array
   */
    changePassword(formData:any,userId:any):Observable<any>{

      let apiURL:string=`${this.baseURL}/v1/user/change-password/${userId}`;
  
      return this.httpClient.post(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
    }
}
