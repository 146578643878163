import { Component, OnInit } from '@angular/core';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { BillsOfLadingService } from 'src/app/services/bills-of-lading/bills-of-lading.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { AssignUnitsToBlService } from 'src/app/services/suppliers/assign-units-to-bl.service';

@Component({
  selector: 'app-supplier-view-bl-units',
  templateUrl: './supplier-view-bl-units.component.html',
  styleUrls: ['./supplier-view-bl-units.component.css']
})
export class SupplierViewBlUnitsComponent implements OnInit{
  isProcessing: boolean = false;
  public bl_id: string = '';
  public errorMessage: string | null = null;
  public warningMessage: string | null = null;
  public bl_data:any;
  makes: any[] = [];
  models: any[] = [];
  colours: any[] = [];
  body_types: any[] = [];
  grades: any[] = [];
  vehicles: any[] = [];
  bl_number:string='';
  selectedVehicles: any[] = []; // Array to store selected vehicles
  public isInProgress: boolean = false;
  public userPermissions: string[] = [];

  constructor(
    private activatedRoute: ActivatedRoute, 
    private billsOfLadingService: BillsOfLadingService, 
    private router: Router,
    private authService: AuthService,
    private globalService: GlobalService,
    private assignUnitsToBlService: AssignUnitsToBlService,
    ){
      
    }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.bl_id = param['get']('bl_id')
    })

    if(this.bl_id){
      this.getBLData(this.bl_id);
       //Get user permissions
      this.userPermissions=this.authService.getPermissions();
    }else{
      //Navigate back to bl list with error
      this.errorMessage="Unable to select BL for update!";
      this.router.navigate(['suppliers/bl-list']);
    }
  }

   /**
   * Get single BL info
   * @param bl_id 
   */
   getBLData(bl_id: string): void {
    this.billsOfLadingService.viewBL(bl_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.bl_data = response;
          
          if(this.bl_data.status==='no_data' || this.bl_data.status.length===0){
            this.errorMessage=this.bl_data.message;
          }else{
            //Load vehicles
            this.getVehiclesByBL(this.bl_data.data.bl_no);

            this.bl_number=this.bl_data.data.bl_no;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  /**
   * Get vehicles associated with the selected BL
   * @param bl_no 
   */
   getVehiclesByBL(bl_no: string): void {
    this.billsOfLadingService.getVehicles(bl_no).subscribe((response: ApiResponse) =>{
      this.vehicles = response.data
      
      if(response.status==='no_data'){
        this.warningMessage="No vehicles associated with this BL were found!";
      }
    });
  }

  // Function to handle checkbox change event
  checkboxChange(event: any, item: any) {
    if (event.target.checked) {
      this.selectedVehicles.push(item);
    } else {
      // Remove unchecked checkbox from the array
      const index = this.selectedVehicles.indexOf(item);
      if (index !== -1) {
        this.selectedVehicles.splice(index, 1);
      }
    }
  }

  unassign(){
    if(this.selectedVehicles.length===0){

      this.errorMessage="Please select unit(s) to unassign to BL!";

    }else{
      this.errorMessage=null;
      this.isInProgress=true;
      this.assignUnitsToBlService.unassignUnit(this.bl_id,this.bl_number,this.selectedVehicles)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.globalService.setGlobalSuccessMessage(response.message);
            //Clear vehicles array
            this.selectedVehicles.length = 0;
            this.isInProgress=true;
            //Reload units
            this.getBLData(this.bl_id);
          }else if(response.status==='warning'){
            this.warningMessage=response.message;
            this.isInProgress=false;
          }else{
            this.errorMessage=response.message;
            this.isInProgress=false;
          }
        },
        error: (error) => {
          // Handle the error
          console.log(error);
          this.isInProgress=false;
          this.errorMessage="An Error occurred while unassigning unit(s) to BL. Please try again!";
        }
      });
    }
  }
}
