import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params,Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { VehiclesService } from 'src/app/services/vehicles/vehicles.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-supplier-view-upload-photos',
  templateUrl: './supplier-view-upload-photos.component.html',
  styleUrls: ['./supplier-view-upload-photos.component.css']
})
export class SupplierViewUploadPhotosComponent implements OnInit{
  public isLoading: boolean = false;
  public isUploading: boolean = false;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public vehicle_id: string | null = null;
  public userPermissions: string[] = [];
  vehicle: any;
  photos: any[] = [];
  activeImage: string | null=null; // Track the currently active image
  files: File[] = [];
  selectedPhotos: any[] = []; // Array to store selected photos to delete
  public delPhotosPopupErrorMessage: string | null = null;
  @ViewChild('DeletePhotosModal') modalDeletePhotos!: ElementRef;

  constructor(
    private activatedRoute:ActivatedRoute,
    private vehiclesService: VehiclesService,
    private globalService: GlobalService,
    private router: Router,
    private authService: AuthService,
  ){}

  ngOnInit(): void {
     this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.vehicle_id = param['get']('vehicle_id')
    })

    if(this.vehicle_id){
      this.isLoading=true;
      this.getVehicleData(this.vehicle_id);

      //Get user permissions
      this.userPermissions=this.authService.getPermissions();
    }else{
      this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
    }
  }

  /**
   * Get vehicle details
   * @param vehicle_id 
   */
  getVehicleData(vehicle_id: string): void{
    this.vehiclesService.getVehicle(vehicle_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.isLoading=false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.vehicle = response;
          if(this.vehicle.status==='no_data' || this.vehicle.status.length===0){
            this.isLoading=false;
            this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again.";
          }else{
            this.getVehiclePhotos(vehicle_id); //Load photos
          }
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.errorMessage=error;
      }
    });
  }

  /**
   * Get vehicle photos
   * @param vehicle_id 
   */
  getVehiclePhotos(vehicle_id: string|null):void
  {
    this.vehiclesService.fetchVehiclePhotos(vehicle_id)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.isLoading=false;
          this.photos = response.data;
          this.setActiveImage(this.photos[0].url); // Set the first image as active initially
        }else if(response.status==='no_data'){
          this.isLoading=false;
          this.errorMessage=response.message;
        }else{
          this.isLoading=false;
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.errorMessage=error;
      }
    });
  }
  
  //Image on preview
  setActiveImage(imageUrl: string): void {
    this.activeImage = imageUrl;
  }
  
  //Select image
  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  //Remove image
  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  /**
   * Upload vehicle photos
   */
  handleSubmit(){
    if(this.files.length===0){
      this.errorMessage="Please select atleast one vehicle photo to proceed!";
    }else{
      this.isUploading=true;

      const formData = new FormData();
      this.files.forEach(file => {
        formData.append('file[]', file);
      });

      this.vehiclesService.uploadPhotos(formData,this.vehicle_id)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.isUploading=false;
            this.globalService.setGlobalSuccessMessage(response.message);
            this.errorMessage=''; //Clear error message incase it's available
           
            // Reload vehicle photos
            this.isLoading=true;
            this.getVehiclePhotos(this.vehicle_id);
            //Cleared selected images
            this.files=[];
          }else if(response.status==='upload_error'){
            this.isUploading=false;
            this.globalService.setGlobalWarningMessage(response.message);
            this.errorMessage=''; //Clear any caught error message incase it's available
          }else{
            this.isUploading=false;
            this.errorMessage=response.message;
          }
        },
        error: (error) => {
          this.isUploading=false;
          this.errorMessage=error;
        }
      });
    }
  }

  openDeletePhotosModal(){
    if (this.modalDeletePhotos) {
      (<any>$(this.modalDeletePhotos.nativeElement)).modal('show');
    }
  }

  closeModal():void {
    if (this.modalDeletePhotos) {
      (<any>$(this.modalDeletePhotos.nativeElement)).modal('hide');
    }
  }

  /**
   * Delete photos
   */
  deletePhotos(){
    if(this.selectedPhotos.length===0){
      this.delPhotosPopupErrorMessage="Please select photo(s) to delete!";
    }else{
      this.isLoading = true;

      this.vehiclesService.deleteVehiclesPhotos(this.vehicle_id,this.selectedPhotos)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.globalService.setGlobalSuccessMessage(response.message);
            this.errorMessage=''; //Clear error message incase it's available
            this.closeModal(); //Close modal
            this.getVehiclePhotos(this.vehicle_id); // Reload vehicle photos
            this.selectedPhotos=[];  //Cleared photos selected for deletion
            this.photos=[]; //Clear photo gallery
            this.activeImage=null; //Clear active photo
          }else if(response.status==='error'){
            this.isLoading=false;
            this.delPhotosPopupErrorMessage = response.message;
            this.errorMessage=''; //Clear any caught error message incase it's available
          }else if(response.status==='partial_delete'){
            this.globalService.setGlobalErrorMessage(response.message);
            this.errorMessage=''; //Clear any caught error message incase it's available
            this.closeModal();  //Close modal
            // Reload vehicle photos
            this.getVehiclePhotos(this.vehicle_id);
            //Cleared selected photos
            this.selectedPhotos=[];
          }else{
            this.isLoading=false;
            this.closeModal();  //Close modal
            this.errorMessage=response.message;
          }
        },
        error: (error) => {
          this.isLoading=false;
          this.closeModal();  //Close modal
          this.errorMessage=error;
        }
      });
    }   
  }

  // Function to handle checkbox change event
  checkboxChange(event: any, item: any) {
    if (event.target.checked) {
      // Add checked checkbox to the array
      this.selectedPhotos.push(item);
    } else {
      // Remove unchecked checkbox from the array
      const index = this.selectedPhotos.indexOf(item);
      if (index !== -1) {
        this.selectedPhotos.splice(index, 1);
      }
    }
  }
}
