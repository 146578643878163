import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent {
  public errorMessage: string | null = null;
  public warningMessage: string | null = null;
  @Input() data: any[] = [];
  @Input() columns: string[] = [];
  visibleColumns: { [key: string]: boolean } = {};

  constructor() {
    // Initialize visibleColumns to show all columns by default
    this.columns.forEach(column => {
      this.visibleColumns[column] = true;
    });
  }

  toggleColumn(column: string) {
    this.visibleColumns[column] = !this.visibleColumns[column];
  }
}
