import { Component, OnInit } from '@angular/core';
import {  FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { VehiclesService } from 'src/app/services/vehicles/vehicles.service';
import { LocationService } from 'src/app/services/locations/location.service';
import { BranchService } from 'src/app/services/branches/branch.service';

@Component({
  selector: 'app-import-stock-at-hand',
  templateUrl: './import-stock-at-hand.component.html',
  styleUrls: ['./import-stock-at-hand.component.css']
})
export class ImportStockAtHandComponent implements OnInit{
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  form!: FormGroup;
  submitted: boolean = false;
  isProcessing: boolean = false;
  fileName: string="Choose an Excel file";
  excel_file: any;
  locations: any[] = [];
  branches: any[] = [];
  invalidLocationSelected: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private vehiclesService:VehiclesService,
    private locationService: LocationService,
    private branchService:BranchService,
  ){
    this.form = this.formBuilder.group({
      excel_file: [null, Validators.required],
      location_id: ['', Validators.required],
      branch_id: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.fetchLocations();
  }

  /**
   * Handle selectd file
   * @param event 
   */
  onFileSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.fileName = fileInput.files?.[0]?.name ?? 'Choose an Excel file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.excel_file = file
    }
  }

  fetchLocations():void{
    this.locationService.getAllLocations().subscribe((response: ApiResponse) =>this.locations = response.data);
  }

  //Load branches when location is selected
  onSelectChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {
      const locationId = target.value;
      
      if(locationId!=='' && locationId!==undefined){
        this.fetchBranchesByLocation(locationId);
      }else{
        this.invalidLocationSelected=true;
      }
     
    }
  }

  fetchBranchesByLocation(locationId: string):void{
    this.branchService.getBranchesByLocation(locationId).subscribe((response: ApiResponse) =>this.branches = response.data);
  }

  handleSubmit() {
    this.submitted = true;
    this.isProcessing = true;

    if (this.form.valid) {
      this.vehiclesService.importStockAtHand(this.excel_file,this.form.value.location_id,this.form.value.branch_id)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.isProcessing = false;
            this.successMessage=response.message;
            this.errorMessage=null;
          }else{
            this.submitted = false;
            this.isProcessing = false;
            this.errorMessage=response.message;
            this.successMessage=null
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isProcessing = false;
          this.errorMessage=error;
          this.successMessage=null
        }
      });
    }else{
      this.isProcessing = false;
    }
  }

}
