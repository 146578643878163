<!--Loading Spinner-->
<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
  
  <!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                
                <div class="col-sm-6">
                    <a routerLink="/suppliers/add-new-bl"  *ngIf="userPermissions.includes('exp-enter-bl')"><i
                        class="fa fa-plus-circle" aria-hidden="true">&nbsp;</i>{{ 'sidebar.exports.add_new_bl.label' | translate }}</a><!--Add New BL-->
                </div><!-- /.col -->
                
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                        <li class="breadcrumb-item active">{{ 'forms.exports.bl_list.label' | translate }}</li><!--Bills Of Lading-->
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    
    <div class="row">
        <div class="col">
            <form>
                <div class="row">
                    <div class="col-md-8">
                        <div class="row" *ngIf="userPermissions.includes('mark-bl-as-shipped')">
                            <div class="col">
                                <input type="submit" (click)="resetBLSelection()" class="btn btn-outline-danger" value="{{'forms.buttons.reset.label'| translate}}">&nbsp;&nbsp;&nbsp;&nbsp;<!--Reset-->
                                <input type="submit" (click)="selectShippedBLs()" class="btn btn-outline-success" value="{{'forms.buttons.mark_as_shipped.label'| translate}}"><!--Mark As Shipped-->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                    <div class="row">
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Type BL No. here to search" (input)="onSearchTextChange($event)">
                        </div>
                    </div>
                </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- /.content-header -->
<section class="content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">{{ 'forms.exports.bl_list.label' | translate }}</h3><!--Bills Of Lading-->
                <!--Error Message-->
                <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
                </div>
                <!--Success Message-->
                <div class="alert alert-success" role="alert" *ngIf="successMessage">
                {{successMessage}}
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                    <!--Error messages here-->
                <table id="example1" class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th *ngIf="userPermissions.includes('mark-bl-as-shipped')"></th>
                            <th>BL Date</th>
                            <th>Created On</th>
                            <th>BL No.</th>
                            <th>Consignee</th>
                            <th>No. Of Units ({{total_units}})</th>
                            <th>Units Added ({{total_units_added}})</th>
                            <th>Vessel</th>
                            <th>Vessel ETA</th>
                            <th *ngIf="userPermissions.includes('exp-edit-bl') || userPermissions.includes('exp-delete-bl') || userPermissions.includes('exp-view-bl-units') || userPermissions.includes('exp-upload-bl-att')"></th>
                        </tr>
                    </thead>
                <tbody>
                    <tr *ngFor="let bl of bills_of_lading | searchBl: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                        <td *ngIf="userPermissions.includes('mark-bl-as-shipped')"><input type="checkbox" [(ngModel)]="bl.checked" (change)="checkboxChange($event, bl.bl_no)" id="bl" name="bl" class="form-control" *ngIf="bl.shipped === 0 && bl.units_added === bl.no_of_units"></td>
                        <td>{{ bl.bl_date| date: 'dd-MM-yyyy' }}</td>
                        <td>{{ bl.date_created| date: 'dd-MM-yyyy' }}</td>
                        <td>{{ bl.bl_no }}</td>
                        <td>{{ bl.consignee_name }}</td>
                        <td>{{ bl.no_of_units }}</td>
                        <td>{{ bl.units_added }}</td>
                        <td>{{ bl.vessel }}</td>
                        <td>{{ bl.vessel_eta| date: 'dd-MM-yyyy' }}</td>
                        <td *ngIf="userPermissions.includes('exp-edit-bl') || userPermissions.includes('exp-delete-bl') || userPermissions.includes('exp-view-bl-units') || userPermissions.includes('exp-upload-bl-att')">
                            <div class="dropdown">
                                <button class="btn btn-default dropdown-toggle" type="button"
                                    data-toggle="dropdown">{{'forms.buttons.btn_action.label'|translate}} <!--Action-->
                                    <span class="caret"></span></button>
                                <ul class="dropdown-menu">
                                    <li *ngIf="userPermissions.includes('exp-edit-bl')"><a class="dropdown-item" routerLink="/suppliers/edit-bl/{{ bl.bl_id }}">{{ 'forms.buttons.btn_edit.label' | translate }}</a></li><!--Edit-->
                                    <li *ngIf="userPermissions.includes('exp-delete-bl')"><a class="dropdown-item" (click)="deleteBL($event,bl.bl_id,bl.bl_no)">{{'forms.buttons.btn_del.label' | translate}}</a></li><!--Delete-->
                                    <li *ngIf="userPermissions.includes('exp-view-bl-units')"><a class="dropdown-item" routerLink="/suppliers/bls/view-units/{{ bl.bl_id }}">{{ 'forms.exports.view_units.label' | translate }}</a></li><!--View Units-->
                                    <li *ngIf="userPermissions.includes('exp-upload-bl-att') && bl.upload_flg==0"><a class="dropdown-item" (click)="openModal(bl.bl_no)"><i class="fas fa-paperclip"></i>&nbsp;Attachment</a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
                </table>
                <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
            </div>
        <!-- /.card-body -->
        </div>
    </div>
</section>

<!-- Update Attachment Modal -->
<div class="modal fade" #UpdateAttachmentModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Upload BL Attachment</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form [formGroup]="form" (ngSubmit)="uploadAttachment()">
                <div class="modal-body">
                    <div class="form-group">
                        <label>Attachment:<span style="color:red;">*</span></label>
                        <div class="input-group mb-3">
                            <div class="custom-file">
                            <input type="file" formControlName="attachment" accept="application/pdf,image/*" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onFileSelected($event)">
                            <label class="custom-file-label" for="inputGroupFile01">{{ fileName }}</label>
                            </div>
                        </div>

                        <span *ngIf="submitted && form.get('attachment')?.hasError('required')" style="color:red"> <strong>Please select BL attachment!</strong></span>
                    </div>
                </div>
                <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showUploadBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>
            
                <ng-template #showUploadBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Upload</button>
                </ng-template>
                </div>
            </form>
        </div>
    </div>
</div>

<!--Mark BLs as shipped modal-->
<div class="modal fade" #MarkBLsAsShippedModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Shipped BLs</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!--Error Message-->
            <div class="alert alert-danger" role="alert" *ngIf="modalErrorMessage">
                {{modalErrorMessage}}
            </div>
            <table class="table">
                <tr  *ngFor="let bl_no of selectedBLs">
                    <td>{{bl_no}}</td>
                </tr>
            </table>
            <form [formGroup]="shippedBLsForm" (ngSubmit)="markSelectedBLsAsShipped()">
                <div class="modal-body">
                    <div class="form-group">
                        <label>Date Shipped:<span style="color:red;">*</span></label>
                        <input type="date" formControlName="date_shipped"  class="form-control" placeholder="Date Shipped" value="">

                        <span *ngIf="submitted && shippedBLsForm.get('date_shipped')?.hasError('required')" style="color:red"> <strong>Please select the date BLs were shipped!</strong></span>
                    </div>
                    <div class="form-group">
                        <label>Waybill Number:</label>
                        <input type="text" formControlName="waybill_no"  class="form-control" placeholder="Waybill Number" value="">
                    </div>
                    <div class="form-group">
                        <label>Waybill Attachment:</label>
                        <div class="input-group mb-3">
                            <div class="custom-file">
                            <input type="file" formControlName="waybill_attachment" accept="application/pdf,image/*" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onWaybillFileSelected($event)">
                            <label class="custom-file-label" for="inputGroupFile01">{{ waybill_fileName }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSubmitBtn" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Processing...
                    </button>
                
                    <ng-template #showSubmitBtn>
                        <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Submit</button>
                    </ng-template>
                </div>
            </form>
        </div>
    </div>
</div>