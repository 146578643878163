<!--Loading Spinner-->
  <div *ngIf="isLoading">
    <app-spinner></app-spinner>
  </div>
  
  <!-- Content Header (Page header) -->
  <section class="content-header">
      <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
             
              <div class="col-sm-6">
                <h3>Verify Spare Parts</h3>
              </div><!-- /.col -->
             
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                  <li class="breadcrumb-item"><a routerLink="/vehicles/pending-parts-verification"><i class="nav-icon fas fa-list-ul"></i>&nbsp;Select Vehicle</a></li>
                  <li class="breadcrumb-item active">Verify Spare Parts</li>
                </ol>
              </div><!-- /.col -->
            </div><!-- /.row -->
          </div><!-- /.container-fluid -->
      </div>
     
      <div class="row">
          <div class="col">
            <form>
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Make & Model:</label>
                            <input type="text" name="make" class="form-control" value="{{vehicle.data.make}} {{vehicle.data.model}}" readonly>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Chassis No.:</label>
                            <input type="text" name="chassis_no" class="form-control" value="{{vehicle.data.chassis_no}}" readonly>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Engine No.:</label>
                            <input type="text" name="engine_no" class="form-control" value="{{vehicle.data.engine_no}}" readonly>
                        </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                          <label>Engine CC:</label>
                          <input type="text" name="model" class="form-control" value="{{vehicle.data.engine_cc}}" readonly>
                      </div>
                  </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col">
                                <input type="submit" (click)="resetSelectedSpareParts()" class="btn btn-outline-danger" value="Reset">&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="submit" (click)="updateSpareParts()" class="btn btn-outline-success" value="Done">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                    <div class="row">
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Type Part Name here to search..." (input)="onSearchTextChange($event)">
                        </div>
                    </div>
                    </div>
                </div>
            </form>
        </div>
      </div>
      </section>
        <!-- /.content-header -->
      <section class="content">
        <div class="container-fluid">
        <div class="card">
            <div class="card-header">
              <h3 class="card-title">Select Spare Parts Received with the vehicle from Port/CFS</h3>
              <!--Error Message-->
              <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
              </div>
              <!--Success Message-->
              <div class="alert alert-success" role="alert" *ngIf="successMessage">
                {{successMessage}}
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                 <!--Error messages here-->
              <table id="example1" class="table table-bordered table-hover">
                <thead>
                <tr>
                    <th></th>
                    <th>Part Name</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let part of spare_parts | searchSparePart: searchText">
                        <td><input type="checkbox" [(ngModel)]="part.checked" (change)="checkboxChange($event, part.part_id)" id="spare_part" name="spare_part" class="form-control"></td>
                        <td>{{ part.name }}</td>
                    </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </section>