import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchAgent'
})
export class SearchAgentPipe implements PipeTransform {

  transform(agents: any, searchText: string){
    
    if (!searchText) {
      return agents; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return agents.filter((agents: any) => agents.company_name.toLowerCase().includes(searchText));
  }

}
