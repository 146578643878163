<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
           
            <div class="col-sm-6">
             
            </div><!-- /.col -->
           
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item active">Vehicle Transfer History</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div><!-- /.content-header -->
    <div class="row">
      <div class="col-md-8">
          <div class="row">
             
          </div>
      </div>
      <div class="col-md-4">
        <div class="row">
            <div class="col">
                <input type="text" class="form-control" placeholder="Type Chassis No. here to search" (input)="onSearchTextChange($event)">
            </div>
        </div>
      </div>
    </div>
    </section>
    <section class="content">
      <div class="container-fluid">
      <div class="card">
          <div class="card-header">
            <h3 class="card-title">Vehicle Transfer History</h3>
          </div>
          <!-- /.card-header -->
          <div class="card-body" style="overflow-x: auto; white-space: nowrap;">
              <!--Error Message-->
              <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
              </div>
            <table id="example1" class="table table-bordered table-hover">
              <thead>
              <tr>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Chassis No.</th>
                  <th>From Location</th>
                  <th>To Location</th>
                  <th>Received At</th>
                  <th>Transferred On</th>
                  <th>Transferred By</th>
                  <th>Received On</th>
                  <th>Received By</th>
                  <th>Duration</th>
                  <th>Transfer Source</th>
                  <th></th>
              </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let vehicle of vehicles | searchVehicle: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }" [ngClass]="{'highlight-row': vehicle.part_flag === 1}">
                    <td>{{vehicle.make}}</td>
                    <td>{{vehicle.model}}</td>
                    <td>{{vehicle.chassis_no}}</td>
                    <td>
                      <ng-container *ngIf="vehicle.from_location; else emptyFromLocation">
                        {{ vehicle.from_location }}
                      </ng-container>
                      <ng-template #emptyFromLocation>
                       CFS/Port
                      </ng-template>
                    </td>
                    <td>
                      <ng-container *ngIf="vehicle.to_location; else emptyToLocation">
                        {{ vehicle.to_location }}
                      </ng-container>
                      <ng-template #emptyToLocation>
                        Any Location/Branch
                      </ng-template>
                    </td>
                    <td>{{vehicle.received_at}}</td>
                    <td>{{vehicle.transferred_on | date: 'dd-MM-yyyy h:mm a'}}</td>
                    <td>{{vehicle.transferred_by}}</td>
                    <td>{{vehicle.received_on | date: 'dd-MM-yyyy h:mm a'}}</td>
                    <td>{{vehicle.received_by}}</td>
                    <td>{{vehicle.transfer_duration}}</td>
                    <td>{{vehicle.transfer_source}}</td>
                    <td>
                      <div class="dropdown">
                          <button class="btn btn-default dropdown-toggle" type="button"
                              data-toggle="dropdown"><i class="fas fa-eye"></i>&nbsp;View
                              <span class="caret"></span></button>
                          <ul class="dropdown-menu">
                              <li><a class="dropdown-item" href="/view-transferred-vehicle/{{vehicle.transfer_log_id}}" *ngIf="vehicle.transfer_log_id!=null && vehicle.transfer_reason!='Delivery'"><i class="fas fa-angle-double-right"></i>&nbsp;Transfer Details</a></li>
                              <li><a class="dropdown-item" routerLink="/view-received-vehicle/{{vehicle.receive_log_id}}" *ngIf="vehicle.transfer_reason!='Delivery'"><i class="fas fa-angle-double-left"></i>&nbsp;Receiving Details</a></li>
                              <li><a class="dropdown-item" href="/view-transferred-vehicle/{{vehicle.transfer_log_id}}" *ngIf="vehicle.transfer_log_id!=null && vehicle.transfer_reason=='Delivery'"><i class="fas fa-angle-double-right"></i>&nbsp;Delivery Details</a></li>
                          </ul>
                      </div>
                    </td>
                  </tr>
              </tbody>
            </table>
            <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </section>