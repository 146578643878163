import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchSparePart'
})
export class SearchSparePartPipe implements PipeTransform {

  transform(parts: any, searchText: string){
    
    if (!searchText) {
      return parts; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return parts.filter((parts: any) => parts.name.toLowerCase().includes(searchText));
  }

}
