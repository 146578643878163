import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class SparePartService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient, private errorHandler: ConfigErrorHandler) { }

  /**
   * Get vehicle spare parts
   * @returns Array
   */
  getAll(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/spare-parts`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Create new spare part
   * @param formData 
   * @returns array
   */
  save(formData:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/spare-parts`;

    return this.httpClient.post(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get record for editing
   * @returns Array
   */
  edit(id:string){

    let apiURL:string=`${this.baseURL}/v1/spare-parts/${id}/edit`;

    return this.httpClient.get(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Update record
   * @param formData 
   * @param Id 
   * @returns array
   */
  update(formData:any,Id: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/spare-parts/${Id}`;

    return this.httpClient.put(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Delete record
   * @param roleId 
   * @returns array
   */
  delete(Id: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/spare-parts/${Id}`;

    return this.httpClient.delete(apiURL, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
