 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Update Vessel ETA</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item active">Update Vessel ETA</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
         <h3 class="card-title">Update Vessel ETA</h3>
            <!--Error message-->
            <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
           <form [formGroup]="form" (ngSubmit)="handleSubmit()">
               <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>BL:<span style="color:red;">*</span></label>
                            <ng-select [(ngModel)]="selectedBL" [ngModelOptions]="{standalone: true}">
                                <ng-option *ngFor="let bl of billsOfLading" [value]="bl.bl_id">{{bl.bl_no}}</ng-option>
                            </ng-select>

                            <span *ngIf="submitted && form.get('bl_no')?.hasError('required')" style="color:red"> <strong>Please select BL!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Vessel ETA:<span style="color:red;">*</span></label>
                            <input type="date" formControlName="vessel_eta" class="form-control" placeholder="Vessel ETA" value="">

                            <span *ngIf="submitted && form.get('vessel_eta')?.hasError('required')" style="color:red"> <strong>Please enter vessel ETA!</strong></span>
                        </div>
                    </div>
               </div>

                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>
                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Update</button>
                </ng-template>
           </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>