import { Component, OnDestroy, OnInit } from '@angular/core';
import { BankAccountsService } from 'src/app/services/bank-accounts/bank-accounts.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-bank-accounts-list',
  templateUrl: './bank-accounts-list.component.html',
  styleUrls: ['./bank-accounts-list.component.css']
})
export class BankAccountsListComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  bank_accounts: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public isProcessing: boolean = false;

  constructor(
    private bankAccountsService:BankAccountsService,
  ){}

  ngOnInit(): void {
    this.fechtBankAccounts();
  }

  fechtBankAccounts():void {
    this.bankAccountsService.getAllBankAccounts().subscribe((response: ApiResponse) =>this.bank_accounts = response.data);
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fechtBankAccounts();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  deleteAccount(event: Event,accountId:string){
    event.preventDefault();
   
  }

}
