import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { CommitmentFeeRefundsService } from 'src/app/services/sales-management/commitment-fee-refunds.service';

@Component({
  selector: 'app-preview-attachment',
  templateUrl: './preview-attachment.component.html',
  styleUrls: ['./preview-attachment.component.css']
})
export class PreviewAttachmentComponent implements OnInit{
  public refund_id: string | null = null;
  public attachment_id: string | null = null;
  public isLoading: boolean = false;
  attachment: any;
  fileURL: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService:GlobalService,
    private commitmentFeeRefundsService:CommitmentFeeRefundsService,
  ){}

  ngOnInit(): void {

    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.attachment_id = param['get']('attachment_id')
      this.refund_id = param['get']('refund_id')
    })

    if(this.attachment_id && this.refund_id){
      this.isLoading = true;
      this.fetchAttachmentUrl(this.attachment_id);
    }else{
      //Navigate back to refund attachments list with error
      this.globalService.setGlobalErrorMessage("Unable to preview attachment. Please try again!");
      this.router.navigate(['view-commitment-fee-refund-attachments',this.refund_id]);
    }
  }

  fetchAttachmentUrl(attachment_id:string){
    this.commitmentFeeRefundsService.getAttachmentUrl(attachment_id)
    .subscribe({ 
        next: (response) => {
          if (response && Object.keys(response).length === 0) {
            this.isLoading = false;
            //Navigate back to refund attachments list with error
            this.globalService.setGlobalErrorMessage("Unable to preview attachment. Please try again!");
            this.router.navigate(['view-commitment-fee-refund-attachments',this.refund_id]);
          }else{
            this.isLoading = false;
            this.attachment = response;
            
            if(this.attachment.status==='no_data' || this.attachment.status.length===0){
              this.isLoading = false;
              //Navigate back to refund attachments list with error
              this.globalService.setGlobalErrorMessage("Unable to preview attachment. Please try again!");
              this.router.navigate(['view-commitment-fee-refund-attachments',this.refund_id]);
            }else{
              this.fileURL=this.attachment.data.file_path;
            }
          }
        },
        error: (error) => {
          this.isLoading = false;
          //Navigate back to refund attachments list with error
          this.globalService.setGlobalErrorMessage("Unable to preview attachment. Please try again!");
          this.router.navigate(['view-commitment-fee-refund-attachments',this.refund_id]);
        }
      });
  }
}
