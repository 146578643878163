import { Component, OnInit } from '@angular/core';
import { PaidPortChargesInvoicesService } from 'src/app/services/payments/paid-port-charges-invoices.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-paid-port-charges-invoices',
  templateUrl: './paid-port-charges-invoices.component.html',
  styleUrls: ['./paid-port-charges-invoices.component.css']
})
export class PaidPortChargesInvoicesComponent implements OnInit{
  public userPermissions: string[] = [];
  invoices: any[] = [];
  public isLoading: boolean = false;
  public errorMessage: string | null = null;
  searchText: string = '';
  page: number = 1;
  count: number = 0;

  constructor(
    private paidPortChargesService:PaidPortChargesInvoicesService,
    private authService: AuthService,
  ){}

  ngOnInit(): void {
    this.getInvoices();
    this.userPermissions=this.authService.getPermissions();
  }

  getInvoices(): void {
    this.paidPortChargesService.getPaidInvoices()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.isLoading = false;
          this.invoices = response.data
        }else{
          this.isLoading = false;
          this.errorMessage=response.message;
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.errorMessage=error;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
    this.page = 1; // Reset to page 1 after applying filter
  }
}
