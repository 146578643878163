import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private httpClient: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }

  /**
   * Get all branches
   * @returns Array
   */
  getAllBranches(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/branches`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Create new model
   * @param formData 
   * @returns array
   */
  saveBranch(formData:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/branches`;

    return this.httpClient.post(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Filter branches by location
   * @returns Array
   */
   getBranchesByLocation(locationId:string): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/filter-branches-by-location/${locationId}`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

/**
 * Print QR codes by branch
 * @param branch_id 
 */
  getChassisNumbersByBranch(branch_id: string):Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/print-qr-codes-by-branch/${branch_id}`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }
}
