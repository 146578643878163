import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { QRCodeModule } from 'angularx-qrcode';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthService } from './services/auth/auth.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { AuthGuard } from './auth.guard';
import { AuthInterceptor } from './authconfig.interceptor';
import {  HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { SidebarComponent } from './adminlte/sidebar/sidebar.component';
import { FooterComponent } from './adminlte/footer/footer.component';
import { TopnavComponent } from './adminlte/topnav/topnav.component';
import { DashboardComponent } from './adminlte/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { EnterBlComponent } from './components/customs/enter-bl/enter-bl.component';
import { BlListComponent } from './components/customs/bl-list/bl-list.component';
import { UpdateBlComponent } from './components/customs/update-bl/update-bl.component';
import { EditBlComponent } from './components/customs/edit-bl/edit-bl.component';
import { ImportedVehiclesComponent } from './components/customs/imported-vehicles/imported-vehicles.component';
import { AddEntryComponent } from './components/customs/add-entry/add-entry.component';
import { EnterDoPaymentsComponent } from './components/customs/do-radiation-payments/enter-do-payments/enter-do-payments.component';
import { SelectBlComponent } from './components/customs/do-radiation-payments/select-bl/select-bl.component';
import { EnterRadiationPaymentsComponent } from './components/customs/do-radiation-payments/enter-radiation-payments/enter-radiation-payments.component';
import { UpaidDutiesComponent } from './components/customs/duty-payments/upaid-duties/upaid-duties.component';
import { AddPortChargesComponent } from './components/customs/add-port-charges/add-port-charges.component';
import { SearchBlPipe } from './pipes/search-bl.pipe';
import { SearchVehiclePipe } from './pipes/search-vehicle.pipe';
import { SearchDutyPaymentPipe } from './pipes/search-duty-payment.pipe';
import { PaidDutiesPendingReceiptAttachmentComponent } from './components/customs/duty-payments/paid-duties-pending-receipt-attachment/paid-duties-pending-receipt-attachment.component';
import { AttachPaymentReceiptComponent } from './components/customs/duty-payments/attach-payment-receipt/attach-payment-receipt.component';
import { RecentlyClearedVehiclesComponent } from './components/customs/recently-cleared-vehicles/recently-cleared-vehicles.component';
import { UpdateEntryComponent } from './components/customs/update-entry/update-entry.component';
import { EnterPortChargesComponent } from './components/customs/enter-port-charges/enter-port-charges.component';
import { VehicleListComponent } from './components/vehicles/vehicle-list/vehicle-list.component';
import { ViewVehicleComponent } from './components/vehicles/view-vehicle/view-vehicle.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { VehicleTransferHistoryComponent } from './components/vehicle-transfer-history/vehicle-transfer-history.component';
import { VehicleTransferInTransitComponent } from './components/vehicle-transfer-in-transit/vehicle-transfer-in-transit.component';
import { ViewTransferredVehicleComponent } from './components/vehicles/view-transferred-vehicle/view-transferred-vehicle.component';
import { ViewReceivedVehicleComponent } from './components/vehicles/view-received-vehicle/view-received-vehicle.component';
import { PrintQrCodesComponent } from './components/customs/print-qr-codes/print-qr-codes.component';
import { UsersListComponent } from './components/settings/users/users-list/users-list.component';
import { SearchUserPipe } from './pipes/search-user.pipe';
import { AddUserComponent } from './components/settings/users/add-user/add-user.component';
import { EditUserComponent } from './components/settings/users/edit-user/edit-user.component';
import { RolesListComponent } from './components/settings/roles/roles-list/roles-list.component';
import { SearchRolePipe } from './pipes/search-role.pipe';
import { AddRoleComponent } from './components/settings/roles/add-role/add-role.component';
import { EditRoleComponent } from './components/settings/roles/edit-role/edit-role.component';
import { MakesListComponent } from './components/settings/makes/makes-list/makes-list.component';
import { AddMakeComponent } from './components/settings/makes/add-make/add-make.component';
import { SearchMakePipe } from './pipes/search-make.pipe';
import { ModelsListComponent } from './components/settings/models/models-list/models-list.component';
import { AddModelComponent } from './components/settings/models/add-model/add-model.component';
import { SearchModelPipe } from './pipes/search-model.pipe';
import { SearchBodyTypePipe } from './pipes/search-body-type.pipe';
import { BodyTypesListComponent } from './components/settings/body-types/body-types-list/body-types-list.component';
import { AddBodyTypeComponent } from './components/settings/body-types/add-body-type/add-body-type.component';
import { ColoursListComponent } from './components/settings/colours/colours-list/colours-list.component';
import { SearchColourPipe } from './pipes/search-colour.pipe';
import { AddColourComponent } from './components/settings/colours/add-colour/add-colour.component';
import { SearchConsigneePipe } from './pipes/search-consignee.pipe';
import { ConsigneesListComponent } from './components/settings/consignees/consignees-list/consignees-list.component';
import { AddConsigneeComponent } from './components/settings/consignees/add-consignee/add-consignee.component';
import { SearchAgentPipe } from './pipes/search-agent.pipe';
import { AgentsListComponent } from './components/settings/agents/agents-list/agents-list.component';
import { AddAgentComponent } from './components/settings/agents/add-agent/add-agent.component';
import { SearchCfsPipe } from './pipes/search-cfs.pipe';
import { CfsListComponent } from './components/settings/cfs/cfs-list/cfs-list.component';
import { AddCfsComponent } from './components/settings/cfs/add-cfs/add-cfs.component';
import { SearchLocationPipe } from './pipes/search-location.pipe';
import { LocationsListComponent } from './components/settings/locations/locations-list/locations-list.component';
import { AddLocationComponent } from './components/settings/locations/add-location/add-location.component';
import { SearchBranchPipe } from './pipes/search-branch.pipe';
import { BranchesListComponent } from './components/settings/branches/branches-list/branches-list.component';
import { AddBranchComponent } from './components/settings/branches/add-branch/add-branch.component';
import { ViewAttachmentsComponent } from './components/vehicles/view-attachments/view-attachments.component';
import { GradeListComponent } from './components/settings/grades/grade-list/grade-list.component';
import { SearchGradePipe } from './pipes/search-grade.pipe';
import { AddGradeComponent } from './components/settings/grades/add-grade/add-grade.component';
import { EditGradeComponent } from './components/settings/grades/edit-grade/edit-grade.component';
import { BankAccountsListComponent } from './components/settings/bank-accounts-list/bank-accounts-list.component';
import { SearchBankAccountPipe } from './pipes/search-bank-account.pipe';
import { AddBankAccountComponent } from './components/settings/bank-accounts/add-bank-account/add-bank-account.component';
import { SparePartsListComponent } from './components/settings/spare-parts/spare-parts-list/spare-parts-list.component';
import { SearchSparePartPipe } from './pipes/search-spare-part.pipe';
import { AddSparePartComponent } from './components/settings/spare-parts/add-spare-part/add-spare-part.component';
import { AllAvailableStockComponent } from './components/stock-management/all-available-stock/all-available-stock.component';
import { SearchStockPipe } from './pipes/search-stock.pipe';
import { StockEnRouteComponent } from './components/stock-management/stock-en-route/stock-en-route.component';
import { StockAtCfsComponent } from './components/stock-management/stock-at-cfs/stock-at-cfs.component';
import { SoldCarsComponent } from './components/stock-management/sold-cars/sold-cars.component';
import { AddPortChargesSelectBlComponent } from './components/customs/add-port-charges-select-bl/add-port-charges-select-bl.component';
import { UploadCnfPaymentsComponent } from './components/upload-cnf-payments/upload-cnf-payments.component';
import { PayAgentsComponent } from './components/customs/agents/pay-agents/pay-agents.component';
import { ViewVehicleExpDetailsComponent } from './components/stock-management/view-vehicle-exp-details/view-vehicle-exp-details.component';
import { AllStockComponent } from './components/stock-management/all-stock/all-stock.component';
import { UpdateVesselEtaComponent } from './components/customs/update-vessel-eta/update-vessel-eta.component';
import { ImportRegNumbersComponent } from './components/vehicles/import-reg-numbers/import-reg-numbers.component';
import { EditVehicleComponent } from './components/vehicles/edit-vehicle/edit-vehicle.component';
import { AddTradeInComponent } from './components/vehicles/add-trade-in/add-trade-in.component';
import { ImportStockAtHandComponent } from './components/vehicles/import-stock-at-hand/import-stock-at-hand.component';
import { EditBankAccountComponent } from './components/settings/bank-accounts/edit-bank-account/edit-bank-account.component';
import { EditMakeComponent } from './components/settings/makes/edit-make/edit-make.component';
import { EditModelComponent } from './components/settings/models/edit-model/edit-model.component';
import { AppsListComponent } from './components/integrations/apps/apps-list/apps-list.component';
import { CreateAppComponent } from './components/integrations/apps/create-app/create-app.component';
import { AccessTokensComponent } from './components/integrations/access-tokens/access-tokens.component';
import { CreateAccessTokenComponent } from './components/integrations/create-access-token/create-access-token.component';
import { CompletedBlsComponent } from './components/customs/completed-bls/completed-bls.component';
import { PrintQrCodeComponent } from './components/vehicles/print-qr-code/print-qr-code.component';
import { ReserveVehicleComponent } from './components/sales-management/reserve-vehicle/reserve-vehicle.component';
import { ViewVehicleReservationsComponent } from './components/sales-management/view-vehicle-reservations/view-vehicle-reservations.component';
import { ViewCommitmentFeePaymentsComponent } from './components/sales-management/view-commitment-fee-payments/view-commitment-fee-payments.component';
import { AddNewCommitmentFeePaymentComponent } from './components/sales-management/add-new-commitment-fee-payment/add-new-commitment-fee-payment.component';
import { CommitmentFeeRefundsComponent } from './components/sales-management/commitment-fee-refunds/commitment-fee-refunds.component';
import { RefundCommitmentFeeComponent } from './components/sales-management/refund-commitment-fee/refund-commitment-fee.component';
import { SearchRefundsPipe } from './pipes/search-refunds.pipe';
import { ViewRefundedCommitmentFeesComponent } from './components/sales-management/view-refunded-commitment-fees/view-refunded-commitment-fees.component';
import { ViewCommitmentFeeRefundAttachmentsComponent } from './components/sales-management/view-commitment-fee-refund-attachments/view-commitment-fee-refund-attachments.component';
import { PreviewAttachmentComponent } from './components/preview-attachment/preview-attachment.component';
import { PreviewVehicleAttachmentComponent } from './components/preview-vehicle-attachment/preview-vehicle-attachment.component';
import { AgentInvoicesComponent } from './components/customs/agents/agent-invoices/agent-invoices.component';
import { PreviewAgencyFeeInvoiceComponent } from './components/customs/agents/preview-agency-fee-invoice/preview-agency-fee-invoice.component';
import { SearchAgencyFeeInvoicesPipe } from './pipes/search-agency-fee-invoices.pipe';
import { PortChargesInvoicesComponent } from './components/customs/port-charges/port-charges-invoices/port-charges-invoices.component';
import { SearchPortChargesInvoicesPipe } from './pipes/search-port-charges-invoices.pipe';
import { PreviewPortChargesInvoiceComponent } from './components/customs/port-charges/preview-port-charges-invoice/preview-port-charges-invoice.component';
import { PayPortChargesComponent } from './components/customs/port-charges/pay-port-charges/pay-port-charges.component';
import { UpdateTaxSettingsComponent } from './components/settings/update-tax-settings/update-tax-settings.component';
import { SetAgentPasswordComponent } from './components/settings/set-agent-password/set-agent-password.component';
import { EditAgentComponent } from './components/settings/agents/edit-agent/edit-agent.component';
import { VehiclesPendingPhotoUploadComponent } from './components/vehicles/vehicles-pending-photo-upload/vehicles-pending-photo-upload.component';
import { VehiclesPendingDocsUploadComponent } from './components/vehicles/vehicles-pending-docs-upload/vehicles-pending-docs-upload.component';
import { VerifySparePartsComponent } from './components/vehicles/verify-spare-parts/verify-spare-parts.component';
import { VehiclesPendingPartsVerificationComponent } from './components/vehicles/vehicles-pending-parts-verification/vehicles-pending-parts-verification.component';
import { EditEntryComponent } from './components/customs/edit-entry/edit-entry.component';
import { SearchEditableEntriesPipe } from './pipes/search-editable-entries.pipe';
import { EditSparePartComponent } from './components/settings/spare-parts/edit-spare-part/edit-spare-part.component';
import { UpdateEntryAttachmentsComponent } from './components/customs/update-entry-attachments/update-entry-attachments.component';
import { AddUnitComponent } from './components/supplier-operations/add-unit/add-unit.component';
import { AssignUnitsToBlComponent } from './components/supplier-operations/assign-units-to-bl/assign-units-to-bl.component';
import { StockListComponent } from './components/supplier-operations/stock-list/stock-list.component';
import { BillsOfLadingComponent } from './components/supplier-operations/bills-of-lading/bills-of-lading.component';
import { AddNewBlComponent } from './components/supplier-operations/add-new-bl/add-new-bl.component';
import { SupplierEditBlComponent } from './components/supplier-operations/supplier-edit-bl/supplier-edit-bl.component';
import { SupplierEditVehicleComponent } from './components/supplier-operations/supplier-edit-vehicle/supplier-edit-vehicle.component';
import { SupplierViewBlUnitsComponent } from './components/supplier-operations/supplier-view-bl-units/supplier-view-bl-units.component';
import { SupplierViewUploadPhotosComponent } from './components/supplier-operations/supplier-view-upload-photos/supplier-view-upload-photos.component';
import { SupplierViewVehicleAttachmentsComponent } from './components/supplier-operations/supplier-view-vehicle-attachments/supplier-view-vehicle-attachments.component';
import { SupplierUpdateVesselEtaComponent } from './components/supplier-operations/supplier-update-vessel-eta/supplier-update-vessel-eta.component';
import { ImportSoldVehiclesComponent } from './components/vehicles/import-sold-vehicles/import-sold-vehicles.component';
import { PrintStockComponent } from './components/stock-management/print/print-stock/print-stock.component';
import { DataTableComponent } from './components/stock-management/print/data-table/data-table.component';
import { ColumnToggleComponent } from './components/stock-management/print/column-toggle/column-toggle.component';
import { EditPortChargesInvoiceComponent } from './components/customs/port-charges/edit-port-charges-invoice/edit-port-charges-invoice.component';
import { PaidPortChargesInvoicesComponent } from './components/customs/port-charges/paid-port-charges-invoices/paid-port-charges-invoices.component';
import { EditPaidPortChargesInvoiceComponent } from './components/customs/port-charges/edit-paid-port-charges-invoice/edit-paid-port-charges-invoice.component';
import { StockReconciliationComponent } from './components/vehicles/stock-reconciliation/stock-reconciliation.component';
import { ChangeUserPasswordComponent } from './components/settings/users/change-user-password/change-user-password.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ActivityLogsComponent } from './components/settings/activity-logs/activity-logs.component';
import { ReverseDutyPaymentsComponent } from './components/customs/duty-payments/reverse-duty-payments/reverse-duty-payments.component';
import { SearchEntriesPipe } from './pipes/search-entries.pipe';
import { VehiclesPendingImportDocsUploadComponent } from './components/vehicles/vehicles-pending-import-docs-upload/vehicles-pending-import-docs-upload.component';
import { VehicleDocsComponent } from './components/vehicles/vehicle-docs/vehicle-docs.component';
import { UpdateVehicleInfoComponent } from './components/vehicles/update-vehicle-info/update-vehicle-info.component';
import { TempEditVehicleComponent } from './components/vehicles/temp-edit-vehicle/temp-edit-vehicle.component';
import { EditLocationComponent } from './components/settings/locations/edit-location/edit-location.component';
import { DeliveryNotesComponent } from './components/sales-management/delivery-notes/delivery-notes.component';
import { PrintDeliveryNoteComponent } from './components/sales-management/print-delivery-note/print-delivery-note.component';
import { SearchDeliveryNotesPipe } from './pipes/search-delivery-notes.pipe';
import { PrintStockQrCodesComponent } from './components/settings/branches/print-stock-qr-codes/print-stock-qr-codes.component';
import { DeleteEntryComponent } from './components/customs/delete-entry/delete-entry.component';
import { DeleteAddtlDutyComponent } from './components/customs/delete-addtl-duty/delete-addtl-duty.component';
import { EndsWithPipe } from './pipes/ends-with.pipe';
import { NewCashPaymentComponent } from './components/cash-management/new-cash-payment/new-cash-payment.component';
import { NewCashReceiptComponent } from './components/cash-management/new-cash-receipt/new-cash-receipt.component';
import { CashTransactionsComponent } from './components/cash-management/cash-transactions/cash-transactions.component';
import { EnterOpeningBalanceComponent } from './components/cash-management/enter-opening-balance/enter-opening-balance.component';
import { CloseDayComponent } from './components/cash-management/close-day/close-day.component';
import { SearchCashTransactionsPipe } from './pipes/search-cash-transactions.pipe';
import { CategoriesListComponent } from './components/cash-management/categories-list/categories-list.component';
import { SearchCashCategoriesPipe } from './pipes/search-cash-categories.pipe';
import { AddCashCategoryComponent } from './components/cash-management/add-cash-category/add-cash-category.component';
import { EditCashCategoryComponent } from './components/cash-management/edit-cash-category/edit-cash-category.component';
import { PrintBlComponent } from './components/customs/print-bl/print/print-bl.component';
import { PrintableVehicleListComponent } from './components/customs/print-bl/printable-vehicle-list/printable-vehicle-list.component';
import { PrintBlColumnToggleComponent } from './components/customs/print-bl/print-bl-column-toggle/print-bl-column-toggle.component';
import { AddDeviceComponent } from './components/settings/devices/add-device/add-device.component';
import { ManageDevicesComponent } from './components/settings/devices/manage-devices/manage-devices.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { SearchDevicesPipe } from './pipes/search-devices.pipe';
import { DashboardSwitcherComponent } from './dashboard-switcher/dashboard-switcher.component';
import { DefaultDashboardComponent } from './default-dashboard/default-dashboard.component';
import { ViewReturnedSalesComponent } from './components/sales-management/view-returned-sales/view-returned-sales.component';
import { OverdueUnshippedBlsComponent } from './overdue-unshipped-bls/overdue-unshipped-bls.component';
import { SearchActivityLogPipe } from './pipes/search-activity-log.pipe';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    FooterComponent,
    TopnavComponent,
    DashboardComponent,
    LoginComponent,
    EnterBlComponent,
    BlListComponent,
    UpdateBlComponent,
    EditBlComponent,
    ImportedVehiclesComponent,
    AddEntryComponent,
    EnterDoPaymentsComponent,
    SelectBlComponent,
    EnterRadiationPaymentsComponent,
    UpaidDutiesComponent,
    AddPortChargesComponent,
    SearchBlPipe,
    SearchVehiclePipe,
    SearchDutyPaymentPipe,
    PaidDutiesPendingReceiptAttachmentComponent,
    AttachPaymentReceiptComponent,
    RecentlyClearedVehiclesComponent,
    UpdateEntryComponent,
    EnterPortChargesComponent,
    VehicleListComponent,
    ViewVehicleComponent,
    SpinnerComponent,
    VehicleTransferHistoryComponent,
    VehicleTransferInTransitComponent,
    ViewTransferredVehicleComponent,
    ViewReceivedVehicleComponent,
    PrintQrCodesComponent,
    UsersListComponent,
    SearchUserPipe,
    AddUserComponent,
    EditUserComponent,
    RolesListComponent,
    SearchRolePipe,
    AddRoleComponent,
    EditRoleComponent,
    MakesListComponent,
    AddMakeComponent,
    SearchMakePipe,
    ModelsListComponent,
    AddModelComponent,
    SearchModelPipe,
    SearchBodyTypePipe,
    BodyTypesListComponent,
    AddBodyTypeComponent,
    ColoursListComponent,
    SearchColourPipe,
    AddColourComponent,
    SearchConsigneePipe,
    ConsigneesListComponent,
    AddConsigneeComponent,
    SearchAgentPipe,
    AgentsListComponent,
    AddAgentComponent,
    SearchCfsPipe,
    CfsListComponent,
    AddCfsComponent,
    SearchLocationPipe,
    LocationsListComponent,
    AddLocationComponent,
    SearchBranchPipe,
    BranchesListComponent,
    AddBranchComponent,
    ViewAttachmentsComponent,
    GradeListComponent,
    SearchGradePipe,
    AddGradeComponent,
    EditGradeComponent,
    BankAccountsListComponent,
    SearchBankAccountPipe,
    AddBankAccountComponent,
    SparePartsListComponent,
    SearchSparePartPipe,
    AddSparePartComponent,
    AllAvailableStockComponent,
    SearchStockPipe,
    StockEnRouteComponent,
    StockAtCfsComponent,
    SoldCarsComponent,
    AddPortChargesSelectBlComponent,
    UploadCnfPaymentsComponent,
    PayAgentsComponent,
    ViewVehicleExpDetailsComponent,
    AllStockComponent,
    UpdateVesselEtaComponent,
    ImportRegNumbersComponent,
    EditVehicleComponent,
    AddTradeInComponent,
    ImportStockAtHandComponent,
    EditBankAccountComponent,
    EditMakeComponent,
    EditModelComponent,
    AppsListComponent,
    CreateAppComponent,
    AccessTokensComponent,
    CreateAccessTokenComponent,
    CompletedBlsComponent,
    PrintQrCodeComponent,
    ReserveVehicleComponent,
    ViewVehicleReservationsComponent,
    ViewCommitmentFeePaymentsComponent,
    AddNewCommitmentFeePaymentComponent,
    CommitmentFeeRefundsComponent,
    RefundCommitmentFeeComponent,
    SearchRefundsPipe,
    ViewRefundedCommitmentFeesComponent,
    ViewCommitmentFeeRefundAttachmentsComponent,
    PreviewAttachmentComponent,
    PreviewVehicleAttachmentComponent,
    AgentInvoicesComponent,
    PreviewAgencyFeeInvoiceComponent,
    SearchAgencyFeeInvoicesPipe,
    PortChargesInvoicesComponent,
    SearchPortChargesInvoicesPipe,
    PreviewPortChargesInvoiceComponent,
    PayPortChargesComponent,
    UpdateTaxSettingsComponent,
    SetAgentPasswordComponent,
    EditAgentComponent,
    VehiclesPendingPhotoUploadComponent,
    VehiclesPendingDocsUploadComponent,
    VerifySparePartsComponent,
    VehiclesPendingPartsVerificationComponent,
    EditEntryComponent,
    SearchEditableEntriesPipe,
    EditSparePartComponent,
    UpdateEntryAttachmentsComponent,
    AddUnitComponent,
    AssignUnitsToBlComponent,
    StockListComponent,
    BillsOfLadingComponent,
    AddNewBlComponent,
    SupplierEditBlComponent,
    SupplierEditVehicleComponent,
    SupplierViewBlUnitsComponent,
    SupplierViewUploadPhotosComponent,
    SupplierViewVehicleAttachmentsComponent,
    SupplierUpdateVesselEtaComponent,
    ImportSoldVehiclesComponent,
    PrintStockComponent,
    DataTableComponent,
    ColumnToggleComponent,
    EditPortChargesInvoiceComponent,
    PaidPortChargesInvoicesComponent,
    EditPaidPortChargesInvoiceComponent,
    StockReconciliationComponent,
    ChangeUserPasswordComponent,
    UnauthorizedComponent,
    ActivityLogsComponent,
    ReverseDutyPaymentsComponent,
    SearchEntriesPipe,
    VehiclesPendingImportDocsUploadComponent,
    VehicleDocsComponent,
    UpdateVehicleInfoComponent,
    TempEditVehicleComponent,
    EditLocationComponent,
    DeliveryNotesComponent,
    PrintDeliveryNoteComponent,
    SearchDeliveryNotesPipe,
    PrintStockQrCodesComponent,
    DeleteEntryComponent,
    DeleteAddtlDutyComponent,
    EndsWithPipe,
    NewCashPaymentComponent,
    NewCashReceiptComponent,
    CashTransactionsComponent,
    EnterOpeningBalanceComponent,
    CloseDayComponent,
    SearchCashTransactionsPipe,
    CategoriesListComponent,
    SearchCashCategoriesPipe,
    AddCashCategoryComponent,
    EditCashCategoryComponent,
    PrintBlComponent,
    PrintableVehicleListComponent,
    PrintBlColumnToggleComponent,
    AddDeviceComponent,
    ManageDevicesComponent,
    TimeAgoPipe,
    SearchDevicesPipe,
    DashboardSwitcherComponent,
    DefaultDashboardComponent,
    ViewReturnedSalesComponent,
    OverdueUnshippedBlsComponent,
    SearchActivityLogPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxDropzoneModule,
    QRCodeModule,
    NgSelectModule,

    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  providers: [
    AuthService,
    GlobalService,
    AuthGuard,
    {provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
