import { Component, OnInit } from '@angular/core';
import { PrintStockService } from 'src/app/services/stock/print-stock.service';
import { LocationService } from 'src/app/services/locations/location.service';
import { BranchService } from 'src/app/services/branches/branch.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-print-stock',
  templateUrl: './print-stock.component.html',
  styleUrls: ['./print-stock.component.css']
})
export class PrintStockComponent implements OnInit{
  public isInProgress: boolean = false;
  public errorMessage: string | null = null;
  public warningMessage: string | null = null;
  locations: any[] = [];
  branches: any[] = [];
  selectedLocation: string='';
  selectedBranch: string='';
  data: any[] = [];
  columns: string[] = ['Make', 'Model', 'ChassisNo','EngineCC','EngineNo','Transmission',
    'BodyType','Drive','Fuel','Mileage','Colour','Year','RegNo','BLNumber','Location','Branch'
  ];
  
  constructor(
    private printStockService:PrintStockService,
    private locationService:LocationService,
    private branchService:BranchService,
  ){}

  ngOnInit(): void {
    this.isInProgress=true;
    this.fetchAvailableStock();
    this.fetchLocations();
  }

  fetchAvailableStock():void{
    this.printStockService.getAvailableStockForPrinting()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.data = response.data;
          this.isInProgress=false;
          this.errorMessage=null;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }

  fetchLocations():void{
    this.locationService.getAllLocations().subscribe((response: ApiResponse) =>this.locations = response.data);
  }

  /**
   * Load branches when the location is selected
   * and also load stock by location
   */
  onLocationChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {

      const locationId = target.value;
      
      if(locationId!=='' && locationId!==undefined){
        this.isInProgress=true;
        this.fetchBranchesByLocation(locationId);
        this.fetchStockByLocation(locationId);
      }else{
        this.branches = []; // Clear the branches
      }
    }
  }

  //Fetch stock by branch
  onBranchChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {

      const branchId = target.value;
      
      if(branchId!=='' && branchId!==undefined){
          this.isInProgress=true;
          this.fetchStockByBranch(branchId);
      }else{
          const locationId =sessionStorage.getItem("locationId");
          this.fetchStockByLocation(locationId!);
      }
    }
  }

  fetchBranchesByLocation(locationId: string):void {
    this.branchService.getBranchesByLocation(locationId)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.branches = response.data
          this.isInProgress=false;
          this.errorMessage=null;
        }else if(response.status==='no_data'){
          this.errorMessage="No branches found within the selected location!";
          this.isInProgress=false;
          this.branches = []; // Clear the branches
        }else{
          this.errorMessage="Unable to load branches within the selected location. Please try again or consult your system administrator!";
          this.isInProgress=false;
          this.branches = []; // Clear the branches
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
        this.branches = []; // Clear the branches
      }
    });
  }

  fetchStockByLocation(locationId: string):void {
    this.printStockService.filterAvailableStockByLocationForPrinting(locationId)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.data = response.data;
          this.isInProgress=false;
          this.errorMessage=null;
        }else if(response.status==='no_data'){
           this.data=[];
           this.errorMessage=response.message;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }

  fetchStockByBranch(branchId: string):void {
    this.printStockService.filterAvailableStockByBranchForPrinting(branchId)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.data = response.data;
          this.isInProgress=false;
          this.errorMessage=null;
        }else if(response.status==='no_data'){
          this.data=[];
          this.errorMessage=response.message;
          this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }
}
