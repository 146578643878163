import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';
import { ApiResponse } from 'src/app/models/IApiResponse'

@Injectable({
  providedIn: 'root'
})
export class StockManagementService {

  private baseURL = environment.apiURL;

  private headers = new HttpHeaders({ 'Content-Type': 'application/json','Accept': 'application/json' });

  constructor(private httpClient: HttpClient, private errorHandler: ConfigErrorHandler) { }

  /**
   * Add Unit By Supplier
   * @param vehicle_data 
   * @param jevic_attachment 
   * @param logbook_attachment
   * @param logbook_trans_attachment
   * @param vehicle_photos
   * @param other_documents
   * @returns array
   */
  addUnit(vehicle_data:any,jevic_attachment:any,logbook_attachment: any,logbook_trans_attachment: any,vehicle_photos:any,other_documents:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/suppliers/add-unit`;

    const formData = new FormData();
    formData.append("make_id", vehicle_data.make_id);
    formData.append("model_id", vehicle_data.model_id);
    formData.append("grade_id", vehicle_data.grade_id);
    formData.append("chassis_no", vehicle_data.chassis_no);
    formData.append("colour", vehicle_data.colour);
    formData.append("yr_of_mfg", vehicle_data.yr_of_mfg);
    formData.append("engine_cc", vehicle_data.engine_cc);
    formData.append("drive", vehicle_data.drive);
    formData.append("fuel", vehicle_data.fuel);
    formData.append("mileage", vehicle_data.mileage);
    formData.append("engine_no", vehicle_data.engine_no);
    formData.append("transmission", vehicle_data.transmission);
    formData.append("body_type", vehicle_data.body_type);
    formData.append("weight", vehicle_data.weight);
    formData.append("cbm", vehicle_data.cbm);
    formData.append("reg_no", vehicle_data.reg_no);
    formData.append("condition", vehicle_data.condition);
    formData.append("no_of_doors", vehicle_data.no_of_doors);
    formData.append("no_of_seats", vehicle_data.no_of_seats);
    formData.append("jevic_attachment", jevic_attachment);
    formData.append("logbook_attachment", logbook_attachment);
    formData.append("logbook_trans_attachment", logbook_trans_attachment);

    other_documents.forEach((file: string | Blob) => {
      formData.append('other_documents[]', file);
    });

    vehicle_photos.forEach((file: string | Blob) => {
      formData.append('vehicle_photos[]', file);
    });

    return this.httpClient.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * View supplier stock
   * @returns Array
   */
  getSupplierStock(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/suppliers/view-stock`;

    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Filter supplier stock
   * @returns Array
   */
  filterSupplierStock(filter:string): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/suppliers/filter-stock/${filter}`;

    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Delete Unit
   * @param vehicleId 
   * @returns array
   */
  deleteUnit(vehicleId: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/suppliers/delete-unit/${vehicleId}`;

    return this.httpClient.delete(apiURL, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
