import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MakeService } from 'src/app/services/makes/make.service';
import { ModelService } from 'src/app/services/models/model.service';
import { ColourService } from 'src/app/services/colours/colour.service';
import { BodyTypeService } from 'src/app/services/body-types/body-type.service';
import { GradeService } from 'src/app/services/grades/grade.service';
import { VehiclesService } from 'src/app/services/vehicles/vehicles.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { LocationService } from 'src/app/services/locations/location.service';
import { BranchService } from 'src/app/services/branches/branch.service';

@Component({
  selector: 'app-add-trade-in',
  templateUrl: './add-trade-in.component.html',
  styleUrls: ['./add-trade-in.component.css']
})
export class AddTradeInComponent implements OnInit{
  public isLoading: boolean = false;
  isProcessing: boolean = false;
  public errorMessage: string | null = null;
  public warningMessage: string | null = null;
  form!: FormGroup;
  makes: any[] = [];
  models: any[] = [];
  colours: any[] = [];
  body_types: any[] = [];
  grades: any[] = [];
  submitted: boolean = false;
  invalidMakeSelected: boolean = false;
  invalidModelSelected: boolean = false;
  locations: any[] = [];
  branches: any[] = [];
  invalidLocationSelected: boolean = false;

  constructor(
    private router: Router,
    private makeService: MakeService,
    private modelService: ModelService,
    private colourService: ColourService,
    private formBuilder: FormBuilder,
    private bodyTypeService:BodyTypeService,
    private gradeService:GradeService,
    private vehiclesService:VehiclesService,
    private globalService: GlobalService,
    private locationService: LocationService,
    private branchService:BranchService,
  ){
    this.form = this.formBuilder.group({
      make_id: ['', Validators.required],
      model_id: ['', Validators.required],
      grade_id: [''],
      chassis_no: ['', Validators.required],
      engine_cc: ['', Validators.required],
      engine_no: ['', Validators.required],
      transmission: ['', Validators.required],
      body_type: ['', Validators.required],
      drive: ['', Validators.required],
      fuel: ['', Validators.required],
      mileage: ['', Validators.required],
      colour: ['', Validators.required],
      yr_of_mfg: ['', [Validators.required,Validators.minLength(4),Validators.maxLength(7)]],
      reg_no: [''],
      no_of_doors: [''],
      no_of_seats: [''],
      location_id: ['', Validators.required],
      branch_id: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.isLoading=true;
    this.getVehicleMakes();
  }

  /**
   * Get vehicle makes
   */
  getVehicleMakes(): void {
    this.makeService.getAllMakes().subscribe({
      next: (response) => {
        if(response.status=="success"){
          this.makes=response.data
          //Load colours
          this.getColours();
        }else{
          this.errorMessage=response.message;
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  /**
   * Get vehicle models associated with
   * the selected make
   * @param makeID 
   */
  getVehicleModels(makeID:string): void {
    this.modelService.getModelsByMake(makeID).subscribe({
      next: (response) => {
        if(response.status=="success"){
          this.models=response.data
          this.isLoading=false;
        }else{
          this.errorMessage=response.message;
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  /**
   * Get colours
   */
  getColours(): void {
    this.colourService.getAllColours().subscribe({
      next: (response) => {
        if(response.status=="success"){
          this.colours=response.data
          //Load body types
          this. getBodyTypes();
        }else{
          this.errorMessage=response.message;
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  getBodyTypes():void {
    this.bodyTypeService.getAllBodyTypes().subscribe({
      next: (response) => {
        if(response.status=="success"){
          this.body_types=response.data
          this.fetchLocations();
        }else{
          this.errorMessage=response.message;
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  /**
   * Get vehicle grades associated with
   * the selected model
   * @param modelID 
   */
  getVehicleGrades(modelID:string): void {
    this.gradeService.getGradesByModel(modelID).subscribe({
      next: (response) => {
        if(response.status=="success"){
          this.grades=response.data
          this.isLoading=false;
        }else{
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  //Load models & grades
  onSelectChange(target: EventTarget|null,option:string) {
    if (target instanceof HTMLSelectElement) {

      if(option==='make'){
        const makeID = target.value;
      
        if(makeID!=='' && makeID!==undefined){
          this.isLoading=true;
          this.getVehicleModels(makeID);
        }else{
          this.invalidMakeSelected=true;
        }
      }else{
        const modelID = target.value;
      
        if(modelID!=='' && modelID!==undefined){
          this.isLoading=true;
          this.getVehicleGrades(modelID);
        }else{
          this.invalidModelSelected=true;
        }
      }
    }
  }

  /**
   * Load locations
   */
  fetchLocations(): void {
    this.locationService.getAllLocations().subscribe({
      next: (response) => {
        if(response.status=="success"){
          this.locations=response.data
          this.isLoading=false;
        }else{
          this.errorMessage=response.message;
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  //Load branches when location is selected
  onLocationChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {
      const locationId = target.value;
      
      if(locationId!=='' && locationId!==undefined){
        this.isLoading=true;
        this.fetchBranchesByLocation(locationId);
      }else{
        this.invalidLocationSelected=true;
      }
     
    }
  }

   /**
   * Get branches associated with
   * the selected location
   * @param locationId 
   */
   fetchBranchesByLocation(locationId:string): void {
    this.branchService.getBranchesByLocation(locationId).subscribe({
      next: (response) => {
        if(response.status=="success"){
          this.branches=response.data
          this.isLoading=false;
        }else{
          this.warningMessage=response.message;
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  handleSubmit(): void {
    this.submitted = true;
    this.isProcessing = true;

    if (this.form.valid) {

      const formData = {
        ...this.form.value,
      };

      this.vehiclesService.addVehicle(formData).subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.globalService.setGlobalSuccessMessage(response.message);
            this.router.navigate(['vehicles']);
          }else{
            this.errorMessage=response.message;
            this.submitted = false;
            this.isProcessing = false;
          }
        },
        error:(error) => {
          this.submitted = false;
          this.isProcessing = false;
          this.errorMessage=error;
        }
      });
    }else{
      this.submitted = false;
      this.isProcessing = false;
      this.errorMessage="Please provide all the required details!";
    }
  }
}
