import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DeliveryNotesService } from 'src/app/services/sales-management/delivery-notes.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-delivery-notes',
  templateUrl: './delivery-notes.component.html',
  styleUrls: ['./delivery-notes.component.css']
})
export class DeliveryNotesComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public warningMessage: string | null = null;
  public popupErrorMessage:string|null = null;
  public popupWarningMessage:string|null = null;
  delivery_notes: any[] = [];
  searchText: string = '';
  public isInProgress: boolean = false;
  public isProcessing: boolean = false;
  submitted: boolean = false;
  form!: FormGroup;
  locations: any[] = [];
  bills_of_lading: any[] = [];
  rowCount: number=0;
  public delivery_id:string='';
  public customerData:any;
  @ViewChild('UpdateCustomerInfoModal') modalElement!: ElementRef;

  constructor(
    private deliveryNotesService:DeliveryNotesService,
    private globalService: GlobalService,
    private formBuilder: FormBuilder
  ){
    this.form = this.formBuilder.group({
      customer_name: ['', Validators.required],
      customer_address: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.isInProgress=true;
    this.deliveryNotesList();
  }

  deliveryNotesList():void{
    this.deliveryNotesService.getDeliveryNotes()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.delivery_notes = response.data;
          this.rowCount = this.delivery_notes.length;
          this.isInProgress=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
    this.page = 1; // Reset to page 1 after applying filter
  }

  openModal(delivery_id:string){
    this.delivery_id=delivery_id;
    this.popupErrorMessage=null;
    this.popupWarningMessage=null;

    //Fetch customer info
    this.deliveryNotesService.getCustomerInfo(this.delivery_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please try again!";
        }else{
          this.customerData = response;
          if(this.customerData.status==='no_data' || this.customerData.status.length===0){
            this.errorMessage=this.customerData.message;
          }else{
            //Open modal
            if (this.modalElement) {
              (<any>$(this.modalElement.nativeElement)).modal('show');
            }
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  closeModal():void {
    if (this.modalElement) {
      (<any>$(this.modalElement.nativeElement)).modal('hide');
    }
  }

  handleSubmit(){
    this.submitted = true;
    this.isProcessing=true;

    if (this.form.valid) {
      this.deliveryNotesService.updateCustomerInfo(this.form.value.customer_name,this.form.value.customer_address,this.delivery_id)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.isProcessing=false;
            this.globalService.setGlobalSuccessMessage(response.message);
            this.closeModal();
           //Refresh list
           this.isInProgress=true;
           this.deliveryNotesList();
          }else if(response.status==='error'){
            this.submitted = false;
            this.isProcessing=false;
            this.popupErrorMessage=response.message;
          }else if(response.status==='warning'){
            this.submitted = false;
            this.isProcessing=false;
            this.popupWarningMessage=response.message;
          }else{
            this.submitted = false;
            this.isProcessing=false;
            this.popupErrorMessage=response.message;
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isProcessing=false;
          this.popupErrorMessage=error;
        }
      });
    }else{
      this.isProcessing=false;
    }
  }
}
