<div *ngIf="isInProgress">
    <app-spinner></app-spinner>
</div>
  
<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                    <li class="breadcrumb-item active">Stock List</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <div class="row">
        <div class="col-md-8">
            <form class="form-inline">
                <div class="form-group">
                    <label>View:</label>&nbsp;
                    <select name="consignee_id"  class="form-control" (change)="onSelectChange($event.target)">
                        <option value="all">All</option>
                        <option value="pending_photos">Pending Photos Upload</option>
                        <option value="pending_imp_docs">Pending Import Docs Upload</option>
                    </select>
                </div>
              </form>
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col">
                    <input type="text" class="form-control" placeholder="Search by Make, Model, Chassis No., Engine No. or BL No." (input)="onSearchTextChange($event)">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /.content-header -->
<section class="content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Stock List&nbsp;&nbsp;&nbsp;Count: {{rowCount}}</h3>
                <!--Error Message-->
                <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>
                <!--Success Message-->
                <div class="alert alert-success" role="alert" *ngIf="successMessage">
                    {{successMessage}}
                </div>
                    <!--Warning Message-->
                <div class="alert alert-warning" role="alert" *ngIf="warningMessage">
                    {{warningMessage}}
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body" style="overflow-x: auto; white-space: nowrap;">
                <!--Error messages here-->
                <table id="example1" class="table table-bordered table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Make</th>
                            <th>Model</th>
                            <th>Chassis No.</th>
                            <th>Engine No.</th>
                            <th>Engine CC</th>
                            <th>Transmission</th>
                            <th>Body Type</th>
                            <th>Drive</th>
                            <th>Fuel</th>
                            <th>Mileage</th>
                            <th>Colour</th>
                            <th>Year</th>
                            <th>Weight</th>
                            <th>CBM</th>
                            <th>Reg No.</th>
                            <th>BL No.</th>
                            <th *ngIf="userPermissions.includes('exp-upload-vhcl-photos') || userPermissions.includes('exp-view-vhcl-atts') || userPermissions.includes('exp-edit-unit')"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let vehicle of vehicles  | searchVehicle: searchText| paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                            <td>{{vehicle.make}}</td>
                            <td>{{vehicle.model}}</td>
                            <td><a routerLink="/suppliers/view-upload-photos/{{vehicle.vehicle_id}}">{{vehicle.chassis_no}}</a></td>
                            <td>{{vehicle.engine_no}}</td>
                            <td>{{vehicle.engine_cc}}</td>
                            <td>{{vehicle.transmission}}</td>
                            <td>{{vehicle.body_type}}</td>
                            <td>{{vehicle.drive}}</td>
                            <td>{{vehicle.fuel}}</td>
                            <td>{{vehicle.mileage}}</td>
                            <td>{{vehicle.colour}}</td>
                            <td>{{vehicle.year}}</td>
                            <td>{{vehicle.weight}}</td>
                            <td>{{vehicle.cbm}}</td>
                            <td>{{vehicle.reg_no}}</td>
                            <td>{{vehicle.bl_no}}</td>
                            <td *ngIf="userPermissions.includes('exp-upload-vhcl-photos') || userPermissions.includes('exp-view-vhcl-atts') || userPermissions.includes('exp-edit-unit')">
                                <div class="dropdown">
                                    <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">Action<span class="caret"></span></button>
                                    <ul class="dropdown-menu">
                                        <li *ngIf="userPermissions.includes('exp-upload-vhcl-photos')"><a class="dropdown-item" routerLink="/suppliers/view-upload-photos/{{vehicle.vehicle_id}}">View/Upload Photos</a></li>
                                        <li *ngIf="userPermissions.includes('exp-view-vhcl-atts')"><a class="dropdown-item" routerLink="/suppliers/view-vehicle-attachments/{{vehicle.vehicle_id}}">View Attachments</a></li>
                                        <li *ngIf="userPermissions.includes('exp-edit-unit')"><a class="dropdown-item" routerLink="/suppliers/edit-unit/{{vehicle.vehicle_id}}">Edit</a></li>
                                        <li *ngIf="userPermissions.includes('exp-delete-unit') && vehicle.bl_no==null"><a class="dropdown-item" (click)="delete(vehicle.vehicle_id,vehicle.make,vehicle.model,vehicle.chassis_no)">Delete</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
            </div>
            <!-- /.card-body -->
        </div>
    </div>
</section>