import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError,BehaviorSubject,combineLatest, map } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class PendingTasksService {
  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Accept': 'application/json' });
  private noOfPendingEntriesSubject = new BehaviorSubject<number>(0);
  private noOfBlsPendingDOAndRadiationPmtSubject = new BehaviorSubject<number>(0);
  private noOfUnpaidDutiesSubject = new BehaviorSubject<number>(0);
  private noOfPaymentsPendingReceiptsAttachmentSubject = new BehaviorSubject<number>(0);
  private noOfBlsPendingPortChargesPmtSubject = new BehaviorSubject<number>(0);
  private noOfBlsPendingETAUpdateSubject = new BehaviorSubject<number>(0);
  private noOfVehiclesPendingPhotoUploadSubject = new BehaviorSubject<number>(0);
  private noOfVehiclesPendingDocsUploadSubject = new BehaviorSubject<number>(0);
  private noOfVehiclesPendingPartsVerificationSubject = new BehaviorSubject<number>(0);

  noOfPendingEntries$ = this.noOfPendingEntriesSubject.asObservable();
  noOfBlsPendingDOAndRadiationPmt$ = this.noOfBlsPendingDOAndRadiationPmtSubject.asObservable();
  noOfUnpaidDuties$ = this.noOfUnpaidDutiesSubject.asObservable();
  noOfPaymentsPendingReceiptsAttachment$ = this.noOfPaymentsPendingReceiptsAttachmentSubject.asObservable();
  noOfBlsPendingPortChargesPmt$ = this.noOfBlsPendingPortChargesPmtSubject.asObservable();
  noOfBlsPendingETAUpdatePmt$ = this.noOfBlsPendingETAUpdateSubject.asObservable();
  noOfVehiclesPendingPhotoUpload$ = this.noOfVehiclesPendingPhotoUploadSubject.asObservable();
  noOfVehiclesPendingDocsUpload$ = this.noOfVehiclesPendingDocsUploadSubject.asObservable();
  noOfVehiclesPendingPartsVerification$ = this.noOfVehiclesPendingPartsVerificationSubject.asObservable();

  constructor(private httpClient: HttpClient, private errorHandler: ConfigErrorHandler) { }

  // Create an observable that combines all the counts
  totalCustomsPendingTasks$ = combineLatest([
    this.noOfUnpaidDutiesSubject,
    this.noOfPendingEntriesSubject,
    this.noOfPaymentsPendingReceiptsAttachmentSubject,
    this.noOfBlsPendingDOAndRadiationPmtSubject,
    this.noOfBlsPendingPortChargesPmtSubject,
    this.noOfBlsPendingETAUpdateSubject,
  ]).pipe(
    map(([unpaidDutiesCount, pendingEntriesCount,noOfPaymentsPendingReceiptsAttachment,noOfBlsPendingDOAndRadiationPmt,noOfBlsPendingPortChargesPmt,noOfBlsPendingETAUpdatePmt]) => unpaidDutiesCount + pendingEntriesCount+noOfPaymentsPendingReceiptsAttachment+noOfBlsPendingDOAndRadiationPmt+noOfBlsPendingPortChargesPmt+noOfBlsPendingETAUpdatePmt)
  );

  // Create an observable that combines all the counts for duty payments
  totalDutyPendingTasks$ = combineLatest([
    this.noOfUnpaidDutiesSubject,
    this.noOfPendingEntriesSubject,
    this.noOfPaymentsPendingReceiptsAttachmentSubject,
  ]).pipe(
    map(([unpaidDutiesCount, pendingEntriesCount,noOfPaymentsPendingReceiptsAttachment]) => unpaidDutiesCount + pendingEntriesCount+noOfPaymentsPendingReceiptsAttachment)
  );

  /**
   * Count No. of vehicles pending entry input
   * @returns Array
   */
  public countVehiclesPendingEntryInput():Observable<ApiResponse>{
    let apiURL:string=`${this.baseURL}/v1/count-vehicles-pending-entry-input`;

    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  //Update the counter for vehicles pending entry
  updateNoOfPendingEntries(count: number) {
    this.noOfPendingEntriesSubject.next(count);
  }

  /**
   * Count No. of BLs pending D.O & Radiation
   * @returns Array
   */
  public countNoOfBLsPendingDOAndRadiationPmt():Observable<ApiResponse>{
    let apiURL:string=`${this.baseURL}/v1/count-bls-pending-do-rad-payments`;

    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  //Update the counter for BLs pending D.O & Radiation
  updateNoOfBLsPendingDOAndRadiationPmt(count: number) {
    this.noOfBlsPendingDOAndRadiationPmtSubject.next(count);
  }

  /**
   * Count No. of unpaid duties
   * @returns Array
   */
  public countUnpaidDuties():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/count-unpaid-duties`;

    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }
  
  //Update the counter for unpaid duties
  updateNoOfUnpaidDuties(count: number) {
    this.noOfUnpaidDutiesSubject.next(count);
  }

  /**
   * Count No. of payments pending receipt attachment
   * @returns Array
   */
  public countPaymentsPendingReceiptsAttachment():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/count-payments-pending-receipts-attachment`;

    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  //Update the counter for payments pending receipts attachment
  updateNoOfPaymentsPendingReceiptAttachment(count: number) {
    this.noOfPaymentsPendingReceiptsAttachmentSubject.next(count);
  }

   /**
   * Count No. of BLs pending Port Charges payment
   * @returns Array
   */
  public countNoOfBLsPendingPortChargesPmt():Observable<ApiResponse>{
    let apiURL:string=`${this.baseURL}/v1/count-bls-pending-port-charges`;

    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  //Update the counter for BLs pending Port Charges
  updateNoOfBLsPendingPortChargesPmt(count: number) {
    this.noOfBlsPendingPortChargesPmtSubject.next(count);
  }

  /**
   * Count No. of BLs pending ETA update
   * @returns Array
   */
  public countNoOfBLsPendingETAUpdatePmt():Observable<ApiResponse>{
    let apiURL:string=`${this.baseURL}/v1/count-bls-pending-vessel-eta-update`;

    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  //Update the counter for BLs pending ETA update
  updateNoOfBLsPendingETAUpdatePmt(count: number) {
    this.noOfBlsPendingETAUpdateSubject.next(count);
  }

  /**
   * Count No. of vehicles pending photo upload
   * @returns Array
   */
  public countVehiclesPendingPhotoUpload():Observable<ApiResponse>{
    let apiURL:string=`${this.baseURL}/v1/count-vehicles-pending-photo-upload`;

    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  //Update the counter for vehicles pending photo upload
  updateNoOfVehiclesPendingPhotoUpload(count: number) {
    this.noOfVehiclesPendingPhotoUploadSubject.next(count);
  }

  /**
   * Count No. of vehicles pending import documents upload
   * @returns Array
   */
  public countVehiclesPendingDocsUpload():Observable<ApiResponse>{
    let apiURL:string=`${this.baseURL}/v1/count-vehicles-pending-import-docs-upload`;

    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  //Update the counter for vehicles pending documents upload
  updateNoOfVehiclesPendingDocsUpload(count: number) {
    this.noOfVehiclesPendingDocsUploadSubject.next(count);
  }

   /**
   * Count No. of vehicles pending spare parts verification
   * @returns Array
   */
   public countVehiclesPendingPartsVerification():Observable<ApiResponse>{
    let apiURL:string=`${this.baseURL}/v1/verify-spare-parts/count-vehicles`;

    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  //Update the counter for vehicles spare parts verification
  updateNoOfVehiclesPendingPartsVerification(count: number) {
    this.noOfVehiclesPendingPartsVerificationSubject.next(count);
  }
}
