import { Component, OnInit } from '@angular/core';
import { ModelService } from 'src/app/services/models/model.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-models-list',
  templateUrl: './models-list.component.html',
  styleUrls: ['./models-list.component.css']
})
export class ModelsListComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  models: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public isLoading: boolean = false;

  constructor(
    private modelService:ModelService,
  ){}

  ngOnInit(): void {
    this.isLoading=true;
    this.fetchVehicleModels();
  }

  fetchVehicleModels():void{
    this.modelService.getAllModels().subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.isLoading = false;
          this.models = response.data
        }else{
          this.isLoading = false;
          this.errorMessage=response.message;
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fetchVehicleModels();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  deleteModel(event: Event,makeId:string){

  }

}
