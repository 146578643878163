import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { BillsOfLadingService } from 'src/app/services/bills-of-lading/bills-of-lading.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MakeService } from 'src/app/services/makes/make.service';
import { ModelService } from 'src/app/services/models/model.service';
import { ColourService } from 'src/app/services/colours/colour.service';
import { BodyTypeService } from 'src/app/services/body-types/body-type.service';
import { GradeService } from 'src/app/services/grades/grade.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { isEmpty } from 'rxjs';

@Component({
  selector: 'app-update-bl',
  templateUrl: './update-bl.component.html',
  styleUrls: ['./update-bl.component.css']
})
export class UpdateBlComponent implements OnInit{
  isProcessing: boolean = false;
  inProgress: boolean = false;
  public bl_id: string | null = null;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public warningMessage: string | null = null;
  public popupErrorMessage: string | null = null;
  public popupSuccessMessage: string | null = null;
  public popupWarningMessage: string | null = null;
  public bl_data:any;
  makes: any[] = [];
  models: any[] = [];
  colours: any[] = [];
  body_types: any[] = [];
  grades: any[] = [];
  vehicles: any[] = [];
  form!: FormGroup;
  submitted: boolean = false;
  updateComplete: boolean = false;
  invalidMakeSelected: boolean = false;
  invalidModelSelected: boolean = false;
  bl_number:string='';
  public userPermissions: string[] = [];
  jevicPlaceHolder: string="Image or PDF file";
  logBookPlaceHolder: string="Image or PDF file";
  logBookTransPlaceHolder: string="Image or PDF file";
  photosPlaceholder: string="Images only";
  otherDocsPlaceholder: string="Images or PDF files";
  jevic_attachment: any;
  logbook_attachment: any;
  logbook_trans_attachment: any;
  vehicle_photos: File[] = [];
  other_documents: File[] = [];

  @ViewChild('closeModal') closeModal!: ElementRef; // Reference to the modal close button

  constructor(
    private activatedRoute: ActivatedRoute, 
    private billsOfLadingService: BillsOfLadingService, 
    private router: Router,
    private makeService: MakeService,
    private modelService: ModelService,
    private colourService: ColourService,
    private formBuilder: FormBuilder,
    private bodyTypeService:BodyTypeService,
    private gradeService:GradeService,
    private authService: AuthService,
    ){
      this.form = this.formBuilder.group({
        make_id: ['', Validators.required],
        model_id: ['', Validators.required],
        grade_id: [''],
        chassis_no: ['', Validators.required],
        engine_cc: ['', Validators.required],
        engine_no: ['', Validators.required],
        transmission: ['', Validators.required],
        body_type: ['', Validators.required],
        drive: ['', Validators.required],
        fuel: ['', Validators.required],
        mileage: ['', Validators.required],
        colour: ['', Validators.required],
        yr_of_mfg: ['', [Validators.required,Validators.minLength(4),Validators.maxLength(7)]],
        weight: ['', Validators.required],
        cbm: ['', Validators.required],
        reg_no: [''],
        condition: ['', Validators.required],
        no_of_doors: [''],
        no_of_seats: [''],
        jevic_attachment:[null],
        logbook_attachment:[null],
        logbook_trans_attachment:[null],
        other_documents:[null],
        photos:[null]
      });
    }

  ngOnInit(): void {
    
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.bl_id = param['get']('bl_id')
    })

    if(this.bl_id){
      this.inProgress=true;
      this.getBLData(this.bl_id);
      //Load vehicle makes
      this.getVehicleMakes();

      //Load colours
      this.getColours();
      this. getBodyTypes();

       //Get user permissions
      this.userPermissions=this.authService.getPermissions();
    }else{
      //Navigate back to bl list with error
      this.errorMessage="Unable to select BL for update!";
      this.router.navigate(['bl-list']);
    }
  }

  /**
   * Get single BL info
   * @param bl_id 
   */
  getBLData(bl_id: string): void {
    this.billsOfLadingService.viewBL(bl_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.bl_data = response;
          
          if(this.bl_data.status==='no_data' || this.bl_data.status.length===0){
            this.errorMessage=this.bl_data.message;
          }else{
            //Load vehicles
            this.getVehiclesByBL(this.bl_data.data.bl_no);

            this.bl_number=this.bl_data.data.bl_no;
          
            //Hide Add unit button if all units have been added
            if(this.bl_data.data.no_of_units===this.bl_data.data.units_added){
              this.updateComplete=true;

              // Close the modal
              this.closeModal.nativeElement.click();
            }
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  /**
   * Get vehicle makes
   */
  getVehicleMakes(): void {
    this.makeService.getAllMakes().subscribe((response: ApiResponse)=>this.makes=response.data);
  }

  /**
   * Get vehicle models associated with
   * the selected make
   * @param makeID 
   */
  getVehicleModels(makeID:string): void {
    this.modelService.getModelsByMake(makeID).subscribe((response: ApiResponse)=>this.models=response.data);
  }

  /**
   * Get colours
   */
  getColours(): void {
    this.colourService.getAllColours().subscribe((response: ApiResponse)=>this.colours=response.data);
  }

  getBodyTypes():void {
    this.bodyTypeService.getAllBodyTypes().subscribe((response: ApiResponse)=>this.body_types=response.data);
  }

  /**
   * Get vehicle grades associated with
   * the selected model
   * @param modelID 
   */
  getVehicleGrades(modelID:string): void {
    this.gradeService.getGradesByModel(modelID).subscribe((response: ApiResponse)=>this.grades=response.data);
  }


  /**
   * Get vehicles associated with the selected BL
   * @param bl_no 
   */
  getVehiclesByBL(bl_no: string): void {
    this.billsOfLadingService.getVehicles(bl_no).subscribe((response: ApiResponse) =>{
      this.vehicles = response.data
      this.inProgress=false;
      if(response.status==='no_data'){
        this.inProgress=false;
        this.warningMessage="No vehicles associated with this BL were found!";
      }
    });
  }

  //Load models & grades
  onSelectChange(target: EventTarget|null,option:string) {
    if (target instanceof HTMLSelectElement) {

      if(option==='make'){
        const makeID = target.value;
      
        if(makeID!=='' && makeID!==undefined){
          this.getVehicleModels(makeID);
        }else{
          this.invalidMakeSelected=true;
        }
      }else{
        const modelID = target.value;
      
        if(modelID!=='' && modelID!==undefined){
          this.getVehicleGrades(modelID);
        }else{
          this.invalidModelSelected=true;
        }
      }
    }
  }

   /**
   * Handle selected Jevic
   * @param event 
   */
   onJevicSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.jevicPlaceHolder = fileInput.files?.[0]?.name ?? 'Image or PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.jevic_attachment = file
    }
  }

   /**
   * Handle selected LogBook
   * @param event 
   */
   onLogBookSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.logBookPlaceHolder = fileInput.files?.[0]?.name ?? 'Image or PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.logbook_attachment = file
    }
  }

   /**
   * Handle selected LogBook Translation
   * @param event 
   */
   onLogBookTranslationSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.logBookTransPlaceHolder = fileInput.files?.[0]?.name ?? 'Image or PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.logbook_trans_attachment = file
    }
  }

   /**
   * Handle selected other documents
   * @param event 
   */
   onOtherDocsSelected(event: any) {
    //Show selected file
    this.otherDocsPlaceholder = event.target.files.length+" file(s) selected" ?? 'Images or PDF files';
    
    //Prepare photos for upload
    if (event.target.files && event.target.files.length) {

      this.other_documents.push(...event.target.files);
    }
  }

  /**
   * Handle selectd photos
   * @param event 
   */
  onPhotosSelected(event: any) {
    //Show selected file
    this.photosPlaceholder = event.target.files.length+" photo(s) selected" ?? 'Images Only';
    
    //Prepare photos for upload
    if (event.target.files && event.target.files.length) {

      this.vehicle_photos.push(...event.target.files);
    }
  }

  addUnit(): void{
    this.submitted = true;
    this.isProcessing = true;

    if (this.form.valid) {

      const formData = {
        bl_no: this.bl_number,
        ...this.form.value,
      };

      this.billsOfLadingService.updateBL(formData,this.jevic_attachment,this.logbook_attachment,this.logbook_trans_attachment,this.vehicle_photos,this.other_documents,this.bl_id).subscribe({
        next: (response) => {
          if(response.status==='success'){

            this.popupSuccessMessage=response.message;

            //Clear form
            this.form.reset();
            this.jevic_attachment=null;
            this.logbook_attachment=null
            this.logbook_trans_attachment=null;
            this.jevicPlaceHolder='Image or PDF file';
            this.logBookPlaceHolder='Image or PDF file';
            this.logBookTransPlaceHolder='Image or PDF file';
            this.otherDocsPlaceholder ="Images or PDF files";
            this.photosPlaceholder="Images Only";
            this.vehicle_photos.length=0;
            this.other_documents.length=0;
            this.submitted = false;
            this.isProcessing = false;
            this.popupErrorMessage=null;
            this.popupWarningMessage=null;

            if(this.bl_id){
              this.getBLData(this.bl_id);
            }
          }else{
            this.popupErrorMessage=response.message;
            this.isProcessing = false;
            this.submitted = false;
            this.popupSuccessMessage=null;
          }
        },
        error: (error) => {
          this.popupErrorMessage=error;
          this.popupSuccessMessage=null;
          this.isProcessing = false;
          this.submitted = false;
        }
      });
    }else{
      this.popupErrorMessage="Please provide all the required details!";
    }
  }

  removeUnit(bl_id:string,bl_no:string,vehicle_id:string,chassis_no:string){
    if(bl_id===null || bl_id===''){

      this.errorMessage="Unable to select unit for removal. Please try again!";

    }else if(bl_no===null || bl_no===''){

      this.errorMessage="Unable to select unit for removal. Please try again!";

    }else{
      if (confirm("Are you sure you want to remove the unit with Chassis No. "+chassis_no+" from this BL?") == true) {
        this.errorMessage=null;
        this.inProgress=true;
        this.billsOfLadingService.removeUnit(bl_id,bl_no,vehicle_id)
        .subscribe({
          next: (response) => {
            if(response.status==='success'){
              this.successMessage=response.message;
              //Reload BL
              this.getBLData(bl_id);
            }else{
              this.errorMessage=response.message;
              this.inProgress=false;
            }
          },
          error: (error) => {
            // Handle the error
            console.log(error);
            this.inProgress=false;
            this.errorMessage="An error occurred while removing unit from BL. Please try again!";
          }
        });
      }
    }
  }
}
