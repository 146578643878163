import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgentService } from 'src/app/services/agents/agent.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';

@Component({
  selector: 'app-pay-agents',
  templateUrl: './pay-agents.component.html',
  styleUrls: ['./pay-agents.component.css']
})
export class PayAgentsComponent implements OnInit{
  payAgents!: FormGroup;
  public invoiceId: string | null = null;
  public invoiceData:any;
  errorMessage: string | null = null;
  successMessage: string | null = null;
  submitted: boolean = false;
  isProcessing: boolean = false;
  fileName: string="Choose an image or a PDF file";
  attachment: any;

  constructor(
    private formBuilder: FormBuilder,
    private agentService: AgentService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
  ){
    this.payAgents = this.formBuilder.group({
      bl_no: [''],
      no_of_units:[''],
      invoice_no: [''],
      amount_paid: ['', Validators.required],
      pmt_mode: ['', Validators.required],
      pmt_ref: ['', Validators.required],
      pmt_date: ['', Validators.required],
      attachment: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.invoiceId = param['get']('invoice_id')
    })

    if(this.invoiceId){
      this.fetchInvoiceData(this.invoiceId);
    }else{
      //Navigate back to invoices list with error
      this.globalService.setGlobalErrorMessage("Unable to select agent fee invoice for payment. Please try again!");
      this.router.navigate(['agent-invoices']);
    }
  }

  /**
   * Get invoice data
   * @param invoiceId 
   */
  fetchInvoiceData(invoiceId: string): void {
    this.agentService.getOpenAgentInvoiceDetails(invoiceId)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.invoiceData = response;
          if(this.invoiceData.status==='no_data' || this.invoiceData.status.length===0){
            this.errorMessage=this.invoiceData.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  /**
   * Handle selectd file
   * @param event 
   */
  onFileSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.fileName = fileInput.files?.[0]?.name ?? 'Choose an image or a PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.attachment = file
    }
  }

  handleSubmit():void {
    this.submitted = true;
    this.isProcessing = true;

    if(this.payAgents.valid) {
      if(this.invoiceId==null || this.invoiceId==undefined || this.invoiceId==''){
        this.globalService.setGlobalErrorMessage("Invalid invoice selected. Please try again. (Missing: Invoice ID)");
        this.router.navigate(['agent-invoices']);
      }else if(this.payAgents.value.bl_no==null || this.payAgents.value.bl_no==undefined || this.payAgents.value.bl_no==''){
        this.globalService.setGlobalErrorMessage("Unable to post payment. Please try again. (Missing: BL No.)");
        this.router.navigate(['agent-invoices']);
      }else if(this.payAgents.value.invoice_no==null || this.payAgents.value.invoice_no==undefined || this.payAgents.value.invoice_no==''){
        this.globalService.setGlobalErrorMessage("Unable to post payment. Please try again. (Missing: Invoice No.)");
        this.router.navigate(['agent-invoices']);
      }else if(Number.isNaN(Number(String(this.payAgents.value.amount_paid).replace(",","")))){
        this.errorMessage="Only numbers are acceptable in Amount Paid field!";
        this.isProcessing = false;
      }else{
        this.agentService.payAgent(this.payAgents.value,this.attachment)
        .subscribe({
          next: (response) => {
            if(response.status==='success'){
              this.submitted = false;
              this.isProcessing = false;
              this.errorMessage=null;
              this.globalService.setGlobalSuccessMessage(response.message);
              this.router.navigate(['agent-invoices']);
            }else if(response.status==='warning'){
              this.submitted = false;
              this.isProcessing = false;
              this.errorMessage=null;
              this.globalService.setGlobalWarningMessage(response.message);
              this.router.navigate(['agent-invoices']);
            }else{
              this.errorMessage=response.message;
              this.submitted = false;
              this.isProcessing = false;
              this.successMessage=null;
            }
          },
          error: (error) => {
            this.errorMessage=error;
            this.successMessage=null;
            this.submitted = false;
            this.isProcessing = false;
          }
        });
      }
    }else{
      this.isProcessing = false;
    }
  }

}
