import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { CommitmentFeeRefundsService } from 'src/app/services/sales-management/commitment-fee-refunds.service';

@Component({
  selector: 'app-view-commitment-fee-refund-attachments',
  templateUrl: './view-commitment-fee-refund-attachments.component.html',
  styleUrls: ['./view-commitment-fee-refund-attachments.component.css']
})
export class ViewCommitmentFeeRefundAttachmentsComponent implements OnInit{
  public refund_id: string | null = null;
  public isLoading: boolean = false;
  public preview: boolean = false;
  public attachments: any[] = [];
  public errorMessage: string | null = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService:GlobalService,
    private commitmentFeeRefundsService:CommitmentFeeRefundsService,
  ){}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.refund_id = param['get']('refund_id')
    })

    if(this.refund_id){
      this.fetchAttachments(this.refund_id);
    }else{
      //Navigate back to refundable list with error
      this.globalService.setGlobalErrorMessage("Unable to view attachments for the selected record. Please try again!");
      this.router.navigate(['view-refunded-commitment-fees']);
    }
  }

  fetchAttachments(refund_id:string): void {
    this.commitmentFeeRefundsService.getRefundedCommitmentFeeAttachments(refund_id)
    .subscribe({ 
        next: (response) => {
          if(response.status==='success'){
            this.isLoading = false;
            this.attachments = response.data
          }else{
            this.isLoading = false;
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.errorMessage=error;
        }
      });
  }
}
