import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchBankAccount'
})
export class SearchBankAccountPipe implements PipeTransform {

  transform(bankAccounts: any, searchText: string){
    
    if (!searchText) {
      return bankAccounts; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return bankAccounts.filter((bankAccounts: any) => bankAccounts.bank_name.toLowerCase().includes(searchText));
  }
}
