import { Component, OnInit } from '@angular/core';
import { UpdateEntryService } from 'src/app/services/entries/update-entry.service';

@Component({
  selector: 'app-recently-cleared-vehicles',
  templateUrl: './recently-cleared-vehicles.component.html',
  styleUrls: ['./recently-cleared-vehicles.component.css']
})
export class RecentlyClearedVehiclesComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  clearedVehicles: any[] = [];
  searchText: string = '';

  constructor(private updateEntryService: UpdateEntryService){}

  ngOnInit(): void {
    this.getRecentlyClearedVehicles();
  }

  getRecentlyClearedVehicles(): void {
    this.updateEntryService.fetchRecentlyClearedVehicles()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.clearedVehicles = response.data;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.getRecentlyClearedVehicles();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

}
