import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class ViewAttachmentsService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }

   /**
   * Get vehicle attachments
   * @returns Array
   */
  getVehicleAttachments(vehicle_id:any): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/view-vehicle-attachments/${vehicle_id}`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get attachment URL
   * @param attachment_id 
   */
  getAttachmentUrl(attachment_id: string){

    let apiURL:string=`${this.baseURL}/v1/vehicle/attachment-url/${attachment_id}`;

    return this.http.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Update attachment
   */
  updateVehicleAttachment(upload_type: string,upload_ref: string,vehicle_id:string,file_url: string,attachment:any):Observable<any>{
    const formData = new FormData();
    formData.append("upload_type", upload_type);
    formData.append("upload_ref", upload_ref);
    formData.append("vehicle_id", vehicle_id);
    formData.append("file_url", file_url);
    formData.append('attachment', attachment);
    
    let apiURL:string=`${this.baseURL}/v1/update-vehicle-attachment`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Add new attachment
   */
  addNewVehicleAttachment(vehicle_id:string,form_data:any,attachment:any):Observable<any>{
    const formData = new FormData();
    formData.append("vehicle_id", vehicle_id);
    formData.append("doc_type", form_data.doc_type);
    formData.append("doc_name", form_data.doc_name);
    formData.append('attachment', attachment);
    
    let apiURL:string=`${this.baseURL}/v1/add-vehicle-attachment`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

}
