import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BillsOfLadingService } from 'src/app/services/bills-of-lading/bills-of-lading.service';
import { AgentService } from 'src/app/services/agents/agent.service';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/shared/global.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bl-list',
  templateUrl: './bl-list.component.html',
  styleUrls: ['./bl-list.component.css']
})
export class BlListComponent implements OnInit{
  @ViewChild('UpdateAttachmentModal') modalElement!: ElementRef;
  public userPermissions: string[] = [];
  page: number = 1;
  count: number = 0;
  bills_of_lading: any[] = [];
  agents: any[] = [];
  selectedBLs: any[] = []; // Array to store selected BLs
  selectedAgentId: string='';
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  searchText: string = '';
  public isLoading: boolean = false;
  total_units: any = 0;
  total_units_added: any = 0;
  form!: FormGroup;
  fileName: string="Choose an image or a PDF file";
  attachment: any;
  isProcessing: boolean = false;
  submitted: boolean = false;
  public bl_no:string='';

  constructor(
      private billsOfLadingService: BillsOfLadingService, 
      private agentService: AgentService, 
      private authService: AuthService,
      private globalService: GlobalService,
      private formBuilder: FormBuilder
    ){
      this.form = this.formBuilder.group({
        attachment:[null,Validators.required]
      });
    }

  ngOnInit(): void {
    this.isLoading=true;
    this.userPermissions=this.authService.getPermissions();
    this.getBillsOflading();
    this.getAgents();
  }

  getBillsOflading(): void {
    this.billsOfLadingService.getAllBLs()
    .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.isLoading = false;
            this.bills_of_lading = response.data
            this.getTotalUnits();
            this.getTotalUnitsAdded();
          }else{
            this.isLoading = false;
            this.errorMessage=response.message;
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.errorMessage=error;
        }
      });
  }

  getAgents(): void{
    this.agentService.getAllAgents().subscribe((response: ApiResponse) =>this.agents = response.data);
  }

  // Function to handle checkbox change event
  checkboxChange(event: any, item: any) {
    if (event.target.checked) {
      // Add checked checkbox to the array
      this.selectedBLs.push(item);
    } else {
      // Remove unchecked checkbox from the array
      const index = this.selectedBLs.indexOf(item);
      if (index !== -1) {
        this.selectedBLs.splice(index, 1);
      }
    }
  }

  // Assign BLs to Agent
  assignBLstoAgent() {
    const agentId = this.selectedAgentId;
   
    if(agentId=="" || agentId==undefined){

      this.errorMessage="Please select an agent to proceed!";

    }else if(this.selectedBLs.length===0){

      this.errorMessage="Please select BLs to assign to an agent!";

    }else{
      this.billsOfLadingService.assignBLstoAgent(agentId, this.selectedBLs)
      .subscribe(
        response => {
          this.successMessage="BLs assigned successfully!";
          //Clear BLs array
          this.selectedBLs.length = 0;
          // Refresh to BL list
          this.getBillsOflading();
        },
        error => {
          // Handle the error
          console.error('Error assigning BLs to agent:', error);
          this.errorMessage="An Error occurred while assigning BLs to agent. Please try again!";
        }
      );
    }
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.getBillsOflading();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  getTotalUnits(): void{
    this.billsOfLadingService.getUnclearedTotalUnits().subscribe((response: ApiResponse) =>this.total_units = response.data);
  }

  getTotalUnitsAdded(): void{
    this.billsOfLadingService.getUnclearedTotalUnitsAdded().subscribe((response: ApiResponse) =>this.total_units_added = response.data);
  }

  openModal(bl_no:string){
    this.bl_no=bl_no;

    if (this.modalElement) {
      (<any>$(this.modalElement.nativeElement)).modal('show');
    }
  }

  closeModal():void {
    if (this.modalElement) {
      (<any>$(this.modalElement.nativeElement)).modal('hide');
    }
  }

   /**
   * Handle selectd file
   * @param event 
   */
   onFileSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.fileName = fileInput.files?.[0]?.name ?? 'Choose an image or a PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.attachment = file
    }
  }

  uploadAttachment(){
    this.submitted = true;
    this.isProcessing=true;

    if (this.form.valid) {
      this.billsOfLadingService.uploadAttachment(this.attachment,this.bl_no)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.globalService.setGlobalSuccessMessage(response.message);
            this.closeModal();
            this.getBillsOflading();
          }else if(response.status==='upload_error'){
            this.submitted = false;
            this.globalService.setGlobalWarningMessage(response.message);
            this.closeModal();
          }else if(response.status==='warning'){
            this.submitted = false;
            this.globalService.setGlobalWarningMessage(response.message);
            this.closeModal();
          }else{
            this.submitted = false;
            this.isProcessing=false;
            this.errorMessage=response.message;
            this.closeModal();
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isProcessing=false;
          this.errorMessage=error;
          this.closeModal();
        }
      });
    }else{
      this.isProcessing=false;
    }
  }

  deleteBL(event: Event,bl_id:string,bl_no:string){
    event.preventDefault();
    this.confirmDelete(bl_id,bl_no);
  }

  confirmDelete(bl_id: string,bl_no: string){
    Swal.fire({
      title: 'Confirm Delete',
      text: 'Are you sure you want to delete the BL identified by BL No '+bl_no+'?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.isProcessing=true;

        this.billsOfLadingService.deleteBL(bl_id,bl_no)
        .subscribe({
          next: (response) => {
            if(response.status==='success'){
              this.isProcessing=false;
              this.successMessage=response.message;
              this.errorMessage=''; //Clear error message incase it's available
             
              //Refresh BL List
              this.getBillsOflading();
            }else if(response.status==='error'){
              this.isProcessing=false;
              this.errorMessage=response.message;
            }else{
              this.isProcessing=false;
              this.errorMessage=response.message;
            }
          },
          error: (error) => {
            this.isProcessing=false;
            this.errorMessage=error;
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      
      }
    })
  }
}
