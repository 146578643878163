  <!--Loading Spinner-->
  <div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>{{ 'forms.exports.add_unit.label' | translate }}</h3><!--Add Unit-->
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/suppliers/assign-units-to-bl"><i class="fas fa-folder-plus"></i>&nbsp;{{ 'sidebar.exports.assign_units_bl.label' | translate }}</a></li><!--Assign Units to BLs-->
               <li class="breadcrumb-item active">{{ 'forms.exports.add_unit.label' | translate }}</li><!--Add Unit-->
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
            <h3 class="card-title">{{ 'forms.exports.add_unit.label' | translate }}</h3><!--Add Unit-->
            <!--Error message-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
            </div>

             <!--Warning message-->
             <div class="alert alert-warning" role="alert" *ngIf="warningMessage">
                {{warningMessage}}
            </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="handleSubmit()">
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.make'|translate}}<span style="color:red;">*</span></label><!--Make-->
                        <select formControlName="make_id" class="form-control" (change)="onSelectChange($event.target,'make')">
                            <option value="">{{'forms.dropdowns.default_value'|translate}}</option><!--Select-->
                            <option [value]="make.id" *ngFor="let make of makes">{{ make.make_name }}</option>
                        </select>

                        <span *ngIf="submitted && form.get('make_id')?.hasError('required')" style="color:red"> <strong>Please select vehicle make!</strong></span>
                        <span *ngIf="invalidMakeSelected" style="color:red"> <strong>Invalid vehicle make selection!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.model'|translate}}<span style="color:red;">*</span></label><!--Model:-->
                        <select formControlName="model_id" class="form-control" (change)="onSelectChange($event.target,'model')">
                            <option value="">{{'forms.dropdowns.default_value'|translate}}</option><!--Select-->
                            <option [value]="model.id" *ngFor="let model of models">{{ model.model_name }}</option>
                        </select>

                        <span *ngIf="submitted && form.get('model_id')?.hasError('required')" style="color:red"><strong>Please select vehicle model</strong></span>
                        <span *ngIf="invalidModelSelected" style="color:red"> <strong>Invalid vehicle model selection!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.grade'|translate}}</label><!--Grade:-->
                        <select formControlName="grade_id" class="form-control">
                            <option value="">{{'forms.dropdowns.default_value'|translate}}</option><!--Select-->
                            <option [value]="grade.id" *ngFor="let grade of grades">{{ grade.grade }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.chassis_no'|translate}}<span style="color:red;">*</span></label><!--Chassis No.-->
                        <input type="text" formControlName="chassis_no" class="form-control" value="">

                        <span *ngIf="submitted && form.get('chassis_no')?.hasError('required')" style="color:red"><strong>Chassis No. is required!</strong> </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.engine_no'|translate}}<span style="color:red;">*</span></label><!--Engine No.-->
                        <input type="text" formControlName="engine_no" class="form-control" value="">

                        <span *ngIf="submitted && form.get('engine_no')?.hasError('required')" style="color:red"><strong>Engine No. is required!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.engine_cc'|translate}}<span style="color:red;">*</span></label><!---Engine CC-->
                        <input type="text" formControlName="engine_cc" class="form-control" value="">

                        <span *ngIf="submitted && form.get('engine_cc')?.hasError('required')" style="color:red"><strong>Engine CC is required!</strong></span>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.trans'|translate}}<span style="color:red;">*</span></label><!--Transmission-->
                        <select formControlName="transmission" class="form-control">
                            <option value="">{{'forms.dropdowns.default_value'|translate}}</option><!--Select-->
                            <option value="MANUAL">MANUAL</option>
                            <option value="AUTOMATIC">AUTOMATIC</option>
                        </select>
                    </div>

                    <span *ngIf="submitted && form.get('transmission')?.hasError('required')" style="color:red"><strong>Please select transmission</strong></span>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.body_type'|translate}}<span style="color:red;">*</span></label><!--Body Type-->
                        <select formControlName="body_type" class="form-control">
                            <option value="">{{'forms.dropdowns.default_value'|translate}}</option><!--Select-->
                            <option [value]="body_type.name" *ngFor="let body_type of body_types">{{ body_type.name }}</option>
                        </select>

                        <span *ngIf="submitted && form.get('body_type')?.hasError('required')" style="color:red"><strong>Please select vehicle body type!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.drive'|translate}}<span style="color:red;">*</span></label><!--Drive-->
                        <select formControlName="drive" class="form-control">
                            <option value="">{{'forms.dropdowns.default_value'|translate}}</option><!--Select-->
                            <option value="2WD">2WD</option>
                            <option value="4WD">4WD</option>
                        </select>

                        <span *ngIf="submitted && form.get('drive')?.hasError('required')" style="color:red"><strong>Please select drive!</strong></span>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.fuel'|translate}}<span style="color:red;">*</span></label><!--Fuel-->
                        <select formControlName="fuel" class="form-control">
                            <option value="">{{'forms.dropdowns.default_value'|translate}}</option><!--Select-->
                            <option value="PETROL">PETROL</option>
                            <option value="PETROL (Hybrid)">PETROL (Hybrid)</option>
                            <option value="DIESEL">DIESEL</option>
                            <option value="DIESEL (Hybrid)">DIESEL (Hybrid)</option>
                            <option value="ELECTRIC">ELECTRIC</option>
                        </select>

                        <span *ngIf="submitted && form.get('fuel')?.hasError('required')" style="color:red"><strong>Please select fuel type!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.mileage'|translate}}<span style="color:red;">*</span></label><!--Mileage-->
                        <input type="text" formControlName="mileage" class="form-control" value="">

                        <span *ngIf="submitted && form.get('mileage')?.hasError('required')" style="color:red"><strong>Mileage is required!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.colour'|translate}}<span style="color:red;">*</span></label><!--Colour-->
                        <select formControlName="colour" class="form-control">
                            <option value="">{{'forms.dropdowns.default_value'|translate}}</option><!--Select-->
                            <option [value]="colour.colour_name" *ngFor="let colour of colours">{{ colour.colour_name }}</option>
                        </select>

                        <span *ngIf="submitted && form.get('colour')?.hasError('required')" style="color:red"><strong>Please select vehicle colour!</strong></span>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.yr_of_mfg'|translate}}<span style="color:red;">*</span></label><!--Yr of Mfg (YYYY/MM)-->
                        <input type="text" formControlName="yr_of_mfg" class="form-control" value="">

                        <span *ngIf="submitted && form.get('yr_of_mfg')?.hasError('required')" style="color:red"><strong>Year of manufcture is required!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.weight'|translate}}</label><!--Weight-->
                        <input type="text" formControlName="weight" class="form-control" value="">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>CBM ({{'forms.labels.add_unit.cbm'|translate}}):</label>
                        <input type="text" formControlName="cbm" class="form-control" value="">
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.reg_no'|translate}}</label><!--Reg No.-->
                        <input type="text" formControlName="reg_no" class="form-control" value="">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.condition'|translate}}<span style="color:red;">*</span></label><!--Condition-->
                        <select formControlName="condition" class="form-control">
                            <option value="">{{'forms.dropdowns.default_value'|translate}}</option><!--Select-->
                            <option value="1">Brand New</option>
                            <option value="2">Foreign Used</option>
                        </select>

                        <span *ngIf="submitted && form.get('condition')?.hasError('required')" style="color:red"><strong>Please select drive!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.no_of_doors'|translate}}</label><!--No. of doors-->
                        <input type="text" formControlName="no_of_doors" class="form-control" value="">
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{'forms.labels.add_unit.no_of_seats'|translate}}</label><!--No. of seats-->
                        <input type="text" formControlName="no_of_seats" class="form-control" value="">
                    </div>
                </div>
                <div class="col-md-4">
                    <label>{{'forms.labels.add_unit.attach_jevic'|translate}}</label><!--Attach Jevic-->
                    <div class="input-group mb-3">
                        <div class="custom-file">
                          <input type="file" formControlName="jevic_attachment" accept="application/pdf,image/*" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onJevicSelected($event)">
                          <label class="custom-file-label" for="inputGroupFile01">{{ jevicPlaceHolder }}</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <label>{{'forms.labels.add_unit.attach_jp_logbk'|translate}}</label><!--Attach Japan LogBook-->
                    <div class="input-group mb-3">
                        <div class="custom-file">
                          <input type="file" formControlName="logbook_attachment" accept="application/pdf,image/*" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onLogBookSelected($event)">
                          <label class="custom-file-label" for="inputGroupFile01">{{ logBookPlaceHolder }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <label>{{'forms.labels.add_unit.attach_logbk_translation'|translate}}</label><!--Attach LogBook Translation-->
                    <div class="input-group mb-3">
                        <div class="custom-file">
                          <input type="file" formControlName="logbook_trans_attachment" accept="application/pdf,image/*" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onLogBookTranslationSelected($event)">
                          <label class="custom-file-label" for="inputGroupFile01">{{ logBookTransPlaceHolder }}</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <label>{{'forms.labels.add_unit.other_docs'|translate}}</label><!--Other Documents (Multiple files)-->
                    <div class="input-group mb-3">
                        <div class="custom-file">
                          <input type="file" formControlName="other_documents" accept="application/pdf,image/*" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" multiple (change)="onOtherDocsSelected($event)">
                          <label class="custom-file-label" for="inputGroupFile01">{{ otherDocsPlaceholder }}</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <label>{{'forms.labels.add_unit.main_photo'|translate}}</label><!--Main Photo-->
                    <div class="input-group mb-3">
                        <div class="custom-file">
                          <input type="file" formControlName="main_photo" accept="image/*" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onMainPhotoSelected($event)">
                          <label class="custom-file-label" for="inputGroupFile01">{{ mainPhotoPlaceholder }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <label>{{'forms.labels.add_unit.photos'|translate}}</label><!--Photos (Multiple files allowed)-->
                    <div class="input-group mb-3">
                        <div class="custom-file">
                          <input type="file" formControlName="photos" accept="image/*" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" multiple (change)="onPhotosSelected($event)">
                          <label class="custom-file-label" for="inputGroupFile01">{{ photosPlaceholder }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Processing...
            </button> 
            
            <ng-template #showSaveBtn>
                <button type="submit" class="btn btn-success" [disabled]="!form.valid"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>{{ 'forms.buttons.save.label' | translate }}</button>
            </ng-template>
            </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>