import { Component, OnInit } from '@angular/core';
import { UpdateEntryService } from 'src/app/services/entries/update-entry.service';
import { ActivatedRoute, Params,Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/shared/global.service';
import { PendingTasksHandler } from 'src/app/shared/pending-tasks-handler';

@Component({
  selector: 'app-update-entry',
  templateUrl: './update-entry.component.html',
  styleUrls: ['./update-entry.component.css']
})
export class UpdateEntryComponent implements OnInit{

  public errorMessage: string | null = null;
  public vehicle_id: string | null = null;
  entry: any;
  form!: FormGroup;
  submitted: boolean = false;
  fileName: string="Choose a PDF file";
  attachment: any;
  isOfficialPmt: boolean = true;
  docRefNoIsEmpty: boolean = false;
  pmtRefNoIsEmpty: boolean = false;
  attachmentIsEmpty: boolean = false;
  isProcessing: boolean = false;

  constructor(
    private updateEntryService: UpdateEntryService,
    private activatedRoute:ActivatedRoute, 
    private formBuilder: FormBuilder,
    private router: Router,
    private globalService: GlobalService,
    private pendingTasksHandler: PendingTasksHandler
    ){
    this.form = this.formBuilder.group({
      entry_id:[''],
      entry_no:['',Validators.required],
      chassis_no:['',Validators.required],
      duty_amount: ['', Validators.required],
      doc_ref_no: ['',],
      payment_ref_no: ['',],
      attachment: [null,],
      payment_type:['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.vehicle_id = param['get']('vehicle_id')
    })

    if(this.vehicle_id){
      this.getEntryData(this.vehicle_id);
    }else{
      this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
    }
  }

  /**
   * Get entry details
   * @param vehicle_id 
   */
  getEntryData(vehicle_id: string): void{
    this.updateEntryService.fetchEntryDetails(vehicle_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.entry = response;

          if(this.entry.status==='no_data' || this.entry.status.length===0){
            this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again.";
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  onSelectChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {

      const paymentType = target.value;

      if(paymentType==='Unofficial'){
        this.isOfficialPmt=false;
      }else{
        this.isOfficialPmt=true;
      }
    }
  }

  /**
   * Handle selectd file
   * @param event 
   */
  onFileSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.fileName = fileInput.files?.[0]?.name ?? 'Choose a PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.attachment = file
    }
  }

  /**
   * Update entry
   */
  handleSubmit() {
    this.submitted = true;
    this.isProcessing=true;

    if(this.form.valid) {
      if(this.form.value.payment_type=='Official' && this.form.value.doc_ref_no==''){
        this.docRefNoIsEmpty=true;
        this.isProcessing=false;
      }else if(this.form.value.payment_type=='Official' && this.form.value.payment_ref_no==''){
        this.pmtRefNoIsEmpty=true;
        this.isProcessing=false;
      }else if(this.form.value.payment_type=='Official' && this.form.value.attachment==null){
        this.attachmentIsEmpty=true;
        this.isProcessing=false;
      }else{
        this.updateEntryService.updateEntry(this.form.value,this.attachment)
        .subscribe({
          next: (response) => {
            if(response.status==='success'){
              this.submitted = false;
              this.isProcessing=false;
              this.globalService.setGlobalSuccessMessage(response.message);
              //Count the remaining unpaid duties
              this.pendingTasksHandler.numberOfPendingDutyPayments();
              // Redirect to recently cleared vehicles
              this.router.navigate(['recently-cleared-vehicles']);
            }else if(response.status==='upload_error'){
              this.submitted = false;
              this.isProcessing=false;
              this.globalService.setGlobalWarningMessage(response.message);
              // Redirect to recently cleared vehicles
              this.router.navigate(['recently-cleared-vehicles']);
            }else{
              this.submitted = false;
              this.isProcessing=false;
              this.errorMessage=response.message;
            }
          },
          error: (error) => {
            this.submitted = false;
            this.isProcessing=false;
            this.errorMessage=error;
          }
        });
      }
    }else{
      this.isProcessing=false;
    }
  }

}
