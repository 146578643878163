import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CashManagementService } from 'src/app/services//cash-management/cash-management.service';
import { GlobalService } from 'src/app/services/shared/global.service';

@Component({
  selector: 'app-enter-opening-balance',
  templateUrl: './enter-opening-balance.component.html',
  styleUrls: ['./enter-opening-balance.component.css']
})
export class EnterOpeningBalanceComponent {
  isProcessing:boolean=false;
  isLoading:boolean=false;
  public errorMessage: string | null = null;
  form!: FormGroup;
  submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private cashManagementService: CashManagementService,
    private globalService: GlobalService,
    private router: Router,
  ){
    this.form = this.formBuilder.group({
      txn_date: ['', Validators.required],
      amount: ['', Validators.required],
    });
  }

  handleSubmit(){
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      const formData = {
        ...this.form.value,
      };

      this.cashManagementService.saveOpeningBalance(formData)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.isProcessing = false;
            this.globalService.setGlobalSuccessMessage(response.message);
            // Redirect cash transactions
            this.router.navigate(['cash-mgnt/transactions']);
          }else{
            this.errorMessage=response.message;
            this.isProcessing = false;
          }
        },
        error: (error) => {
          this.errorMessage=error;
          this.isProcessing = false;
        }
      });
    }else{
      this.isProcessing = false;
    }
  }
}
