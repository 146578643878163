<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Update Tax Settings</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item active">Update Tax Settings</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Update Tax Settings</h3>
                <!--Error Message-->
                <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                    <span [innerHTML]="errorMessage"></span>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <form [formGroup]="form" (ngSubmit)="handleSubmit()">
                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Withholding Tax (%):<span style="color:red;">*</span></label>
                                <input type="text" formControlName="wh_tax_pct" class="form-control" placeholder="Withholding Tax" [(ngModel)]="taxData.data.wh_tax_pct" value="">
                                <span *ngIf="submitted && form.get('wh_tax_pct')?.hasError('required')" style="color:red"> <strong>Withholding Tax percentage deduction is required!</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Withholding VAT (%):<span style="color:red;">*</span></label>
                                <input type="text" formControlName="wh_vat_pct" class="form-control" placeholder="Withholding VAT" [(ngModel)]="taxData.data.wh_vat_pct" value="">
                                <span *ngIf="submitted && form.get('wh_vat_pct')?.hasError('required')" style="color:red"> <strong>Withholding VAT percentage deduction is required!</strong></span>
                            </div>
                        </div>
                    </div>
                    
                    <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Processing...
                    </button>
                    <ng-template #showSaveBtn>
                        <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Update</button>
                    </ng-template>
                </form>
            </div>
            <!-- /.card-body -->
        </div>
   </div>
   </section>