<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4" *ngIf="isLoggedIn">
    <!-- Brand Logo -->
    <a routerLink="/" class="brand-link">
      <img src="/assets/adminlte/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
      <span class="brand-text font-weight-light">Al-Husnain Motors Ltd</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img src="/assets/adminlte/img/no_image.jpg" class="img-circle elevation-2" alt="User Image">
        </div>
        <div class="info">
          <a href="#" class="d-block">{{ user_full_name }}</a>
        </div>
      </div>
      
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

          <li class="nav-item">
            <a routerLink="/" class="nav-link">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>Dashboard</p>
            </a>
          </li>

          <li class="nav-item" *ngIf="userPermissions.includes('exp-add-unit') || userPermissions.includes('exp-enter-bl') || userPermissions.includes('assign-units-to-bl') || userPermissions.includes('exp-view-bl-list') || userPermissions.includes('exp-update-vsl-eta') || userPermissions.includes('exp-view-stk-list') || userPermissions.includes('add-imp-docs')">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-ship"></i>
              <p>
                &nbsp;Exports
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item" *ngIf="userPermissions.includes('exp-add-unit')">
                <a routerLink="/suppliers/add-unit" class="nav-link">
                  <i class="fas fa-plus-circle"></i>&nbsp;
                  <p>Add Units</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('exp-enter-bl')">
                <a routerLink="/suppliers/add-new-bl" class="nav-link">
                  <i class="fas fa-file"></i>&nbsp;
                  <p>Add New BL</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('assign-units-to-bl')">
                <a routerLink="/suppliers/assign-units-to-bl" class="nav-link">
                  <i class="fas fa-folder-plus"></i>&nbsp;
                  <p>Assign Units to BLs</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('exp-view-bl-list')">
                <a routerLink="/suppliers/bl-list" class="nav-link">
                  <i class="fas fa-eye"></i>&nbsp;
                  <p>View Bills Of Lading</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('exp-update-vsl-eta')">
                <a routerLink="/suppliers/update-vessel-eta" class="nav-link">
                  <i class="fas fa-calendar-alt"></i>&nbsp;
                  <p>Update Vessel ETA</p>
                  <span class="badge badge-warning right">{{noOfBlsPendingETAUpdatePmt}}</span>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('exp-view-stk-list')">
                <a routerLink="/suppliers/stock-list" class="nav-link">
                  <i class="fas fa-list-ul"></i>&nbsp;
                  <p>Stock List</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('add-imp-docs')">
                <a routerLink="/vehicles/pending-imp-docs-upload" class="nav-link">
                  <i class="fas fa-file-alt"></i>
                  <p>&nbsp;Upload Import Docs</p>
                  <span class="badge badge-warning right">{{noOfVehiclesPendingDocsUpload}}</span>
                </a>
              </li>
            </ul>
          </li>
      
          <li class="nav-item" *ngIf="userPermissions.includes('enter-bl') || userPermissions.includes('view-bl-list') || userPermissions.includes('edit-bl') ||  userPermissions.includes('view-bl') || userPermissions.includes('update-bl') || userPermissions.includes('delete-bl') || userPermissions.includes('assign-bl') || userPermissions.includes('add-entry') ||  userPermissions.includes('update-entry') || userPermissions.includes('add-do-rad-pmt') ||  userPermissions.includes('add-duty-pmt') ||  userPermissions.includes('attach-duty-pmt-rcpt') || userPermissions.includes('update-vsl-eta') || userPermissions.includes('add-cnf-pmt') || userPermissions.includes('add-port-chgs') || userPermissions.includes('edit-port-chgs-inv') || userPermissions.includes('del-port-chgs-inv')">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-anchor"></i>
              <p>
                Imports & Customs
                <i class="fas fa-angle-left right"></i>
                <span class="badge badge-danger right">{{noOfCustomsPendingTasks}}</span>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item" *ngIf="userPermissions.includes('enter-bl')">
                <a routerLink="/enter-bl" class="nav-link">
                  <i class="fas fa-file"></i>&nbsp;
                  <p>Enter BL</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('view-bl-list')">
                <a routerLink="/bl-list" class="nav-link">
                  <i class="fas fa-list-ul"></i>&nbsp;
                  <p>BLs List</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('update-vsl-eta')">
                <a routerLink="/update-vessel-eta" class="nav-link">
                  <i class="fas fa-calendar-alt"></i>&nbsp;
                  <p>Update Vessel ETA</p>
                  <span class="badge badge-warning right">{{noOfBlsPendingETAUpdatePmt}}</span>
                </a>
              </li>
              <li class="nav-item"  *ngIf="userPermissions.includes('add-do-rad-pmt')">
                <a routerLink="/do-rad-payment/select-bl" class="nav-link">
                  <i class="fas fa-list-ul"></i>&nbsp;
                  <p>D.O & Radiation Pmt.</p>
                  <span class="badge badge-warning right">{{noOfBlsPendingDOAndRadiationPmt}}</span>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('add-port-chgs') || userPermissions.includes('edit-port-chgs-inv') || userPermissions.includes('del-port-chgs-inv')">
                <a routerLink="/port-charges-invoices" class="nav-link">
                  <i class="fas fa-money-bill-alt"></i>&nbsp;
                  <p>Port Charges Invoices</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('add-port-chgs')">
                <a routerLink="/add-port-charges/select-bl" class="nav-link">
                  <i class="fas fa-money-bill-alt"></i>&nbsp;
                  <p>Add Port Charges</p>
                  <span class="badge badge-warning right">{{noOfBlsPendingPortChargesPmt}}</span>
                </a>
              </li>
              <!---Start Duty Payments--->
              <li class="nav-item" *ngIf="userPermissions.includes('add-duty-pmt') || userPermissions.includes('attach-duty-pmt-rcpt')">
                <a href="#" class="nav-link">
                  <i class="nav-icon far fa-money-bill-alt"></i>
                  <p>
                    Duty Payments
                    <i class="right fas fa-angle-left"></i>
                    <span class="badge badge-danger right">{{noOfDutyPendingTasks}}</span>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <!--Entry transactions-->
                  <li class="nav-item" *ngIf="userPermissions.includes('add-entry') || userPermissions.includes('update-entry')">
                    <a href="#" class="nav-link">
                      <i class="nav-icon fas fa-folder-open"></i>
                      <p>
                        Entry Transactions
                        <i class="right fas fa-angle-left"></i>
                      </p>
                    </a>
                    <ul class="nav nav-treeview">
                      <li class="nav-item"  *ngIf="userPermissions.includes('add-entry')">
                        <a routerLink="/imported-vehicles" class="nav-link">
                          <i class="fas fa-folder-plus nav-icon"></i>
                          <p>Add Entry</p>
                          <span class="badge badge-warning right">{{noOfPendingEntries}}</span>
                        </a>
                      </li>
                      <li class="nav-item"  *ngIf="userPermissions.includes('update-entry')">
                        <a routerLink="/recently-cleared-vehicles" class="nav-link">
                          <i class="fas fa-eraser nav-icon"></i>
                          <p>Additional Duty</p>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <!--End Entry transactions-->
                </ul>
                <ul class="nav nav-treeview">
                  <li class="nav-item" *ngIf="userPermissions.includes('add-duty-pmt')">
                    <a routerLink="/unpaid-duties" class="nav-link">
                      <i class="fas fa-money-check nav-icon"></i>
                      <p>Add Payment</p>
                      <span class="badge badge-warning right">{{noOfUnpaidDuties}}</span>
                    </a>
                  </li>
                  <li class="nav-item" *ngIf="userPermissions.includes('attach-duty-pmt-rcpt')">
                    <a routerLink="/paid-duties-pending-attachment" class="nav-link">
                      <i class="fas fa-paperclip nav-icon"></i>
                      <p>Attach Pmt. Receipts</p>
                      <span class="badge badge-warning right">{{noOfPaymentsPendingReceiptsAttachment}}</span>
                    </a>
                  </li>
                </ul>
              </li>
              <!---End Duty Payments--->
              <li class="nav-item" *ngIf="userPermissions.includes('add-cnf-pmt')">
                <a routerLink="/import-cnf-payments" class="nav-link">
                  <i class="fas fa-yen-sign"></i>&nbsp;
                  <p>Import C & F Payments</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('pay-agents')">
                <a routerLink="/agent-invoices" class="nav-link">
                  <i class="fab fa-amazon-pay"></i>&nbsp;
                  <p>Pay Agents</p>
                </a>
              </li>
            </ul>
          </li>
          <!--Vehicle Managment-->
          <li class="nav-item" *ngIf="userPermissions.includes('view-vhcl-list') || userPermissions.includes('import-vhcl-regnos') || userPermissions.includes('import-stk-at-hnd') || userPermissions.includes('view-vhcl-details') || userPermissions.includes('add-vhcl-photos') || userPermissions.includes('ver-spare-parts')">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-car"></i>
              <p>
                Vehicle Management
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item" *ngIf="userPermissions.includes('view-vhcl-list')">
                <a routerLink="/vehicles" class="nav-link">
                  <i class="fas fa-list-ul"></i>
                  <p>&nbsp;Vehicle List</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('add-vhcl-photos')">
                <a routerLink="/vehicles/pending-photo-upload" class="nav-link">
                  <i class="fas fa-image"></i>
                  <p>&nbsp;Upload Vehicle Photos</p>
                  <span class="badge badge-warning right">{{noOfVehiclesPendingPhotoUpload}}</span>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('view-vhcl-atts')">
                <a routerLink="/vehicles/pending-docs-upload" class="nav-link">
                  <i class="fas fa-file-alt"></i>
                  <p>&nbsp;Upload Documents</p>
                  <span class="badge badge-warning right">{{noOfVehiclesPendingDocsUpload}}</span>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('ver-spare-parts')">
                <a routerLink="/vehicles/pending-parts-verification" class="nav-link">
                  <i class="fas fa-check"></i>
                  <p>&nbsp;Verify Spare Parts</p>
                  <span class="badge badge-warning right">{{noOfVehiclesPendingPartsVerification}}</span>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('import-vhcl-regnos')">
                <a routerLink="/update-reg-nos" class="nav-link">
                  <i class="fas fa-file-import"></i>
                  <p>&nbsp;Import Reg. Numbers</p>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="/add-trade-in-vehicle" class="nav-link" *ngIf="userPermissions.includes('add-trade-in-vhcl')">
                  <i class="fas fa-exchange-alt"></i>
                  <p>&nbsp;Add Trade In Vehicle</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('import-stk-at-hnd')">
                <a routerLink="/import-stock-at-hand" class="nav-link">
                  <i class="fas fa-file-import"></i>
                  <p>&nbsp;Import Stock At Hand</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('import-stk-at-hnd')">
                <a routerLink="/import-sold-vehicles" class="nav-link">
                  <i class="fas fa-file-import"></i>
                  <p>&nbsp;Import Sold Vehicles</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('import-stk-at-hnd')">
                <a routerLink="/stock-reconciliation" class="nav-link">
                  <i class="fas fa-file-import"></i>
                  <p>&nbsp;Stock Reconciliation</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('edit-vhcl')">
                <a routerLink="/update-vehicle-info" class="nav-link">
                  <i class="fas fa-eraser"></i>
                  <p>&nbsp;Update Vehice Info</p>
                </a>
              </li>
            </ul>
          </li>
          <!--End Vehicle Management-->
          <!--Stock Management-->
          <li class="nav-item" *ngIf="userPermissions.includes('view-vhcl-dtls-exp-sum') || userPermissions.includes('view-all-avail-stk') || userPermissions.includes('view-stk-enr') || userPermissions.includes('view-stk-at-cfs') || userPermissions.includes('view-sld-cars') || userPermissions.includes('view-vhcls-in-transit') || userPermissions.includes('view-vhcl-transfer-hist') || userPermissions.includes('print-stk')">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-hdd"></i>
              <p>
                Stock Overview
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item" *ngIf="userPermissions.includes('view-vhcl-dtls-exp-sum')">
                <a routerLink="/stock-overview/all-stock" class="nav-link">
                  <i class="fas fa-database nav-icon"></i>
                  <p>&nbsp;All Stock</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('view-all-avail-stk')">
                <a routerLink="/stock-overview/all-available-stock" class="nav-link">
                  <i class="far fa-compass nav-icon"></i>
                  <p>&nbsp;All Available Stock</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('view-stk-enr')">
                <a routerLink="/stock-overview/stock-en-route" class="nav-link">
                  <i class="far fa fa-water nav-icon"></i>
                  <p>&nbsp;En route</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('view-stk-at-cfs')">
                <a routerLink="/stock-overview/stock-at-cfs" class="nav-link">
                  <i class="far fas fa-anchor nav-icon"></i>
                  <p>&nbsp;On Port/CFS</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('view-sld-cars')">
                <a routerLink="/stock-overview/sold-cars" class="nav-link">
                  <i class="far fa-money-bill-alt nav-icon"></i>
                  <p>&nbsp;Sold</p>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="/print-stock" class="nav-link" *ngIf="userPermissions.includes('print-stk')">
                  <i class="fa fa-print nav-icon"></i>
                  <p>Print Stock</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('view-vhcls-in-transit') || userPermissions.includes('view-vhcl-transfer-hist')">
                <a href="#" class="nav-link">
                  <i class="nav-icon fas fa-sync"></i>
                  <p>
                    &nbsp;Vehicle Transfers
                    <i class="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item" *ngIf="userPermissions.includes('view-vhcls-in-transit')">
                    <a routerLink="/vehicle-transfer-in-transit" class="nav-link">
                      <i class="fas fa-angle-double-right"></i>&nbsp;
                      <p>In Transit</p>
                    </a>
                  </li>
                  <li class="nav-item" *ngIf="userPermissions.includes('view-vhcl-transfer-hist')">
                    <a routerLink="/vehicle-transfer-history" class="nav-link">
                      <i class="fas fa-sd-card"></i>&nbsp;
                      <p>History</p>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <!--End Stock Management-->
          <!--Sales Management-->
          <li class="nav-item" *ngIf="userPermissions.includes('process-sale')">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-cash-register"></i>
              <p>
                Sales Management
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item" *ngIf="userPermissions.includes('process-sale')">
                <a href="#" class="nav-link">
                  <i class="nav-icon far fa-money-bill-alt"></i>
                  <p>
                    Commitment Fees
                    <i class="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a routerLink="/commitment-fee-refunds" class="nav-link">
                      <i class="fa fa-undo nav-icon"></i>
                      <p>Refund</p>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="/view-refunded-commitment-fees" class="nav-link">
                      <i class="far fa-eye nav-icon"></i>
                      <p>View Refunded</p>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('process-sale')">
                <a routerLink="/sales/delivery-notes" class="nav-link">
                  <i class="fas fa-print nav-icon"></i>
                  <p>Print Delivery Notes</p>
                </a>
              </li>
            </ul>
          </li>
          <!--End Sales Management-->
          <!--Transactions-->
          <li class="nav-item" *ngIf="userPermissions.includes('view-completed-bls') || userPermissions.includes('edit-entry') || userPermissions.includes('edit-port-chgs-inv') || userPermissions.includes('add-duty-pmt')">
            <a href="#" class="nav-link">
              <i class="nav-icon fa fa-hourglass-end" aria-hidden="true"></i>
              <p>
                Transactions
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item" *ngIf="userPermissions.includes('view-completed-bls')">
                <a routerLink="/completed-bls" class="nav-link">
                  <i class="fas fa-folder nav-icon"></i>
                  <p>Completed BLs</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('edit-entry')">
                <a routerLink="/transactions/edit-entry" class="nav-link">
                  <i class="fas fa-eraser nav-icon"></i>
                  <p>Edit Entry</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('edit-entry')">
                <a routerLink="transactions/update-entry-attachments" class="nav-link">
                  <i class="fas fa-paperclip nav-icon"></i>
                  <p>Update Entry Attachments</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('delete-entry')">
                <a routerLink="/transactions/delete-entry" class="nav-link">
                  <i class="fas fa-trash nav-icon"></i>
                  <p>Delete Entry</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('edit-port-chgs-inv')">
                <a routerLink="transactions/paid-port-charges-invoices" class="nav-link">
                  <i class="fas fa-eraser nav-icon"></i>
                  <p>Edit Paid Port Charges Invoices</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('add-duty-pmt')">
                <a routerLink="transactions/reverse-duty-payments" class="nav-link">
                  <i class="fa fa-undo nav-icon"></i>
                  <p>Reverse Duty Payments</p>
                </a>
              </li>
              <!--<li class="nav-item">
                <a routerLink="#" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Another menu</p>
                </a>
              </li>-->
            </ul>
          </li>
          <!--End Transactions-->
          <!--Cash Management-->
          <li class="nav-item" *ngIf="userPermissions.includes('rcv-cash') || userPermissions.includes('process-pmt') || userPermissions.includes('view-cash-trans')">
            <a href="#" class="nav-link">
              <i class="nav-icon fa fa-credit-card" aria-hidden="true"></i>
              <p>
                Cash Management
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item" *ngIf="userPermissions.includes('rcv-cash')">
                <a routerLink="/cash-mgnt/new-cash-rcpt" class="nav-link">
                  <i class="fa fa-plus-circle nav-icon"></i>
                  <p>Receive Cash</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('process-pmt')">
                <a routerLink="/cash-mgnt/new-cash-pmt" class="nav-link">
                  <i class="fa fa-minus-circle nav-icon"></i>
                  <p>New Cash Payment</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('view-cash-trans')">
                <a routerLink="/cash-mgnt/transactions" class="nav-link">
                  <i class="fa fa-list-ul nav-icon"></i>
                  <p>Cash Transactions</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('rcv-cash') || userPermissions.includes('process-pmt')">
                <a routerLink="/cash-mgnt/close-day" class="nav-link">
                  <i class="fa fa-window-close nav-icon"></i>
                  <p>Close Day</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('mng-cash-categories')">
                <a routerLink="/cash-mgnt/categories-list" class="nav-link">
                  <i class="fa fa-cog nav-icon"></i>
                  <p>Manage Categories</p>
                </a>
              </li>
            </ul>
          </li>
          <!--End Cash Management-->
          <!--Settings-->
          <li class="nav-item" *ngIf="userPermissions.includes('mng-vhcl-makes') || userPermissions.includes('mng-vhcl-models') || userPermissions.includes('mng-vhcl-body-types') || userPermissions.includes('mng-vhcl-colours') || userPermissions.includes('mng-vhcl-spare-parts') || userPermissions.includes('mng-consignees') || userPermissions.includes('mng-agents') || userPermissions.includes('mng-cfs') || userPermissions.includes('mng-locations') || userPermissions.includes('mng-branches') || userPermissions.includes('mng-users') || userPermissions.includes('mng-roles') || userPermissions.includes('update-tax-settings')">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-cogs"></i>
              <p>
                Settings
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item" *ngIf="userPermissions.includes('mng-vhcl-makes')">
                <a routerLink="/settings/makes-list" class="nav-link">
                  <i class="fas fa-cog nav-icon"></i>
                  <p>Manage Vehicle Makes</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('mng-vhcl-models')">
                <a routerLink="/settings/models-list" class="nav-link">
                  <i class="fas fa-cog nav-icon"></i>
                  <p>Manage Vehicle Models</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('mng-vhcl-grds')">
                <a routerLink="/settings/grades-list" class="nav-link">
                  <i class="fas fa-cog nav-icon"></i>
                  <p>Manage Vehicle Grades</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('mng-vhcl-body-types')">
                <a routerLink="/settings/body-types-list" class="nav-link">
                  <i class="fas fa-cog nav-icon"></i>
                  <p>Manage Body Types</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('mng-vhcl-colours')">
                <a routerLink="/settings/colours-list" class="nav-link">
                  <i class="fas fa-cog nav-icon"></i>
                  <p>Manage Colours</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('mng-vhcl-spare-parts')">
                <a routerLink="/settings/spare-parts-list" class="nav-link">
                  <i class="fas fa-cog nav-icon"></i>
                  <p>Manage Spare Parts</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('mng-consignees')">
                <a routerLink="/settings/consignees-list" class="nav-link">
                  <i class="fas fa-cog nav-icon"></i>
                  <p>Manage Consignees</p>
                </a>
              </li>
              <li class="nav-item"  *ngIf="userPermissions.includes('mng-agents')">
                <a routerLink="/settings/agents-list" class="nav-link">
                  <i class="fas fa-cog nav-icon"></i>
                  <p>Manage Agents</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('mng-cfs')">
                <a routerLink="/settings/cfs-list" class="nav-link">
                  <i class="fas fa-cog nav-icon"></i>
                  <p>Manage CFSes</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('mng-locations')">
                <a routerLink="/settings/locations-list" class="nav-link">
                  <i class="fas fa-cog nav-icon"></i>
                  <p>Manage Locations</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('mng-branches')">
                <a routerLink="/settings/branches-list" class="nav-link">
                  <i class="fas fa-cog nav-icon"></i>
                  <p>Manage Branches</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('mng-bnk-accts')">
                <a routerLink="/settings/bank-accounts-list" class="nav-link">
                  <i class="fas fa-cog nav-icon"></i>
                  <p>Manage Bank Accounts</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('mng-users')">
                <a routerLink="/settings/users-list" class="nav-link">
                  <i class="fas fa-cog nav-icon"></i>
                  <p>Manage Users</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('mng-roles')">
                <a routerLink="/settings/roles-list" class="nav-link">
                  <i class="fas fa-cog nav-icon"></i>
                  <p>Manage Roles</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('update-tax-settings')">
                <a routerLink="/settings/update-tax" class="nav-link">
                  <i class="fas fa-cog nav-icon"></i>
                  <p>Update Tax Settings</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userPermissions.includes('view-actvty_lgs')">
                <a href="/settings/activity-logs" class="nav-link">
                  <i class="fa fa-history nav-icon"></i>
                  <p>Activity Logs</p>
                </a>
              </li>
            </ul>
          </li>
          <!--Integrations-->
          <li class="nav-item" *ngIf="userPermissions.includes('create-app')">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-plug"></i>
              <p>
                Integrations
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a routerLink="/integrations/apps" class="nav-link">
                  <i class="fas fas fa-microchip"></i>&nbsp;
                  <p>Apps</p>
                </a>
              </li>
            </ul>
          </li>
          <!--End Integrations-->
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>