import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { ConsigneeService } from 'src/app/services/consignees/consignee.service';
import { CfsService } from 'src/app/services/cfs/cfs.service';
import { BillsOfLadingService } from 'src/app/services/bills-of-lading/bills-of-lading.service';
import { ActivatedRoute, Params,Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-supplier-edit-bl',
  templateUrl: './supplier-edit-bl.component.html',
  styleUrls: ['./supplier-edit-bl.component.css']
})
export class SupplierEditBlComponent implements OnInit{
  public billOfLading:any;
  public bl_id: string | null = null;
  public errorMessage: string | null = null;
  consignees: any[] = [];
  cfses: any[] = [];
  form!: FormGroup;
  submitted: boolean = false;
  user_language: any='en';

  constructor(
    private consigneeService: ConsigneeService, 
    private cfsService: CfsService, 
    private billsOfLadingService: BillsOfLadingService, 
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private globalService: GlobalService,
    private translate: TranslateService
    ){
      this.form = this.formBuilder.group({
        bl_no: ['', Validators.required],
        bl_date: ['', Validators.required],
        consignee_id: ['', Validators.required],
        no_of_units: ['', Validators.required],
        cfs_id: ['', Validators.required],
        vessel: ['', Validators.required],
        vessel_eta:[''],
        old_bl_no: [''],
      });

      this.user_language=localStorage.getItem('language');
      translate.setDefaultLang('en');
      translate.use(this.user_language);
    }

  ngOnInit(): void {
     //Get Consignees
     this.getConsignees();

     //Get CFSes
     this.getCFSes();
 
     this.activatedRoute.paramMap.subscribe((param: Params)=>{
       this.bl_id = param['get']('bl_id')
     })
 
     if(this.bl_id){
       this.getBLData(this.bl_id);
     }else{
       //Navigate back to list with error
       this.errorMessage="Unable to select BL for editing. Please try again!";
       this.router.navigate(['suppliers/bl-list']);
     }
  }

  handleSubmit() {
    this.submitted = true;
    this.errorMessage=null;
    
    if (this.form.valid) {
      this.billsOfLadingService.editBL(this.form.value,this.bl_id)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.globalService.setGlobalSuccessMessage(response.message);
            // Redirect to BL list
            this.router.navigate(['suppliers/bl-list']);
          }else{
            this.submitted = false;
            this.errorMessage=response.message;
          }
        },
        error: (error) => {
          this.submitted = false;
          this.errorMessage=error;
        }
      });
    }
  }

  getConsignees(): void {
    this.consigneeService.getAllConsignees().subscribe((response: ApiResponse)=>this.consignees=response.data);
  }

  getCFSes(): void {
    this.cfsService.getAllCFSes().subscribe((response: ApiResponse)=>this.cfses=response.data);
  }

  /**
   * Get single BL info
   * @param bl_id 
   */
  getBLData(bl_id: string): void {
    this.billsOfLadingService.getBL(bl_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.billOfLading = response;
    
          if(this.billOfLading.status==='no_data' || this.billOfLading.status.length===0){
            this.errorMessage=this.billOfLading.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }
}
