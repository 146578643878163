import { Component, OnInit } from '@angular/core';
import { DutyPaymentsService } from 'src/app/services/payments/duty-payments.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-paid-duties-pending-receipt-attachment',
  templateUrl: './paid-duties-pending-receipt-attachment.component.html',
  styleUrls: ['./paid-duties-pending-receipt-attachment.component.css']
})
export class PaidDutiesPendingReceiptAttachmentComponent implements OnInit {
  page: number = 1;
  count: number = 0;
  paid_duties:any[] = [];
  searchText: string = '';
  isLoading: boolean = false;
  errorMessage: string | null = null;

  constructor(private dutyPaymentsService: DutyPaymentsService){}

  ngOnInit(): void {
    this.isLoading=true;
    this.getPaidDutiesPendingReceiptAttachments();
  }

  /**
   * Get all paid duties
   * pending receipt attachments
   */
  getPaidDutiesPendingReceiptAttachments()
  {
    this.dutyPaymentsService.getPaidDutiesPendingReceiptAttachments().subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.isLoading = false;
          this.paid_duties=response.data
        }else{
          this.isLoading = false;
          this.errorMessage=response.message;
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.errorMessage=error;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.getPaidDutiesPendingReceiptAttachments();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

}
