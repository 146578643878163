<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
           
            <div class="col-sm-6">
             
            </div><!-- /.col -->
           
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item active">Transferred Vehicles In Transit</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div><!-- /.content-header -->
    <div class="row">
        <div class="col-md-8">
            <div class="row">
               
            </div>
        </div>
        <div class="col-md-4">
          <div class="row">
              <div class="col">
                  <input type="text" class="form-control" placeholder="Type Chassis No. here to search" (input)="onSearchTextChange($event)">
              </div>
          </div>
        </div>
    </div>
</section>
<section class="content">
    <div class="container-fluid">
    <div class="card">
        <div class="card-header">
        <h3 class="card-title">Transferred Vehicles In Transit</h3>
            <!--Error Message-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
            </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
            <!--Error messages here-->
        <table id="example1" class="table table-bordered table-hover">
            <thead>
            <tr>
                <th>Make</th>
                <th>Model</th>
                <th>Chassis No.</th>
                <th>From Location</th>
                <th>To Location</th>
                <th>Dispatched On</th>
                <th>Dispatched By</th>
                <th>Driver</th>
                <th>Transfer Reason</th>
                <th>Notes/Comment</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
                <tr *ngFor="let vehicle of vehicles | searchVehicle: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                <td>{{vehicle.make}}</td>
                <td>{{vehicle.model}}</td>
                <td>{{vehicle.chassis_no}}</td>
                <td>{{vehicle.from_location}}</td>
                <td>{{vehicle.to_location}}</td>
                <td>{{vehicle.dispatched_on | date: 'dd-MM-yyyy h:mm a'}}</td>
                <td>{{vehicle.dispatched_by}}</td>
                <td>{{vehicle.driver_name}}</td>
                <td>{{vehicle.transfer_reason}}</td>
                <td>{{vehicle.transfer_notes}}</td>
                <td>
                <a routerLink="/view-transferred-vehicle/{{vehicle.transfer_log_id}}" class="btn btn-success" role="button"><i class="fas fa-eye"></i>&nbsp;More Details</a>
                </td>
                </tr>
            </tbody>
        </table>
        <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
        </div>
        <!-- /.card-body -->
    </div>
    </div>
</section>