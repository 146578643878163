import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/shared/global.service';
import { PaidPortChargesInvoicesService } from 'src/app/services/payments/paid-port-charges-invoices.service';

@Component({
  selector: 'app-edit-paid-port-charges-invoice',
  templateUrl: './edit-paid-port-charges-invoice.component.html',
  styleUrls: ['./edit-paid-port-charges-invoice.component.css']
})
export class EditPaidPortChargesInvoiceComponent implements OnInit{
  form!: FormGroup;
  public invoiceId: string | null = null;
  public invoiceData:any;
  errorMessage: string | null = null;
  submitted: boolean = false;
  isProcessing: boolean = false;
  public isLoading: boolean = false;
  showExRate: boolean = false;
  fileName: string="Choose an image or a PDF file";
  attachment: any;

  constructor(
    private formBuilder: FormBuilder,
    private paidPportChargesService:PaidPortChargesInvoicesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService
  ){
    this.form = this.formBuilder.group({
      bl_no:['', Validators.required],
      no_of_units:['', Validators.required],
      invoice_no:['', Validators.required],
      amount_kes: ['', Validators.required],
      amount_usd: ['', Validators.required],
      currency:['', Validators.required],
      ex_rate: [''],
      attachment: [null],
    });
  }

  ngOnInit(): void {
      this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.invoiceId = param['get']('invoice_id')
    })

    if(this.invoiceId){
      this.fetchInvoiceData(this.invoiceId);
    }else{
      //Navigate back to invoices list with error
      this.globalService.setGlobalErrorMessage("Unable to select port charges invoice for payment. Please try again!");
      this.router.navigate(['transactions/paid-port-charges-invoices']);
    }
  }

   /**
   * Get invoice data
   * @param invoiceId 
   */
   fetchInvoiceData(invoiceId: string): void {
    this.paidPportChargesService.getInvoiceDetails(invoiceId)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.invoiceData = response;
          if(this.invoiceData.status==='no_data' || this.invoiceData.status.length===0){
            this.errorMessage=this.invoiceData.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  /**
   * Handle selectd file
   * @param event 
   */
  onFileSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.fileName = fileInput.files?.[0]?.name ?? 'Choose an image or a PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.attachment = file
    }
  }

  /**
   * Show Exchange rate field if USD is selected
   * and hide if KES or another option is selected
   */
  onDropdownChange(event: any) {
    const selectedOption = event.target as HTMLInputElement;

    if(selectedOption.value!=='' && selectedOption.value!==undefined){
      this.errorMessage=null; //Clear error message if any

      if(selectedOption.value=='USD'){
        this.showExRate=true;
      }else{
        this.showExRate=false;
      }
    }else{
      this.errorMessage="Invalid currency selection!";
    }
  }

  handleSubmit() {
    this.submitted = true;
    this.isProcessing=true;
   
    if (this.form.valid) {
      if(this.invoiceId==null || this.invoiceId==undefined || this.invoiceId==''){
        this.globalService.setGlobalErrorMessage("Invalid invoice selected. Please try again. (Missing: Invoice ID)");
        this.router.navigate(['transactions/paid-port-charges-invoices']);
      }else if(this.form.value.bl_no==null || this.form.value.bl_no==undefined || this.form.value.bl_no==''){
        this.globalService.setGlobalErrorMessage("Unable to post payment. Please try again. (Missing: BL No.)");
        this.router.navigate(['transactions/paid-port-charges-invoices']);
      }else if(this.form.value.invoice_no==null || this.form.value.invoice_no==undefined || this.form.value.invoice_no==''){
        this.globalService.setGlobalErrorMessage("Unable to post payment. Please try again. (Missing: Invoice No.)");
        this.router.navigate(['transactions/paid-port-charges-invoices']);
      }else if(this.form.value.currency=='USD' && (this.form.value.ex_rate==undefined || this.form.value.ex_rate=='')){
        this.globalService.setGlobalErrorMessage("Please enter exchange rate!");
        this.isProcessing = false;
      }else if(this.form.value.currency=='USD' && Number.isNaN(Number(String(this.form.value.ex_rate).replace(",","")))){
        this.errorMessage="Only numbers are acceptable in Exchange Rate field!";
        this.isProcessing = false;
      }else{
        this.paidPportChargesService.updateInvoice(this.form.value,this.attachment,this.invoiceId)
        .subscribe({
          next: (response) => {
            if(response.status==='success'){
              this.submitted = false;
              this.globalService.setGlobalSuccessMessage(response.message);
              this.isProcessing=false;
              this.errorMessage=null;
              this.router.navigate(['transactions/paid-port-charges-invoices']);
            }else{
              this.submitted = false;
              this.isProcessing=false;
              this.errorMessage=response.message;
            }
          },
          error: (error) => {
            this.submitted = false;
            this.isProcessing=false;
            this.errorMessage=error;
          }
        });
      }
    }else{
      this.isProcessing=false;
    }
  }
}
