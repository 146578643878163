<!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Create Access Token</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/integrations/apps"><i class="fa fa-list-ul"></i>&nbsp;View Apps</a></li>
               <li class="breadcrumb-item active">Create Access Token</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
         <h3 class="card-title">Create Access Token</h3>
         <!--Error Message-->
         <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            <span [innerHTML]="errorMessage"></span>
          </div>
          <!--Success Message-->
          <div class="alert alert-success" role="alert" *ngIf="successMessage">
            {{successMessage}}
          </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
           <form [formGroup]="form" (ngSubmit)="handleSubmit()">
               <div class="row clearfix">
                   <div class="col-md-4">
                       <div class="form-group">
                           <label>App Name:</label>
                           <input type="text" formControlName="app_name" class="form-control" placeholder="App Name" [(ngModel)]="appData.data.name" readonly>
                       </div>
                   </div>
                   <div class="col-md-8">
                        <div class="form-group">
                            <label>Access Token:</label>
                            <textarea class="form-control">{{accessToken}}</textarea>
                        </div>
                   </div>
               </div>
              
               <input type="text" formControlName="client_id"  [(ngModel)]="appData.data.id" hidden>
               <input type="text" formControlName="client_secret"  [(ngModel)]="appData.data.secret" hidden>

                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>
                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-plus-circle" aria-hidden="true">&nbsp;</i>Generate</button>
                </ng-template>
           </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>