import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchBranch'
})
export class SearchBranchPipe implements PipeTransform {

  transform(branches: any, searchText: string){
    
    if (!searchText) {
      return branches; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return branches.filter((branches: any) => 
      (branches.location_name.toLowerCase().includes(searchText))||
      (branches.branch_name.toLowerCase().includes(searchText))
    );
  }

}
