import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class CnfPaymentsService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }

   /**
   * Import C & F payments
   */
   import(cnf_data:any,excel_file:any):Observable<any>{
    const formData = new FormData();
    formData.append("ex_rate", cnf_data.ex_rate);
    formData.append("excel_file", excel_file);

    let apiURL:string=`${this.baseURL}/v1/import-cnf-payments`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }
}
