import { Component, OnInit } from '@angular/core';
import { ConsigneeService } from 'src/app/services/consignees/consignee.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-consignees-list',
  templateUrl: './consignees-list.component.html',
  styleUrls: ['./consignees-list.component.css']
})
export class ConsigneesListComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  consignees: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;

  constructor(
    private consigneeService:ConsigneeService,
  ){}

  ngOnInit(): void {
    this.fetchConsignees();
  }

  fetchConsignees():void{
    this.consigneeService.getAllConsignees().subscribe((response: ApiResponse) =>this.consignees = response.data);
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fetchConsignees();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  deleteConsignee(event: Event,consigneeId:string): void{
    event.preventDefault();
  }
}
