import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CnfPaymentsService } from 'src/app/services/payments/cnf-payments.service';

@Component({
  selector: 'app-upload-cnf-payments',
  templateUrl: './upload-cnf-payments.component.html',
  styleUrls: ['./upload-cnf-payments.component.css']
})
export class UploadCnfPaymentsComponent {
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  form!: FormGroup;
  submitted: boolean = false;
  isProcessing: boolean = false;
  fileName: string="Choose an Excel file";
  excel_file: any;

  constructor(
    private formBuilder: FormBuilder,
    private cnfPaymentsService:CnfPaymentsService
  ){
    this.form = this.formBuilder.group({
      ex_rate: ['', Validators.required],
      excel_file: [null, Validators.required],
    })
  }

  /**
   * Handle selectd file
   * @param event 
   */
  onFileSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.fileName = fileInput.files?.[0]?.name ?? 'Choose an Excel file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.excel_file = file
    }
  }

  handleSubmit() {
    this.submitted = true;
    this.isProcessing = true;

    if (this.form.valid) {
      this.cnfPaymentsService.import(this.form.value,this.excel_file)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.isProcessing = false;
            this.successMessage=response.message;
          }else{
            this.submitted = false;
            this.isProcessing = false;
            this.errorMessage=response.message;
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isProcessing = false;
          this.errorMessage=error;
        }
      });
    }else{
      this.isProcessing = false;
    }
  }
}
