import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/shared/global.service';
import { BillsOfLadingService } from 'src/app/services/bills-of-lading/bills-of-lading.service';
import { ActivatedRoute, Params,Router } from '@angular/router';

@Component({
  selector: 'app-enter-port-charges',
  templateUrl: './enter-port-charges.component.html',
  styleUrls: ['./enter-port-charges.component.css']
})
export class EnterPortChargesComponent implements OnInit{
  form!: FormGroup;
  submitted: boolean = false;
  public errorMessage: string | null = null;
  public bl_id: string | null = null;
  public billOfLading: any;

  constructor(
    private formBuilder: FormBuilder,
    private billsOfLadingService: BillsOfLadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalService: GlobalService
  ){
    this.form = this.formBuilder.group({
      bl_no: ['', Validators.required],
      amount: ['', Validators.required]
    });
  }
  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.bl_id = param['get']('bl_id')
    })

    if(this.bl_id){
      this.getBLData(this.bl_id);
    }else{
      //Navigate back to the list with error
      this.errorMessage="Unable to select BL for adding port charges!";
      this.router.navigate(['bl-list']);
    }
  }

  getBLData(bl_id:string): void {
    this.billsOfLadingService.getBL(bl_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.billOfLading = response;
    
          if(this.billOfLading.status==='no_data' || this.billOfLading.status.length===0){
            this.errorMessage=this.billOfLading.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  handleSubmit() {
    this.submitted = true;
   
    if(this.form.valid) {
      if(Number.isNaN(Number(this.form.value.amount))) {
        this.submitted = false;
        this.errorMessage="Invalid amount. Only numbers are allowed!";
      }else{
        this.submitted = false;
        this.globalService.setportChargesAmountPaid(this.form.value.amount);
        this.router.navigate([`add-port-charges/${this.bl_id}`]);
      }
    }
  }
}
