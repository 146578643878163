import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { PendingTasksService } from 'src/app/services/pending-tasks/pending-tasks.service';
import { PendingTasksHandler } from 'src/app/shared/pending-tasks-handler';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  user_full_name: any="";
  public userPermissions: string[] = [];
  public isLoggedIn: boolean=false;
  noOfPendingEntries:number=0;
  noOfBlsPendingDOAndRadiationPmt:number=0;
  noOfUnpaidDuties:number=0;
  noOfPaymentsPendingReceiptsAttachment:number =0;
  noOfBlsPendingPortChargesPmt:number=0;
  noOfBlsPendingETAUpdatePmt:number=0;
  noOfCustomsPendingTasks:number=0;
  noOfDutyPendingTasks:number=0;
  noOfVehiclesPendingPhotoUpload:number=0;
  noOfVehiclesPendingDocsUpload:number=0;
  noOfVehiclesPendingPartsVerification:number=0;

  constructor(
    private authService: AuthService,
    private pendingTasksService: PendingTasksService,
    private pendingTasksHandler: PendingTasksHandler
    ) { }

  ngOnInit(): void {
    this.user_full_name=sessionStorage.getItem('userFullName');
    this.userPermissions=this.authService.getPermissions();
    this.isLoggedIn = this.authService.isLoggedIn;
    // Subscribe to the count updates
    this.pendingTasksService.noOfPendingEntries$.subscribe((count) => {
      this.noOfPendingEntries = count;
    });

    this.pendingTasksService.noOfBlsPendingDOAndRadiationPmt$.subscribe((count) => {
      this.noOfBlsPendingDOAndRadiationPmt = count;
    });

    this.pendingTasksService.noOfUnpaidDuties$.subscribe((count) => {
      this.noOfUnpaidDuties = count;
    });

    this.pendingTasksService.noOfPaymentsPendingReceiptsAttachment$.subscribe((count) => {
      this.noOfPaymentsPendingReceiptsAttachment = count;
    });

    this.pendingTasksService.noOfBlsPendingPortChargesPmt$.subscribe((count) => {
      this.noOfBlsPendingPortChargesPmt = count;
    });

    this.pendingTasksService.noOfBlsPendingETAUpdatePmt$.subscribe((count) => {
      this.noOfBlsPendingETAUpdatePmt = count;
    });

    this.pendingTasksService.totalCustomsPendingTasks$.subscribe((noOfCustomsPendingTasks) => {
      this.noOfCustomsPendingTasks=noOfCustomsPendingTasks;
    });

    this.pendingTasksService.totalDutyPendingTasks$.subscribe((noOfDutyPendingTasks) => {
      this.noOfDutyPendingTasks=noOfDutyPendingTasks;
    });

    this.pendingTasksService.noOfVehiclesPendingPhotoUpload$.subscribe((count) => {
      this.noOfVehiclesPendingPhotoUpload = count;
    });

    this.pendingTasksService.noOfVehiclesPendingDocsUpload$.subscribe((count) => {
      this.noOfVehiclesPendingDocsUpload = count;
    });

    this.pendingTasksService.noOfVehiclesPendingPartsVerification$.subscribe((count) => {
      this.noOfVehiclesPendingPartsVerification = count;
    });
    
    //Count pending tasks
    if(this.userPermissions.includes('add-entry')){
      this.pendingTasksHandler.numberOfVehiclesPendingEntryInput();
    }

    if(this.userPermissions.includes('add-do-rad-pmt')){
      this.pendingTasksHandler.numberOfBLsPendingDOAndRadiationPmt();
    }

    if(this.userPermissions.includes('add-duty-pmt')){
      this.pendingTasksHandler.numberOfPendingDutyPayments();
    }

    if(this.userPermissions.includes('attach-duty-pmt-rcpt')){
      this.pendingTasksHandler.numberOfPaymentsPendingReceiptsAttachment();
    }

    if(this.userPermissions.includes('add-port-chgs')){
      this.pendingTasksHandler.numberOfBLsPendingPortChargesPmt();
    }

    if(this.userPermissions.includes('update-vsl-eta')){
      this.pendingTasksHandler.numberOfBLsPendingETAUpdatePmt();
    }

    if(this.userPermissions.includes('add-vhcl-photos')){
      this.pendingTasksHandler.numberOfVehiclesPendingPhotoUpload();
    }

    if(this.userPermissions.includes('add-imp-docs')){
      this.pendingTasksHandler.numberOfVehiclesPendingDocsUpload();
    }

    if(this.userPermissions.includes('ver-spare-parts')){
      this.pendingTasksHandler.numberOfVehiclesPendingPartsVerification();
    }
  }
}
