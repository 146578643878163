 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Attach Payment Receipt</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a href="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/paid-duties-pending-attachment"><i class="fa fa-list-ul"></i>&nbsp;Duties Pending Receipt Attachments</a></li>
               <li class="breadcrumb-item active">Attach Payment Receipt</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
    </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
         <h3 class="card-title">Attach Payment Receipt</h3>
         <!--Error messages-->
         <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
          </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
           <form [formGroup]="form" (ngSubmit)="handleSubmit()">
               <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Entry No:</label>
                            <input type="text" name="entry_no" class="form-control" placeholder="Entry No." value="{{ duty?.data?.entry_no }}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                       <div class="form-group">
                           <label>Make:</label>
                           <input type="text" name="make" class="form-control" placeholder="Make" value="{{ duty?.data?.make }}" readonly>
                       </div>
                    </div>
                    <div class="col-md-4">
                       <div class="form-group">
                           <label>Model:</label>
                           <input type="text" name="model" class="form-control" placeholder="Model" value="{{ duty?.data?.model }}" readonly>
                       </div>
                    </div>
               </div>
               <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Chassis No.</label>
                            <input type="text" name="chassis_no" class="form-control" placeholder="Chasis No." value="{{ duty.data.chassis_no }}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Document Reference:</label>
                            <input type="text" name="doc_ref_no" class="form-control" placeholder="Document Reference Number" value="{{ duty?.data?.doc_ref_no }}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Ref. Number:</label>
                            <input type="text" name="payment_ref_no" class="form-control" placeholder="Payment Reference Number" value="{{ duty?.data?.pmt_ref_no }}" readonly>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Amount Paid:</label>
                            <input type="text" name="amount_paid" class="form-control" placeholder="Amount Paid" value="{{ duty?.data?.amount_paid | currency:'Kshs ' }}" readonly>
                        </div>
                    </div>
                   
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Payment Date:</label>
                            <input type="text" name="payment_date" class="form-control" placeholder="Payment Date" value="{{ duty?.data?.pmt_date | date: 'dd-MM-yyyy'}}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Bank:</label>
                            <input type="text" name="bank_name" class="form-control" placeholder="Bank Name" value="{{ duty?.data?.bank_name }}" readonly>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Receipt Copy:<span style="color:red;">*</span></label>
                            <div class="input-group mb-3">
                                <div class="custom-file">
                                  <input type="file" formControlName="attachment" accept="application/pdf,image/*" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onFileSelected($event)">
                                  <label class="custom-file-label" for="inputGroupFile01">{{ fileName }}</label>
                                </div>
                            </div>

                            <span *ngIf="submitted && form.get('attachment')?.hasError('required')" style="color:red"> <strong>Please attach a copy of the receipt!</strong></span>
                        </div>
                    </div>
                </div>
                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>

                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Save</button>
                </ng-template>
           </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>