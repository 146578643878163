import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchLocation'
})
export class SearchLocationPipe implements PipeTransform {

  transform(locations: any, searchText: string){
    
    if (!searchText) {
      return locations; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return locations.filter((locations: any) => locations.location_name.toLowerCase().includes(searchText));
  }

}
