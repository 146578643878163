import { Component, OnInit } from '@angular/core';
import { StockService } from 'src/app/services/stock/stock.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-sold-cars',
  templateUrl: './sold-cars.component.html',
  styleUrls: ['./sold-cars.component.css']
})
export class SoldCarsComponent implements OnInit{
  public userPermissions: string[] = [];
  public errorMessage: string | null = null;
  vehicles: any[] = [];
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  public isInProgress: boolean = false;
  rowCount: number=0;

  constructor(
    private stockService:StockService,
    private authService: AuthService,
  ){}

  ngOnInit(): void {
    this.isInProgress=true;
    this.userPermissions=this.authService.getPermissions();
    this.fetchSoldCars();
  }

  fetchSoldCars():void{
    this.stockService.getSoldCars()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicles = response.data;
          this.rowCount = this.vehicles.length;
          this.isInProgress=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.rowCount =0;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fetchSoldCars();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }
}
