 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Add Port Charges</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/add-port-charges/select-bl"><i class="fa fa-list-ul"></i>&nbsp;View Bills of Lading</a></li>
                <li class="breadcrumb-item active">Add Port Charges</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
            <h3 class="card-title">Add Port Charges</h3>
            <!--Error message-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
            </div>
            <!--Warning message-->
            <div class="alert alert-warning" role="alert" *ngIf="warningMessage">
                {{warningMessage}}
            </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
           <form [formGroup]="paymentForm" (ngSubmit)="handleSubmit()">
               <div class="row clearfix">
                   <div class="col-md-4">
                       <div class="form-group">
                           <label>BL No.:</label>
                           <input #bl_no type="text" formControlName="bl_no" class="form-control" placeholder="BL No." [value]="this.billOfLading.data.bl_no" readonly>
                       </div>
                   </div>
                   <div class="col-md-4">
                       <div class="form-group">
                           <label>Consignee:</label>
                           <input type="text" name="consignee_name" class="form-control" placeholder="Consignee" [value]="this.billOfLading.data.consignee_name" readonly>
                       </div>
                   </div>
                   <div class="col-md-4">
                       <div class="form-group">
                           <label>No. of Units:</label>
                           <input type="text" #no_of_units name="no_of_units" class="form-control" placeholder="No. of Units" [value]="this.billOfLading.data.no_of_units" readonly>
                       </div>
                   </div>
               </div>
               <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Agent:</label>
                            <input type="text" name="agent_name" class="form-control" placeholder="Agent" [value]="this.billOfLading.data.agent_name" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Vessel:</label>
                            <input type="text" name="vessel" class="form-control" placeholder="Agent" [value]="this.billOfLading.data.vessel" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Vessel ETA:</label>
                            <input type="text"  class="form-control" value="{{ this.billOfLading.data.vessel_eta| date: 'dd-MM-yyyy' }}" readonly>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Total Amount:</label>
                            <input #total_amount type="text" name="total_amount" class="form-control" placeholder="Total Amount Paid" [value]="portChargesAmountPaid" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Invoice No.:<span style="color:red;">*</span></label>
                            <input #invoice_no type="text" name="invoice_no" class="form-control" placeholder="Invoice No." value="">
                        </div>
                    </div>
                </div>
                <table id="example1" class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Make</th>
                            <th>Model</th>
                            <th>Engine CC</th>
                            <th>Colour</th>
                            <th>Body Type</th>
                            <th>Weight</th>
                            <th>CBM</th>
                            <th>Chasis No.</th>
                            <th>Amount Paid</th>
                        </tr>
                    </thead>
                    <tbody formArrayName="vehicles">
                       <tr *ngFor="let x of vehiclesArray.controls; let i = index;" [formGroupName]="i">
                            <td hidden><input type="text" formControlName="vehicle_id"></td>
                            <td>{{ x?.get('make')?.value  }}</td>
                            <td>{{ x?.get('model')?.value  }}</td>
                            <td>{{ x?.get('colour')?.value  }}</td>
                            <td>{{ x?.get('engine_cc')?.value  }}</td>
                            <td>{{ x?.get('body_type')?.value  }}</td>
                            <td>{{ x?.get('weight')?.value  }}</td>
                            <td>{{ x?.get('cbm')?.value  }}</td>
                            <td><input type="text" formControlName="chassis_no" class="form-control" readonly></td>
                            <td>
                                <input type="text" formControlName="amount" class="form-control" [(ngModel)]="portChargesAmountPaidPerUnit">
                                <span *ngIf="submitted && paymentForm.get('amount')?.hasError('required')" style="color:red"> <strong>Amount paid is required!</strong></span>
                            </td>
                       </tr>
                    </tbody>
                </table>

                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>

                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Save</button>
                </ng-template>
           </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>