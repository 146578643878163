import { Component, OnInit } from '@angular/core';
import { BillsOfLadingService } from 'src/app/services/bills-of-lading/bills-of-lading.service';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-completed-bls',
  templateUrl: './completed-bls.component.html',
  styleUrls: ['./completed-bls.component.css']
})
export class CompletedBlsComponent implements OnInit{
  public userPermissions: string[] = [];
  page: number = 1;
  count: number = 0;
  bills_of_lading: any[] = [];
  agents: any[] = [];
  selectedBLs: any[] = []; // Array to store selected BLs
  selectedAgentId: string='';
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  searchText: string = '';
  public isLoading: boolean = false;
  total_units: any = 0;
  total_units_added: any = 0;

  constructor(
      private billsOfLadingService: BillsOfLadingService,  
      private authService: AuthService
    ){}

  ngOnInit(): void {
    this.isLoading=true;
    this.userPermissions=this.authService.getPermissions();
    this.getBillsOflading();
  }
  
  getBillsOflading(): void {
    this.billsOfLadingService.getCompletedBLs()
    .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.isLoading = false;
            this.bills_of_lading = response.data
            this.getTotalUnits();
            this.getTotalUnitsAdded();
          }else{
            this.isLoading = false;
            this.errorMessage=response.message;
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.errorMessage=error;
        }
      });
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.getBillsOflading();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  getTotalUnits(): void{
    this.billsOfLadingService.getClearedTotalUnits().subscribe((response: ApiResponse) =>this.total_units = response.data);
  }

  getTotalUnitsAdded(): void{
    this.billsOfLadingService.getClearedTotalUnitsAdded().subscribe((response: ApiResponse) =>this.total_units_added = response.data);
  }
}
