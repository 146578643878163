 <!--Loading Spinner-->
<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>

 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h3>New Cash Payment</h3>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                    <li class="breadcrumb-item"><a routerLink="/cash-mgnt/transactions"><i class="fa fa-list-ul"></i>&nbsp;Cash Transactions</a></li>
                    <li class="breadcrumb-item active">New Cash Payment</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
       </div><!-- /.container-fluid -->
    </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
         <h3 class="card-title">New Cash Payment</h3>
         <!--Error messages-->
         <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            <span [innerHTML]="errorMessage"></span>
          </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
           <form [formGroup]="form" (ngSubmit)="handleSubmit()">
               <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Date:</label>
                            <input type="date" formControlName="pmt_date" class="form-control" placeholder="Payment Date" value="">

                            <span *ngIf="submitted && form.get('pmt_date')?.hasError('required')" style="color:red"> <strong>Please enter or select the payment date!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Amount:<span style="color:red;">*</span></label>
                            <input type="text" formControlName="amt_paid"  class="form-control" placeholder="Amount Paid" value="">

                            <span *ngIf="submitted && form.get('amt_paid')?.hasError('required')" style="color:red"> <strong>Please enter amount paid!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Comment:<span style="color:red;">*</span></label>
                            <input type="text" formControlName="comment"  class="form-control" placeholder="Comment" value="">

                            <span *ngIf="submitted && form.get('amt_paid')?.hasError('required')" style="color:red"> <strong>Please enter a comment about the payment!</strong></span>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Category:<span style="color:red;">*</span></label>
                            <select formControlName="category" class="form-control">
                                <option value="">Select</option>
                                <option [value]="category.id" *ngFor="let category of categories">{{ category.name }}</option>
                            </select>

                            <span *ngIf="submitted && form.get('category')?.hasError('required')" style="color:red"> <strong>Please select a payment reason!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Authorised By:<span style="color:red;">*</span></label>
                            <input type="text"  formControlName="authorised_by" class="form-control" placeholder="Authorised By" value="">

                            <span *ngIf="submitted && form.get('authorised_by')?.hasError('required')" style="color:red"> <strong>Please enter name of the person authorising the payment!</strong></span>
                        </div>
                    </div>
                </div>
               
                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>
            
                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Save</button>
                </ng-template>
           </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>