import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { BillsOfLadingService } from 'src/app/services/suppliers/bills-of-lading.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { PendingTasksHandler } from 'src/app/shared/pending-tasks-handler';

@Component({
  selector: 'app-supplier-update-vessel-eta',
  templateUrl: './supplier-update-vessel-eta.component.html',
  styleUrls: ['./supplier-update-vessel-eta.component.css']
})
export class SupplierUpdateVesselEtaComponent implements OnInit{
  errorMessage: string | null = null;
  form!: FormGroup;
  submitted: boolean = false;
  billsOfLading: any[] = [];
  selectedBL!: string;
  isProcessing: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private billsOfLadingService:BillsOfLadingService,
    private globalService: GlobalService,
    private pendingTasksHandler: PendingTasksHandler,
    private router: Router,
  ){
    this.form = this.formBuilder.group({
      vessel_eta:['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.fetchBLs();
  }

  fetchBLs():void {
    this.billsOfLadingService.getBLPendingVesselETA_Update().subscribe((response: ApiResponse) =>this.billsOfLading = response.data);
  }

  handleSubmit() {
    this.submitted = true;
    this.isProcessing = true;

    if(this.form.valid) {
      if(this.selectedBL==null || this.selectedBL==undefined){
        this.errorMessage="Please select BL to proceed!";
        this.isProcessing = false;
      }else{
        this.billsOfLadingService.updateVesselETA(this.selectedBL,this.form.value.vessel_eta)
        .subscribe({
          next: (response) => {
            if(response.status==='success'){
              this.submitted = false;
              this.isProcessing = false;
              this.errorMessage=null;
              this.globalService.setGlobalSuccessMessage(response.message);
              //Count BLs pending ETA update
              this.pendingTasksHandler.numberOfBLsPendingETAUpdatePmt();
              // Redirect to BL list
              this.router.navigate(['suppliers/bl-list']);
            }else{
              this.errorMessage=response.message;
              this.submitted = false;
              this.isProcessing = false;
            }
          },
          error: (error) => {
            this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
            this.submitted = false;
            this.isProcessing = false;
          }
        });
      }
    }else{
      this.isProcessing = false;
    }
  }
}
