 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Update Grade</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/settings/grades-list"><i class="fa fa-list-ul"></i>&nbsp;View Grades</a></li>
               <li class="breadcrumb-item active">Update Grade</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
         <h3 class="card-title">Update Grade</h3>
         <!--Error Message-->
         <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            <span [innerHTML]="errorMessage"></span>
        </div>
          <!--Success Message-->
          <div class="alert alert-success" role="alert" *ngIf="successMessage">
            {{successMessage}}
          </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
           <form [formGroup]="form" (ngSubmit)="handleSubmit()">
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Model:<span style="color:red;">*</span></label>
                            <select formControlName="model_id" [(ngModel)]="this.gradeData.data.model_id" class="form-control">
                                <option [value]="model.id" *ngFor="let model of models">{{model.model_name}}</option>
                            </select>
                            <span *ngIf="submitted && form.get('model_id')?.hasError('required')" style="color:red"> <strong>Please select model!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                       <div class="form-group">
                           <label>Grade:<span style="color:red;">*</span></label>
                           <input type="text" formControlName="grade" class="form-control" [(ngModel)]="this.gradeData.data.grade" placeholder="Vehicle Grade" [value]="this.gradeData.data.grade">
                           <span *ngIf="submitted && form.get('grade')?.hasError('required')" style="color:red"> <strong>Please enter grade!</strong></span>
                       </div>
                    </div>
                </div>
              
               <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Processing...
                </button>
                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Update</button>
                </ng-template>
           </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>