import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Injectable({
  providedIn: 'root'
})
export class BillsOfLadingService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json','Accept': 'application/json' });

  constructor(private http: HttpClient, private errorHandler: ConfigErrorHandler) { }

  /**
   * Get unshipped bills of lading
   */
  getUnshippedBLs(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/suppliers/unshipped-bls`;
    
    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get total units in all uncleared bLs
   */
  getUnshippedTotalUnits():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/suppliers/unshipped-bls/count-total-units`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

   /**
   * Get total units added in all uncleared bLs
   */
   getUnshippedTotalUnitsAdded():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/suppliers/unshipped-bls/count-total-units-added`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Mark BL(s) as shipped
   * @param shipping_data
   * @param selectedBLs 
   * @returns 
   */
  markBLsAsShipped(shipping_data: any,waybill_attachment:any, selectedBLs: any[]): Observable<any> {

    const formData = new FormData();
    formData.append("date_shipped", shipping_data.date_shipped);
    formData.append("waybill_no", shipping_data.waybill_no);
    formData.append("waybill_attachment", waybill_attachment);

    selectedBLs.forEach((blId, index) => {
      formData.append(`selected_bls[${index}]`, blId);
    });
  

    let apiURL:string=`${this.baseURL}/v1/suppliers/mark-bls-as-shipped`;

    return this.http.post<any>(apiURL, formData);
  }

  /**
   * Upload attachment
   */
  uploadAttachment(attachment:any,bl_no: string):Observable<any>{
    const formData = new FormData();
    formData.append("bl_no", bl_no);
    formData.append("attachment", attachment);
   
    let apiURL:string=`${this.baseURL}/v1/upload-bl-attachment`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Delete BL
   * @param bl_id 
   * @param bl_no 
   * @returns array
   */
  deleteBL(bl_id: string|null,bl_no: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/billsoflading/${bl_id}/${bl_no}`;

    return this.http.delete(apiURL, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Get unshipped BLs pending Vessel ETA update
   */
   getBLPendingVesselETA_Update(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/suppliers/get-bls-pending-vessel-eta-update`;
    
    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Update Vessel ETA
   * @param bl_id
   * @param vessel_eta
   * @returns 
   */
  updateVesselETA(bl_id:string, vessel_eta: any[]): Observable<any> {

    let apiURL:string=`${this.baseURL}/v1/update-vessel-eta`;

    const data = {
      bl_id:bl_id,
      vessel_eta: vessel_eta
    };

    return this.http.post<any>(apiURL, data);
  }
}
