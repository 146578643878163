<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Refundable Commitment Fees</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item active">Refundable Commitment Fees</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
       <div class="row">
            <div class="col-md-8"></div>

            <div class="col-md-4">
                <input type="text" class="form-control" placeholder="Type Chassis Number Customer Name or Phone Number to search..." (input)="onSearchTextChange($event)">
            </div>
       </div>
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
        <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Refundable Commitment Fees</h3>
                    <!--Error Message-->
                    <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                        <span [innerHTML]="errorMessage"></span>
                    </div>
                </div>

                <!-- /.card-header -->
                <div class="card-body" style="overflow-x: auto; white-space: nowrap;">
                    <table id="example1" class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Make</th>
                                <th>Model</th>
                                <th>Chassis No.</th>
                                <th>Customer Name</th>
                                <th>Phone Number</th>
                                <th>Amount to Refund</th>
                                <th>Comment</th>
                                <th>Reservation Date</th>
                            </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let refundable_fee of refundable_fees | searchRefunds: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                                <td>
                                    <div class="dropdown">
                                        <button class="btn btn-default dropdown-toggle" type="button"
                                            data-toggle="dropdown">Action
                                            <span class="caret"></span></button>
                                        <ul class="dropdown-menu">
                                            <li><a class="dropdown-item" routerLink="/refund-commitment-fee/{{refundable_fee.id}}/{{refundable_fee.customer_id}}"><i class="fa fa-undo"></i>&nbsp;Refund</a></li>
                                            <li><a class="dropdown-item" routerLink="/view-commitment-fees/{{refundable_fee.id}}/{{refundable_fee.vehicle_id}}"><i class="far fa-money-bill-alt"></i>&nbsp;Commitment Fees</a></li>
                                        </ul>
                                    </div>
                                </td>
                                <td>{{ refundable_fee.make }}</td>
                                <td>{{ refundable_fee.model }}</td>
                                <td>{{ refundable_fee.chassis_no }}</td>
                                <td>{{ refundable_fee.first_name }} {{ refundable_fee.middle_name }} {{ refundable_fee.surname }}</td>
                                <td>{{ refundable_fee.phone_no }}</td>
                                <td>{{ refundable_fee.refundable_amt| currency:' ' }}</td>
                                <td class="wrap-text">{{ refundable_fee.comment }}</td>
                                <td>{{ refundable_fee.reservation_date| date: 'dd-MM-yyyy' }}</td>
                           </tr>
                        </tbody>
                    </table>
                    <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
                </div>
                <!-- /.card-body -->
        </div>
   </div>
</section>
