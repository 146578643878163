import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CashManagementService } from 'src/app/services/cash-management/cash-management.service';
import { GlobalService } from 'src/app/services/shared/global.service';

@Component({
  selector: 'app-close-day',
  templateUrl: './close-day.component.html',
  styleUrls: ['./close-day.component.css']
})
export class CloseDayComponent implements OnInit{
  isProcessing:boolean=false;
  isLoading:boolean=false;
  public errorMessage: string | null = null;
  public balanceData:any;
  form!: FormGroup;
  submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private cashManagementService: CashManagementService,
    private globalService: GlobalService,
    private router: Router,
  ){
    this.form = this.formBuilder.group({
      txn_date: [''],
      balance: [''],
    });
  }
  
  ngOnInit(): void {
    this.isLoading=true;
    this.getBalanceCf();
  }

  getBalanceCf(){
    this.cashManagementService.getBalanceCf()
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.balanceData = response;
      
          if(this.balanceData.status==='no_data' || this.balanceData.status.length===0){
            //this.isLoading=false;
            this.globalService.setGlobalErrorMessage(this.balanceData.message);
            // Redirect to cash transactions
            this.router.navigate(['cash-mgnt/transactions']);
          }else if(this.balanceData.status==='success'){
            this.isLoading=false;
          }else{
            this.errorMessage=this.balanceData.message;
            this.isLoading=false;
          }
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.errorMessage=error;
      }
    });
  }

  handleSubmit(){
    this.submitted = true;
    this.isProcessing=true;

    this.cashManagementService.closeDay()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.submitted = false;
          this.globalService.setGlobalSuccessMessage(response.message);
          // Redirect to cash transactions
          this.router.navigate(['cash-mgnt/transactions']);
        }else{
          this.submitted = false;
          this.isProcessing=false;
          this.errorMessage=response.message;
        }
      },
      error: (error) => {
        this.submitted = false;
        this.isProcessing=false;
        this.errorMessage=error;
      }
    });
  }
}
