import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { throwError } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ConfigErrorHandler{
    
    public error: string="";

    constructor(private router: Router) { }

    handleError(error: HttpErrorResponse) {
        console.log('Error response:', error);
        let error_msg = '';

        if (error.error instanceof ErrorEvent) {
            // Client-side error
            error_msg = error.error.message;
            this.error=error_msg;
        } else {
            if (error.error && error.error.error === true) {
                // Server-side error
                if(error.error.code==401){
                    error_msg="Your session has expired!";
                    localStorage.setItem("error", error_msg);
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userID');
                    localStorage.removeItem('userFullName');
                    localStorage.removeItem('userEmail');
                    localStorage.removeItem('userPermissions');
                   
                    window.location.href ="/login";
                }else if(error.error.code==422){
                    console.log(`Error Code: ${error.error.code}\nMessage: ${error.message}`);

                    //Loop through the errors object and extract all error messages for different fields.
                    if(error.error.errors){
                        const errorFields = Object.keys(error.error.errors);
                
                        errorFields.forEach(field => {
                            const fieldErrors = error.error.errors[field];
                            if (Array.isArray(fieldErrors)) {
                                fieldErrors.forEach(message => {
                                    error_msg += `<br>${message}`;
                                });
                            }else{
                                error_msg = "Invalid data entry. Please check your inputs and try again!";
                            }
                        });
                    }else{
                        error_msg = "Invalid data entry. Please check your inputs and try again!";
                    }
                }else if(error.error.code==413){
                    error_msg = "The uploaded file is too large. Please check the file size and try again!";
                    console.log(`Error Code: ${error.error.code}\nMessage: ${error.message}`);
                }else{
                    error_msg = 'Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.';
                    console.log(`Error Code: ${error.error.code}\nMessage: ${error.message}`);
                }
            }
        }

        return throwError(() => new Error(error_msg));
    }
}