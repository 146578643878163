import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModelService } from 'src/app/services/models/model.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { GradeService } from 'src/app/services/grades/grade.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-edit-grade',
  templateUrl: './edit-grade.component.html',
  styleUrls: ['./edit-grade.component.css']
})
export class EditGradeComponent implements OnInit {
  form!: FormGroup;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  isProcessing: boolean = false;
  submitted: boolean = false;
  models: any[] = [];
  public gradeData:any;
  public grade_id: string | null = null;

  constructor(
    private modelService:ModelService,
    private formBuilder: FormBuilder,
    private router: Router,
    private globalService: GlobalService,
    private gradeService:GradeService,
    private activatedRoute: ActivatedRoute,
  ){
    this.form = this.formBuilder.group({
      model_id: ['', Validators.required],
      grade: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.grade_id = param['get']('grade_id')
    })

    if(this.grade_id){
      this.getGrade(this.grade_id);
      this.fetchVehiclemodels();
    }else{
      //Navigate back to list with error
      this.errorMessage="Unable to select record for editing!";
      this.router.navigate(['settings/grades-list']);
    }
  }

  fetchVehiclemodels():void{
    this.modelService.getAllModels().subscribe((response: ApiResponse) =>this.models = response.data);
  }

  getGrade(grade_id:any){
    this.gradeService.getGradeData(grade_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          console.log("Resp: "+response);
          this.gradeData = response;
    
          if(this.gradeData.status==='no_data' || this.gradeData.status.length===0){
            this.errorMessage=this.gradeData.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  handleSubmit():void {
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      const formData = {
        ...this.form.value,
      };

      this.gradeService.updateGrade(formData,this.grade_id).subscribe(response => {
        if(response.status==='success'){

          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalSuccessMessage(response.message);
         
          // Redirect back to grades list
          this.router.navigate(['settings/grades-list']);
        }else if(response.status==='error'){
          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalErrorMessage(response.message);
        }else{
          this.submitted=false;
          this.isProcessing = false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
        }
      },
      error => {
        this.isProcessing = false;
        this.errorMessage=error;
      });
    }else{
      this.isProcessing = false;
    }
  }
}
