<!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Pay Agents</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/agent-invoices"><i class="nav-icon fas fa-documents"></i>&nbsp;Agents Invoices</a></li>
               <li class="breadcrumb-item active">Pay Agents</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
    </div>
</section>
<!-- /.content-header -->
<section class="content">
<div class="container-fluid">
    <div class="card">
       <div class="card-header">
            <h3 class="card-title">Pay Agents</h3>
            <!--Error message-->
            <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!--Success messages-->
            <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="successMessage">
                {{successMessage}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
       </div>
       <!-- /.card-header -->
       <form [formGroup]="payAgents" (ngSubmit)="handleSubmit()">
            <div class="card-body">
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Consignee:</label>
                            <input type="text" name="consignee_name" class="form-control" placeholder="Consignee Name" value="{{invoiceData?.data?.consignee}}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Agent:</label>
                            <input type="text" name="agent_name" class="form-control" placeholder="Agent Name" value="{{invoiceData?.data?.agent}}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>BL No.:</label>
                            <input type="text" formControlName="bl_no" class="form-control" placeholder="BL No." [(ngModel)]="invoiceData.data.bl_no" readonly>
                        </div>
                    </div>
                    
                </div>
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>No. of Units:</label>
                            <input type="text" formControlName="no_of_units" class="form-control" placeholder="No. of Units" [(ngModel)]="invoiceData.data.no_of_units" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Invoice No.:</label>
                            <input type="text" formControlName="invoice_no" class="form-control" placeholder="Invoice No." [(ngModel)]="invoiceData.data.invoice_no" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Balance Due:</label>
                            <input type="text" name="balance_due" class="form-control" placeholder="Balance Due" value="{{invoiceData?.data?.balance|currency:' '}}" readonly>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Amount Paid:<span style="color:red;">*</span></label>
                            <input type="text" formControlName="amount_paid" class="form-control" placeholder="Amount Paid" value="">
                            <span *ngIf="submitted && payAgents.get('amount_paid')?.hasError('required')" style="color:red"> <strong>Amount paid is required!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Payment Mode:<span style="color:red;">*</span></label>
                           <select formControlName="pmt_mode" class="form-control">
                                <option value="">Select</option>
                                <option value="Cash">Cash</option>
                                <option value="Cheque">Cheque</option>
                                <option value="Bank Transfer">Bank Transfer</option>
                           </select>
    
                            <span *ngIf="submitted && payAgents.get('pmt_mode')?.hasError('required')" style="color:red"> <strong>Please select payment mode!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Payment Ref:<span style="color:red;">*</span></label>
                            <input type="text" formControlName="pmt_ref"  class="form-control" placeholder="Payment Ref." value="">
    
                            <span *ngIf="submitted && payAgents.get('pmt_ref')?.hasError('required')" style="color:red"> <strong>Payment reference is required!</strong></span>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Payment Date:<span style="color:red;">*</span></label>
                            <input type="date" formControlName="pmt_date"  class="form-control" placeholder="Payment Date" value="">
    
                            <span *ngIf="submitted && payAgents.get('pmt_date')?.hasError('required')" style="color:red"> <strong>Please select payment date!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Attachment:<span style="color:red;">*</span></label>
                            <div class="input-group mb-3">
                                <div class="custom-file">
                                  <input type="file" formControlName="attachment" accept="application/pdf,image/*" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onFileSelected($event)">
                                  <label class="custom-file-label" for="inputGroupFile01">{{ fileName }}</label>
                                </div>
                            </div>

                            <span *ngIf="submitted && payAgents.get('attachment')?.hasError('required')" style="color:red"> <strong>Please add payment attachment!</strong></span>
                        </div>
                    </div>
                </div>

                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>
            
                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Save</button>
                </ng-template>
            </div>
        </form>
    </div>
</div>
</section>