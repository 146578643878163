<!--Loading Spinner-->
<div *ngIf="isInProgress">
    <app-spinner></app-spinner>
  </div>
  
  <!-- Content Header (Page header) -->
  <section class="content-header">
      <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
             
              <div class="col-sm-6">
               
              </div><!-- /.col -->
             
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                  <li class="breadcrumb-item active">Sold Cars</li>
                </ol>
              </div><!-- /.col -->
            </div><!-- /.row -->
          </div><!-- /.container-fluid -->
      </div><!-- /.content-header -->
      <div class="row">
        <div class="col-md-8">
            <div class="row">
               
            </div>
        </div>
        <div class="col-md-4">
          <div class="row">
              <div class="col">
                  <input type="text" class="form-control" placeholder="Type here to search..." (input)="onSearchTextChange($event)">
              </div>
          </div>
        </div>
      </div>
      </section>
      <section class="content">
        <div class="container-fluid">
        <div class="card">
            <div class="card-header">
              <h3 class="card-title">Sold Cars&nbsp;&nbsp;&nbsp;Count: {{rowCount}}</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <!--Error Message-->
                <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                  {{errorMessage}}
                </div>
              <table id="example1" class="table table-bordered table-hover table-striped">
                <thead>
                  <tr>
                      <th>Make</th>
                      <th>Model</th>
                      <th>Chassis No.</th>
                      <th>Engine CC</th>
                      <th>Engine No</th>
                      <th>Transmission</th>
                      <th>Body Type</th>
                      <th>Drive</th>
                      <th>Fuel</th>
                      <th>Mileage</th>
                      <th>Colour</th>
                      <th>Month Year</th>
                      <th>Reg No.</th>
                      <th *ngIf="userPermissions.includes('process-sale')"></th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let vehicle of vehicles | searchStock: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                      <td>{{vehicle.make}}</td>
                      <td>{{vehicle.model}}</td>
                      <td>{{vehicle.chassis_no}}</td>
                      <td>{{vehicle.engine_cc}}</td>
                      <td>{{vehicle.engine_no}}</td>
                      <td>{{vehicle.transmission}}</td>
                      <td>{{vehicle.body_type}}</td>
                      <td>{{vehicle.drive}}</td>
                      <td>{{vehicle.fuel}}</td>
                      <td>{{vehicle.mileage}}</td>
                      <td>{{vehicle.colour}}</td>
                      <td>{{vehicle.month_yr}}</td>
                      <td>{{vehicle.reg_no}}</td>
                      <td *ngIf="userPermissions.includes('process-sale') || userPermissions.includes('view-vhcl-atts')">
                        <div class="dropdown">
                            <button class="btn btn-default dropdown-toggle" type="button"
                                data-toggle="dropdown">Action
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li *ngIf="userPermissions.includes('process-sale') && vehicle.reserved === 1"><a class="dropdown-item" routerLink="/view-vehicle-reservations/{{vehicle.vehicle_id}}">View Reservations</a></li>
                                <li *ngIf="userPermissions.includes('view-vhcl-atts')"><a class="dropdown-item" routerLink="/view-vehicle-attachments/{{vehicle.vehicle_id}}">View Attachments</a></li>
                                <li *ngIf="userPermissions.includes('print-qr-codes')"><a class="dropdown-item" routerLink="/print-qr-code/{{vehicle.chassis_no}}">Print QR Code</a></li>
                            </ul>
                        </div>
                      </td>
                    </tr>
                </tbody>
              </table>
              <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </section>