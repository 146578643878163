import { Component } from '@angular/core';
import { BranchService } from 'src/app/services/branches/branch.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-branches-list',
  templateUrl: './branches-list.component.html',
  styleUrls: ['./branches-list.component.css']
})
export class BranchesListComponent {
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  branches: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;

  constructor(
    private branchService:BranchService,
  ){}

  ngOnInit(): void {
    this.fetchBranches();
  }

  fetchBranches():void{
    this.branchService.getAllBranches().subscribe((response: ApiResponse) =>this.branches = response.data);
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fetchBranches();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  deleteBranch(event: Event,locationId:string): void{
    event.preventDefault();
  }
}
