<!--Loading Spinner-->
<div *ngIf="isLoading">
  <app-spinner></app-spinner>
</div>

<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
           
            <div class="col-sm-6">
              
            </div><!-- /.col -->
           
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item active">Edit Entry</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
  
    <div class="row">
      <div class="col-md-8">
          <div class="row">
             
          </div>
      </div>
      <div class="col-md-4">
        <div class="row">
            <div class="col">
                <input type="text" class="form-control" placeholder="Search by Make, Model, Chassis No., Entry No., Document Ref No., Ref No." (input)="onSearchTextChange($event)">
            </div>
        </div>
      </div>
    </div>
    </section>
      <!-- /.content-header -->
    <section class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Edit Entry</h3>
            <!--Error Message-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
              {{errorMessage}}
            </div>
            <!--Success Message-->
            <div class="alert alert-success" role="alert" *ngIf="successMessage">
              {{successMessage}}
            </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <table id="example1" class="table table-bordered table-hover">
              <thead>
                <tr>
                    <th>BL No.</th>
                    <th>Make</th>
                    <th>Model</th>
                    <th>Chassis No.</th>
                    <th>Entry No.</th>
                    <th>Entry Date</th>
                    <th>Document Ref No.</th>
                    <th>Payment Ref. No.</th>
                    <th>Duty Amount</th>
                    <th>Transaction Type</th>
                    <th></th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let vehicle of editableEntries | searchEditableEntries: searchText| paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }" [ngClass]="{'highlight_paid': vehicle.bank_acc_id!==null}">
                    <td>{{vehicle.bl_no}}</td>
                    <td>{{vehicle.make_name}}</td>
                    <td>{{vehicle.model_name}}</td>
                    <td>{{vehicle.chassis_no}}</td>
                    <td>{{vehicle.entry_no}}</td>
                    <td>{{vehicle.entry_date| date:'dd-MM-yyyy'}}</td>
                    <td>{{vehicle.doc_ref_no}}</td>
                    <td>{{vehicle.pmt_ref_no}}</td>
                    <td>{{vehicle.amount_paid|currency:' '}}</td>
                    <td *ngIf="vehicle.doc_ref_no !== null && vehicle.doc_ref_no | endsWith:'-1'; else checkNull">Entry</td>
                    <ng-template #checkNull>
                      <td *ngIf="vehicle.doc_ref_no === null; else notEndingWith">Additional Duty</td>
                    </ng-template>
                    <ng-template #notEndingWith>
                      <td>Additional Duty</td>
                    </ng-template>
                    <td *ngIf="vehicle.doc_ref_no !== null"><a (click)="openModal(vehicle.entry_id,vehicle.txn_id)" class="btn btn-danger btn-sm active" role="button" aria-pressed="true" data-target="#addVehicleModal"><i
                      class="fa fa-eraser" aria-hidden="true">&nbsp;</i>Edit</a></td>
                    <td *ngIf="vehicle.doc_ref_no === null"><a (click)="openUnofficialModal(vehicle.txn_id)" class="btn btn-danger btn-sm active" role="button" aria-pressed="true" data-target="#addVehicleModal"><i
                        class="fa fa-eraser" aria-hidden="true">&nbsp;</i>Edit</a></td>
                  </tr>
              </tbody>
            </table>
            <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </section>

     <!-- Official Modal -->
     <div class="modal fade" #EditEntryModal tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Edit Entry</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!--Error message-->
                <div class="alert alert-danger" role="alert" *ngIf="popupErrorMessage">
                    {{popupErrorMessage}}
                </div>
                <!--Success message-->
                <div class="alert alert-success" role="alert" *ngIf="popupSuccessMessage">
                    {{popupSuccessMessage}}
                </div>

                 <!--Warning message-->
                <div class="alert alert-warning" role="alert" *ngIf="popupWarningMessage">
                    {{popupWarningMessage}}
                </div>

                <form [formGroup]="form" (ngSubmit)="handleSubmit()">
                  <div class="modal-body">
                    <div class="container-fluid">
                      <div class="row clearfix">
                        <div class="col-md-4">
                           <div class="form-group">
                               <label>Make:</label>
                               <input type="text" name="make" class="form-control" placeholder="Make" value="{{ entry?.data?.make }}" readonly>
                           </div>
                        </div>
                        <div class="col-md-4">
                           <div class="form-group">
                               <label>Model:</label>
                               <input type="text" name="model" class="form-control" placeholder="Model" value="{{ entry?.data?.model }}" readonly>
                           </div>
                        </div>
                        <div class="col-md-4">
                           <div class="form-group">
                               <label>Chassis No.</label>
                               <input type="text" formControlName="chassis_no" class="form-control" placeholder="Chassis No." [(ngModel)]="entry.data.chassis_no" readonly>

                               <span *ngIf="submitted && form.get('chassis_no')?.hasError('required')" style="color:red"> <strong>Chassis number appears to be missing. Please refresh this page!</strong></span>
                            </div>
                        </div>
                      </div>
                      <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Colour:</label>
                                <input type="text" name="colour" class="form-control" placeholder="Colour" value="{{ entry?.data?.colour }}" readonly>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Entry No.:<span style="color:red;">*</span></label>
                                <input type="text"  formControlName="entry_no" class="form-control" placeholder="Entry No." [(ngModel)]="entry.data.entry_no">
    
                                <span *ngIf="submitted && form.get('entry_no')?.hasError('required')" style="color:red"> <strong>Please enter entry number!</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Entry Date:<span style="color:red;">*</span></label>
                                <input type="date" formControlName="entry_date" class="form-control" placeholder="Entry Date" [(ngModel)]="entry.data.entry_date">
    
                                <span *ngIf="submitted && form.get('entry_date')?.hasError('required')" style="color:red"> <strong>Please enter or select entry date!</strong></span>
                            </div>
                        </div>
                      </div>
                      <div class="separator">Payment Details</div>
                      <div class="row clearfix">
                          <div class="col-md-4">
                            <div class="form-group">
                                <label>Duty Amount:<span style="color:red;">*</span></label>
                                <input type="text" formControlName="duty_amount"  class="form-control" placeholder="Duty Amount" [(ngModel)]="entry.data.duty_amount">
    
                                <span *ngIf="submitted && form.get('duty_amount')?.hasError('required')" style="color:red"> <strong>Please enter duty amount!</strong></span>
                            </div>
                          </div>
                          <div class="col-md-4">
                              <div class="form-group">
                                  <label>Document Reference:<span style="color:red;">*</span></label>
                                  <input type="text" formControlName="doc_ref_no" class="form-control" placeholder="Document Reference Number" [(ngModel)]="entry.data.doc_ref_no">

                                  <span *ngIf="submitted && form.get('doc_ref_no')?.hasError('required')" style="color:red"> <strong>Please enter Document Reference Number!</strong></span>
                              </div>
                          </div>
                          <div class="col-md-4">
                              <div class="form-group">
                                  <label>Payment Ref. Number:<span style="color:red;">*</span></label>
                                  <input type="text" formControlName="payment_ref_no" class="form-control" placeholder="Payment Reference Number" [(ngModel)]="entry.data.pmt_ref_no">

                                  <span *ngIf="submitted && form.get('payment_ref_no')?.hasError('required')" style="color:red"> <strong>Please enter Payment Reference Number!</strong></span>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                <div class="modal-footer">
                    <button type="button" #closeModal class="btn btn-danger" data-dismiss="modal"><i class="fa fa-window-close" aria-hidden="true"></i>&nbsp;Cancel</button>

                    <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Processing...
                    </button>

                    <ng-template #showSaveBtn>
                        <button type="submit" class="btn btn-success" ><i class="fa fa-save"
                            aria-hidden="true">&nbsp;</i>Save</button>
                    </ng-template>
                </div>
                </form>
            </div>
        </div>
    </div>

    <!-- Unofficial Modal -->
    <div class="modal fade" #EditEntryUnofficialModal tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Edit Entry (Unofficial)</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>

              <!--Error message-->
              <div class="alert alert-danger" role="alert" *ngIf="popupErrorMessage">
                  {{popupErrorMessage}}
              </div>
              <!--Success message-->
              <div class="alert alert-success" role="alert" *ngIf="popupSuccessMessage">
                  {{popupSuccessMessage}}
              </div>

               <!--Warning message-->
              <div class="alert alert-warning" role="alert" *ngIf="popupWarningMessage">
                  {{popupWarningMessage}}
              </div>

              <form [formGroup]="unofficialDutyform" (ngSubmit)="updateUnofficialDuty()">
                <div class="modal-body">
                  <div class="container-fluid">
                    <div class="row clearfix">
                      <div class="col-md-4">
                         <div class="form-group">
                             <label>Make:</label>
                             <input type="text" name="make" class="form-control" placeholder="Make" value="{{ entry?.data?.make }}" readonly>
                         </div>
                      </div>
                      <div class="col-md-4">
                         <div class="form-group">
                             <label>Model:</label>
                             <input type="text" name="model" class="form-control" placeholder="Model" value="{{ entry?.data?.model }}" readonly>
                         </div>
                      </div>
                      <div class="col-md-4">
                         <div class="form-group">
                             <label>Chassis No.</label>
                             <input type="text" formControlName="chassis_no" class="form-control" placeholder="Chassis No." [(ngModel)]="entry.data.chassis_no" readonly>

                             <span *ngIf="submitted && unofficialDutyform.get('chassis_no')?.hasError('required')" style="color:red"> <strong>Chassis number appears to be missing. Please refresh this page!</strong></span>
                          </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-4">
                          <div class="form-group">
                              <label>Colour:</label>
                              <input type="text" name="colour" class="form-control" placeholder="Colour" value="{{ entry?.data?.colour }}" readonly>
                          </div>
                      </div>
                      <div class="col-md-4">
                          <div class="form-group">
                              <label>Entry No.:<span style="color:red;">*</span></label>
                              <input type="text"  name="entry_no" class="form-control" placeholder="Entry No." value="{{ entry?.data?.entry_no }}" readonly>
                          </div>
                      </div>
                      <div class="col-md-4">
                          <div class="form-group">
                              <label>Entry Date:<span style="color:red;">*</span></label>
                              <input type="date" name="entry_date" class="form-control" placeholder="Entry Date" value="{{ entry?.data?.entry_date }}" readonly>
                          </div>
                      </div>
                    </div>
                    <div class="separator">Payment Details</div>
                    <div class="row clearfix">
                        <div class="col-md-4">
                          <div class="form-group">
                              <label>Duty Amount:<span style="color:red;">*</span></label>
                              <input type="text" formControlName="duty_amount"  class="form-control" placeholder="Duty Amount" [(ngModel)]="entry.data.duty_amount">
  
                              <span *ngIf="submitted && unofficialDutyform.get('duty_amount')?.hasError('required')" style="color:red"> <strong>Please enter duty amount!</strong></span>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              <div class="modal-footer">
                  <button type="button" #closeUnofficialModal class="btn btn-danger" data-dismiss="modal"><i class="fa fa-window-close" aria-hidden="true"></i>&nbsp;Cancel</button>

                  <button class="btn btn-success" type="button" *ngIf="isProcessing; else showUnofficialSaveBtn" disabled>
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Processing...
                  </button>

                  <ng-template #showUnofficialSaveBtn>
                      <button type="submit" class="btn btn-success" ><i class="fa fa-save"
                          aria-hidden="true">&nbsp;</i>Save</button>
                  </ng-template>
              </div>
              </form>
          </div>
      </div>
  </div>