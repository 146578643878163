import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class DeleteEntryService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }

  /**
   * Get editable entries
   * @returns Array
   */
  fetchEntries(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/entries/deletable-list`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Delete Entry
   * @param entry_id 
   */
  deleteEntry(entry_id: string){

    let apiURL:string=`${this.baseURL}/v1/entries/delete/${entry_id}`;

    return this.http.delete<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get additional duties associated with
   * the selected entry
   * @returns Array
   */
  fetchAdditionalDuties(entry_id: string|null): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/entries/get-additional-duties/${entry_id}`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Delete additional duty
   * @param txn_id 
   */
  deleteAdditionalDuty(txn_id: string, doc_ref_no:string, pmt_type:string){

    let apiURL:string=`${this.baseURL}/v1/entries/delete-additional-duty/${txn_id}/${doc_ref_no}/${pmt_type}`;

    return this.http.delete<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
