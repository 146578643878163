import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchStock'
})
export class SearchStockPipe implements PipeTransform {

  transform(stockData: any, searchText: string){
    
    if (!searchText) {
      return stockData; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return stockData.filter((item: any) =>
      (item.bl_no && item.bl_no.toLowerCase().includes(searchText)) ||
      (item.entry_no && item.entry_no.toLowerCase().includes(searchText)) ||
      (item.make && item.make.toLowerCase().includes(searchText)) ||
      (item.model && item.model.toLowerCase().includes(searchText)) ||
      (item.chassis_no && item.chassis_no.toLowerCase().includes(searchText)) ||
      (item.reg_no && item.reg_no.toLowerCase().includes(searchText)) ||
      (item.colour && item.colour.toLowerCase().includes(searchText)) ||
      (item.month_yr && item.month_yr.toLowerCase().includes(searchText)) ||
      (item.agency && item.agency.toLowerCase().includes(searchText)) ||
      (item.company && item.company.toLowerCase().includes(searchText))
    );
  }

}
