import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { ViewAttachmentsService } from 'src/app/services/vehicles/view-attachments.service';

@Component({
  selector: 'app-preview-vehicle-attachment',
  templateUrl: './preview-vehicle-attachment.component.html',
  styleUrls: ['./preview-vehicle-attachment.component.css']
})
export class PreviewVehicleAttachmentComponent implements OnInit{
  public vehicle_id: string | null = null;
  public attachment_id: string | null = null;
  public isLoading: boolean = false;
  attachment: any;
  fileURL: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService:GlobalService,
    private viewAttachmentsService:ViewAttachmentsService,
  ){}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.attachment_id = param['get']('attachment_id')
      this.vehicle_id = param['get']('vehicle_id')
    })

    if(this.attachment_id && this.vehicle_id){
      this.isLoading = true;
      this.fetchAttachmentUrl(this.attachment_id);
    }else{
      //Navigate back to refund attachments list with error
      this.globalService.setGlobalErrorMessage("Unable to preview attachment. Please try again!");
      this.router.navigate(['view-vehicle-attachments',this.vehicle_id]);
    }
  }

  fetchAttachmentUrl(attachment_id:string){
    this.viewAttachmentsService.getAttachmentUrl(attachment_id)
    .subscribe({ 
        next: (response) => {
          if (response && Object.keys(response).length === 0) {
            this.isLoading = false;
            //Navigate back to refund attachments list with error
            this.globalService.setGlobalErrorMessage("Unable to preview attachment. Please try again!");
            this.router.navigate(['view-vehicle-attachments',this.vehicle_id]);
          }else{
            this.isLoading = false;
            this.attachment = response;
            
            if(this.attachment.status==='no_data' || this.attachment.status.length===0){
              this.isLoading = false;
              //Navigate back to refund attachments list with error
              this.globalService.setGlobalErrorMessage("Unable to preview attachment. Please try again!");
              this.router.navigate(['view-vehicle-attachments',this.vehicle_id]);
            }else{
              this.fileURL=this.attachment.data.file_path;
            }
          }
        },
        error: (error) => {
          this.isLoading = false;
          //Navigate back to refund attachments list with error
          this.globalService.setGlobalErrorMessage("Unable to preview attachment. Please try again!");
          this.router.navigate(['view-vehicle-attachments',this.vehicle_id]);
        }
      });
  }
}
