import { Component, OnInit } from '@angular/core';
import { VehiclesService } from 'src/app/services/vehicles/vehicles.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vehicles-pending-photo-upload',
  templateUrl: './vehicles-pending-photo-upload.component.html',
  styleUrls: ['./vehicles-pending-photo-upload.component.css']
})
export class VehiclesPendingPhotoUploadComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public warningMessage: string | null = null;
  vehicles: any[] = [];
  searchText: string = '';
  public isInProgress: boolean = false;
  public userPermissions: string[] = [];

  constructor(
    private vehiclesService: VehiclesService,
    private authService: AuthService,
    private globalService: GlobalService,
    private router: Router,
  ){}

  ngOnInit(): void {
    this.isInProgress=true;
    this.fetchVehicles();

    //Get user permissions
    this.userPermissions=this.authService.getPermissions();

    if(!this.userPermissions.includes('add-vhcl-photos')){
       //Navigate back to dashboard with error
       this.globalService.setGlobalErrorMessage("Access Denied!");
       this.router.navigate(['/']);
    }
  }

  fetchVehicles():void{
    this.vehiclesService.getVehiclesPendingPhotoUpload()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicles = response.data;
          this.isInProgress=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }
}
