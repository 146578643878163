 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Edit User</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/settings/users-list"><i class="fa fa-list-ul"></i>&nbsp;Users List</a></li>
               <li class="breadcrumb-item active">Edit User</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
         <h3 class="card-title">Edit User</h3>
          <!--Error Message-->
          <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            <span [innerHTML]="errorMessage"></span>
          </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
           <form [formGroup]="form" (ngSubmit)="handleSubmit()">
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Name:<span style="color:red;">*</span></label>
                            <input type="text" formControlName="name" class="form-control" placeholder="User's Full Name" [(ngModel)]="userData.data.name" [value]="userData?.data?.name">
                            <span *ngIf="form.get('name')?.hasError('required') && form.get('name')?.touched" style="color:red"> <strong>Please enter user's full name!</strong></span>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                   <div class="col-md-4">
                        <div class="form-group">
                            <label>Email:<span style="color:red;">*</span></label>
                            <input type="email" formControlName="email" class="form-control" placeholder="Email" [(ngModel)]="userData.data.email" [value]="userData.data.email">
                            <span *ngIf="form.get('email')?.hasError('required') && form.get('email')?.touched" style="color:red"> <strong>Email is required!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Username:<span style="color:red;">*</span></label>
                            <input type="text" formControlName="username" class="form-control" placeholder="Username" [(ngModel)]="userData.data.username" [value]="userData.data.username">
                            <span *ngIf="form.get('username')?.hasError('required') && form.get('username')?.touched" style="color:red"> <strong>Please assign user a username!</strong></span>
                            <span *ngIf="form.get('username')?.hasError('minlength') && form.get('username')?.touched" style="color:red">
                                <strong>Username must be at least 6 characters long!</strong>
                            </span>
                            <span *ngIf="form.get('username')?.hasError('maxlength') && form.get('username')?.touched" style="color:red">
                                <strong>Username must not exceed 10 characters!</strong>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Role:<span style="color:red;">*</span></label>
                            <select formControlName="role_id" class="form-control" [(ngModel)]="userData.data.role_id">
                                 <option value="userData?.data?.role_id">{{userData?.data?.role_name}}</option>
                                 <option [value]="role.id" *ngFor="let role of roles">{{ role.name }}</option>
                            </select>
                            <span *ngIf="form.get('role_id')?.hasError('required') && form.get('role_id')?.touched" style="color:red"> <strong>Please assign user a role!</strong></span>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                   <div class="col-md-4">
                       <div class="form-group">
                           <label>Location:<span style="color:red;">*</span></label>
                           <select formControlName="location_id" class="form-control" (change)="onSelectChange($event.target)" [(ngModel)]="userData.data.location_id">
                                <option value="userData?.data?.location_id">{{userData?.data?.location_name}}</option>
                                <option [value]="location.id" *ngFor="let location of locations">{{ location.location_name }}</option>
                           </select>
                           <span *ngIf="invalidLocationSelected" style="color:red"> <strong>Invalid location selected!</strong></span>
                           <span *ngIf="form.get('location_id')?.hasError('required') && form.get('location_id')?.touched" style="color:red"> <strong>Location is required!</strong></span>
                       </div>
                   </div>
                   <div class="col-md-4">
                        <div class="form-group">
                            <label>Branch:<span style="color:red;">*</span></label>
                            <select formControlName="branch_id" class="form-control" [(ngModel)]="userData.data.branch_id">
                                <option [value]="userData?.data?.branch_id">{{userData?.data?.branch_name}}</option>
                                <option [value]="branch.id" *ngFor="let branch of branches">{{ branch.branch_name }}</option>
                            </select>
                            <span *ngIf="form.get('branch_id')?.hasError('required') && form.get('branch_id')?.touched" style="color:red"> <strong>Branch is required!</strong></span>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Stock Access Level:<span style="color:red;">*</span></label>
                            <select formControlName="stk_access_level" class="form-control" [(ngModel)]="userData.data.stk_access_level">
                                <option value="3">All Locations</option>
                                <option value="2">User's Country</option>
                                <option value="1">User's Location</option>
                                <option value="0">User's Branch</option>
                            </select>
                            <span *ngIf="form.get('stk_access_level')?.hasError('required')" style="color:red"> <strong>Please select Stock Access Level!</strong></span>
                        </div>
                    </div>
                </div>

                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>
                
                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Update</button>
                </ng-template>
           </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>