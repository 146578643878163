import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/users/user.service';
import { ApiResponse } from 'src/app/models/IApiResponse';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  users: any[] = [];
  public isProcessing: boolean = false;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;

  constructor(
    private userService: UserService
  ){}

  ngOnInit(): void {
    this.fetchUsers();
  }

  fetchUsers():void{
    this.userService.getAllUsers().subscribe((response: ApiResponse) =>this.users = response.data);
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fetchUsers();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  deleteUser(event: Event,userId:string){
    event.preventDefault();
    this.confirmBox(userId);
  }

  confirmBox(userId: string){
    Swal.fire({
      title: 'Are you sure you want to delete this user account?',
      text: 'The action is irreversible!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.isProcessing=true;

        this.userService.deleteUser(userId)
        .subscribe({
          next: (response) => {
            if(response.status==='success'){
              this.isProcessing=false;
              this.successMessage=response.message;
              this.errorMessage=''; //Clear error message incase it's available
             
              //Reload users
              this.fetchUsers();
            }else if(response.status==='error'){
              this.isProcessing=false;
              this.errorMessage=response.message;
            }else{
              this.isProcessing=false;
              this.errorMessage=response.message;
            }
          },
          error: (error) => {
            this.isProcessing=false;
            this.errorMessage=error;
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      
      }
    })
  }

}
