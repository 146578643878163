<div *ngIf="isInProgress">
  <app-spinner></app-spinner>
</div>

<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item active">Vehicle List</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>

    <div class="row">
        <div class="col-md-8">
          <div class="row" *ngIf="userPermissions.includes('transfer-vhcl-sys') && selectedVehicles.length>0">
              <div class="col-md-1">
                <label>Location:</label>
              </div>
              <div class="col-md-3">
                  <select name="locations" class="form-control" [(ngModel)]="selectedLocation" (change)="onSelectChange($event.target)">
                    <option value="">Select</option>
                    <option [value]="location.id" *ngFor="let location of locations">{{ location.location_name }}</option>
                  </select>
              </div>
              <div class="col-md-1">
                <label>Branch:</label>
              </div>
              <div class="col-md-3">
                <select name="branches" class="form-control" [(ngModel)]="selectedBranch">
                  <option value="">Select</option>
                  <option [value]="branch.id" *ngFor="let branch of branches">{{ branch.branch_name }}</option>
                </select>
              </div>
              <div class="col">
                  <input type="submit" (click)="transferVehicles()" class="btn btn-outline-dark" value="Transfer">
              </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row">
              <div class="col">
                  <input type="text" class="form-control" placeholder="Search by Make, Model, Chassis No., Engine No., BL No. or Vessel Name" (input)="onSearchTextChange($event)">
              </div>
          </div>
        </div>
      </div>
    </section>
      <!-- /.content-header -->
    <section class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Vehicle List</h3>
              <!--Error Message-->
              <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
              </div>
              <!--Success Message-->
              <div class="alert alert-success" role="alert" *ngIf="successMessage">
                {{successMessage}}
              </div>
               <!--Warning Message-->
              <div class="alert alert-warning" role="alert" *ngIf="warningMessage">
                {{warningMessage}}
              </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body" style="overflow-x: auto; overflow-y: auto; white-space: nowrap; height: 650px;">
               <!--Error messages here-->
            <table id="example1" class="table table-bordered table-hover table-striped">
              <thead>
              <tr>
                  <th *ngIf="userPermissions.includes('transfer-vhcl-sys')"></th>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Chassis No.</th>
                  <th>Engine No.</th>
                  <th>Engine CC</th>
                  <th>Transmission</th>
                  <th>Body Type</th>
                  <th>Drive</th>
                  <th>Fuel</th>
                  <th>Mileage</th>
                  <th>Colour</th>
                  <th>Year</th>
                  <th>Weight</th>
                  <th>CBM</th>
                  <th>Reg No.</th>
                  <th>BL No.</th>
                  <th>Vessel Name</th>
                  <th *ngIf="userPermissions.includes('view-location')">Location</th>
                  <th *ngIf="userPermissions.includes('view-status')">Status</th>
                  <th *ngIf="userPermissions.includes('update-sp')">Price</th>
                  <th *ngIf="userPermissions.includes('add-vhcl-photos') || userPermissions.includes('view-vhcl-details') || userPermissions.includes('view-vhcl-atts') || userPermissions.includes('edit-vhcl') || userPermissions.includes('process-sale')"></th>
              </tr>
              </thead>
              <tbody>
                <tr *ngFor="let vehicle of vehicles  | searchVehicle: searchText| paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }" [ngClass]="{'highlight_reserved': vehicle.reserved===1}">
                    <td *ngIf="userPermissions.includes('transfer-vhcl-sys')">
                      <input type="checkbox" [(ngModel)]="vehicle.checked" (change)="checkboxChange($event, vehicle.vehicle_id)" id="vehicle" name="vehicle" *ngIf="vehicle.status === 1 || vehicle.status === 2">
                    </td>
                    <td>{{vehicle.make}}</td>
                    <td>{{vehicle.model}}</td>
                    <td><a routerLink="/view-vehicle/{{vehicle.vehicle_id}}">{{vehicle.chassis_no}}</a></td>
                    <td>{{vehicle.engine_no}}</td>
                    <td>{{vehicle.engine_cc}}</td>
                    <td>{{vehicle.transmission}}</td>
                    <td>{{vehicle.body_type}}</td>
                    <td>{{vehicle.drive}}</td>
                    <td>{{vehicle.fuel}}</td>
                    <td>{{vehicle.mileage}}</td>
                    <td>{{vehicle.colour}}</td>
                    <td>{{vehicle.year}}</td>
                    <td>{{vehicle.weight}}</td>
                    <td>{{vehicle.cbm}}</td>
                    <td>{{vehicle.reg_no}}</td>
                    <td>{{vehicle.bl_no}}</td>
                    <td>{{vehicle.vessel}}</td>
                    <td *ngIf="userPermissions.includes('view-location') && vehicle.status === 0">{{vehicle.cfs_name}} {{vehicle.vessel_eta}}</td> <!--Show CFS Name and ETA on location column if vehicle is En route-->
                    <td *ngIf="userPermissions.includes('view-location') && vehicle.status === 1">{{vehicle.cfs_name}}</td> <!--Show CFS Name on location column if vehicle is at CFS-->
                    <td *ngIf="userPermissions.includes('view-location') && vehicle.status === 2 || vehicle.status === 3 || vehicle.status === 4">{{vehicle.location_name}} - {{vehicle.branch_name}}</td> <!--Show location and branch if vehicle is in stock,being transferred or on road test-->
                    <td *ngIf="userPermissions.includes('view-location') && vehicle.status === 6">Japan Stock</td>
                    <td *ngIf="vehicle.status === 0 && userPermissions.includes('view-status')">En route</td>
                    <td *ngIf="vehicle.status === 1 && userPermissions.includes('view-status')">At CFS</td>
                    <td *ngIf="vehicle.status === 2 && userPermissions.includes('view-status')">In Stock</td>
                    <td *ngIf="vehicle.status === 3 && userPermissions.includes('view-status')">Being Transferred</td>
                    <td *ngIf="vehicle.status === 4 && userPermissions.includes('view-status')">On Road Test</td>
                    <td *ngIf="vehicle.status === 6 && userPermissions.includes('view-status')">Not Shipped</td>
                    <td *ngIf="userPermissions.includes('update-sp')">{{vehicle.price | currency:' '}}</td>
                    <td *ngIf="userPermissions.includes('add-vhcl-photos') || userPermissions.includes('view-vhcl-details') || userPermissions.includes('add-vhcl-atts') || userPermissions.includes('view-vhcl-atts') || userPermissions.includes('rplc-vhcl-atts') || userPermissions.includes('edit-vhcl') || userPermissions.includes('process-sale')">
                      <div class="dropdown">
                          <button class="btn btn-default dropdown-toggle" type="button"
                              data-toggle="dropdown">Action
                              <span class="caret"></span></button>
                          <ul class="dropdown-menu">
                              <li *ngIf="userPermissions.includes('add-vhcl-photos')"><a class="dropdown-item" routerLink="/view-vehicle/{{vehicle.vehicle_id}}">View/Upload Photos</a></li>
                              <li *ngIf="userPermissions.includes('view-vhcl-atts') || userPermissions.includes('add-vhcl-atts') || userPermissions.includes('rplc-vhcl-atts')"><a class="dropdown-item" routerLink="/view-vehicle-docs/{{vehicle.vehicle_id}}">View/Upload Documents</a></li>
                              <li *ngIf="userPermissions.includes('view-vhcl-details')"><a class="dropdown-item" routerLink="/view-vehicle-attachments/{{vehicle.vehicle_id}}">View Details</a></li>
                              <li *ngIf="userPermissions.includes('edit-vhcl')"><a class="dropdown-item" routerLink="/edit-vehicle/{{vehicle.vehicle_id}}">Edit</a></li>
                              <li *ngIf="userPermissions.includes('update-sp')"><a class="dropdown-item" (click)="openModal(vehicle.vehicle_id,vehicle.price)">Update Vehicle Price</a></li>
                              <li *ngIf="userPermissions.includes('process-sale') && vehicle.reserved === 0"><a class="dropdown-item" routerLink="/reserve-vehicle/{{vehicle.vehicle_id}}">Reserve</a></li>
                              <li *ngIf="userPermissions.includes('process-sale') && vehicle.reserved === 1"><a class="dropdown-item" routerLink="/view-vehicle-reservations/{{vehicle.vehicle_id}}">View Reservations</a></li>
                              <li *ngIf="userPermissions.includes('process-sale') && vehicle.status!=5 && vehicle.reserved === 0"><a class="dropdown-item" (click)="markVehicleAsSold(vehicle.vehicle_id)">Mark As Sold</a></li>
                              <li *ngIf="userPermissions.includes('print-qr-codes')"><a class="dropdown-item" routerLink="/print-qr-code/{{vehicle.chassis_no}}">Print QR Code</a></li>
                          </ul>
                      </div>
                    </td>
                </tr>
              </tbody>
            </table>
            <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </section>

    <!-- Modal -->
<div class="modal fade" #UpdatePriceModal tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Update Vehicle Price</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="form" (ngSubmit)="updatePrice()">
        <div class="modal-body">
            <div class="form-group">
                <label>New Price:<span style="color:red;">*</span></label>
                <div class="input-group mb-3">
                    <div class="custom-file">
                      <input type="text" formControlName="new_price" class="form-control" placeholder="New Price">
                    </div>
                </div>

                <span *ngIf="submitted && form.get('new_price')?.hasError('required')" style="color:red"> <strong>Please enter price to proceed!</strong></span>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button class="btn btn-success" type="button" *ngIf="isInProgress; else showUpdateBtn" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Processing...
          </button>
    
          <ng-template #showUpdateBtn>
              <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Update</button>
          </ng-template>
        </div>
      </form>
    </div>
  </div>
</div>