import { Component, OnInit, ViewChild, ElementRef, AfterViewInit  } from '@angular/core';
import { GlobalService } from 'src/app/services/shared/global.service';
import { BillsOfLadingService } from 'src/app/services/bills-of-lading/bills-of-lading.service';
import { ActivatedRoute, Params,Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { DoRadiationPaymentsService } from 'src/app/services/payments/do-radiation-payments.service';
import { PendingTasksHandler } from 'src/app/shared/pending-tasks-handler';

export interface Vehicle {
  vehicle_id: string;
  make: string;
  model: string;
  chasis_no:string;
  do_amount: number;
  rad_amount: number;
  idf_amount: number;
  rad_paid: number;
  idf_paid: number;
}

@Component({
  selector: 'app-enter-radiation-payments',
  templateUrl: './enter-radiation-payments.component.html',
  styleUrls: ['./enter-radiation-payments.component.css']
})
export class EnterRadiationPaymentsComponent implements OnInit {

  @ViewChild('bl_no', { static: true })
  bl_no!: ElementRef;

  @ViewChild('no_of_units', { static: true })
  no_of_units!: ElementRef;

  @ViewChild('rad_pmt_status', { static: true })
  rad_pmt_status!: ElementRef;

  private doAmountPaid!: number;
  private noOfUnits!:number;
  public doAmountPaidPerUnit!:string
  public billOfLading: any;
  public bl_id: string | null = null;
  public errorMessage: string | null = null;
  public warningMessage: string | null = null;
  public formatter: any;
  vehicleList: Vehicle[] = [];
  paymentForm!: FormGroup;
  submitted: boolean = false;
  isProcessing: boolean = false;
  applyToAllRadControl = new FormControl(false);
  applyToAllIdfControl = new FormControl(false);

  constructor(
    private globalService: GlobalService,
    private billsOfLadingService: BillsOfLadingService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private doRadiationPaymentsService: DoRadiationPaymentsService,
    private pendingTasksHandler: PendingTasksHandler,
    private router: Router
  ){
    this.paymentForm = this.formBuilder.group({
      BlNo: [''],
      vehicles: this.formBuilder.array([])
    });

    this.formatter = new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Subscribe to changes on the controls
    this.applyToAllRadControl.valueChanges.subscribe(value => this.applyToAllRadiationAmounts(value));
    this.applyToAllIdfControl.valueChanges.subscribe(value => this.applyToAllIdfAmounts(value));
  }

  get vehiclesArray() {
    return <FormArray>this.paymentForm.get('vehicles');
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.bl_id = param['get']('bl_id')
    })

    if(this.bl_id){
      this.getBLData(this.bl_id);
    }else{
      this.errorMessage="Unable to load BL information please try again!";
    }
  }

  ngAfterViewInit() {
    //Get vehicles
    const bl_number = this.bl_no.nativeElement.value;
    const rad_pmt_status=this.rad_pmt_status.nativeElement.value;
    
    if(rad_pmt_status==0){
      this.getVehiclesByBL(bl_number);

       //Calculate D.O amount per unit
      this.noOfUnits=this.no_of_units.nativeElement.value;
      this.doAmountPaid=this.globalService.getdoAmountPaid();

      let amountPerUnit=this.doAmountPaid/this.noOfUnits;
      this.doAmountPaidPerUnit=this.formatter.format(amountPerUnit);
    }else{
      this.getUnpaidDeliveryOrders(bl_number);
    }
  }

   /**
   * Get single BL info
   * @param bl_id 
   */
  getBLData(bl_id: string): void {
    this.billsOfLadingService.viewBL(bl_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.billOfLading = response;
    
          if(this.billOfLading.status==='no_data' || this.billOfLading.status.length===0){
            this.errorMessage=this.billOfLading.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  /**
   * Get vehicles associated with the selected BL
   * @param bl_no 
   */
  getVehiclesByBL(bl_no: string):void {
    this.billsOfLadingService.getVehicles(bl_no).subscribe((response: ApiResponse) =>{

      if(response.status==='no_data'){
        this.warningMessage="No vehicles associated with this BL were found!";
      }else{
        this.vehicleList=[...response.data];
        this.displayRows();
      }
    });
  }

  /**
   * Get unpaid D.Os
   * @param bl_no 
   */
  getUnpaidDeliveryOrders(bl_no: string):void {
    this.doRadiationPaymentsService.getUnpaidRadiationAndIDF(bl_no).subscribe((response: ApiResponse) =>{
      if(response.status==='no_data'){
        this.warningMessage="No unpaid delivery orders found!";
      }else{
        this.vehicleList=[...response.data];
        this.displayRows();
      }
    });
  }

  createRow(vehicle: any) {
    return this.formBuilder.group({
      vehicle_id: [vehicle.vehicle_id],
      make: [vehicle.make],
      model: [vehicle.model],
      chassis_no: [vehicle.chassis_no],
      do_amount: [vehicle.do_amount],
      rad_amount: [vehicle.rad_amount],
      idf_amount: [vehicle.idf_amount],
      rad_paid: [vehicle.rad_paid],
      idf_paid: [vehicle.idf_paid],
    });
  }

  displayRows() {
    let transformedItems = this.vehicleList.map((vehicle: any) =>
      this.createRow(vehicle)
    );
    this.paymentForm.setControl('vehicles', this.formBuilder.array(transformedItems));
  }

  //Apply the first radiation amount to all rows
  applyToAllRadiationAmounts(isChecked: boolean|null) {
    if (isChecked) {
      const radAmount = this.vehiclesArray.at(0).get('rad_amount')?.value;
      this.vehiclesArray.controls.forEach((group, index) => {
        if (index > 0) { // Skip the first row
          group.get('rad_amount')?.setValue(radAmount);
        }
      });
    }
  }

  //Apply the first IDF amount to all rows
  applyToAllIdfAmounts(isChecked: boolean|null) {
    if (isChecked) {
      const idfAmount = this.vehiclesArray.at(0).get('idf_amount')?.value;
      this.vehiclesArray.controls.forEach((group, index) => {
        if (index > 0) { // Skip the first row
          group.get('idf_amount')?.setValue(idfAmount);
        }
      });
    }
  }

  /**
   * Post payments
   * @param formData 
   */
  handleSubmit() {
    this.submitted = true;
    this.isProcessing=true

    this.submitted = false;
    const BlNo = this.bl_no.nativeElement.value;
    const radPmtStatus=this.rad_pmt_status.nativeElement.value;
    const vehicles = this.paymentForm.value.vehicles;
  
    const payload = {
      bl_no: BlNo,
      rad_pmt_status: radPmtStatus,
      vehicles: vehicles.map((vehicle:any) => ({
        vehicle_id: vehicle.vehicle_id,
        chassis_no: vehicle.chassis_no,
        do_amount: vehicle.do_amount,
        rad_amount: vehicle.rad_amount,
        idf_amount: vehicle.idf_amount
      }))
    };

    this.doRadiationPaymentsService.postPayments(payload)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.globalService.setGlobalSuccessMessage(response.message);
          //Count BLs pending D.O and Radiation payments
          this.pendingTasksHandler.numberOfBLsPendingDOAndRadiationPmt();
          // Redirect to BL list
          this.router.navigate(['do-rad-payment/select-bl']);
        }else{
          this.errorMessage=response.message;
          this.isProcessing=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isProcessing=false;
      }
    });
  }
}
