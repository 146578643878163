import { Component, OnInit } from '@angular/core';
import { VehiclesService } from 'src/app/services/vehicles/vehicles.service';

@Component({
  selector: 'app-vehicles-pending-docs-upload',
  templateUrl: './vehicles-pending-docs-upload.component.html',
  styleUrls: ['./vehicles-pending-docs-upload.component.css']
})
export class VehiclesPendingDocsUploadComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public warningMessage: string | null = null;
  vehicles: any[] = [];
  searchText: string = '';
  public isInProgress: boolean = false;
  public vehicle_id:string='';

  constructor(
    private vehiclesService: VehiclesService,
  ){
  
  }

  ngOnInit(): void {
    this.isInProgress=true;
    this.fetchVehicles();
  }

  fetchVehicles():void{
    this.vehiclesService.getVehiclesPendingImportDocsUpload()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicles = response.data;
          this.isInProgress=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }
}
