import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params,Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { CountryService } from 'src/app/services/countries/country.service';
import { LocationService } from 'src/app/services/locations/location.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.css']
})
export class EditLocationComponent implements OnInit{
  form!: FormGroup;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  isProcessing: boolean = false;
  submitted: boolean = false;
  countries: any[] = [];
  billsOfLading: any[] = [];
  selectedBL!: string;
  public locationId: string | null = null;
  public locationData:any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private globalService: GlobalService,
    private countryService:CountryService,
    private locationService:LocationService,
    private activatedRoute: ActivatedRoute,
  ){
    this.form = this.formBuilder.group({
      country_id: ['', Validators.required],
      location_name: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.locationId = param['get']('location_id')
    })

    if(this.locationId){
      this.fetchLocationData(this.locationId);
    }else{
      //Navigate back to users list with error
      this.globalService.setGlobalErrorMessage("Unable to select make for update!");
      this.router.navigate(['settings/makes-list']);
    }

    this.fetchCountries();
    this.fetchBLs();
  }

  /**
   * Get location data
   * @param userId 
   */
  fetchLocationData(locationId: string): void {
    this.locationService.edit(locationId)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.locationData = response;
          this.selectedBL=this.locationData.data.default_bl_no;
          if(this.locationData.status==='no_data' || this.locationData.status.length===0){
            this.errorMessage=this.locationData.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  fetchCountries():void{
    this.countryService.getAllCountries().subscribe((response: ApiResponse) =>this.countries = response.data);
  }

  fetchBLs():void {
    this.locationService.getBLs().subscribe((response: ApiResponse) =>this.billsOfLading = response.data);
  }

  handleSubmit():void{
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      const formData = {
        ...this.form.value,
      };

      if(this.selectedBL==null || this.selectedBL==undefined){
        this.errorMessage="Please select BL to proceed!";
        this.isProcessing = false;
      }else{
        this.locationService.update(formData,this.selectedBL,this.locationId).subscribe(response => {
          if(response.status==='success'){

            this.submitted=false;
            this.isProcessing = false;
            this.globalService.setGlobalSuccessMessage(response.message);
          
            // Redirect back to makes list
            this.router.navigate(['settings/locations-list']);
          }else if(response.status==='error'){
            this.submitted=false;
            this.isProcessing = false;
            this.globalService.setGlobalErrorMessage(response.message);
          }else{
            this.submitted=false;
            this.isProcessing = false;
            this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
          }
        },
        error => {
          this.isProcessing = false;
          this.errorMessage=error;
        });
      }
    }else{
      this.isProcessing = false;
    }
  }
}
