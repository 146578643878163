import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchCashCategories'
})
export class SearchCashCategoriesPipe implements PipeTransform {

  transform(categories: any, searchText: string) {
    if (!searchText) {
      return categories; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return categories.filter((categories: any) => categories.name.toLowerCase().includes(searchText));
  }
}
