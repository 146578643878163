import { Component, OnInit } from '@angular/core';
import { BodyTypeService } from 'src/app/services/body-types/body-type.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-body-types-list',
  templateUrl: './body-types-list.component.html',
  styleUrls: ['./body-types-list.component.css']
})
export class BodyTypesListComponent implements OnInit {
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  body_types: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;

  constructor(
    private bodyTypeService: BodyTypeService
  ){}

  ngOnInit(): void {
    this.fetchBodyTypes();
  }

  fetchBodyTypes():void{
    this.bodyTypeService.getAllBodyTypes().subscribe((response: ApiResponse) =>this.body_types = response.data);
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fetchBodyTypes();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  deleteBodyType(event: Event,bodyTypeId:string): void{
    event.preventDefault();
  }

}
