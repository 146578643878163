import { Component, OnInit } from '@angular/core';
import { VerifySparePartsService } from 'src/app/services/vehicles/verify-spare-parts.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-verify-spare-parts',
  templateUrl: './verify-spare-parts.component.html',
  styleUrls: ['./verify-spare-parts.component.css']
})
export class VerifySparePartsComponent implements OnInit{
  searchText: string = '';
  public isLoading: boolean = false;
  public isUploading: boolean = false;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public vehicleId: string | null = null;
  public receive_log_id: string | null = null;
  vehicle: any;
  spare_parts: any;
  selectedSpareParts: any[] = []; // Array to store selected spare parts

  constructor(
    private verifySparePartsService:VerifySparePartsService,
    private globalService:GlobalService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ){}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.vehicleId = param['get']('vehicle_id');
      this.receive_log_id = param['get']('receive_log_id');
    })

    if(this.vehicleId && this.receive_log_id){
      this.getVehicleData(this.vehicleId);
    }else{
      //Navigate back to vehicles list with an error message
      this.globalService.setGlobalErrorMessage("Unable to select vehicle for spare parts verification. Please try again!");
      this.router.navigate(['vehicles/pending-parts-verification']);
    }
  }

  /**
   * Get vehicle details
   * @param vehicle_id 
   */
  getVehicleData(vehicle_id: string): void{
    this.verifySparePartsService.fetchVehicleDetails(vehicle_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.isLoading=false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.vehicle = response;
          if(this.vehicle.status==='no_data' || this.vehicle.status.length===0){
            this.isLoading=false;
            this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again.";
          }else{
            this.fetchSpareParts(); //Load spare parts
          }
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.errorMessage=error;
      }
    });
  }

  fetchSpareParts():void{
    this.verifySparePartsService.fetchAllSpareParts()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.spare_parts = response.data;
          this.isLoading=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isLoading=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

   // Function to handle checkbox change event
   checkboxChange(event: any, item: any) {
    if (event.target.checked) {
      // Add checked checkbox to the array
      this.selectedSpareParts.push(item);
    } else {
      // Remove unchecked checkbox from the array
      const index = this.selectedSpareParts.indexOf(item);
      if (index !== -1) {
        this.selectedSpareParts.splice(index, 1);
      }
    }
  }

  resetSelectedSpareParts(){
    this.selectedSpareParts=[];
    this.isLoading=true;
    this.fetchSpareParts();
  }

  updateSpareParts(){
    if(this.vehicleId=="" || this.vehicleId==undefined){

      //Redirect to vehicles list with an error message
      this.globalService.setGlobalErrorMessage("Unable to select vehicle for spare parts verification. Please try again!");
      this.router.navigate(['vehicles/pending-parts-verification']);

    }else if(this.selectedSpareParts.length===0){

      this.errorMessage="Please select spare parts to proceed!";

    }else{
      this.verifySparePartsService.updateVehicleSpareParts(this.vehicleId, this.selectedSpareParts, this.receive_log_id)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            //Clear selected spare parts array
            this.selectedSpareParts.length = 0;
            // Redirect to vehicle list with a success message
            this.globalService.setGlobalSuccessMessage(response.message);
            this.router.navigate(['vehicles/pending-parts-verification']);
          }else if(response.status==='error'){
            this.errorMessage=response.message;
          }else{
            this.errorMessage="An error occurred while updating vehicle spare parts. Please try again or consult your system administrator!";
          }
        },
        error: (error) => {
          // Handle the error
          console.error('Error verifying spare parts:', error);
          this.errorMessage="An error occurred while updating vehicle spare parts. Please try again or consult your system administrator!";
        }
      });
    }
  }

}
