 <!--Loading Spinner-->
 <div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>

 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Close Day</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/cash-mgnt/transactions"><i class="fa fa-list-ul"></i>&nbsp;Cash Transactions</a></li>
               <li class="breadcrumb-item active">Close Day</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
    </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
         <h3 class="card-title">Close Day</h3>
         <!--Error messages-->
         <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            <span [innerHTML]="errorMessage"></span>
          </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
           <form [formGroup]="form" (ngSubmit)="handleSubmit()">
               <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Date:</label>
                            <input type="text" name="txn_date" class="form-control" placeholder="Transaction Date" value="{{balanceData.data.txn_date | date:'dd-MM-yyyy'}}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Balance C/F:<span style="color:red;">*</span></label>
                            <input type="text" name="balance"  class="form-control" placeholder="Balance C/F" value="{{balanceData.data.balance | currency:' '}}" readonly>
                        </div>
                    </div>
                </div>
            
                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>
            
                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fas fa-wallet" aria-hidden="true" disabled>&nbsp;</i>Close Day</button>
                </ng-template>
           </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>