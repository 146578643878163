  <!--Loading Spinner-->
  <div *ngIf="inProgress">
    <app-spinner></app-spinner>
 </div>
 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Print BL</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/bl-list"><i class="fa fa-list-ul"></i>&nbsp;View Bills of Lading</a></li>
               <li class="breadcrumb-item active">Print BL</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
    </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid main-container">
   <div class="card">
       <div class="card-header">
            <p class="printout-title">BILL OF LADING DETAILS</p>

            <table class="table table-bordered custom-table">
                <tr>
                    <td><span class="header-labels">BL No.:</span></td>
                    <td>{{bl_data.data.bl_no}}</td>
                    <td><span class="header-labels">Consignee</span></td>
                    <td>{{bl_data.data.consignee_name}}</td>
                </tr>
                <tr>
                    <td><span class="header-labels">Agent Name</span></td>
                    <td>{{bl_data.data.agent_name}}</td>
                    <td><span class="header-labels">No. of Units</span></td>
                    <td>{{bl_data.data.no_of_units}}</td>
                </tr>
                <tr>
                    <td><span class="header-labels">CFS</span></td>
                    <td>{{bl_data.data.cfs_name}}</td>
                    <td><span class="header-labels">BL Date</span></td>
                    <td>{{bl_data.data.bl_date| date: 'dd-MM-yyyy'}}</td>
                </tr>
                <tr>
                    <td><span class="header-labels">Vessel Name</span></td>
                    <td>{{bl_data.data.vessel}}</td>
                    <td><span class="header-labels">Vessel ETA</span></td>
                    <td>{{bl_data.data.vessel_eta| date: 'dd-MM-yyyy'}}</td>
                </tr>
            </table>
          <!--Error messages-->
          <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
          </div>
          <!--Warning messages-->
         <div class="alert alert-warning" role="alert" *ngIf="warningMessage">
            {{warningMessage}}
         </div>
       </div>
       <!-- /.card-header -->
       <app-printable-vehicle-list [data]="vehicles" [columns]="columns"></app-printable-vehicle-list>
       <!-- /.card-body -->
   </div>
   </div>
   </section>