import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { PermissionGuard } from './permission.guard';
import { LoginComponent } from './components/login/login.component';
import { EnterBlComponent } from './components/customs/enter-bl/enter-bl.component';
import { BlListComponent } from './components/customs/bl-list/bl-list.component';
import { UpdateBlComponent } from './components/customs/update-bl/update-bl.component';
import { EditBlComponent } from './components/customs/edit-bl/edit-bl.component';
import { ImportedVehiclesComponent } from './components/customs/imported-vehicles/imported-vehicles.component';
import { AddEntryComponent } from './components/customs/add-entry/add-entry.component';
import { SelectBlComponent } from './components/customs/do-radiation-payments/select-bl/select-bl.component'
import { EnterDoPaymentsComponent } from './components/customs/do-radiation-payments/enter-do-payments/enter-do-payments.component'
import { EnterRadiationPaymentsComponent } from './components/customs/do-radiation-payments/enter-radiation-payments/enter-radiation-payments.component';
import { UpaidDutiesComponent } from './components/customs/duty-payments/upaid-duties/upaid-duties.component';
import { PaidDutiesPendingReceiptAttachmentComponent } from './components/customs/duty-payments/paid-duties-pending-receipt-attachment/paid-duties-pending-receipt-attachment.component';
import { AttachPaymentReceiptComponent } from './components/customs/duty-payments/attach-payment-receipt/attach-payment-receipt.component';
import { AddPortChargesComponent } from './components/customs/add-port-charges/add-port-charges.component';
import { RecentlyClearedVehiclesComponent } from './components/customs/recently-cleared-vehicles/recently-cleared-vehicles.component';
import { UpdateEntryComponent } from './components/customs/update-entry/update-entry.component';
import { EnterPortChargesComponent } from './components/customs/enter-port-charges/enter-port-charges.component';
import { VehicleListComponent } from './components/vehicles/vehicle-list/vehicle-list.component';
import { ViewVehicleComponent } from './components/vehicles/view-vehicle/view-vehicle.component';
import { VehicleTransferInTransitComponent } from './components/vehicle-transfer-in-transit/vehicle-transfer-in-transit.component';
import { VehicleTransferHistoryComponent } from './components/vehicle-transfer-history/vehicle-transfer-history.component';
import { ViewTransferredVehicleComponent } from './components/vehicles/view-transferred-vehicle/view-transferred-vehicle.component';
import { ViewReceivedVehicleComponent } from './components/vehicles/view-received-vehicle/view-received-vehicle.component';
import { PrintQrCodesComponent } from './components/customs/print-qr-codes/print-qr-codes.component';
import { UsersListComponent } from './components/settings/users/users-list/users-list.component';
import { AddUserComponent } from './components/settings/users/add-user/add-user.component';
import { EditUserComponent } from './components/settings/users/edit-user/edit-user.component';
import { RolesListComponent } from './components/settings/roles/roles-list/roles-list.component';
import { AddRoleComponent } from './components/settings/roles/add-role/add-role.component';
import { EditRoleComponent } from './components/settings/roles/edit-role/edit-role.component';
import { MakesListComponent } from './components/settings/makes/makes-list/makes-list.component';
import { AddMakeComponent } from './components/settings/makes/add-make/add-make.component';
import { ModelsListComponent } from './components/settings/models/models-list/models-list.component';
import { AddModelComponent } from './components/settings/models/add-model/add-model.component';
import { BodyTypesListComponent } from './components/settings/body-types/body-types-list/body-types-list.component';
import { AddBodyTypeComponent } from './components/settings/body-types/add-body-type/add-body-type.component';
import { ColoursListComponent } from './components/settings/colours/colours-list/colours-list.component';
import { AddColourComponent } from './components/settings/colours/add-colour/add-colour.component';
import { ConsigneesListComponent } from './components/settings/consignees/consignees-list/consignees-list.component';
import { AddConsigneeComponent } from './components/settings/consignees/add-consignee/add-consignee.component';
import { AgentsListComponent } from './components/settings/agents/agents-list/agents-list.component';
import { AddAgentComponent } from './components/settings/agents/add-agent/add-agent.component';
import { CfsListComponent } from './components/settings/cfs/cfs-list/cfs-list.component';
import { AddCfsComponent } from './components/settings/cfs/add-cfs/add-cfs.component';
import { LocationsListComponent } from './components/settings/locations/locations-list/locations-list.component';
import { AddLocationComponent } from './components/settings/locations/add-location/add-location.component';
import { BranchesListComponent } from './components/settings/branches/branches-list/branches-list.component';
import { AddBranchComponent } from './components/settings/branches/add-branch/add-branch.component';
import { ViewAttachmentsComponent } from './components/vehicles/view-attachments/view-attachments.component';
import { GradeListComponent } from './components/settings/grades/grade-list/grade-list.component';
import { AddGradeComponent } from './components/settings/grades/add-grade/add-grade.component';
import { EditGradeComponent } from './components/settings/grades/edit-grade/edit-grade.component';
import { BankAccountsListComponent } from './components/settings/bank-accounts-list/bank-accounts-list.component';
import { AddBankAccountComponent } from './components/settings/bank-accounts/add-bank-account/add-bank-account.component';
import { SparePartsListComponent } from './components/settings/spare-parts/spare-parts-list/spare-parts-list.component';
import { AddSparePartComponent } from './components/settings/spare-parts/add-spare-part/add-spare-part.component';
import { AllAvailableStockComponent } from './components/stock-management/all-available-stock/all-available-stock.component';
import { StockEnRouteComponent } from './components/stock-management/stock-en-route/stock-en-route.component';
import { StockAtCfsComponent } from './components/stock-management/stock-at-cfs/stock-at-cfs.component';
import { SoldCarsComponent } from './components/stock-management/sold-cars/sold-cars.component';
import { AddPortChargesSelectBlComponent } from './components/customs/add-port-charges-select-bl/add-port-charges-select-bl.component';
import { UploadCnfPaymentsComponent } from './components/upload-cnf-payments/upload-cnf-payments.component';
import { PayAgentsComponent } from './components/customs/agents/pay-agents/pay-agents.component';
import { ViewVehicleExpDetailsComponent } from './components/stock-management/view-vehicle-exp-details/view-vehicle-exp-details.component';
import { AllStockComponent } from './components/stock-management/all-stock/all-stock.component';
import { UpdateVesselEtaComponent } from './components/customs/update-vessel-eta/update-vessel-eta.component';
import { ImportRegNumbersComponent } from './components/vehicles/import-reg-numbers/import-reg-numbers.component';
import { EditVehicleComponent } from './components/vehicles/edit-vehicle/edit-vehicle.component';
import { AddTradeInComponent } from './components/vehicles/add-trade-in/add-trade-in.component';
import { ImportStockAtHandComponent } from './components/vehicles/import-stock-at-hand/import-stock-at-hand.component';
import { EditBankAccountComponent } from './components/settings/bank-accounts/edit-bank-account/edit-bank-account.component';
import { EditMakeComponent } from './components/settings/makes/edit-make/edit-make.component';
import { EditModelComponent } from './components/settings/models/edit-model/edit-model.component';
import { AppsListComponent } from './components/integrations/apps/apps-list/apps-list.component';
import { CreateAppComponent } from './components/integrations/apps/create-app/create-app.component';
import { AccessTokensComponent } from './components/integrations/access-tokens/access-tokens.component';
import { CreateAccessTokenComponent } from './components/integrations/create-access-token/create-access-token.component';
import { CompletedBlsComponent } from './components/customs/completed-bls/completed-bls.component';
import { PrintQrCodeComponent } from './components/vehicles/print-qr-code/print-qr-code.component';
import { ReserveVehicleComponent } from './components/sales-management/reserve-vehicle/reserve-vehicle.component';
import { ViewVehicleReservationsComponent } from './components/sales-management/view-vehicle-reservations/view-vehicle-reservations.component';
import { ViewCommitmentFeePaymentsComponent } from './components/sales-management/view-commitment-fee-payments/view-commitment-fee-payments.component';
import { AddNewCommitmentFeePaymentComponent } from './components/sales-management/add-new-commitment-fee-payment/add-new-commitment-fee-payment.component';
import { CommitmentFeeRefundsComponent } from './components/sales-management/commitment-fee-refunds/commitment-fee-refunds.component';
import { RefundCommitmentFeeComponent } from './components/sales-management/refund-commitment-fee/refund-commitment-fee.component';
import { ViewRefundedCommitmentFeesComponent } from './components/sales-management/view-refunded-commitment-fees/view-refunded-commitment-fees.component';
import { ViewCommitmentFeeRefundAttachmentsComponent } from './components/sales-management/view-commitment-fee-refund-attachments/view-commitment-fee-refund-attachments.component';
import { PreviewAttachmentComponent } from './components/preview-attachment/preview-attachment.component';
import { PreviewVehicleAttachmentComponent } from './components/preview-vehicle-attachment/preview-vehicle-attachment.component';
import { AgentInvoicesComponent } from './components/customs/agents/agent-invoices/agent-invoices.component';
import { PreviewAgencyFeeInvoiceComponent } from './components/customs/agents/preview-agency-fee-invoice/preview-agency-fee-invoice.component';
import { PortChargesInvoicesComponent } from './components/customs/port-charges/port-charges-invoices/port-charges-invoices.component';
import { PreviewPortChargesInvoiceComponent } from './components/customs/port-charges/preview-port-charges-invoice/preview-port-charges-invoice.component';
import { PayPortChargesComponent } from './components/customs/port-charges/pay-port-charges/pay-port-charges.component';
import { UpdateTaxSettingsComponent } from './components/settings/update-tax-settings/update-tax-settings.component';
import { SetAgentPasswordComponent } from './components/settings/set-agent-password/set-agent-password.component';
import { EditAgentComponent } from './components/settings/agents/edit-agent/edit-agent.component';
import { VehiclesPendingPhotoUploadComponent } from './components/vehicles/vehicles-pending-photo-upload/vehicles-pending-photo-upload.component';
import { VehiclesPendingDocsUploadComponent } from './components/vehicles/vehicles-pending-docs-upload/vehicles-pending-docs-upload.component';
import { VehiclesPendingPartsVerificationComponent } from './components/vehicles/vehicles-pending-parts-verification/vehicles-pending-parts-verification.component';
import { VerifySparePartsComponent } from './components/vehicles/verify-spare-parts/verify-spare-parts.component';
import { EditEntryComponent } from './components/customs/edit-entry/edit-entry.component';
import { EditSparePartComponent } from './components/settings/spare-parts/edit-spare-part/edit-spare-part.component';
import { UpdateEntryAttachmentsComponent } from './components/customs/update-entry-attachments/update-entry-attachments.component';
import { AddUnitComponent } from './components/supplier-operations/add-unit/add-unit.component';
import { AssignUnitsToBlComponent } from './components/supplier-operations/assign-units-to-bl/assign-units-to-bl.component';
import { StockListComponent } from './components/supplier-operations/stock-list/stock-list.component';
import { BillsOfLadingComponent } from './components/supplier-operations/bills-of-lading/bills-of-lading.component';
import { AddNewBlComponent } from './components/supplier-operations/add-new-bl/add-new-bl.component';
import { SupplierEditBlComponent } from './components/supplier-operations/supplier-edit-bl/supplier-edit-bl.component';
import { SupplierEditVehicleComponent } from './components/supplier-operations/supplier-edit-vehicle/supplier-edit-vehicle.component';
import { SupplierViewBlUnitsComponent } from './components/supplier-operations/supplier-view-bl-units/supplier-view-bl-units.component';
import { SupplierViewUploadPhotosComponent } from './components/supplier-operations/supplier-view-upload-photos/supplier-view-upload-photos.component';
import { SupplierViewVehicleAttachmentsComponent } from './components/supplier-operations/supplier-view-vehicle-attachments/supplier-view-vehicle-attachments.component';
import { SupplierUpdateVesselEtaComponent } from './components/supplier-operations/supplier-update-vessel-eta/supplier-update-vessel-eta.component';
import { ImportSoldVehiclesComponent } from './components/vehicles/import-sold-vehicles/import-sold-vehicles.component';
import { PrintStockComponent } from './components/stock-management/print/print-stock/print-stock.component';
import { EditPortChargesInvoiceComponent } from './components/customs/port-charges/edit-port-charges-invoice/edit-port-charges-invoice.component';
import { PaidPortChargesInvoicesComponent } from './components/customs/port-charges/paid-port-charges-invoices/paid-port-charges-invoices.component';
import { EditPaidPortChargesInvoiceComponent } from './components/customs/port-charges/edit-paid-port-charges-invoice/edit-paid-port-charges-invoice.component';
import { StockReconciliationComponent } from './components/vehicles/stock-reconciliation/stock-reconciliation.component';
import { ChangeUserPasswordComponent } from './components/settings/users/change-user-password/change-user-password.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ActivityLogsComponent } from './components/settings/activity-logs/activity-logs.component';
import { ReverseDutyPaymentsComponent } from './components/customs/duty-payments/reverse-duty-payments/reverse-duty-payments.component';
import { VehiclesPendingImportDocsUploadComponent } from './components/vehicles/vehicles-pending-import-docs-upload/vehicles-pending-import-docs-upload.component';
import { VehicleDocsComponent } from './components/vehicles/vehicle-docs/vehicle-docs.component';
import { UpdateVehicleInfoComponent } from './components/vehicles/update-vehicle-info/update-vehicle-info.component';
import { TempEditVehicleComponent } from './components/vehicles/temp-edit-vehicle/temp-edit-vehicle.component';
import { EditLocationComponent } from './components/settings/locations/edit-location/edit-location.component';
import { DeliveryNotesComponent } from './components/sales-management/delivery-notes/delivery-notes.component';
import { PrintDeliveryNoteComponent } from './components/sales-management/print-delivery-note/print-delivery-note.component';
import { PrintStockQrCodesComponent } from './components/settings/branches/print-stock-qr-codes/print-stock-qr-codes.component';
import { DeleteEntryComponent } from './components/customs/delete-entry/delete-entry.component';
import { DeleteAddtlDutyComponent } from './components/customs/delete-addtl-duty/delete-addtl-duty.component';
import { NewCashPaymentComponent } from './components/cash-management/new-cash-payment/new-cash-payment.component';
import { NewCashReceiptComponent } from './components/cash-management/new-cash-receipt/new-cash-receipt.component';
import { CashTransactionsComponent } from './components/cash-management/cash-transactions/cash-transactions.component';
import { EnterOpeningBalanceComponent } from './components/cash-management/enter-opening-balance/enter-opening-balance.component';
import { CloseDayComponent } from './components/cash-management/close-day/close-day.component';
import { CategoriesListComponent } from './components/cash-management/categories-list/categories-list.component';
import { AddCashCategoryComponent } from './components/cash-management/add-cash-category/add-cash-category.component';
import { EditCashCategoryComponent } from './components/cash-management/edit-cash-category/edit-cash-category.component';
import { PrintBlComponent } from './components/customs/print-bl/print/print-bl.component';
import { AddDeviceComponent } from './components/settings/devices/add-device/add-device.component';
import { ManageDevicesComponent } from './components/settings/devices/manage-devices/manage-devices.component';
import { DashboardSwitcherComponent } from './dashboard-switcher/dashboard-switcher.component';
import { ViewReturnedSalesComponent } from './components/sales-management/view-returned-sales/view-returned-sales.component';
import { OverdueUnshippedBlsComponent } from './overdue-unshipped-bls/overdue-unshipped-bls.component';

const routes: Routes = [
  {path: '',component: DashboardSwitcherComponent,canActivate: [AuthGuard]},
  {path: 'login',component: LoginComponent},
  {path: 'unauthorized',component: UnauthorizedComponent,canActivate: [AuthGuard]},
  {path: 'enter-bl',component: EnterBlComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'enter-bl'}},
  {path: 'bl-list',component: BlListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-bl-list'}},
  {path: 'update-bl/:bl_id',component: UpdateBlComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'update-bl'}},
  {path: 'edit-bl/:bl_id',component: EditBlComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'edit-bl' }},
  {path: 'print-bl/:bl_id',component: PrintBlComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'print-bl'}},
  {path: 'imported-vehicles',component: ImportedVehiclesComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-entry'}},
  {path: 'add-entry/:vehicle_id',component: AddEntryComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-entry' }},
  {path: 'recently-cleared-vehicles',component: RecentlyClearedVehiclesComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'update-entry'}},
  {path: 'update-entry/:vehicle_id',component: UpdateEntryComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'update-entry'}},
  {path: 'do-rad-payment/select-bl',component: SelectBlComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-do-rad-pmt'}},
  {path: 'enter-do-payment/:bl_id/:bl_no',component: EnterDoPaymentsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-do-rad-pmt'}},
  {path: 'enter-radiation-payment/:bl_id',component: EnterRadiationPaymentsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-do-rad-pmt'}},
  {path: 'unpaid-duties',component: UpaidDutiesComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-duty-pmt'}},
  {path: 'paid-duties-pending-attachment',component: PaidDutiesPendingReceiptAttachmentComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'attach-duty-pmt-rcpt'}},
  {path: 'attach-payment-receipt/:txn_id',component: AttachPaymentReceiptComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'attach-duty-pmt-rcpt'}},
  {path: 'port-charges-invoices',component: PortChargesInvoicesComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-port-chgs'}},
  {path: 'preview-port-charges-invoice/:invoice_id',component: PreviewPortChargesInvoiceComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-port-chgs'}},
  {path: 'pay-port-charges/:invoice_id',component: PayPortChargesComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-port-chgs'}},
  {path: 'enter-port-charges/:bl_id',component: EnterPortChargesComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-port-chgs'}},
  {path: 'add-port-charges/select-bl',component: AddPortChargesSelectBlComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-port-chgs'}},
  {path: 'add-port-charges/:bl_id',component: AddPortChargesComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-port-chgs'}},
  {path: 'vehicles',component: VehicleListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-vhcl-list'}},
  {path: 'view-vehicle/:vehicle_id',component: ViewVehicleComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-vhcl-details'}},
  {path: 'edit-vehicle/:vehicle_id',component: EditVehicleComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'edit-vhcl'}},
  {path: 'vehicles/pending-photo-upload',component: VehiclesPendingPhotoUploadComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-vhcl-photos'}},
  {path: 'vehicles/pending-docs-upload',component: VehiclesPendingDocsUploadComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-vhcl-atts'}},
  {path: 'view-vehicle-docs/:vehicle_id',component: VehicleDocsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: ['add-vhcl-atts','view-vhcl-atts','rplc-vhcl-atts','exp-view-vhcl-atts']}},
  {path: 'vehicle-transfer-in-transit',component: VehicleTransferInTransitComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-vhcls-in-transit'}},
  {path: 'vehicle-transfer-history',component: VehicleTransferHistoryComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-vhcl-transfer-hist'}},
  {path: 'view-transferred-vehicle/:transfer_log_id',component: ViewTransferredVehicleComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-vhcl-transfer-hist'}}, //TODO: Add a sepate permission for this
  {path: 'view-received-vehicle/:receive_log_id',component: ViewReceivedVehicleComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-vhcl-transfer-hist'}}, //TODO: Add a sepate permission for this
  {path: 'print-qr-codes/:bl_no',component: PrintQrCodesComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'print-qr-codes'}},
  {path: 'settings/users-list',component: UsersListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-users'}},
  {path: 'settings/add-user',component: AddUserComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-users'}},
  {path: 'settings/edit-user/:user_id',component: EditUserComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-users'}},
  {path: 'settings/change-user-password/:user_id/:name',component: ChangeUserPasswordComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-users'}},
  {path: 'settings/roles-list',component: RolesListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-roles'}},
  {path: 'settings/add-role',component: AddRoleComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-roles'}},
  {path: 'settings/edit-role/:role_id',component: EditRoleComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-roles'}},
  {path: 'settings/makes-list',component: MakesListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-vhcl-makes'}},
  {path: 'settings/add-make',component: AddMakeComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-vhcl-makes'}},
  {path: 'settings/edit-make/:id',component: EditMakeComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-vhcl-makes'}},
  {path: 'settings/models-list',component: ModelsListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-vhcl-models'}},
  {path: 'settings/add-model',component: AddModelComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-vhcl-models'}},
  {path: 'settings/edit-model/:id',component: EditModelComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-vhcl-models'}},
  {path: 'settings/body-types-list',component: BodyTypesListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-vhcl-body-types'}},
  {path: 'settings/add-body-type',component: AddBodyTypeComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-vhcl-body-types'}},
  {path: 'settings/colours-list',component: ColoursListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-vhcl-colours'}},
  {path: 'settings/add-colour',component: AddColourComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-vhcl-colours'}},
  {path: 'settings/consignees-list',component: ConsigneesListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-consignees'}},
  {path: 'settings/add-consignee',component: AddConsigneeComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-consignees'}},
  {path: 'settings/agents-list',component: AgentsListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-agents'}},
  {path: 'settings/add-agent',component: AddAgentComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-agents'}},
  {path: 'settings/set-agent-password/:agent_id/:agent_name',component: SetAgentPasswordComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-agents'}},
  {path: 'settings/edit-agent/:agent_id',component: EditAgentComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-agents'}},
  {path: 'settings/cfs-list',component: CfsListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-cfs'}},
  {path: 'settings/add-cfs',component: AddCfsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-cfs'}},
  {path: 'settings/locations-list',component: LocationsListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-locations'}},
  {path: 'settings/add-location',component: AddLocationComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-locations'}},
  {path: 'settings/edit-location/:location_id',component: EditLocationComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-locations'}},
  {path: 'settings/branches-list',component: BranchesListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-branches'}},
  {path: 'settings/add-branch',component: AddBranchComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-branches'}},
  {path: 'settings/grades-list',component: GradeListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-vhcl-grds'}},
  {path: 'settings/add-grade',component: AddGradeComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-vhcl-grds'}},
  {path: 'settings/edit-grade/:grade_id',component: EditGradeComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-vhcl-grds'}},
  {path: 'settings/bank-accounts-list',component: BankAccountsListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-bnk-accts'}},
  {path: 'settings/add-bank-account',component: AddBankAccountComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-bnk-accts'}},
  {path: 'settings/edit-bank-account/:id',component: EditBankAccountComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-bnk-accts'}},
  {path: 'settings/update-tax',component: UpdateTaxSettingsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'update-tax-settings'}},
  {path: 'view-vehicle-attachments/:vehicle_id',component: ViewAttachmentsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-vhcl-details'}},
  {path: 'settings/spare-parts-list',component: SparePartsListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-vhcl-spare-parts'}},
  {path: 'settings/add-spare-part',component: AddSparePartComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-vhcl-spare-parts'}},
  {path: 'settings/edit-spare-part/:id',component: EditSparePartComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-vhcl-spare-parts'}},
  {path: 'settings/activity-logs',component: ActivityLogsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-actvty_lgs'}},
  {path: 'settings/print-qr-codes/:branch_id',component: PrintStockQrCodesComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'print-qr-codes'}},
  {path: 'settings/add-device/:user_id/:name',component: AddDeviceComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-devices'}},
  {path: 'settings/manage-devices',component: ManageDevicesComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-devices'}},
  {path: 'stock-overview/all-stock',component: AllStockComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-vhcl-dtls-exp-sum'}},
  {path: 'stock-overview/all-available-stock',component: AllAvailableStockComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-all-avail-stk'}},
  {path: 'stock-overview/stock-en-route',component: StockEnRouteComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-stk-enr'}},
  {path: 'stock-overview/stock-at-cfs',component: StockAtCfsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-stk-at-cfs'}},
  {path: 'stock-overview/sold-cars',component: SoldCarsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-sld-cars'}},
  {path: 'import-cnf-payments',component: UploadCnfPaymentsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-cnf-pmt'}},
  {path: 'agent-invoices',component: AgentInvoicesComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'pay-agents'}},
  {path: 'preview-agency-fee-invoice/:invoice_id',component: PreviewAgencyFeeInvoiceComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'pay-agents'}},
  {path: 'pay-agents/:invoice_id',component: PayAgentsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'pay-agents'}},
  {path: 'vehicle-expe-details/:vehicle_id',component: ViewVehicleExpDetailsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-total_expe'}},
  {path: 'update-vessel-eta',component: UpdateVesselEtaComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'update-vsl-eta'}},
  {path: 'update-reg-nos',component: ImportRegNumbersComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'import-vhcl-regnos'}},
  {path: 'add-trade-in-vehicle',component: AddTradeInComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-trade-in-vhcl'}},
  {path: 'import-stock-at-hand',component: ImportStockAtHandComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'import-stk-at-hnd'}},
  {path: 'import-sold-vehicles',component: ImportSoldVehiclesComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'import-stk-at-hnd'}},
  {path: 'stock-reconciliation',component: StockReconciliationComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'import-stk-at-hnd'}},
  {path: 'integrations/apps',component: AppsListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'create-app'}},
  {path: 'integrations/create-app',component: CreateAppComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'create-app'}},
  {path: 'integrations/access-tokens/:app_id',component: AccessTokensComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'create-app'}},
  {path: 'integrations/create-access-token/:app_id',component: CreateAccessTokenComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'create-app'}},
  {path: 'completed-bls',component: CompletedBlsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-completed-bls'}},
  {path: 'print-qr-code/:chassis_no',component: PrintQrCodeComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'print-qr-codes'}},
  {path: 'reserve-vehicle/:vehicle_id',component: ReserveVehicleComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'process-sale'}},
  {path: 'view-vehicle-reservations/:vehicle_id',component: ViewVehicleReservationsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'process-sale'}},
  {path: 'view-commitment-fees/:reservation_id/:vehicle_id',component: ViewCommitmentFeePaymentsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'process-sale'}},
  {path: 'add-new-commitment-fee/:reservation_id/:vehicle_id',component: AddNewCommitmentFeePaymentComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'process-sale'}},
  {path: 'commitment-fee-refunds',component: CommitmentFeeRefundsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'process-sale'}},
  {path: 'refund-commitment-fee/:reservation_id/:customer_id',component: RefundCommitmentFeeComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'process-sale'}},
  {path: 'view-refunded-commitment-fees',component: ViewRefundedCommitmentFeesComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'process-sale'}},
  {path: 'view-commitment-fee-refund-attachments/:refund_id',component: ViewCommitmentFeeRefundAttachmentsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'process-sale'}},
  {path: 'preview-attachment/:attachment_id/:refund_id',component: PreviewAttachmentComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'process-sale'}},
  {path: 'preview-vehicle-attachment/:attachment_id/:vehicle_id',component: PreviewVehicleAttachmentComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-vhcl-atts'}},
  {path: 'vehicles/pending-parts-verification',component: VehiclesPendingPartsVerificationComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'ver-spare-parts'}},
  {path: 'verify-spare-parts/:vehicle_id/:receive_log_id',component: VerifySparePartsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'ver-spare-parts'}},
  {path: 'transactions/edit-entry',component: EditEntryComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'edit-entry'}},
  {path: 'transactions/update-entry-attachments',component: UpdateEntryAttachmentsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'edit-entry'}},
  {path: 'transactions/delete-entry',component: DeleteEntryComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'delete-entry' }},
  {path: 'transactions/delete-addtl-duty/:vehicle_id',component: DeleteAddtlDutyComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'delete-entry' }},
  {path: 'suppliers/add-unit',component: AddUnitComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'exp-add-unit'}},
  {path: 'suppliers/edit-unit/:vehicle_id',component: SupplierEditVehicleComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'exp-edit-unit'}},
  {path: 'suppliers/assign-units-to-bl',component: AssignUnitsToBlComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'assign-units-to-bl'}},
  {path: 'suppliers/stock-list',component: StockListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'exp-view-stk-list'}},
  {path: 'suppliers/bl-list',component: BillsOfLadingComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'exp-view-bl-list'}},
  {path: 'suppliers/add-new-bl',component: AddNewBlComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'exp-enter-bl'}},
  {path: 'suppliers/edit-bl/:bl_id',component: SupplierEditBlComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'exp-edit-bl'}},
  {path: 'suppliers/bls/view-units/:bl_id',component: SupplierViewBlUnitsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'exp-view-bl-units'}},
  {path: 'suppliers/view-upload-photos/:vehicle_id',component: SupplierViewUploadPhotosComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'exp-upload-vhcl-photos'}},
  {path: 'suppliers/view-vehicle-attachments/:vehicle_id',component: SupplierViewVehicleAttachmentsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'exp-view-vhcl-atts'}},
  {path: 'suppliers/update-vessel-eta',component: SupplierUpdateVesselEtaComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'exp-update-vsl-eta'}},
  {path: 'vehicles/pending-imp-docs-upload',component: VehiclesPendingImportDocsUploadComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-imp-docs'}},
  {path: 'print-stock',component: PrintStockComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'print-stk'}},
  {path: 'edit-port-charges-invoice/:invoice_id',component: EditPortChargesInvoiceComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'edit-port-chgs-inv'}},
  {path: 'transactions/paid-port-charges-invoices',component: PaidPortChargesInvoicesComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'edit-port-chgs-inv'}},
  {path: 'transactions/edit-port-charges-invoice/:invoice_id',component: EditPaidPortChargesInvoiceComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'edit-port-chgs-inv'}},
  {path: 'transactions/reverse-duty-payments',component: ReverseDutyPaymentsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'add-duty-pmt'}},
  {path: 'update-vehicle-info',component: UpdateVehicleInfoComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'edit-vhcl'}},
  {path: 'update-vehicle-info/:vehicle_id',component: TempEditVehicleComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'edit-vhcl'}},
  {path: 'sales/delivery-notes',component: DeliveryNotesComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: ['print-del-nt','attach-sgn-del-nt','edit-del-nt-cust-info']}},
  {path: 'sales/print-delivery-note/:delivery_id',component: PrintDeliveryNoteComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'print-del-nt'}},
  {path: 'sales/returned-sales',component: ViewReturnedSalesComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'process-sale'}},
  {path: 'cash-mgnt/new-cash-rcpt',component: NewCashReceiptComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'rcv-cash'}},
  {path: 'cash-mgnt/new-cash-pmt',component: NewCashPaymentComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'process-pmt'}},
  {path: 'cash-mgnt/transactions',component: CashTransactionsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-cash-trans'}},
  {path: 'cash-mgnt/enter-opening-bal',component: EnterOpeningBalanceComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: ['rcv-cash','process-pmt']}},
  {path: 'cash-mgnt/close-day',component: CloseDayComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: ['rcv-cash','process-pmt']}},
  {path: 'cash-mgnt/categories-list',component: CategoriesListComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-cash-categories'}},
  {path: 'cash-mgnt/add-category',component: AddCashCategoryComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-cash-categories'}},
  {path: 'cash-mgnt/edit-category/:id',component: EditCashCategoryComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'mng-cash-categories'}},
  {path: 'overdue-unshipped-bls',component: OverdueUnshippedBlsComponent,canActivate: [AuthGuard,PermissionGuard],data: { permission: 'view-bl-list'}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
