<!--Loading Spinner-->
<div *ngIf="isLoading">
  <app-spinner></app-spinner>
</div>

<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
           
            <div class="col-sm-6">
              <a routerLink="/enter-bl" class="btn btn-success pull-left" class="btn btn-success"><i
                class="fa fa-plus-circle" aria-hidden="true">&nbsp;</i>New BL</a>
            </div><!-- /.col -->
           
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item active">Select BL</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>

    <div class="row">
      <div class="col-md-8">
          <div class="row">
             
          </div>
      </div>
      <div class="col-md-4">
        <div class="row">
            <div class="col">
                <input type="text" class="form-control" placeholder="Type BL No. here to search" (input)="onSearchTextChange($event)">
            </div>
        </div>
      </div>
    </div>
    </section>
      <!-- /.content-header -->
    <section class="content">
      <div class="container-fluid">
      <div class="card">
          <div class="card-header">
            <h3 class="card-title">D.O, Radiation & IDF Payments (Select BL)</h3>
            <!--Error Message-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
              {{errorMessage}}
            </div>
            <!--Success Message-->
            <div class="alert alert-success" role="alert" *ngIf="successMessage">
              {{successMessage}}
            </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <table id="example1" class="table table-bordered table-hover">
              <thead>
                <tr>
                    <th>Date Created</th>
                    <th>BL No.</th>
                    <th>Consignee</th>
                    <th>No. Of Units</th>
                    <th>Units Added</th>
                    <th>Agent</th>
                    <th></th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let bl of bills_of_lading | searchBl: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                      <td>{{ bl.date_created| date: 'dd-MM-yyyy' }}</td>
                      <td>{{ bl.bl_no }}</td>
                      <td>{{ bl.consignee_name }}</td>
                      <td>{{ bl.no_of_units }}</td>
                      <td>{{ bl.units_added }}</td>
                      <td>{{ bl.agent_name }}</td>
                      <td><a *ngIf="bl.units_added>0 && bl.no_of_units>0 && bl.units_added==bl.no_of_units" routerLink="/enter-do-payment/{{bl.bl_id }}/{{ bl.bl_no }}" class="btn btn-success btn-sm active" role="button" aria-pressed="true"><i
                        class="fa fa-check" aria-hidden="true">&nbsp;</i>Select</a></td>
                  </tr>
              </tbody>
            </table>
            <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </section>