import { Component } from '@angular/core';
import { LocationService } from 'src/app/services/locations/location.service';
import { ApiResponse } from 'src/app/models/IApiResponse';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.css']
})
export class LocationsListComponent {
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  locations: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public isProcessing: boolean = false;

  constructor(
    private locationService:LocationService,
  ){}

  ngOnInit(): void {
    this.fetchLocations();
  }

  fetchLocations():void{
    this.locationService.getAllLocations().subscribe((response: ApiResponse) =>this.locations = response.data);
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fetchLocations();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  deleteLocation(event: Event,locationId:string): void{
    event.preventDefault();
    this.confirmBox(locationId);
  }

  confirmBox(userId: string){
    Swal.fire({
      title: 'Confirm Delete',
      text: 'Are you sure you want to delete this location? The action is irreversible!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.isProcessing=true;

        this.locationService.delete(userId)
        .subscribe({
          next: (response) => {
            if(response.status==='success'){
              this.isProcessing=false;
              this.successMessage=response.message;
              this.errorMessage=''; //Clear error message incase it's available
             
              //Reload users
              this.fetchLocations();
            }else if(response.status==='error'){
              this.isProcessing=false;
              this.errorMessage=response.message;
            }else{
              this.isProcessing=false;
              this.errorMessage=response.message;
            }
          },
          error: (error) => {
            this.isProcessing=false;
            this.errorMessage=error;
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      
      }
    })
  }

}
