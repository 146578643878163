import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { VehiclesService } from 'src/app/services/vehicles/vehicles.service';
import { VehicleReservationService } from 'src/app/services/sales-management/vehicle-reservation.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-reserve-vehicle',
  templateUrl: './reserve-vehicle.component.html',
  styleUrls: ['./reserve-vehicle.component.css']
})
export class ReserveVehicleComponent implements OnInit{
  form!: FormGroup;
  public vehicle_id: string | null = null;
  public errorMessage: string | null = null;
  public isProcessing: boolean = false;
  public isLoading: boolean = false;
  public isHiredPurchse: boolean = false;
  public isBankFinance: boolean = false;
  public vehicleDetails:any;
  public reservations: any[] = [];
  submitted: boolean = false;
  public formatter: any;
  public agreed_amount!:string;
  public deposit_to_be_paid!:string;
  public invoice_amount!:string;
  public cust_contrib!:string;
  public commitment_fee!:string;
  public other_charges!:string;
  public comm_agreed!:string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private vehiclesService: VehiclesService,
    private vehicleReservationService: VehicleReservationService,
    private formBuilder: FormBuilder,
  ){
    this.form = this.formBuilder.group({
      first_name: ['', Validators.required],
      middle_name: [''],
      surname: ['', Validators.required],
      phone_no: ['', Validators.required],
      deal_type: ['', Validators.required],
      agreed_amount: ['', Validators.required],
      deposit_to_be_paid:[''],
      no_of_instalments:[''],
      invoice_amount:[''],
      cust_contrib:[''],
      commitment_fee:[''],
      broker_name:[''],
      broker_phone_no:[''],
      comm_agreed:[''],
      other_charges:[''],
      date_reserved:[''],
      comment:['']
    });

    this.formatter = new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  ngOnInit(): void {
    this.isLoading=true;

    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.vehicle_id = param['get']('vehicle_id')
    })

    if(this.vehicle_id){
      this.fetchVehicleDetails(this.vehicle_id);
    }else{
      //Navigate back to vehicle list with error
      this.globalService.setGlobalErrorMessage("Unable to select Vehicle for reservation!");
      this.router.navigate(['vehicles']);
    }
  }

  fetchVehicleDetails(vehicleId:string):void {
    this.vehiclesService.getVehicle(vehicleId)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
          this.isLoading=false;
        }else{
          this.vehicleDetails = response;
          this.fetchPreviousReservations(vehicleId);
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  fetchPreviousReservations(vehicleId:string): void {
    this.vehicleReservationService.getPreviousReservations(vehicleId)
    .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.isLoading = false;
            this.reservations = response.data
          }else{
            this.isLoading = false;
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.errorMessage=error;
        }
      });
  }

  onSelectChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {
      const selectedOption = target.value;
        if(selectedOption!=='' && selectedOption!==undefined){
         if(selectedOption==='Hire Purchase'){
            this.isHiredPurchse=true;
            this.isBankFinance=false;
         }else if(selectedOption==='Bank Finance'){
            this.isBankFinance=true;
            this.isHiredPurchse=false;
         }else{
          this.isHiredPurchse=false;
          this.isBankFinance=false;
         }
        }
    }
  }

  /**
   * Format amounts to 1,000 format
   */
  numberFormat(): void {
    //Format agreed amount
    if(this.form.value.agreed_amount!=='' && this.form.value.agreed_amount!==undefined){
      this.agreed_amount=this.formatter.format(this.form.value.agreed_amount.replaceAll(",",""));
    }

    //Format deposit
    if(this.form.value.deposit_to_be_paid!=='' && this.form.value.deposit_to_be_paid!==undefined){
      this.deposit_to_be_paid=this.formatter.format(this.form.value.deposit_to_be_paid.replaceAll(",",""));
    }

    //Format Invoice amount
    if(this.form.value.invoice_amount!=='' && this.form.value.invoice_amount!==undefined){
      this.invoice_amount=this.formatter.format(this.form.value.invoice_amount.replaceAll(",",""));
    }

    //Format customer's contribution amount
    if(this.form.value.cust_contrib!=='' && this.form.value.cust_contrib!==undefined){
      this.cust_contrib=this.formatter.format(this.form.value.cust_contrib.replaceAll(",",""));
    }

    //Format commitment fee
    if(this.form.value.commitment_fee!=='' && this.form.value.commitment_fee!==undefined){
      this.commitment_fee=this.formatter.format(this.form.value.commitment_fee.replaceAll(",",""));
    }

    //Format other charges amount
    if(this.form.value.other_charges!=='' && this.form.value.other_charges!==undefined){
      this.other_charges=this.formatter.format(this.form.value.other_charges.replaceAll(",",""));
    }

    //Format commission amount
    if(this.form.value.comm_agreed!=='' && this.form.value.comm_agreed!==undefined){
      this.comm_agreed=this.formatter.format(this.form.value.comm_agreed.replaceAll(",",""));
    }
  }

  handleSubmit():void{
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {

      const formData = {
        vehicle_id: this.vehicle_id,
        ...this.form.value,
      };

      if(Number.isNaN(Number(this.form.value.agreed_amount.replaceAll(",","")))) {

        this.submitted = false;
        this.isProcessing = false;
        this.errorMessage="Invalid agreed amount. Only numbers are allowed!";

      }else if(this.form.value.deposit_to_be_paid!=='' && this.form.value.deposit_to_be_paid!==undefined && Number.isNaN(Number(this.form.value.deposit_to_be_paid.replaceAll(",","")))){

        this.submitted = false;
        this.isProcessing = false;
        this.errorMessage="Invalid deposit amount. Only numbers are allowed!";

      }else if(this.form.value.no_of_instalments!=='' && Number.isNaN(Number(this.form.value.no_of_instalments.replaceAll(",","")))){

        this.submitted = false;
        this.isProcessing = false;
        this.errorMessage="Invalid number of instalments. Only numbers are allowed!";

      }else if(this.form.value.invoice_amount!=='' && this.form.value.invoice_amount!==undefined && Number.isNaN(Number(this.form.value.invoice_amount.replaceAll(",","")))){

        this.submitted = false;
        this.isProcessing = false;
        this.errorMessage="Invalid invoice amount. Only numbers are allowed!";

      }else if(this.form.value.commitment_fee!=='' && this.form.value.commitment_fee!==undefined && Number.isNaN(Number(this.form.value.commitment_fee.replaceAll(",","")))){

        this.submitted = false;
        this.isProcessing = false;
        this.errorMessage="Invalid commitment fee. Only numbers are allowed!";

      }else if(this.form.value.comm_agreed!=='' && this.form.value.comm_agreed!==undefined && Number.isNaN(Number(this.form.value.comm_agreed.replaceAll(",","")))){

        this.submitted = false;
        this.isProcessing = false;
        this.errorMessage="Invalid commission amount. Only numbers are allowed!";

      }else if(this.form.value.deal_type==='Bank Finance' && this.form.value.cust_contrib===''){

        this.submitted = false;
        this.isProcessing = false;
        this.errorMessage="Please enter customer's contribution!";

      }else if(this.form.value.cust_contrib!=='' && this.form.value.cust_contrib!==undefined && Number.isNaN(Number(this.form.value.cust_contrib.replaceAll(",","")))){

        this.submitted = false;
        this.isProcessing = false;
        this.errorMessage="Invalid customer's contribution. Only numbers are allowed!";

      }else if(this.form.value.other_charges!=='' && this.form.value.other_charges!==undefined && Number.isNaN(Number(this.form.value.other_charges.replaceAll(",","")))){

        this.submitted = false;
        this.isProcessing = false;
        this.errorMessage="Invalid data has been entered in other charges field. Only numbers are allowed!";

      }else{
        this.vehicleReservationService.create(formData).subscribe(response => {
          if(response.status==='success'){

            this.submitted=false;
            this.isProcessing = false;
            this.globalService.setGlobalSuccessMessage(response.message);
          
            // Redirect back to makes list
            this.router.navigate(['vehicles']);
          }else if(response.status==='error'){
            this.submitted=false;
            this.isProcessing = false;
            this.globalService.setGlobalErrorMessage(response.message);
          }else{
            this.submitted=false;
            this.isProcessing = false;
            this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
          }
        },
        error => {
          this.errorMessage=error;
          this.isProcessing = false;
        });
      }
    }else{
      this.isProcessing = false;
    }
  }

}
