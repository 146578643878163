import { Component, OnInit} from '@angular/core';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { BillsOfLadingService } from 'src/app/services/bills-of-lading/bills-of-lading.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-print-bl',
  templateUrl: './print-bl.component.html',
  styleUrls: ['./print-bl.component.css']
})
export class PrintBlComponent implements OnInit{
  isProcessing: boolean = false;
  inProgress: boolean = false;
  public bl_id: string | null = null;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public warningMessage: string | null = null;
  public bl_data:any;
  vehicles: any[] = [];
  columns: string[] = ['Make', 'Model', 'ChassisNo','EngineNo','EngineCC','Transmission',
    'BodyType','Drive','Fuel','Mileage','Colour','Year','Weight','CBM'
  ];
  public userPermissions: string[] = [];

  constructor(
    private activatedRoute: ActivatedRoute, 
    private billsOfLadingService: BillsOfLadingService, 
    private router: Router,
    private authService: AuthService,
    ){}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.bl_id = param['get']('bl_id')
    })

    if(this.bl_id){
      this.inProgress=true;
      this.getBLData(this.bl_id);
    
      //Get user permissions
      this.userPermissions=this.authService.getPermissions();
    }else{
      //Navigate back to bl list with error
      this.errorMessage="Unable to select BL for printing!";
      this.router.navigate(['bl-list']);
    }
  }

  /**
   * Get single BL info
   * @param bl_id 
   */
  getBLData(bl_id: string): void {
    this.billsOfLadingService.viewBL(bl_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.bl_data = response;
          
          if(this.bl_data.status==='no_data' || this.bl_data.status.length===0){
            this.errorMessage=this.bl_data.message;
            this.inProgress=false;
          }else{
            //Load vehicles
            this.getVehiclesByBL(this.bl_data.data.bl_no);
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  /**
   * Get vehicles associated with the selected BL
   * @param bl_no 
   */
  getVehiclesByBL(bl_no: string): void {
    this.billsOfLadingService.getVehiclesForPrinting(bl_no).subscribe((response: ApiResponse) =>{
      this.vehicles = response.data
      this.inProgress=false;
      if(response.status==='no_data'){
        this.inProgress=false;
        this.warningMessage="No vehicles associated with this BL were found!";
      }
    });
  }
}
