import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class VerifySparePartsService {
  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }

   /**
   * Get vehicles pending spare parts verification
   * @returns Array
   */
   getVehiclesPendingPartsVerification(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/verify-spare-parts/fetch-vehicles`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get vehicle details
   * @param vehicle_id 
   */
  fetchVehicleDetails(vehicle_id: string){

    let apiURL:string=`${this.baseURL}/v1/verify-spare-parts/get-vehicle-details/${vehicle_id}`;

    return this.http.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  /**
  * Get all spare parts 
  * @returns Observable<ApiResponse>
  */
  fetchAllSpareParts(): Observable<ApiResponse>
  {
    let apiURL:string=`${this.baseURL}/v1/verify-spare-parts/fetch-all-spare-parts`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Update vehicle spare parts
   * @param vehicleId 
   * @param selectedSpareParts 
   * @returns 
   */
  updateVehicleSpareParts(vehicleId: string, selectedSpareParts: any[],receive_log_id: string|null): Observable<any> {

    let apiURL:string=`${this.baseURL}/v1/verify-spare-parts/update`;

    const data = {
      vehicle_id: vehicleId,
      receive_log_id:receive_log_id,
      selected_spare_parts: selectedSpareParts
    };

    return this.http.post<any>(apiURL, data);
  }
}
