<!--Loading Spinner-->
<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
           
            <div class="col-sm-6">
                <a routerLink="/integrations/create-access-token/{{appId}}" class="btn btn-success pull-left" class="btn btn-success"><i
                    class="fa fa-plus-circle" aria-hidden="true">&nbsp;</i>Create Access Token</a>
            </div><!-- /.col -->
           
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/integrations/apps"><i class="fa fa-list-ul"></i>&nbsp;View Apps</a></li>
                <li class="breadcrumb-item active">Access Tokens</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
</section>
<!-- /.content-header -->
<section class="content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Access Tokens</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <!--Error Message-->
                <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
                </div>
                <!--Success Message-->
                <div class="alert alert-success" role="alert" *ngIf="successMessage">
                {{successMessage}}
                </div>
                <table id="example1" class="table table-bordered table-hover">
                    <thead>
                    <tr>
                        <th>Token ID</th>
                        <th>Created On</th>
                        <th>Updated On</th>
                        <th>Expires On</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let token of tokens | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                            <td>{{token.id}}</td>
                            <td>{{token.created_at | date: 'dd-MM-yyyy'}}</td>
                            <td>{{token.updated_at | date: 'dd-MM-yyyy'}}</td>
                            <td>{{token.expires_at | date: 'dd-MM-yyyy'}}</td>
                            <td>
                                <a href="#" class="btn btn-danger" (click)="revokeToken($event,token.id)" role="button">Revoke</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
            </div>
            <!-- /.card-body -->
        </div>
    </div>
</section>