import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchVehicle'
})
export class SearchVehiclePipe implements PipeTransform {

  transform(vehicles: any, searchText: string) {
    
    if (!searchText) {
      return vehicles; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return vehicles.filter((vehicles: any) => 
      (vehicles.make && vehicles.make.toLowerCase().includes(searchText))||
      (vehicles.model && vehicles.model.toLowerCase().includes(searchText))||
      (vehicles.chassis_no && vehicles.chassis_no.toLowerCase().includes(searchText))||
      (vehicles.engine_no && vehicles.engine_no.toLowerCase().includes(searchText))||
      (vehicles.vessel && vehicles.vessel.toLowerCase().includes(searchText))||
      (vehicles.bl_no && vehicles.bl_no.toLowerCase().includes(searchText))
    );
  }

}
