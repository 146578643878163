import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/shared/global.service';
import { TaxSettingsService } from 'src/app/services/tax-settings/tax-settings.service';

@Component({
  selector: 'app-update-tax-settings',
  templateUrl: './update-tax-settings.component.html',
  styleUrls: ['./update-tax-settings.component.css']
})
export class UpdateTaxSettingsComponent implements OnInit{
  form!: FormGroup;
  public errorMessage: string | null = null;
  isProcessing: boolean = false;
  submitted: boolean = false;
  public Id: string | null = null;
  public taxData:any;
  
  constructor(
    private globalService: GlobalService,
    private taxSettingsService:TaxSettingsService,
    private formBuilder: FormBuilder,
  ){
    this.form = this.formBuilder.group({
      wh_tax_pct: ['', Validators.required],
      wh_vat_pct: ['', Validators.required],
    });
  }
  
  ngOnInit(): void {
    this.fetchTaxSettings();
  }

  /**
   * Get tax settings
   */
  fetchTaxSettings(): void {
    this.taxSettingsService.getTaxSettings()
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.taxData = response;
          if(this.taxData.status==='no_data' || this.taxData.status.length===0){
            this.errorMessage=this.taxData.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }
  
  handleSubmit():void{
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      const formData = {
        ...this.form.value,
      };
      
      this.taxSettingsService.updateTaxSettings(formData).subscribe(response => {
        if(response.status==='success'){
          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalSuccessMessage(response.message);
        }else if(response.status==='warning'){
          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalWarningMessage(response.message);
        }else if(response.status==='error'){
          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalErrorMessage(response.message);
        }else{
          this.submitted=false;
          this.isProcessing = false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
        }
      },
      error => {
        this.isProcessing = false;
        this.errorMessage=error;
      });
    }else{
      this.isProcessing = false;
    }
  }
}
