import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/shared/global.service';
import { PortChargesService } from 'src/app/services/payments/port-charges.service';

@Component({
  selector: 'app-edit-port-charges-invoice',
  templateUrl: './edit-port-charges-invoice.component.html',
  styleUrls: ['./edit-port-charges-invoice.component.css']
})
export class EditPortChargesInvoiceComponent implements OnInit{
  form!: FormGroup;
  public invoiceId: string | null = null;
  public invoiceData:any;
  errorMessage: string | null = null;
  submitted: boolean = false;
  isProcessing: boolean = false;
  public isLoading: boolean = false;
  fileName: string="Choose an image or a PDF file";
  attachment: any;

  constructor(
    private formBuilder: FormBuilder,
    private portChargesService:PortChargesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService
  ){
    this.form = this.formBuilder.group({
      invoice_no:['', Validators.required],
      amount_kes: ['', Validators.required],
      amount_usd: ['', Validators.required],
      attachment: [null],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.invoiceId = param['get']('invoice_id')
    })

    if(this.invoiceId){
      this.fetchInvoiceData(this.invoiceId);
    }else{
      //Navigate back to invoices list with error
      this.globalService.setGlobalErrorMessage("Unable to select port charges invoice for payment. Please try again!");
      this.router.navigate(['port-charges-invoices']);
    }
  }

  /**
   * Get invoice data
   * @param invoiceId 
   */
  fetchInvoiceData(invoiceId: string): void {
    this.portChargesService.getInvoiceDetails(invoiceId)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.invoiceData = response;
          if(this.invoiceData.status==='no_data' || this.invoiceData.status.length===0){
            this.errorMessage=this.invoiceData.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  /**
   * Handle selectd file
   * @param event 
   */
  onFileSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.fileName = fileInput.files?.[0]?.name ?? 'Choose an image or a PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.attachment = file
    }
  }

  handleSubmit() {
    this.submitted = true;
    this.isProcessing=true;
   
    if (this.form.valid) {
      this.portChargesService.updateInvoice(this.form.value,this.attachment,this.invoiceId)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.globalService.setGlobalSuccessMessage(response.message);
            this.isProcessing=false;
            this.errorMessage=null;
            this.router.navigate(['port-charges-invoices']);
          }else{
            this.submitted = false;
            this.isProcessing=false;
            this.errorMessage=response.message;
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isProcessing=false;
          this.errorMessage=error;
        }
      });
    }else{
      this.isProcessing=false;
    }
  }
}
