 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Vehicle Details</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/vehicle-transfer-in-transit"><i class="fas fa-angle-double-right"></i>&nbsp;Transferred Vehicles In Transit</a></li>
               <li class="breadcrumb-item active">Vehicle Details</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->

    <!--Loading Spinner-->
    <div *ngIf="isLoading">
      <app-spinner></app-spinner>
    </div>

   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
         <h3 class="card-title">Vehicle Details</h3>
         <!--Error Message-->
         <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
          </div>
          <!--Success Message-->
          <div class="alert alert-success" role="alert" *ngIf="successMessage">
            {{successMessage}}
          </div>
       </div>
       <!-- /.card-header -->
       <div class="card card-solid">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-sm-6">
              <h3 class="d-inline-block d-sm-none">Vehicle Details</h3>
              <div class="col-12">
                <img [src]="activeImage" class="product-image">
              </div>
              <div class="col-12 product-image-thumbs">
                <div class="product-image-thumb" *ngFor="let photo of photos" (click)="setActiveImage(photo.image_url)"  [ngClass]="{ 'active': photo.image_url === activeImage }"><img [src]="photo.image_url" width="640" height="480"></div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
                <h3 class="my-3">{{vehicle?.data?.make}} {{vehicle?.data?.model}}</h3>
             
                <form method="post" action="#">
                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Chassis No.:</label>
                                <input type="text" class="form-control" value="{{vehicle.data.chassis_no}}" readonly>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Engine CC:</label>
                                <input type="text" class="form-control" value="{{vehicle.data.engine_cc}}" readonly>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Engine No.:</label>
                                <input type="text" class="form-control" value="{{vehicle.data.engine_no}}" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-4">
                          <div class="form-group">
                              <label>Transmission:</label>
                              <input type="text" class="form-control" value="{{vehicle.data.transmission}}" readonly>
                          </div>
                      </div>
                      <div class="col-md-4">
                          <div class="form-group">
                              <label>Body Type:</label>
                              <input type="text" class="form-control" value="{{vehicle.data.body_type}}" readonly>
                          </div>
                      </div>
                      <div class="col-md-4">
                          <div class="form-group">
                              <label>Drive:</label>
                              <input type="text" class="form-control" value="{{vehicle.data.drive}}" readonly>
                          </div>
                      </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Fuel:</label>
                            <input type="text" class="form-control" value="{{vehicle.data.fuel}}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Mileage:</label>
                            <input type="text" class="form-control" value="{{vehicle.data.mileage}}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Colour:</label>
                            <input type="text" class="form-control" value="{{vehicle.data.colour}}" readonly>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-4">
                      <div class="form-group">
                          <label>Year:</label>
                          <input type="text" class="form-control" value="{{vehicle.data.year}}" readonly>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="form-group">
                          <label>Weight:</label>
                          <input type="text" class="form-control" value="{{vehicle.data.weight}}" readonly>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="form-group">
                          <label>CBM:</label>
                          <input type="text" class="form-control" value="{{vehicle.data.cbm}}" readonly>
                      </div>
                  </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>From:</label>
                            <input type="text" class="form-control" value="{{vehicle.data.from_location}}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>To:</label>
                            <input type="text" class="form-control" value="{{vehicle.data.to_location}}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Driver:</label>
                            <input type="text" class="form-control" value="{{vehicle.data.driver_name}}" readonly>
                        </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Transferred On:</label>
                            <input type="text" class="form-control" value="{{vehicle.data.transferred_on | date: 'dd-MM-yyyy h:mm a'}}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Transfer By:</label>
                            <input type="text" class="form-control" value="{{vehicle.data.transferred_by}}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Transfer Reason:</label>
                            <input type="text" class="form-control" value="{{vehicle.data.transfer_reason}}" readonly>
                        </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                        <h6><b>Available Spare Parts</b></h6>
                        <ul *ngFor="let spare_part of spare_parts">
                            <li>{{spare_part.name}}</li>
                        </ul>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Comment:</label>
                            <input type="text" class="form-control" value="{{vehicle.data.comment}}" readonly>
                        </div>
                    </div>
                  </div>
              </form>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
      </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>