import { Component } from '@angular/core';
import { CfsService } from 'src/app/services/cfs/cfs.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-cfs-list',
  templateUrl: './cfs-list.component.html',
  styleUrls: ['./cfs-list.component.css']
})
export class CfsListComponent {
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  cfses: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;

  constructor(
    private cfsService:CfsService,
  ){}

  ngOnInit(): void {
    this.fetchCFSes();
  }

  fetchCFSes():void{
    this.cfsService.getAllCFSes().subscribe((response: ApiResponse) =>this.cfses = response.data);
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fetchCFSes();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  deleteCFS(event: Event,agentId:string): void{
    event.preventDefault();
  }
}
