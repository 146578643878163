import { Component, OnInit } from '@angular/core';
import { AgentService } from 'src/app/services/agents/agent.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';

@Component({
  selector: 'app-preview-agency-fee-invoice',
  templateUrl: './preview-agency-fee-invoice.component.html',
  styleUrls: ['./preview-agency-fee-invoice.component.css']
})
export class PreviewAgencyFeeInvoiceComponent implements OnInit{
  public invoice_id: string | null = null;
  public isLoading: boolean = false;
  attachment: any;
  fileURL: string = '';

  constructor(
    private agentService:AgentService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService:GlobalService
  ){}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.invoice_id = param['get']('invoice_id')
    })

    if(this.invoice_id){
      this.isLoading = true;
      this.fetchAttachmentUrl(this.invoice_id);
    }else{
      //Navigate back to agent invoices list with error
      this.globalService.setGlobalErrorMessage("Unable to preview attachment. Please try again!");
      this.router.navigate(['agent-invoices']);
    }
  }

  fetchAttachmentUrl(invoice_id:string){
    this.agentService.getAgencyFeeInvoiceAttachmentUrl(invoice_id)
    .subscribe({ 
        next: (response) => {
          if (response && Object.keys(response).length === 0) {
            this.isLoading = false;
            //Navigate back to  agent invoices list with error
            this.globalService.setGlobalErrorMessage("Unable to preview attachment. Please try again!");
            this.router.navigate(['agent-invoices']);
          }else{
            this.isLoading = false;
            this.attachment = response;
            
            if(this.attachment.status==='no_data' || this.attachment.status.length===0){
              this.isLoading = false;
              //Navigate back to  agent invoices list with error
              this.globalService.setGlobalErrorMessage("Unable to preview attachment. Please try again!");
              this.router.navigate(['agent-invoices']);
            }else{
              this.isLoading = false;
              this.fileURL=this.attachment.data.file_path;
            }
          }
        },
        error: (error) => {
          this.isLoading = false;
          //Navigate back to agent invoices list with error
          this.globalService.setGlobalErrorMessage("Unable to preview attachment. Please try again!");
          this.router.navigate(['agent-invoices']);
        }
      });
  }

}
