import { Component, OnInit } from '@angular/core';
import { EntryService } from 'src/app/services/entries/entry.service';
import { ConsigneeService } from 'src/app/services/consignees/consignee.service';
import { AgentService } from 'src/app/services/agents/agent.service';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-imported-vehicles',
  templateUrl: './imported-vehicles.component.html',
  styleUrls: ['./imported-vehicles.component.css']
})
export class ImportedVehiclesComponent implements OnInit{

  page: number = 1;
  count: number = 0;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  warningMessage: string | null = null;
  importedVehicles: any[] = [];
  searchText: string = '';
  agents: any[] = [];
  isProcessing: boolean = false;
  consignees: any[] = [];
  public consigneeId:string='';
  public agentId:string='';
  rowCount: number=0;
  etaFilter!: FormGroup;
  startDate:any='';
  endDate:any='';
  submitted: boolean = false;

  constructor(
    private entryService: EntryService,
    private consigneeService: ConsigneeService,
    private agentService: AgentService,
    private formBuilder: FormBuilder,
    ){
      this.etaFilter = this.formBuilder.group({
        eta_from: ['', Validators.required],
        eta_to: ['', Validators.required],
      });
    }

  ngOnInit(): void {
    this.getImportedVehicles();
    this.getConsignees();
    this.getAgents();
  }

  getImportedVehicles(): void {
    this.entryService.getImportedVehicles()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.importedVehicles = response.data;
          this.rowCount = this.importedVehicles.length;
        }else if(response.status==='no_data'){
          this.rowCount =0;
          this.errorMessage=response.message;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.getImportedVehicles();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  getConsignees(): void {
    this.consigneeService.getAllConsignees().subscribe((response: ApiResponse)=>this.consignees=response.data);
  }

  getAgents(): void{
    this.agentService.getAllAgents().subscribe((response: ApiResponse) =>this.agents = response.data);
  }

  /**
   * Filter by consignee
   * @param consigneeId 
   */
  onSelectChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {
      this.consigneeId = target.value;

      if(this.consigneeId!==undefined){
        this.isProcessing=true
        this.filter(this.consigneeId,this.agentId,this.startDate,this.endDate);
      }else{
        this.warningMessage="Invalid consignee selected. Please try again!";
      }
    }
  }

  /**
   * Filter by agent
   * @param agentId 
   */
  onAgentSelectChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {
      this.agentId = target.value;

      if(this.agentId!==undefined){
        this.isProcessing=true
        this.filter(this.consigneeId,this.agentId,this.startDate,this.endDate);
      }else{
        this.warningMessage="Invalid agent selected. Please try again!";
      }
    }
  }

  filter(consigneeId: string,agentId:string,startDate:any,endDate:any):void {
    this.entryService.filterImportedVehicles(consigneeId,agentId,startDate,endDate)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.importedVehicles = response.data;
          this.rowCount = this.importedVehicles.length;
          this.isProcessing=false;
          this.warningMessage=null;
          this.errorMessage=null;
        }else if(response.status==='no_data'){
          this.rowCount =0;
          this.importedVehicles=[];
          this.warningMessage=response.message;
          this.isProcessing=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isProcessing=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isProcessing=false;
      }
    });
  }

  handleSubmit(){
    this.submitted=true;

    if(this.etaFilter.valid) {
      this.isProcessing=true
      this.startDate=this.etaFilter.value.eta_from;
      this.endDate=this.etaFilter.value.eta_to;
     
      this.filter(this.consigneeId,this.agentId,this.startDate,this.endDate);
    }
  }
}
