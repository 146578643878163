import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class PrintStockService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Accept': 'application/json' });

  constructor(
    private http: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }

  /**
   * Get available stock for printing
   * @returns Array
   */
  getAvailableStockForPrinting(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/print/fetch-available-stock`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Filter available stock by location
   * @returns Array
   */
  filterAvailableStockByLocationForPrinting(locationId: string): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/print/filter-stock-by-location/${locationId}`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Filter available stock by branch
   * @returns Array
   */
   filterAvailableStockByBranchForPrinting(branchId: string): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/print/filter-stock-by-branch/${branchId}`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
