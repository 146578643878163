import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { AuthService } from "./services/auth/auth.service";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Check if the request URL contains Passport's oauth token route
        if(req.url.includes("oauth/token")){
            // Skip authentication for this request
            return next.handle(req);
        }

        // Continue with the authenticated request
        const authToken = this.authService.getToken();
        
        req = req.clone({
            setHeaders: {
                Authorization: "Bearer " + authToken
            }
        });
        return next.handle(req);
    }
}