import { Component, OnInit } from '@angular/core';
import { StockManagementService } from 'src/app/services/suppliers/stock-management.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GlobalService } from 'src/app/services/shared/global.service';

@Component({
  selector: 'app-stock-list',
  templateUrl: './stock-list.component.html',
  styleUrls: ['./stock-list.component.css']
})
export class StockListComponent implements OnInit{
  public userPermissions: string[] = [];
  page: number = 1;
  count: number = 0;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public warningMessage: string | null = null;
  vehicles: any[] = [];
  searchText: string = '';
  public isInProgress: boolean = false;
  locations: any[] = [];
  bills_of_lading: any[] = [];
  rowCount: number=0;

  constructor(
    private authService: AuthService,
    private stockManagementService:StockManagementService,
    private globalService: GlobalService,
  ){}

  ngOnInit(): void {
    this.isInProgress=true;
    this.fetchStockList();
    this.userPermissions=this.authService.getPermissions();
  }

  fetchStockList():void{
    this.stockManagementService.getSupplierStock()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicles = response.data;
          this.rowCount = this.vehicles.length;
          this.isInProgress=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
    this.page = 1; // Reset to page 1 after applying filter
  }

  /**
   * Filter stock list
   */
  onSelectChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {

      const filter = target.value;
      this.isInProgress=true

      if(filter==='all'){
        this.fetchStockList();
      }else{
        this.filterStockList(filter);
      }
    }
  }

  filterStockList(filter:string):void{
    this.stockManagementService.filterSupplierStock(filter)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicles = response.data;
          this.rowCount = this.vehicles.length;
          this.isInProgress=false;
          this.errorMessage=null;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }

  delete(vehicle_id: string, make: string, model:string,chassis_no:string){
    let prompt = "Are you sure you want to delete "+make+" "+model+" with chassis number "+chassis_no+"?";

    if (confirm(prompt) == true) {
      this.isInProgress=true;
      this.stockManagementService.deleteUnit(vehicle_id)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.globalService.setGlobalSuccessMessage(response.message);
            this.isInProgress=true;
            // Refresh list
            this.fetchStockList();
          }else if(response.status==='warning'){
            this.warningMessage=response.message;
            this.isInProgress=false;
          }else{
            this.errorMessage=response.message;
            this.isInProgress=false;
          }
        },
        error: (error) => {
          // Handle the error
          console.log(error);
          this.isInProgress=false;
          this.errorMessage="An Error occurred while assigning unit(s) to BL. Please try again!";
        }
      });
    }
  }
}
