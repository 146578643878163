import { Injectable } from "@angular/core";
import { PendingTasksService } from 'src/app/services/pending-tasks/pending-tasks.service';
import { GlobalService } from 'src/app/services/shared/global.service';

@Injectable({
    providedIn: 'root'
})
export class PendingTasksHandler{
    pendingEntries: any;
    noOfpendingEntries:number=0;
    unpaidPaidDuties: any;
    noOfUnpaidDuties:number=0;
    paymentsPendingReceiptsAttachment: any;
    noOfPaymentsPendingReceiptsAttachment:number =0;
    blsPendingDOAndRadiationPmt:any;
    noOfBlsPendingDOAndRadiationPmt:number=0;
    blsPendingPortChargesPmt:any;
    noOfBlsPendingPortChargesPmt:number=0;
    blsPendingETAUpdatePmt:any;
    noOfBlsPendingETAUpdatePmt:number=0;
    noOfVehiclesPendingPhotoUpload:number=0;
    noOfVehiclesPendingDocsUpload:number=0;
    noOfVehiclesPendingPartsVerification:number=0;
    
    constructor(
        private pendingTasksService: PendingTasksService,
        private globalService: GlobalService,
    ){}
    
    numberOfVehiclesPendingEntryInput(): void {
      this.pendingTasksService.countVehiclesPendingEntryInput()
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.pendingEntries=response.data;
            this.noOfpendingEntries=Number(this.pendingEntries.no_of_vehicles_pending_entries);
            this.pendingTasksService.updateNoOfPendingEntries(this.noOfpendingEntries);
          }else{
            //this.globalService.setGlobalWarningMessage("Unable to determine number of vehicles pending Entry input. Navigate to the dashboard and refresh the page.");
          }
        },
        error: (error) => {
          console.log("An error occurred while counting number of vehicles pending Entry input. "+error);
          //this.globalService.setGlobalErrorMessage("An error occurred while counting number of vehicles pending Entry input. "+error);
        }
      });
    }

    numberOfPendingDutyPayments(): void {
      this.pendingTasksService.countUnpaidDuties()
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.unpaidPaidDuties=response.data;
            this.noOfUnpaidDuties=Number(this.unpaidPaidDuties.no_of_unpaid_duties);
            this.pendingTasksService.updateNoOfUnpaidDuties(this.noOfUnpaidDuties);
          }else{
            //this.globalService.setGlobalWarningMessage("Unable to determine number of unpaid duties. Navigate to the dashboard and refresh the page.");
          }
        },
        error: (error) => {
          console.log("An error occurred while counting number of unpaid duties. "+error);
          //this.globalService.setGlobalErrorMessage("An error occurred while counting number of unpaid duties. "+error);
        }
    });
  }

  numberOfPaymentsPendingReceiptsAttachment(): void {
    this.pendingTasksService.countPaymentsPendingReceiptsAttachment()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.paymentsPendingReceiptsAttachment=response.data;
          this.noOfPaymentsPendingReceiptsAttachment=Number(this.paymentsPendingReceiptsAttachment.pending_receipt_attch_count);
          this.pendingTasksService.updateNoOfPaymentsPendingReceiptAttachment(this.noOfPaymentsPendingReceiptsAttachment);
        }else{
          //this.globalService.setGlobalWarningMessage("Unable to determine number of payments pending receipts attachment. Navigate to the dashboard and refresh the page.");
        }
      },
      error: (error) => {
        console.log("An error occurred while counting number of payments pending receipts attachment. "+error);
        //this.globalService.setGlobalErrorMessage("An error occurred while counting number of payments pending receipts attachment. "+error);
      }
    });
  }

  numberOfBLsPendingDOAndRadiationPmt(): void {
    this.pendingTasksService.countNoOfBLsPendingDOAndRadiationPmt()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.blsPendingDOAndRadiationPmt=response.data;
          this.noOfBlsPendingDOAndRadiationPmt=Number(this.blsPendingDOAndRadiationPmt.pending_do_rad_count);
          this.pendingTasksService.updateNoOfBLsPendingDOAndRadiationPmt(this.noOfBlsPendingDOAndRadiationPmt);
        }else{
          //this.globalService.setGlobalWarningMessage("Unable to determine number of BLs pending D.O and Radiation payments. Navigate to the dashboard and refresh the page.");
        }
      },
      error: (error) => {
        console.log("An error occurred while counting number of BLs pending D.O and Radiation payments. "+error);
        //this.globalService.setGlobalErrorMessage("An error occurred while counting number of BLs pending D.O and Radiation payments. "+error);
      }
    });
  }

  numberOfBLsPendingPortChargesPmt(): void {
    this.pendingTasksService.countNoOfBLsPendingPortChargesPmt()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.blsPendingPortChargesPmt=response.data;
          this.noOfBlsPendingPortChargesPmt=Number(this.blsPendingPortChargesPmt.pending_port_charges_count);
          this.pendingTasksService.updateNoOfBLsPendingPortChargesPmt(this.noOfBlsPendingPortChargesPmt);
        }else{
          //this.globalService.setGlobalWarningMessage("Unable to determine number of BLs pending Port Charges payments. Navigate to the dashboard and refresh the page.");
        }
      },
      error: (error) => {
        console.log("An error occurred while counting number of BLs pending Port Charges payments. "+error);
        //this.globalService.setGlobalErrorMessage("An error occurred while counting number of BLs pending Port Charges payments. "+error);
      }
    });
  }

  numberOfBLsPendingETAUpdatePmt(): void {
    this.pendingTasksService.countNoOfBLsPendingETAUpdatePmt()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.blsPendingETAUpdatePmt=response.data;
          this.noOfBlsPendingETAUpdatePmt=Number(this.blsPendingETAUpdatePmt.no_of_bls_pending_eta_update_count);
          this.pendingTasksService.updateNoOfBLsPendingETAUpdatePmt(this.noOfBlsPendingETAUpdatePmt);
        }else{
          //this.globalService.setGlobalWarningMessage("Unable to determine number of BLs pending ETA update. Navigate to the dashboard and refresh the page.");
        }
      },
      error: (error) => {
        console.log("An error occurred while counting number of BLs pending ETA update. "+error);
        //this.globalService.setGlobalErrorMessage("An error occurred while counting number of BLs pending ETA update. "+error);
      }
    });
  }

  numberOfVehiclesPendingPhotoUpload(): void {
    this.pendingTasksService.countVehiclesPendingPhotoUpload()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.noOfVehiclesPendingPhotoUpload=Number(response.data);
          this.pendingTasksService.updateNoOfVehiclesPendingPhotoUpload(this.noOfVehiclesPendingPhotoUpload);
        }else{
          //this.globalService.setGlobalWarningMessage("Unable to determine number of vehicles pending photo upload. Navigate to the dashboard and refresh the page.");
        }
      },
      error: (error) => {
        console.log("An error occurred while counting number of vehicles pending photo upload. "+error);
        //this.globalService.setGlobalErrorMessage("An error occurred while counting number of vehicles pending photo upload. "+error);
      }
    });
  }

  numberOfVehiclesPendingDocsUpload(): void {
    this.pendingTasksService.countVehiclesPendingDocsUpload()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.noOfVehiclesPendingDocsUpload=Number(response.data);
          this.pendingTasksService.updateNoOfVehiclesPendingDocsUpload(this.noOfVehiclesPendingDocsUpload);
        }else{
          //this.globalService.setGlobalWarningMessage("Unable to determine number of vehicles pending import documents upload. Navigate to the dashboard and refresh the page.");
        }
      },
      error: (error) => {
        console.log("An error occurred while counting number of vehicles pending import documents upload. "+error);
        //this.globalService.setGlobalErrorMessage("An error occurred while counting number of vehicles pending import documents upload. "+error);
      }
    });
  }

  numberOfVehiclesPendingPartsVerification(): void {
    this.pendingTasksService.countVehiclesPendingPartsVerification()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.noOfVehiclesPendingPartsVerification=Number(response.data);
          this.pendingTasksService.updateNoOfVehiclesPendingPartsVerification(this.noOfVehiclesPendingPartsVerification);
        }else{
          //this.globalService.setGlobalWarningMessage("Unable to determine number of vehicles pending spare parts verification. Navigate to the dashboard and refresh the page.");
        }
      },
      error: (error) => {
        console.log("An error occurred while counting number of vehicles pending spare parts verification. "+error);
        //this.globalService.setGlobalErrorMessage("An error occurred while counting number of vehicles pending spare parts verification. "+error);
      }
    });
  }
  
}