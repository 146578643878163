<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>New Bank Account</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/settings/bank-accounts-list"><i class="fa fa-list-ul"></i>&nbsp;View Bank Accounts</a></li>
               <li class="breadcrumb-item active">New Bank Account</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
          <h3 class="card-title">New Bank Account</h3>
          <!--Error Message-->
          <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            <span [innerHTML]="errorMessage"></span>
          </div>
          <!--Success Message-->
          <div class="alert alert-success" role="alert" *ngIf="successMessage">
            {{successMessage}}
          </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
           <form [formGroup]="form" (ngSubmit)="handleSubmit()">
                <div class="row clearfix">
                    <div class="col-md-4">
                       <div class="form-group">
                           <label>Bank Name:<span style="color:red;">*</span></label>
                           <input type="text" formControlName="bank_name" class="form-control" placeholder="Bank Name" [(ngModel)]="bankAccount.data.bank_name">
                           <span *ngIf="submitted && form.get('bank_name')?.hasError('required')" style="color:red"> <strong>This field is required!</strong></span>
                       </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Account Number:<span style="color:red;">*</span></label>
                            <input type="text" formControlName="account_no" class="form-control" placeholder="Account Number"[(ngModel)]="bankAccount.data.account_no">
                            <span *ngIf="submitted && form.get('account_no')?.hasError('required')" style="color:red"> <strong>This field is required!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Consignee:<span style="color:red;">*</span></label>&nbsp;
                            <select formControlName="consignee_id"  class="form-control" [(ngModel)]="bankAccount.data.consignee_id">
                                <option value="">Select</option>
                                <option [value]="consignee.id" *ngFor="let consignee of consignees">{{ consignee.consignee_name }}</option>
                            </select>
                            <span *ngIf="submitted && form.get('consignee_id')?.hasError('required')" style="color:red"> <strong>Please select a consignee!</strong></span>
                        </div>
                    </div>
                </div>

                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>
            
                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Save</button>
                </ng-template>
           </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>