import { Component } from '@angular/core';
import { AppService } from 'src/app/services/integrations/app.service';
import { Router,ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-access-tokens',
  templateUrl: './access-tokens.component.html',
  styleUrls: ['./access-tokens.component.css']
})
export class AccessTokensComponent {
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  tokens: any[] = [];
  appId!: string;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public isLoading: boolean = false;
  public isProcessing: boolean = false

  constructor(
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ){}

  ngOnInit(): void {
    this.isLoading=true;

    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.appId = param['get']('app_id')
    })

    if(this.appId){
      this.fetchTokens(this.appId);
    }else{
      //Navigate back to list with error
      this.errorMessage="Unable to retrieve tokens associated with the selected app!";
      this.router.navigate(['integrations/apps']);
    }
   
  }

  fetchTokens(appId:string):void{
    this.appService.getAccessTokensByApp(appId).subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.tokens = response.data
          this.isLoading=false;
        }else{
          this.errorMessage=response.message;
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fetchTokens(this.appId);
  }

  revokeToken(event: Event,tokenId:string){
    event.preventDefault();
    this.confirmBox(this.appId,tokenId);
  }

  confirmBox(appId: string,tokenId:string){
    Swal.fire({
      title: 'Are you sure you want to revoke this token?',
      text: 'The action is irreversible!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.isProcessing=true;

        this.appService.revokeToken(tokenId)
        .subscribe({
          next: (response) => {
            if(response.status==='success'){
              this.isProcessing=false;
              this.successMessage=response.message;
              this.errorMessage=''; //Clear error message incase it's available
             
              //Reload tokens
              this.fetchTokens(appId);
            }else if(response.status==='error'){
              this.isProcessing=false;
              this.errorMessage=response.message;
            }else{
              this.isProcessing=false;
              this.errorMessage=response.message;
            }
          },
          error: (error) => {
            this.isProcessing=false;
            this.errorMessage=error;
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      
      }
    })
  }
}
