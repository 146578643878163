import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ConfigErrorHandler } from '../../shared/config.errorhandler';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.css']
})
export class TopnavComponent implements OnInit{

  user_full_name: any="";

  constructor(public auth: AuthService,public handler: ConfigErrorHandler,private router: Router) { }

  ngOnInit(): void {
    this.user_full_name=localStorage.getItem('userFullName');
  }

  logout() {
    this.auth.logout().subscribe({
      next: (response) =>console.log(response),
      error: (e) => this.handler.handleError(e),
      complete: () =>{
        sessionStorage.clear(); //Clear user session

        let accessToken = sessionStorage.removeItem('accessToken');
        
        if (accessToken == null ) {
          window.location.href = '/login';
        }
      } 
    });
  }
}
