import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModelService } from 'src/app/services/models/model.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { GradeService } from 'src/app/services/grades/grade.service';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { MakeService } from 'src/app/services/makes/make.service';

@Component({
  selector: 'app-add-grade',
  templateUrl: './add-grade.component.html',
  styleUrls: ['./add-grade.component.css']
})
export class AddGradeComponent implements OnInit{
  form!: FormGroup;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  isProcessing: boolean = false;
  submitted: boolean = false;
  models: any[] = [];
  makes: any[] = [];
  
  constructor(
    private modelService:ModelService,
    private formBuilder: FormBuilder,
    private router: Router,
    private globalService: GlobalService,
    private gradeService:GradeService,
    private makeService:MakeService,
  ){
    this.form = this.formBuilder.group({
      model_id: ['', Validators.required],
      grade: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.fetchVehicleMakes();
  }

  fetchVehicleMakes():void {
    this.makeService.getAllMakes().subscribe((response: ApiResponse) =>this.makes = response.data);
  }

  fetchVehiclemodels(makeId:string):void {
    this.modelService.getModelsByMake(makeId).subscribe((response: ApiResponse)=>this.models=response.data);
  }

  onSelectChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {

      const makeId = target.value;

      if(makeId!=='' && makeId!==undefined){
        this.fetchVehiclemodels(makeId);
      }
    }
  }

  handleSubmit():void{
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      const formData = {
        ...this.form.value,
      };

      this.gradeService.saveGrade(formData).subscribe(response => {
        if(response.status==='success'){

          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalSuccessMessage(response.message);
         
          // Redirect back to grades list
          this.router.navigate(['settings/grades-list']);
        }else if(response.status==='error'){
          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalErrorMessage(response.message);
        }else{
          this.submitted=false;
          this.isProcessing = false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
        }
      },
      error => {
        this.isProcessing = false;
        this.errorMessage=error;
      });
    }else{
      this.isProcessing = false;
    }
  }

}
