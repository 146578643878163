import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchModel'
})
export class SearchModelPipe implements PipeTransform {

  transform(models: any, searchText: string){
    
    if (!searchText) {
      return models; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return models.filter((models: any) => models.model_name.toLowerCase().includes(searchText));
  }

}
