import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class MakeService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient, private errorHandler: ConfigErrorHandler) { }

  /**
   * Get vehicle makes
   * @returns Array
   */
  getAllMakes(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/makes`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Create new make
   * @param formData 
   * @returns array
   */
  saveMake(formData:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/makes`;

    return this.httpClient.post(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get vehicle make for editing
   * @returns Array
   */
  edit(id:string){

    let apiURL:string=`${this.baseURL}/v1/makes/${id}/edit`;

    return this.httpClient.get(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Update make
   * @param makedData 
   * @param Id 
   * @returns array
   */
  update(makedData:any,Id: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/makes/${Id}`;

    return this.httpClient.put(apiURL,makedData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
