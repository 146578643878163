import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EntryService } from 'src/app/services/entries/entry.service';
import { ActivatedRoute, Params,Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/shared/global.service';
import { PendingTasksHandler } from 'src/app/shared/pending-tasks-handler';

@Component({
  selector: 'app-add-entry',
  templateUrl: './add-entry.component.html',
  styleUrls: ['./add-entry.component.css']
})
export class AddEntryComponent implements OnInit{

  public errorMessage: string | null = null;
  public vehicle_id: string | null = null;
  vehicle: any;
  form!: FormGroup;
  submitted: boolean = false;
  entryFileName: string="Choose a PDF file";
  pmtSlipFileName: string="Choose a PDF file";
  entry_attachment: any;
  pmt_slip_attachment: any;
  public entryNo: string = '';
  public docRefNo: string = '';
  isProcessing: boolean = false;

  constructor(
    private entryService: EntryService,
    private activatedRoute:ActivatedRoute, 
    private formBuilder: FormBuilder,
    private router: Router,
    private globalService: GlobalService,
    private pendingTasksHandler: PendingTasksHandler
    ){
    this.form = this.formBuilder.group({
      chassis_no: ['', Validators.required],
      entry_no: ['', Validators.required],
      entry_date: ['', Validators.required],
      duty_amount: ['', Validators.required],
      entry_attachment: [null, Validators.required],
      pmt_slip_attachment: [null, Validators.required],
      doc_ref_no: ['', Validators.required],
      payment_ref_no: ['', Validators.required],
    });
  }

  ngOnInit(): void {

    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.vehicle_id = param['get']('vehicle_id')
    })

    if(this.vehicle_id){
      this.getVehicleData(this.vehicle_id);
    }else{
      this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
    }
  }

  /**
   * Get vehicle details
   * @param vehicle_id 
   */
  getVehicleData(vehicle_id: string): void{
    this.entryService.getVehicle(vehicle_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.vehicle = response;

          if(this.vehicle.status==='no_data' || this.vehicle.status.length===0){
            this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again.";
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  /**
   * Handle selectd file
   * @param event 
   */
  onEntryFileSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.entryFileName = fileInput.files?.[0]?.name ?? 'Choose a PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.entry_attachment = file
    }
  }

   /**
   * Handle selectd file
   * @param event 
   */
  onPaymentSlipFileSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.pmtSlipFileName = fileInput.files?.[0]?.name ?? 'Choose a PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.pmt_slip_attachment = file
    }
  }

  /**
   * Copy Entry No. and generate Document Reference No.
   */
  copyEntryNo():void {
    this.docRefNo=this.entryNo+"-1"
  }

  /**
   * Save entry
   */
  handleSubmit() {
    this.submitted = true;
    this.isProcessing=true;
   
    if (this.form.valid) {
      this.entryService.addEntry(this.form.value,this.entry_attachment,this.pmt_slip_attachment,this.vehicle_id)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.globalService.setGlobalSuccessMessage(response.message);
            //Count the remaining unpaid duties
            this.pendingTasksHandler.numberOfPendingDutyPayments();
            // Redirect to Imported Cars list
            this.router.navigate(['imported-vehicles']);
          }else if(response.status==='upload_error'){
            this.submitted = false;
            this.globalService.setGlobalWarningMessage(response.message);
            // Redirect to Imported Cars list
            this.router.navigate(['imported-vehicles']);
          }else{
            this.submitted = false;
            this.isProcessing=false;
            this.errorMessage=response.message;
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isProcessing=false;
          this.errorMessage=error;
        }
      });
    }else{
      this.isProcessing=false;
    }
  }

}
