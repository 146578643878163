<!--Loading Spinner-->
<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>

<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
                <div class="col-sm-6">
                    <a routerLink="/cash-mgnt/new-cash-rcpt" class="btn btn-success pull-left" class="btn btn-success"><i
                    class="fa fa-plus-circle" aria-hidden="true">&nbsp;</i>Receive Cash</a>
                    &nbsp;&nbsp;
                    <a routerLink="/cash-mgnt/new-cash-pmt" class="btn btn-success pull-left" class="btn btn-success"><i
                        class="fa fa-minus-circle" aria-hidden="true">&nbsp;</i>New Cash Payment</a>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                        <li class="breadcrumb-item active">Cash Transactions</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
        <div class="row">
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-12">
                        <form [formGroup]="form" (ngSubmit)="filterByDate()"  class="form-inline">
                            <div class="form-group">
                                <label>From:<span style="color:red;">*</span></label>&nbsp;&nbsp;
                                <input type="date" formControlName="date_from" class="form-control" placeholder="Date From" value="">
                                <span *ngIf="submitted && form.get('date_from')?.hasError('required')" style="color:red"> <strong>Please enter or select starting date!</strong></span>
                            </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div class="form-group">
                                <label>To:<span style="color:red;">*</span></label>&nbsp;&nbsp;
                                <input type="date" formControlName="date_to" class="form-control" placeholder="Date To" value="">
                                <span *ngIf="submitted && form.get('date_to')?.hasError('required')" style="color:red"> <strong>Please enter or select ending date!</strong></span>
                            </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <div class="form-group">
                                <button type="submit" class="btn btn-outline-success"><i class="fas fa-filter" aria-hidden="true">&nbsp;</i>Filter By Date</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                  <div class="col">
                      <input type="text" class="form-control" placeholder="Type here to search..." (input)="onSearchTextChange($event)">
                  </div>
              </div>
            </div>
        </div>
    </div>
    </section>
    <!-- /.content-header -->
    <section class="content">
      <div class="container-fluid">
      <div class="card">
          <div class="card-header">
            <h3 class="card-title">Cash Transactions</h3>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <!--Error Message-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
            </div>
            <table id="example1" class="table table-bordered table-hover">
              <thead>
                <tr>
                    <th>Date</th>
                    <th>Money In</th>
                    <th>Money Out</th>
                    <th>Running Balance</th>
                    <th>Comments</th>
                    <th>Category</th>
                    <th>Authorised By</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let transaction of transactions | searchCashTransactions: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                    <td>{{ transaction.txn_date | date:'dd-MM-yyyy' }}</td>
                    <td *ngIf="transaction.txn_type === 'in'">{{ transaction.amount | number:'1.2-2' }}</td>
                    <td *ngIf="transaction.txn_type !== 'in'"></td>
                    <td *ngIf="transaction.txn_type === 'out'">{{ transaction.amount | number:'1.2-2' }}</td>
                    <td *ngIf="transaction.txn_type !== 'out'"></td>
                    <td>{{ transaction.running_bal | number:'1.2-2'}}</td>
                    <td>{{ transaction.comment }}</td>
                    <td>{{ transaction.category_name }}</td>
                    <td>{{ transaction.authorised_by }}</td>
                  </tr>
              </tbody>
            </table>
            <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </section>