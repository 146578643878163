 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Stock Reconciliation</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item active">Stock Reconciliation</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
    </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
         <h3 class="card-title">Stock Reconciliation</h3>
         <!--Error message-->
         <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            <span [innerHTML]="errorMessage"></span>
          </div>
          <!--Success message-->
         <div class="alert alert-success" role="alert" *ngIf="successMessage">
            <span [innerHTML]="successMessage"></span>
          </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
           <form [formGroup]="form" (ngSubmit)="handleSubmit()">
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Attachment:<span style="color:red;">*</span></label>
                            <div class="input-group mb-3">
                                <div class="custom-file">
                                  <input type="file" formControlName="excel_file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onFileSelected($event)">
                                  <label class="custom-file-label" for="inputGroupFile01">{{ fileName }}</label>
                                </div>
                            </div>

                            <span *ngIf="submitted && form.get('excel_file')?.hasError('required')" style="color:red"> <strong>Please attach Excel file with vehicle registration numbers!</strong></span>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Location:<span style="color:red;">*</span></label>
                            <select formControlName="location_id" class="form-control" (change)="onSelectChange($event.target)">
                                 <option value="">Select</option>
                                 <option  [value]="location.id" *ngFor="let location of locations">{{ location.location_name }}</option>
                            </select>
                            <span *ngIf="invalidLocationSelected" style="color:red"> <strong>Invalid location selected!</strong></span>
                            <span *ngIf="submitted && form.get('location_id')?.hasError('required')" style="color:red"> <strong>Location is required!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                         <div class="form-group">
                             <label>Branch:<span style="color:red;">*</span></label>
                             <select formControlName="branch_id" class="form-control">
                                 <option value="">Select</option>
                                 <option  [value]="branch.id" *ngFor="let branch of branches">{{ branch.branch_name }}</option>
                             </select>
                             <span *ngIf="submitted && form.get('branch_id')?.hasError('required')" style="color:red"> <strong>Branch is required!</strong></span>
                         </div>
                     </div>
                </div>
                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>

                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fas fa-file-import" aria-hidden="true">&nbsp;</i>Import</button>
                </ng-template>
           </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>