import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class GradeService {
  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private httpClient: HttpClient,
    private errorHandler: ConfigErrorHandler
  ) { }

   /**
   * Get vehicle grades
   * @returns Array
   */
  getAllGrades(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/grades`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Create new grade
   * @param formData 
   * @returns array
   */
  saveGrade(formData:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/grades`;

    return this.httpClient.post(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get grade record for editing
   * @param gradeId 
   */
  getGradeData(gradeId: string){

    let apiURL:string=`${this.baseURL}/v1/grades/${gradeId}/edit`;

    return this.httpClient.get(apiURL).pipe(catchError(this.errorHandler.handleError));
  
  }

  /**
   * Edit BL
   * @param formData 
   * @param gradeId 
   * @returns array
   */
  updateGrade(formData:any,gradeId: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/grades/${gradeId}`;

    return this.httpClient.put(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Delete grade
   * @param gradeId 
   * @returns array
   */
  deleteGrade(gradeId: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/grades/${gradeId}`;

    return this.httpClient.delete(apiURL, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get all models associated with a particular make
   * @param makeID
   * @returns Array
   */
  getGradesByModel(modelID:string): Observable<ApiResponse>{
    
    let apiURL:string=`${this.baseURL}/v1/get-grades-by-model/${modelID}`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

}
