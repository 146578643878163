 <!--Loading Spinner-->
 <div *ngIf="isLoading">
    <app-spinner></app-spinner>
  </div>
  
   <!-- Content Header (Page header) -->
   <section class="content-header">
      <div class="content-header">
         <div class="container-fluid">
           <div class="row mb-2">
             <div class="col-sm-6">
                 <h3>Reverse Duty Payments</h3>
             </div>
             <div class="col-sm-6">
               <ol class="breadcrumb float-sm-right">
                 <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                 <li class="breadcrumb-item active">Reverse Duty Payments</li>
               </ol>
             </div><!-- /.col -->
           </div><!-- /.row -->
         </div><!-- /.container-fluid -->
     </div>
     <div class="row">
      <div class="col-md-8">
          <div class="row">
            <div class="col">
                <input type="submit" (click)="reversePayments()" class="btn btn-outline-danger" value="Reverse Selected">
            </div>
          </div>
      </div>
      <div class="col-md-4">
        <div class="row">
            <div class="col">
                <input type="text" class="form-control" placeholder="Type Ref. Number, BL Number or Chassis Number here to search" (input)="onSearchTextChange($event)">
            </div>
        </div>
      </div>
    </div>
     </section>
       <!-- /.content-header -->
     <section class="content">
     <div class="container-fluid">
     <div class="card">
         <div class="card-header">
           <h3 class="card-title">Reverse Duty Payments (Showing only payments done within the last 30 days)</h3>
            <!--Error messages-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
            </div>
         </div>
         <!-- /.card-header -->
         <div class="card-body" style="overflow-x: auto; overflow-y: auto; white-space: nowrap; height: 650px;">
          <table id="example1" class="table table-bordered table-hover">
              <thead>
                  <tr>
                    <th></th>
                    <th>BL No.</th>
                    <th>Entry No.</th>
                    <th>Entry Date</th>
                    <th>Make</th>
                    <th>Model</th>
                    <th>Chassis No.</th>
                    <th>Document Reference</th>
                    <th>Ref. Number</th>
                    <th>Bank Name</th>
                    <th>Duty Amount</th>
                    <th>Date Paid</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let duty of paid_duties | searchDutyPayment: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                      <td>
                        <input type="checkbox" class="form-control" [(ngModel)]="duty.checked" (change)="checkboxChange($event, duty.txn_id)" id="duty_payment" name="duty_payment">
                      </td>
                      <td>{{ duty.bl_no }}</td>
                      <td>{{ duty.entry_no }}</td>
                      <td>{{ duty.entry_date| date: 'dd-MM-yyyy' }}</td>
                      <td>{{ duty.make }}</td>
                      <td>{{ duty.model }}</td>
                      <td>{{ duty.chassis_no }}</td>
                      <td>{{ duty.doc_ref_no }}</td>
                      <td>{{ duty.pmt_ref_no }}</td>
                      <td>{{ duty.bank_name }}</td>
                      <td>{{ duty.amount_paid| currency:'Kshs ' }}</td>
                      <td>{{ duty.date_paid| date: 'dd-MM-yyyy' }}</td>
                  </tr>
              </tbody>
          </table>
          <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
         </div>
         <!-- /.card-body -->
     </div>
     </div>
     </section>