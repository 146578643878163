import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/integrations/app.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-apps-list',
  templateUrl: './apps-list.component.html',
  styleUrls: ['./apps-list.component.css']
})
export class AppsListComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  apps: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public isLoading: boolean = false;
  public isProcessing: boolean = false;

  constructor(
    private appService: AppService
  ){}

  ngOnInit(): void {
    this.isLoading=true;
    this.fetchApps();
  }

  fetchApps():void{
    this.appService.getAll().subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.apps = response.data
          this.isLoading=false;
        }else{
          this.errorMessage=response.message;
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fetchApps();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  deleteApp(event: Event,appId:string){
    event.preventDefault();
    this.confirmBox(appId);
  }

  confirmBox(appId: string){
    Swal.fire({
      title: 'Are you sure you want to delete this app?',
      text: 'The action is irreversible!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.isProcessing=true;

        this.appService.deleteApp(appId)
        .subscribe({
          next: (response) => {
            if(response.status==='success'){
              this.isProcessing=false;
              this.successMessage=response.message;
              this.errorMessage=''; //Clear error message incase it's available
             
              //Reload apps
              this.fetchApps();
            }else if(response.status==='error'){
              this.isProcessing=false;
              this.errorMessage=response.message;
            }else{
              this.isProcessing=false;
              this.errorMessage=response.message;
            }
          },
          error: (error) => {
            this.isProcessing=false;
            this.errorMessage=error;
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      
      }
    })
  }

}
