import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }

  /**
   * Get all vehicles
   * @returns Array
   */
  getAllVehicles(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/vehicles`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get single vehicle record
   * @param vehicle_id 
   */
  getVehicle(vehicle_id: string){

    let apiURL:string=`${this.baseURL}/v1/get-vehicle-details/${vehicle_id}/web`;

    return this.http.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Get vehicle photos
   * @param vehicle_id 
   * @returns Observable<ApiResponse>
   */
  fetchVehiclePhotos(vehicle_id: string|null): Observable<ApiResponse>
  {
    let apiURL:string=`${this.baseURL}/v1/get-vehicle-photos/${vehicle_id}/web`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Upload vehicle photos
   * 
   * @param formData 
   * @param vehicle_id 
   * @returns Observable<any>
   */
  uploadPhotos(formData:FormData,vehicle_id: string|null):Observable<any>{
    
    let apiURL:string=`${this.baseURL}/v1/upload-vehicle-photos/${vehicle_id}`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Import vehicle registration numbers
   */
  import(excel_file:any):Observable<any>{
    const formData = new FormData();
    formData.append("excel_file", excel_file);

    let apiURL:string=`${this.baseURL}/v1/import-reg-numbers`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Get vehicle record for editing
   * @param vehicle_id 
   */
  editVehicle(vehicle_id: string){

    let apiURL:string=`${this.baseURL}/v1/vehicles/${vehicle_id}/edit`;

    return this.http.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Update Vehicle details
   * @param vehicle_data 
   * @param vehicle_id 
   * @returns array
   */
  updateVehicle(vehicle_data:any,vehicle_id: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/vehicles/${vehicle_id}`;

    return this.http.put(apiURL,vehicle_data, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Add Trade-In vehicle
   * @param vehicle_data 
   * @returns array
   */
  addVehicle(vehicle_data:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/vehicles`;

    return this.http.post(apiURL,vehicle_data, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Import stock at hand
   */
  importStockAtHand(excel_file:any,locationId:any,branchId:any):Observable<any>{
    const formData = new FormData();
    formData.append("excel_file", excel_file);
    formData.append("location_id", locationId);
    formData.append("branch_id", branchId);

    let apiURL:string=`${this.baseURL}/v1/import-stock-at-hand`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Import sold vehicles
   */
  importSoldVehicles(excel_file:any):Observable<any>{
    const formData = new FormData();
    formData.append("excel_file", excel_file);

    let apiURL:string=`${this.baseURL}/v1/import-sold-vehicles`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Stock Reconciliation
   */
   stockReconciliation(excel_file:any,locationId:any,branchId:any):Observable<any>{
    const formData = new FormData();
    formData.append("excel_file", excel_file);
    formData.append("location_id", locationId);
    formData.append("branch_id", branchId);

    let apiURL:string=`${this.baseURL}/v1/stock-reconciliation`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Mark Vehicle as sold
   * @param vehicle_id 
   * @returns array
   */
  markVehicleAsSold(vehicle_id: string|null):Observable<any>{
    
    let apiURL:string=`${this.baseURL}/v1/mark-vehicle-as-sold`;

    return this.http.post(apiURL,{vehicle_id:vehicle_id}, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Transfer vehicle(s)
   * @param locationId 
   * @param branchId 
   * @param selectedVehicles 
   * @returns 
   */
   transferVehicles(locationId: string, branchId: string, selectedVehicles: any[]): Observable<any> {

    let apiURL:string=`${this.baseURL}/v1/transfer-vehicles`;

    const data = {
      location_id: locationId,
      branch_id: branchId,
      selected_vehicles: selectedVehicles
    };

    return this.http.post<any>(apiURL, data);
  }

  /**
   * Get vehicles pending photo upload
   * @returns Array
   */
  getVehiclesPendingPhotoUpload(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/vehicles-pending-photo-upload`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get vehicles pending import documents upload
   * @returns Array
   */
  getVehiclesPendingImportDocsUpload(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/vehicles-pending-import-docs-upload`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Upload import documents
   */
  uploadImportDocuments(attachment:any,vehicle_id: string):Observable<any>{
    const formData = new FormData();
    formData.append("vehicle_id", vehicle_id);
    formData.append("attachment", attachment);
   
    let apiURL:string=`${this.baseURL}/v1/upload-import-documents`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get vehicle duty payment details
   * @param vehicle_id 
   * @returns Observable<ApiResponse>
   */
  fetchVehicleDutyPmtDetails(vehicle_id: string|null): Observable<ApiResponse>
  {
    let apiURL:string=`${this.baseURL}/v1/get-vehicle-duty-pmt-details/${vehicle_id}`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get vehicles that require info update
   * @returns Array
   */
  getVehiclesToUpdate(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-vehicles-to-update`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
  * Get available vehicle spare parts
  * @param chassis_no
  * @returns Observable<ApiResponse>
  */
  fetchVehicleSpareParts(chassis_no: string|null): Observable<ApiResponse>
  {
    let apiURL:string=`${this.baseURL}/v1/get-available-spare-parts/${chassis_no}`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Update Vehicle price
   * @param form_data 
   * @param vehicle_id 
   * @returns array
   */
  updateVehiclePrice(form_data:any,vehicle_id: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/update-vehicle-price/${vehicle_id}`;

    return this.http.put(apiURL,form_data, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Delete vehicle photos 
   * @param vehicleId
   * @param selectedVehicles 
   * @returns 
   */
   deleteVehiclesPhotos(vehicleId:string|null,selectedPhotos: any[]): Observable<any> {

    let apiURL:string=`${this.baseURL}/v1/delete-vehicle-photos`;

    const data = {
      vehicle_id:vehicleId,
      selected_photos: selectedPhotos
    };

    return this.http.post<any>(apiURL, data);
  }
}
