<div *ngIf="isInProgress">
    <app-spinner></app-spinner>
</div>
  
  <!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                    <li class="breadcrumb-item active">Vehicles Pending Documents Upload</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
  
    <div class="row">
        <div class="col-md-8">
        
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col">
                    <input type="text" class="form-control" placeholder="Search by Make, Model, Chassis No., Engine No. or BL No." (input)="onSearchTextChange($event)">
                </div>
            </div>
        </div>
    </div>
</section>
        <!-- /.content-header -->
<section class="content">
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">Vehicles Pending Documents Upload</h3>
            <!--Error Message-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
            </div>
            <!--Success Message-->
            <div class="alert alert-success" role="alert" *ngIf="successMessage">
                {{successMessage}}
            </div>
                <!--Warning Message-->
            <div class="alert alert-warning" role="alert" *ngIf="warningMessage">
                {{warningMessage}}
            </div>
        </div>
    <!-- /.card-header -->
    <div class="card-body" style="overflow-x: auto; white-space: nowrap;">
        <!--Error messages here-->
        <table id="example1" class="table table-bordered table-hover table-striped">
            <thead>
                <tr>
                    <th>Make</th>
                    <th>Model</th>
                    <th>Chassis No.</th>
                    <th>Engine No.</th>
                    <th>Engine CC</th>
                    <th>Body Type</th>
                    <th>Drive</th>
                    <th>Fuel</th>
                    <th>Colour</th>
                    <th>Year</th>
                    <th>BL No.</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let vehicle of vehicles  | searchVehicle: searchText| paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }" [ngClass]="{'highlight_reserved': vehicle.reserved===1}">
                    <td>{{vehicle.make}}</td>
                    <td>{{vehicle.model}}</td>
                    <td><a routerLink="/view-vehicle/{{vehicle.vehicle_id}}">{{vehicle.chassis_no}}</a></td>
                    <td>{{vehicle.engine_no}}</td>
                    <td>{{vehicle.engine_cc}}</td>
                    <td>{{vehicle.body_type}}</td>
                    <td>{{vehicle.drive}}</td>
                    <td>{{vehicle.fuel}}</td>
                    <td>{{vehicle.colour}}</td>
                    <td>{{vehicle.year}}</td>
                    <td>{{vehicle.bl_no}}</td>
                    <td><a routerLink="/view-vehicle-docs/{{vehicle.vehicle_id}}" class="btn btn-success btn-sm active" role="button" aria-pressed="true"><i
                        class="fas fa-paperclip" aria-hidden="true">&nbsp;</i>Add Docs</a></td>
                </tr>
            </tbody>
        </table>
        <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
    </div>
    <!-- /.card-body -->
    </div>
</div>
</section>