<!--Loading Spinner-->
<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Edit Paid Port Charges Invoice</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/transactions/paid-port-charges-invoices"><i class="nav-icon fas fa-documents"></i>&nbsp;Paid Port Charges Invoices</a></li>
               <li class="breadcrumb-item active">Edit Paid Port Charges Invoice</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
</section>
<!-- /.content-header -->
<section class="content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Edit Paid Port Charges Invoice</h3>
                <!--Error messages here-->
                <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorMessage">
                    {{errorMessage}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <form  [formGroup]="form" (ngSubmit)="handleSubmit()">
                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>BL No.:</label>
                                <input type="text" formControlName="bl_no" class="form-control" placeholder="BL No." [(ngModel)]="invoiceData.data.bl_no" readonly>

                                <span *ngIf="submitted && form.get('bl_no')?.hasError('required')" style="color:red"> <strong>BL No. is missing please try to reload the page!</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>No. of Units:</label>
                                <input type="text" formControlName="no_of_units" class="form-control" placeholder="No. of Units" [(ngModel)]="invoiceData.data.no_of_units" readonly>

                                <span *ngIf="submitted && form.get('no_of_units')?.hasError('required')" style="color:red"> <strong>No. of units is missing please try to reload the page!</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Invoice No.:</label>
                                <input type="text" formControlName="invoice_no" class="form-control" placeholder="Invoice Number" [(ngModel)]="invoiceData.data.invoice_no" readonly>

                                <span *ngIf="submitted && form.get('invoice_no')?.hasError('required')" style="color:red"> <strong>Invoice No. is required!</strong></span>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Amount Kshs:<span style="color:red;">*</span></label>
                                <input type="text" formControlName="amount_kes" class="form-control" placeholder="Amount In Kshs" [(ngModel)]="invoiceData.data.amount_kes">

                                <span *ngIf="submitted && form.get('amount_kes')?.hasError('required')" style="color:red"> <strong>Please enter invoice amount in Kshs!</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Amount USD:<span style="color:red;">*</span></label>
                                <input type="text" formControlName="amount_usd" class="form-control" placeholder="Amount In USD" [(ngModel)]="invoiceData.data.amount_usd">

                                <span *ngIf="submitted && form.get('amount_usd')?.hasError('required')" style="color:red"> <strong>Please enter invoice amount in USD!</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Invoice Copy:</label>
                                <div class="input-group mb-3">
                                    <div class="custom-file">
                                        <input type="file" formControlName="attachment" accept="application/pdf,image/*" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onFileSelected($event)">
                                        <label class="custom-file-label" for="inputGroupFile01">{{ fileName }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group">
                               <label>Pay In:<span style="color:red;">*</span></label>
                               <select formControlName="currency" class="form-control" (change)=" onDropdownChange($event)">
                                    <option value="">Select</option>
                                    <option value="KES">KES</option>
                                    <option value="USD">USD</option>
                               </select>
    
                               <span *ngIf="submitted && form.get('currency')?.hasError('required')" style="color:red"> <strong>Please select the currency used to pay for the invoice!</strong></span>
                            </div>
                        </div>
                        <div class="col-md-4" *ngIf="showExRate">
                            <div class="form-group">
                                <label>Ex. Rate:<span style="color:red;">*</span></label>
                                <input type="text" formControlName="ex_rate" class="form-control" placeholder="Exchange Rate" value="">
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Processing...
                    </button>
                
                    <ng-template #showSaveBtn>
                        <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Update</button>
                    </ng-template>
                </form>
            </div>
            <!-- /.card-body -->
        </div>
    </div>
</section>
