import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchActivityLog'
})
export class SearchActivityLogPipe implements PipeTransform {

  transform(activity_logs:any, searchText: string) {
    if (!searchText) {
      return activity_logs; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return activity_logs.filter((activity_logs: any) => 
      (activity_logs.name && activity_logs.name.toLowerCase().includes(searchText))||
      (activity_logs.action && activity_logs.action.toLowerCase().includes(searchText))
    );
  }

}
