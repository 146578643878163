 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h3>{{ 'forms.exports.view_units.label' | translate }}</h3><!--View Units-->
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                    <li class="breadcrumb-item"><a routerLink="/suppliers/bl-list"><i class="fa fa-list-ul"></i>&nbsp;{{ 'sidebar.exports.view_bls.label' | translate }}</a></li><!--View Bills of Lading-->
                    <li class="breadcrumb-item active">{{ 'forms.exports.view_units.label' | translate }}</li><!--View Units-->
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
   </div>
</section>
     <!-- /.content-header -->
<section class="content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">{{ 'forms.exports.view_units.label' | translate }}</h3><!--View Units-->
                <!--Error messages-->
                <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
                </div>
                <!--Warning messages-->
                <div class="alert alert-warning" role="alert" *ngIf="warningMessage">
                {{warningMessage}}
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <form method="post" action="#">
                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>BL No.:</label>
                                <input type="text" class="form-control" value="{{ bl_data.data.bl_no }}" readonly>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Consignee:</label>
                                <input type="text" class="form-control" value="{{ bl_data.data.consignee_name }}" readonly>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>No. of Units:</label>
                                <input type="text"  class="form-control" value="{{ bl_data.data.no_of_units }}" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>CFS:</label>
                                <input type="text" class="form-control" value="{{ bl_data.data.cfs_name }}" readonly>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Units Added:</label>
                                <input type="text"  class="form-control" value="{{ bl_data.data.units_added }}" readonly>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>BL Date:</label>
                                <input type="text"  class="form-control" value="{{ bl_data.data.bl_date| date: 'dd-MM-yyyy' }}" readonly>
                            </div>
                        </div>
                    </div>
                    <div style="float: left;" class="mb-2">
                        <button type="button" class="btn btn-danger btn-sm" (click)="unassign()">
                            <i class="fa fa-minus-circle" aria-hidden="true">&nbsp;</i>{{ 'forms.buttons.unassign_bl.label' | translate }}
                        </button>
                    </div>
                    <table id="example1" class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Make</th>
                                <th>Model</th>
                                <th>Chassis No.</th>
                                <th>Engine No.</th>
                                <th>Engine CC</th>
                                <th>Transmission</th>
                                <th>Body Type</th>
                                <th>Drive</th>
                                <th>Fuel</th>
                                <th>Mileage</th>
                                <th>Colour</th>
                                <th>Yr of Mfg</th>
                                <th>Weight</th>
                                <th>CBM</th>
                                <th *ngIf="userPermissions.includes('view-vhcl-details')||userPermissions.includes('edit-vhcl')"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let vehicle of vehicles">
                                <td>
                                    <input type="checkbox" [(ngModel)]="vehicle.checked" (change)="checkboxChange($event, vehicle.vehicle_id)" id="vehicle" name="vehicle">
                                </td>
                                <td>{{ vehicle.make }}</td>
                                <td>{{ vehicle.model }}</td>
                                <td>{{ vehicle.chassis_no }}</td>
                                <td>{{ vehicle.engine_no }}</td>
                                <td>{{ vehicle.engine_cc }}</td>
                                <td>{{ vehicle.transmission }}</td>
                                <td>{{ vehicle.body_type }}</td>
                                <td>{{ vehicle.drive }}</td>
                                <td>{{ vehicle.fuel }}</td>
                                <td>{{ vehicle.mileage }}</td>
                                <td>{{ vehicle.colour}}</td>
                                <td>{{ vehicle.year }}</td>
                                <td>{{ vehicle.weight }}</td>
                                <td>{{ vehicle.cbm}}</td>
                                <td *ngIf="userPermissions.includes('view-vhcl-details')||userPermissions.includes('edit-vhcl')">
                                    <div class="dropdown">
                                        <button class="btn btn-default dropdown-toggle" type="button"
                                            data-toggle="dropdown">Action
                                            <span class="caret"></span></button>
                                        <ul class="dropdown-menu">
                                            <li *ngIf="userPermissions.includes('view-vhcl-details')"><a class="dropdown-item" routerLink="/suppliers/view-upload-photos/{{vehicle.vehicle_id}}">{{ 'forms.exports.upload_photos.label' | translate }}</a></li><!--View/Upload Photos-->
                                            <li *ngIf="userPermissions.includes('edit-vhcl')"><a class="dropdown-item" routerLink="/suppliers/edit-unit/{{vehicle.vehicle_id}}">{{ 'forms.buttons.btn_edit.label' | translate }}</a></li><!--Edit-->
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
            <!-- /.card-body -->
        </div>
    </div>
</section>