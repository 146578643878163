 <!--Loading Spinner-->
 <div *ngIf="isLoading">
  <app-spinner></app-spinner>
</div>

 <!-- Main content -->
 <div class="content">
    <div class="container-fluid">
      <!-- Info boxes -->
      <div class="row" style="padding-top: 5px;">
        <div class="col-12 col-sm-6 col-md-3">
          <a routerLink="/stock-overview/stock-en-route">
            <div class="info-box">
              <span class="info-box-icon bg-info elevation-1"><i class="fas fa-ship"></i></span>
                <div class="info-box-content">
                  <span class="info-box-text">Vehicles En route</span>
                  <span class="info-box-number">
                    {{noOfVehiclesEnroute}}
                  </span>
                </div>
              <!-- /.info-box-content -->
            </div>
          </a>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->
        <div class="col-12 col-sm-6 col-md-3">
          <a routerLink="/unpaid-duties">
            <div class="info-box mb-3">
              <span class="info-box-icon bg-danger elevation-1"><i class="far fa-money-bill-alt"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">Unpaid Duties</span>
                <span class="info-box-number">{{noOfUnpaidDuties}}</span>
              </div>
              <!-- /.info-box-content -->
            </div>
          </a>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->

        <!-- fix for small devices only -->
        <div class="clearfix hidden-md-up"></div>

        <div class="col-12 col-sm-6 col-md-3">
          <a routerLink="/update-vessel-eta">
            <div class="info-box mb-3">
              <span class="info-box-icon bg-success elevation-1"><i class="fa fa-hourglass-end"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">BLs Pending ETA</span>
                <span class="info-box-number">{{noOfBlsPendingETAUpdatePmt}}</span>
              </div>
              <!-- /.info-box-content -->
            </div>
          </a>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->
        <div class="col-12 col-sm-6 col-md-3">
          <a routerLink="/imported-vehicles">
            <div class="info-box mb-3">
              <span class="info-box-icon bg-warning elevation-1"><i class="fa fa-hourglass-end"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">Pending Duty Entry</span>
                <span class="info-box-number">{{noOfPendingEntries}}</span>
              </div>
              <!-- /.info-box-content -->
            </div>
          </a>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
      <div class="row">
        <div class="col-lg-6">
          <div class="card card-success card-outline">
            <div class="card-header">
              <h5 class="m-0">Vehicles At CFS & InStock</h5>
            </div>
            <div class="card-body" style="width: 600px;height:400px;">
              <div class="row">
                <div class="col-md-6">
                  <canvas id="VehiclesAtCFSChart">{{ at_cfs_chart }}</canvas>

                  <div class="no-data-container" *ngIf="!units_at_cfs || units_at_cfs.length === 0">
                    <p class="text-danger no-data-error">No units available in CFS!</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <canvas id="VehiclesInStock">{{ in_stock_chart }}</canvas>

                  <div class="no-data-container" *ngIf="!units_in_stock || units_in_stock.length === 0">
                    <p class="text-danger no-data-error">No units available in stock!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card card-success card-outline">
            <div class="card-header">
              <h3 class="card-title">
                <i class="ion ion-clipboard mr-1"></i>
                Available Makes
              </h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
              <div class="row" style="padding-bottom: 10px;">
                <div class="col-md-4">
                  <select class="form-control" (change)="onSelectChange($event.target)" [(ngModel)]="selectedBranch">
                      <option value="">All Branches</option>
                      <option [value]="branch.branch_id" *ngFor="let branch of branches">{{ branch.location_name }} - {{ branch.branch_name }}</option>
                  </select>
                </div>
                <div class="col-md-5">
                 
                </div>
                <div class="col-md-3">
                  <input type="text" class="form-control" placeholder="Type here to search..." (input)="onSearchTextChange($event)">
                </div>
             </div>
             <div class="row">
                <div class="col-md-12">
                  <ul class="todo-list" data-widget="todo-list">
                    <li *ngFor="let row of stock_available_by_make| searchMake: searchText | paginate: { itemsPerPage: 5, currentPage: page, totalItems: count }">
                      <span class="text">{{ row.make_name }}</span>
                      <small class="badge badge-success"><i class="far fa-clock"></i> {{ row.units_available }}</small>
                      <div class="tools">
                        <i class="fas fa-eye"  (click)="openAvailableModelsModal(row.make_id,row.make_name)"></i>
                      </div>
                    </li>  
                  </ul>
                </div>
             </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer clearfix">
              <div class="float-right">
                <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
        <!-- /.col-md-6 -->
        <div class="col-lg-6">
          <div class="card card-success card-outline">
            <div class="card-header">
              <h5 class="m-0">Total Stock</h5>
            </div>
            <div class="card-body" style="width: 600px;height:400px;">
              <canvas  id="TotalStockChart" >{{ total_stock_chart }}</canvas>
            </div>
          </div>

          <div class="card card-success card-outline">
            <div class="card-header">
              <h5 class="m-0">Unpaid Duties</h5>
            </div>
            <div class="card-body" style="width: 600px;height:400px;">
              <div class="row">
                <div class="col-md-12">
                  <ul class="todo-list" data-widget="todo-list">
                    <li *ngFor="let row of unpaid_duties">
                      <span class="text">{{ row.consignee_name }}</span>
                      <small class="badge badge-success"><i class="far fa-clock"></i> {{ row.unpaid_duty_count }}</small>
                      <span class="text">{{ row.unpaid_duty_total| currency:'Kshs ' }}</span>
                      <a routerLink="/unpaid-duties">
                        <div class="tools">
                          <i class="fas fa-eye"></i>
                        </div>
                      </a>
                    </li>  
                  </ul>
                </div>
             </div>
            </div>
          </div>
        </div>
        <!-- /.col-md-6 -->
      </div>
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content -->

<!-- Modal -->
<div class="modal fade" #AvailableModels tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{make_name}} MODELS</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="row">
        <div class="col" style="margin: 2px 5px;">
          <input type="text" class="form-control" placeholder="Type here to search..." (input)="modelOnSearchTextChange($event)">
        </div>
      </div>
      <table id="example1" class="table table-bordered table-hover table-striped">
        <tr *ngFor="let row of stock_available_by_model | searchModel: modelSearchText | paginate: { itemsPerPage: 10, currentPage: model_page, totalItems: model_count }">
          <td><a href="#" (click)="openAvailableUnitsModal(row.model_id,row.model_name)">{{row.model_name}} ({{row.units_available}})</a></td>
        </tr>
      </table>
      <pagination-controls (pageChange)="modelPageChangeEvent($event)"></pagination-controls>
    </div>
  </div>
</div>

<!-- Available Units Modal -->
<div class="modal fade" #AvailableUnits tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content" style="overflow-x: auto; overflow-y: auto; white-space: nowrap;">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Units Under {{make_name}} {{model_name}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="row">
        <div class="col-md-8"></div>
        <div class="col-md-4">
          <div class="row">
              <div class="col" style="margin: 2px 5px;">
                  <input type="text" class="form-control" placeholder="Search by Chassis Number" (input)="unitsOnSearchTextChange($event)">
              </div>
          </div>
        </div>
      </div>
      <table id="example1" class="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <th>MAKE</th>
            <th>MODEL</th>
            <th>CHASSIS NO.</th>
            <th>CC</th>
            <th>FUEL</th>
            <th>COLOUR</th>
            <th>TOTAL COST</th>
            <th>LOCATION</th>
          </tr>
        </thead>
        <tr *ngFor="let unit of units_by_model | searchVehicle: unitsSearchText | paginate: { itemsPerPage: 10, currentPage: units_page, totalItems: units_count }">
          <td>{{unit.make}}</td>
          <td>{{unit.model}}</td>
          <td>{{unit.chassis_no}}</td>
          <td>{{unit.engine_cc}}</td>
          <td>{{unit.fuel}}</td>
          <td>{{unit.colour}}</td>
          <td>{{unit.total| currency:'Kshs '}}</td>
          <td>{{unit.location_name}}-{{unit.branch_name}}</td>
        </tr>
      </table>
      <pagination-controls (pageChange)="unitsPageChangeEvent($event)"></pagination-controls>
    </div>
  </div>
</div>

<!--Overdue Unshipped BLs Modal-->
<div class="modal" #OverdueUnshippedBLs tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Overdue Unshipped BLs</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>There are {{noOfOverdueUnshippedBLs}} overdue unshipped BLs</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="viewOverdueUnshippedBLs()"><i class="fa fa-eye" aria-hidden="true">&nbsp;</i>View</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal"><i class="fa fa-window-close" aria-hidden="true">&nbsp;</i>Close</button>
      </div>
    </div>
  </div>
</div>