import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class CfsService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient, private errorHandler: ConfigErrorHandler) { }

  /**
   * Get All CFSes
   * @returns Array
   */
  public getAllCFSes():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/cfs`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Create new consignee
   * @param formData
   * @returns array
   */
  saveCFS(formData:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/cfs`;

    return this.httpClient.post(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
