 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h3>{{ 'forms.exports.edit_bl.label' | translate }}</h3><!--Edit Bill of Lading-->
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                        <li class="breadcrumb-item"><a routerLink="/suppliers/bl-list"><i class="fa fa-list-ul"></i>&nbsp;{{ 'sidebar.exports.view_bls.label' | translate }}</a></li><!--View Bills of Lading-->
                        <li class="breadcrumb-item active">{{ 'forms.exports.edit_bl.label' | translate }}</li><!--Edit Bill of Lading-->
                    </ol>   
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
</section>
  <!-- /.content-header -->
<section class="content">
<div class="container-fluid">
<div class="card">
    <div class="card-header">
      <h3 class="card-title">{{ 'forms.exports.edit_bl.label' | translate }}</h3><!--Edit Bill of Lading-->
      <!--Error messages-->
      <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
        {{errorMessage}}
      </div>
    </div>
    <!-- /.card-header -->
    <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="handleSubmit()">
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>BL No.:<span style="color:red;">*</span></label>
                        <input type="text" formControlName="bl_no" [(ngModel)]="this.billOfLading.data.bl_no" class="form-control" placeholder="Enter BL Number">

                        <span *ngIf="submitted && form.get('bl_no')?.hasError('required')" style="color:red"> <strong>Please enter BL number!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>BL Date:</label>
                        <input type="date" formControlName="bl_date" [(ngModel)]="this.billOfLading.data.bl_date" class="form-control" placeholder="BL Date">

                        <span *ngIf="submitted && form.get('bl_date')?.hasError('required')" style="color:red"> <strong>Please enter BL Date!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Consignee:<span style="color:red;">*</span></label>
                        <select formControlName="consignee_id"  [(ngModel)]="this.billOfLading.data.consignee_id" class="form-control">
                            <option [value]="consignee.id" *ngFor="let consignee of consignees">{{ consignee.consignee_name }}</option>
                        </select>

                        <span *ngIf="submitted && form.get('consignee_id')?.hasError('required')" style="color:red"> <strong>Please select Consignee!</strong></span>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>No. of Units:<span style="color:red;">*</span></label>
                        <input type="text" formControlName="no_of_units" [(ngModel)]="this.billOfLading.data.no_of_units" class="form-control" placeholder="Number of Units">

                        <span *ngIf="submitted && form.get('no_of_units')?.hasError('required')" style="color:red"> <strong>Please enter number of units on the BL!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>CFS:<span style="color:red;">*</span></label>
                        <select formControlName="cfs_id"   [(ngModel)]="this.billOfLading.data.cfs_id" class="form-control">
                            <option [value]="cfs.id" *ngFor="let cfs of cfses">{{ cfs.name }}</option>
                        </select>

                        <span *ngIf="submitted && form.get('cfs_id')?.hasError('required')" style="color:red"> <strong>Please select CFS!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Vessel:<span style="color:red;">*</span></label>
                        <input type="text" formControlName="vessel" [(ngModel)]="this.billOfLading.data.vessel" class="form-control" placeholder="Vessel Name">

                        <span *ngIf="submitted && form.get('vessel')?.hasError('required')" style="color:red"> <strong>Please enter vessel name!</strong></span>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Vessel ETA:</label>
                        <input type="date" formControlName="vessel_eta" [(ngModel)]="this.billOfLading.data.vessel_eta" class="form-control" placeholder="Vessel ETA">

                        <span *ngIf="submitted && form.get('vessel_eta')?.hasError('required')" style="color:red"> <strong>Please enter vessel ETA!</strong></span>
                    </div>
                </div>
            </div>
            <input type="hidden" formControlName="old_bl_no" [(ngModel)]="this.billOfLading.data.old_bl_no" class="form-control"  [value]="this.billOfLading.data.old_bl_no">
            <button type="submit" class="btn btn-success" [disabled]="!form.valid"><i class="fa fa-save"
                aria-hidden="true">&nbsp;</i>{{ 'forms.buttons.update.label' | translate }}</button>
        </form>
    </div>
    <!-- /.card-body -->
</div>
</div>
</section>