import { Component } from '@angular/core';
import { DeleteEntryService } from 'src/app/services/entries/delete-entry.service';

@Component({
  selector: 'app-delete-entry',
  templateUrl: './delete-entry.component.html',
  styleUrls: ['./delete-entry.component.css']
})
export class DeleteEntryComponent {
  page: number = 1;
  count: number = 0;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  deletableEntries: any[] = [];
  searchText: string = '';
  public isLoading: boolean = false;
  isProcessing: boolean = false;
  submitted: boolean = false;
  public entry_id:string='';
  public txn_id:string='';

  constructor(
    private deleteEntryService: DeleteEntryService
  ){
   
  }

  ngOnInit(): void {
    this.isLoading=true;
    this.getDeletableEntries();
  }

  getDeletableEntries(): void {
    this.deleteEntryService.fetchEntries()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.deletableEntries = response.data;
          this.isLoading=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isLoading=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
    this.page = 1; // Reset to page 1 after applying filter
  }

  deleteEntry(entry_id: string,entry_no: string){
    this.errorMessage=null;
    this.successMessage=null;
    
    let prompt = "Are you sure you want to delete entry with number "+entry_no+"?";
    if (confirm(prompt) == true) {
      this.isLoading=true;
      this.deleteEntryService.deleteEntry(entry_id)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.deletableEntries = response.data;
            this.successMessage =response.message;
            this.getDeletableEntries();
          }else{
            this.errorMessage=response.message;
            this.isLoading=false;
          }
        },
        error: (error) => {
          this.errorMessage=error;
          this.isLoading=false;
        }
      });
    }
  }
}
