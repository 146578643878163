import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute, Params } from '@angular/router';
import { AppService } from 'src/app/services/integrations/app.service';

@Component({
  selector: 'app-create-access-token',
  templateUrl: './create-access-token.component.html',
  styleUrls: ['./create-access-token.component.css']
})
export class CreateAccessTokenComponent implements OnInit{
  form!: FormGroup;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public app_id: string | null = null;
  appData: any;
  public accessToken: string | null = null;
  isProcessing: boolean = false;
  submitted: boolean = false;
  tokenData: any;

  constructor(
    private appService:AppService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ){
    this.form = this.formBuilder.group({
      app_name: [''],
      client_id: ['', Validators.required],
      client_secret: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.app_id = param['get']('app_id')
    })

    if(this.app_id){
      this.getAppDetails(this.app_id);
    }else{
      //Navigate back to list with error
      this.errorMessage="Unable to create token!";
      this.router.navigate(['integrations/access-tokens']);
    }
  }

  getAppDetails(app_id:any){
    this.appService.show(app_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.appData = response;
    
          if(this.appData.status==='no_data' || this.appData.status.length===0){
            this.errorMessage=this.appData.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }


  handleSubmit():void{
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      const formData = {
        ...this.form.value,
      };

      this.appService.generateAccessToken(formData).subscribe(response => {
        this.tokenData=response;
        
        if(this.tokenData.access_token!==''){
          this.accessToken=this.tokenData.access_token;
          this.submitted=false;
          this.isProcessing = false;
          this.successMessage="Access Token generated successfully. Please copy before navigating away from this page!"
        }else{
          this.submitted=false;
          this.isProcessing = false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
        }
      },
      error => {
        this.isProcessing = false;
        this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
      });
    }else{
      this.isProcessing = false;
    }
  }
}
