import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor() { }

  public _globalSuccessMessage: string = '';
  public _globalErrorMessage: string = '';
  public _globalWarningMessage: string = '';
  public _doAmountPaid!: number;
  public _portChargesAmountPaid!: number;

  //Global Error message emitter
  globalSuccessMessageUpdated: EventEmitter<string> = new EventEmitter<string>();

  //Global Error message emitter
  globalErrorMessageUpdated: EventEmitter<string> = new EventEmitter<string>();

  //Global Warning message emitter
  globalWarningMessageUpdated: EventEmitter<string> = new EventEmitter<string>();

  //D.O amount paid emitter
  doAmountPaidUpdated: EventEmitter<number> = new EventEmitter<number>();

  //Port Charges amount paid emitter
  portChargesAmountPaid: EventEmitter<number> = new EventEmitter<number>();

  //Global success message functions
  setGlobalSuccessMessage(value: string) {
    this._globalSuccessMessage = value;
    this.globalSuccessMessageUpdated.emit(this._globalSuccessMessage);
  }

  getGlobalSuccessMessage(): string {
    return this._globalSuccessMessage;
  }

  //Global error message functions
  setGlobalErrorMessage(value: string) {
    this._globalErrorMessage = value;
    this.globalErrorMessageUpdated.emit(this._globalErrorMessage);
  }

  getGlobalErrorMessage(): string {
    return this._globalErrorMessage;
  }

   //Global warning message functions
  setGlobalWarningMessage(value: string) {
    this._globalWarningMessage = value;
    this.globalWarningMessageUpdated.emit(this._globalWarningMessage);
  }

  getGlobalWarningMessage(): string {
    return this._globalWarningMessage;
  }

  //D.O amount paid functions
  setdoAmountPaid(value: number) {
    this._doAmountPaid=value;
    this.doAmountPaidUpdated.emit(this._doAmountPaid);
  }

  getdoAmountPaid(): number {
    return this._doAmountPaid;
  }

  //Port charges amount paid functions
  setportChargesAmountPaid(value: number) {
    this._portChargesAmountPaid=value;
    this.portChargesAmountPaid.emit(this._portChargesAmountPaid);
  }

  getportChargesAmountPaid(): number {
    return this._portChargesAmountPaid;
  }
}
