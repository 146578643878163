import { Component, OnInit } from '@angular/core';
import { DutyPaymentsService } from 'src/app/services/payments/duty-payments.service';
import { ConsigneeService } from 'src/app/services/consignees/consignee.service';
import { BankAccountsService } from 'src/app/services/bank-accounts/bank-accounts.service';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/shared/global.service';
import { PendingTasksHandler } from 'src/app/shared/pending-tasks-handler';
import { AgentService } from 'src/app/services/agents/agent.service';
import { EntryService } from 'src/app/services/entries/entry.service';

@Component({
  selector: 'app-upaid-duties',
  templateUrl: './upaid-duties.component.html',
  styleUrls: ['./upaid-duties.component.css']
})
export class UpaidDutiesComponent implements OnInit {
  page: number = 1;
  count: number = 0;
  unpaid_duties:any[] = [];
  searchText: string = '';
  consignees: any[] = [];
  bank_accounts:any[] = [];
  dutyPayment!: FormGroup;
  submitted: boolean = false;
  isProcessing: boolean = false;
  errorMessage: string | null = null;
  warningMessage: string | null = null;
  successMessage: string | null = null;
  selectedDuties: any[] = []; // Array to store selected duties
  amountToPay: number=0;
  public formatter: any;
  unpaidDuty: any;
  agents: any[] = [];
  public consigneeId:string='';
  public agentId:string='';
  public entryAttachment:any={};

  constructor(
    private dutyPaymentsService: DutyPaymentsService,
    private consigneeService: ConsigneeService,
    private bankAccountsService: BankAccountsService,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private pendingTasksHandler: PendingTasksHandler,
    private agentService: AgentService,
    private entryService: EntryService,
    ){
      this.dutyPayment = this.formBuilder.group({
        payment_date: ['', Validators.required],
        bank_account: ['', Validators.required],
      });

      this.formatter = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

  ngOnInit(): void {
    this.isProcessing=true;

    this.getConsignees();
    this.getUnpaidDuties();
    this.getUnpaidDutiesTotal();
    this.getAgents();
  }

  getConsignees(): void {
    this.consigneeService.getAllConsignees().subscribe((response: ApiResponse)=>this.consignees=response.data);
  }

   /**
   * Get bank accounts
   */
   getBankAccounts(consigneeId:any)
   {
    this.bankAccountsService.filterBankAccountsByConsignee(consigneeId).subscribe((response: ApiResponse)=>this.bank_accounts=response.data);
   }

  getAgents(): void{
    this.agentService.getAllAgents().subscribe((response: ApiResponse) =>this.agents = response.data);
  }

  /**
   * Get all unpaid duties
   */
  getUnpaidDuties()
  {
    this.dutyPaymentsService.getUnpaidDuties().subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.unpaid_duties = response.data;
          this.isProcessing=false;
          this.warningMessage=null;
          this.errorMessage=null;
        }else if(response.status==='no_data'){
           this.unpaid_duties=[];
           this.warningMessage=response.message;
           this.isProcessing=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isProcessing=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isProcessing=false;
      }
    });
  }

  /**
   * Filter unpaid duties by consignee
   * @param consigneeId 
   */
  onSelectChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {
      this.consigneeId = target.value;

      if(this.consigneeId!=='' && this.consigneeId!==undefined){
        this.isProcessing=true
        this.fetchUnpaidDutiesByConsignee(this.consigneeId,this.agentId);
        this.getUnpaidDutiesTotalByConsignee(this.consigneeId,this.agentId);
        this.getBankAccounts(this.consigneeId);
      }else{
        this.getUnpaidDuties();
        this.getUnpaidDutiesTotal();
        this.bank_accounts=[];
        this.getAgents();
      }

      //Reset amount to pay
      this.amountToPay=0;
    }
  }

  /**
   * Filter unpaid duties by agent
   * @param agentId 
   */
  onAgentSelectChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {
      this.agentId = target.value;

      if(this.agentId!==undefined){
        this.isProcessing=true
        this.fetchUnpaidDutiesByAgent(this.consigneeId,this.agentId);
        this.getUnpaidDutiesTotalByAgent(this.consigneeId,this.agentId);
      }else{
        this.warningMessage="Invalid agent selected. Please try again!";
      }
    }
  }

  fetchUnpaidDutiesByConsignee(consigneeId: string,agentId:string):void {
    this.dutyPaymentsService.getUnpaidDutiesByConsignee(consigneeId,agentId)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.unpaid_duties = response.data;
          this.isProcessing=false;
          this.warningMessage=null;
          this.errorMessage=null;
        }else if(response.status==='no_data'){
           this.unpaid_duties=[];
           this.warningMessage=response.message;
           this.isProcessing=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isProcessing=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isProcessing=false;
      }
    });
  }

  fetchUnpaidDutiesByAgent(consigneeId: string,agentId:string):void {
    this.dutyPaymentsService.getUnpaidDutiesByAgent(consigneeId,agentId)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.unpaid_duties = response.data;
          this.isProcessing=false;
          this.warningMessage=null;
          this.errorMessage=null;
        }else if(response.status==='no_data'){
           this.unpaid_duties=[];
           this.warningMessage=response.message;
           this.isProcessing=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isProcessing=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isProcessing=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  // Function to handle checkbox change event
  checkboxChange(event: any, item: any,amount_to_pay:number) {
    if (event.target.checked) {
      // Add selected duty to the array
      this.selectedDuties.push(item);
      //Update amount to pay
      this.amountToPay=this.formatter.format(Number(this.amountToPay.toString().replaceAll(",",""))+Number(amount_to_pay));
    } else {
      // Remove unselected duty from the array
      const index = this.selectedDuties.indexOf(item);
      if (index !== -1) {
        this.selectedDuties.splice(index, 1);
      }
      //Update amount to pay
      this.amountToPay=this.formatter.format(Number(this.amountToPay.toString().replaceAll(",",""))-Number(amount_to_pay));
    }
  }

  getUnpaidDutiesTotal(): void {
    this.dutyPaymentsService.getUnpaidDutiesTotal().subscribe((response: ApiResponse) =>this.unpaidDuty = response.data);
  }

  getUnpaidDutiesTotalByConsignee(consigneeId:string,agentId:string): void {
    this.dutyPaymentsService.getUnpaidDutiesTotalByConsignee(consigneeId,agentId).subscribe((response: ApiResponse) =>this.unpaidDuty = response.data);
  }

  getUnpaidDutiesTotalByAgent(consigneeId:string,agentId:string): void {
    this.dutyPaymentsService.getUnpaidDutiesTotalByAgent(consigneeId,agentId).subscribe((response: ApiResponse) =>this.unpaidDuty = response.data);
  }

  handleSubmit():void {
    
    this.submitted=true;

    if(this.dutyPayment.valid) {
      if(this.selectedDuties.length===0){
        this.errorMessage="Please select duties to pay!";
      }else{
        this.isProcessing=true;

        this.dutyPaymentsService.addDutyPayment(this.dutyPayment.value,this.selectedDuties,this.amountToPay)
        .subscribe({
          next: (response) => {
            if(response.status==='success'){
              this.submitted = false;
              this.isProcessing=false;
              this.globalService.setGlobalSuccessMessage(response.message);
            
              // Refresh upaid duties list
              this.getUnpaidDuties();
              this.getUnpaidDutiesTotal();
              //Reset selected duties array and total amount to pay
              this.selectedDuties = [];
              this.amountToPay=0;
              this.pendingTasksHandler.numberOfPendingDutyPayments(); //Count unpaid
            }else if(response.status==='error'){
              this.submitted = false;
              this.isProcessing=false;
              this.globalService.setGlobalErrorMessage(response.message);

               // Refresh upaid duties list
               this.getUnpaidDuties();
               this.getUnpaidDutiesTotal();
               //Reset selected duties array and total amount to pay
               this.selectedDuties = [];
               this.amountToPay=0;
            }else{
              this.submitted = false;
              this.isProcessing=false;
              this.globalService.setGlobalErrorMessage(response.message);

               // Refresh upaid duties list
               this.getUnpaidDuties();
               this.getUnpaidDutiesTotal();
               //Reset selected duties array and total amount to pay
               this.selectedDuties = [];
               this.amountToPay=0;
            }
          },
          error: (error) => {
            this.submitted = false;
            this.isProcessing=false;
            this.errorMessage=error;
          }
        });
      }
    }
  }

  viewEntryAndPaymentSlip(entry_id:string,txn_id:string){
    this.isProcessing=true;
    this.entryService.getEntryAndPaymentSlip(entry_id,txn_id)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.entryAttachment = response.data;
          this.isProcessing=false;
          this.warningMessage=null;
          this.errorMessage=null;
          window.open(this.entryAttachment.file_path, '_blank')?.focus();
        }else if(response.status==='no_data'){
           this.warningMessage=response.message;
           this.isProcessing=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please try again!";
          this.isProcessing=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isProcessing=false;
      }
    });
  }
}
