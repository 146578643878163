import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchEntries'
})
export class SearchEntriesPipe implements PipeTransform {

  transform(entries: any, searchText: string) {

    if (!searchText) {
      return entries; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return entries.filter((entries: any) => 
    (entries.doc_ref_no && entries.doc_ref_no.toLowerCase().includes(searchText)) ||  
    (entries.entry_no && entries.entry_no.toLowerCase().includes(searchText)) ||
    (entries.chassis_no && entries.chassis_no.toLowerCase().includes(searchText)) ||
    (entries.doc_ref_no && entries.doc_ref_no.toLowerCase().includes(searchText)));
  }

}
