import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EntryService } from 'src/app/services/entries/entry.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-update-entry-attachments',
  templateUrl: './update-entry-attachments.component.html',
  styleUrls: ['./update-entry-attachments.component.css']
})
export class UpdateEntryAttachmentsComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public warningMessage: string | null = null;
  entryAttachments: any[] = [];
  searchText: string = '';
  public userPermissions: string[] = [];
  public popupErrorMessage: string | null = null;
  public popupSuccessMessage: string | null = null;
  public popupWarningMessage: string | null = null;
  public isLoading: boolean = false;
  isProcessing: boolean = false;
  submitted: boolean = false;
  form!: FormGroup;
  additionalDutyForm!: FormGroup;
  public txn_id:string='';
  entry_attachment: any;
  pmt_slip_attachment: any;
  public entryAttachment:any={};
  entryFileName: string="Choose a PDF file";
  pmtSlipFileName: string="Choose a PDF file";
  @ViewChild('UpdateEntryAttachmentsModal') modalElement!: ElementRef;
  @ViewChild('UpdateAdditionalDutyPaymentSlipModal') additionalDutyModalElement!: ElementRef;

  constructor(
    private entryService: EntryService,
    private globalService: GlobalService,
    private formBuilder: FormBuilder
  ){
    this.form = this.formBuilder.group({
      entry_attachment: [null, Validators.required],
      pmt_slip_attachment: [null, Validators.required],
    });

    this.additionalDutyForm = this.formBuilder.group({
      pmt_slip_attachment: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.isLoading=true;
    this.getEntryAttachments();
  }

  getEntryAttachments(): void {
    this.entryService.getEntryAttachments()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.entryAttachments = response.data;
          this.isLoading=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isLoading=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
    this.page = 1; // Reset to page 1 after applying filter
  }

   /**
   * Handle selectd file
   * @param event 
   */
  onEntryFileSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.entryFileName = fileInput.files?.[0]?.name ?? 'Choose a PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.entry_attachment = file
    }
  }

   /**
   * Handle selectd file
   * @param event 
   */
  onPaymentSlipFileSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.pmtSlipFileName = fileInput.files?.[0]?.name ?? 'Choose a PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.pmt_slip_attachment = file
    }
  }

  openModal(txn_id:string){
    this.txn_id=txn_id;

    if (this.modalElement) {
      (<any>$(this.modalElement.nativeElement)).modal('show');
    }
  }

  closeModal():void {
    if (this.modalElement) {
      (<any>$(this.modalElement.nativeElement)).modal('hide');
    }
  }

  openAdditionalDutyModal(txn_id:string){
    this.txn_id=txn_id;

    if (this.additionalDutyModalElement) {
      (<any>$(this.additionalDutyModalElement.nativeElement)).modal('show');
    }
  }

  closeAdditionalDutyModal():void {
    if (this.additionalDutyModalElement) {
      (<any>$(this.additionalDutyModalElement.nativeElement)).modal('hide');
    }
  }

  handleSubmit(){
    this.submitted = true;
    this.isProcessing=true;

    if (this.form.valid) {
      this.entryService.updateEntryAttachments(this.txn_id,this.entry_attachment,this.pmt_slip_attachment)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.isProcessing=false;
            this.globalService.setGlobalSuccessMessage(response.message);
            this.closeModal();
           //Refresh list
           this.isLoading=true;
           this.getEntryAttachments();
          }else if(response.status==='error'){
            this.submitted = false;
            this.isProcessing=false;
            this.popupErrorMessage=response.message;
          }else if(response.status==='warning'){
            this.submitted = false;
            this.isProcessing=false;
            this.popupWarningMessage=response.message;
          }else{
            this.submitted = false;
            this.isProcessing=false;
            this.popupErrorMessage=response.message;
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isProcessing=false;
          this.popupErrorMessage=error;
        }
      });
    }else{
      this.isProcessing=false;
    }
  }

  viewEntryAndPaymentSlip(entry_id:string,txn_id:string){
    this.isProcessing=true;
    this.entryService.getEntryAndPaymentSlip(entry_id,txn_id)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.entryAttachment = response.data;
          this.isProcessing=false;
          this.warningMessage=null;
          this.errorMessage=null;
          window.open(this.entryAttachment.file_path, '_blank')?.focus();
        }else if(response.status==='no_data'){
           this.warningMessage=response.message;
           this.isProcessing=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please try again!";
          this.isProcessing=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isProcessing=false;
      }
    });
  }

  updateAdditionalDutyPmtSlipAttachment(){
    this.submitted = true;
    this.isProcessing=true;

    if (this.additionalDutyForm.valid) {
      this.entryService.updatePaymentSlipAttachment(this.txn_id,this.pmt_slip_attachment)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.isProcessing=false;
            this.globalService.setGlobalSuccessMessage(response.message);
            this.closeAdditionalDutyModal();
           //Refresh list
           this.isLoading=true;
           this.getEntryAttachments();
          }else if(response.status==='error'){
            this.submitted = false;
            this.isProcessing=false;
            this.popupErrorMessage=response.message;
          }else if(response.status==='warning'){
            this.submitted = false;
            this.isProcessing=false;
            this.popupWarningMessage=response.message;
          }else{
            this.submitted = false;
            this.isProcessing=false;
            this.popupErrorMessage=response.message;
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isProcessing=false;
          this.popupErrorMessage=error;
        }
      });
    }else{
      this.isProcessing=false;
    }
  }
}
