import { Component, OnInit } from '@angular/core';
import { ColourService } from 'src/app/services/colours/colour.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-colours-list',
  templateUrl: './colours-list.component.html',
  styleUrls: ['./colours-list.component.css']
})
export class ColoursListComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  colours: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;

  constructor(
    private colourService:ColourService,
  ){}

  ngOnInit(): void {
    this.fetchColours();
  }

  fetchColours():void{
    this.colourService.getAllColours().subscribe((response: ApiResponse) =>this.colours = response.data);
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fetchColours();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  deleteColour(event: Event,bodyTypeId:string): void{
    event.preventDefault();
  }

}
