import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchCashTransactions'
})
export class SearchCashTransactionsPipe implements PipeTransform {

  transform(transactions: any, searchText: string){
    
    if (!searchText) {
      return transactions; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return transactions.filter((transactions: any) => transactions.comment.toLowerCase().includes(searchText));
  }

}
