import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/services/cash-management/categories.service';
import { GlobalService } from 'src/app/services/shared/global.service';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.css']
})
export class CategoriesListComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  categories: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  isLoading:boolean = false;

  constructor(
    private categoriesService: CategoriesService,
    private globalService: GlobalService,
  ){}

  ngOnInit(): void {
    this.isLoading = true;
    this.fetchCategories();
  }

  fetchCategories():void{
    this.categoriesService.getAllCategories()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.isLoading = false;
          this.categories = response.data
        }else{
          this.isLoading = false;
          this.errorMessage=response.message;
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.errorMessage=error;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  delete(event: Event,Id:string,categoryName:string): void{
    event.preventDefault();
    let text = "Do you really want to delete "+categoryName+"?";

    if (confirm(text) == true) {
      this.isLoading = true;
      this.categoriesService.delete(Id).subscribe(response => {
        
        if(response.status==='success'){

          this.isLoading = false;
          this.globalService.setGlobalSuccessMessage(response.message);
         
          // Refresh categories ist
          this.fetchCategories();
        }else if(response.status==='no_data' || response.status==='error'){
          this.isLoading = false;
          this.globalService.setGlobalErrorMessage(response.message);
        }else{
          this.isLoading = false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator!";
        }
      },
      error => {
        this.isLoading = false;
        this.errorMessage=error;
      });
    } 
  }
}
