import { Component, OnInit } from '@angular/core';
import { DoRadiationPaymentsService } from 'src/app/services/payments/do-radiation-payments.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-select-bl',
  templateUrl: './select-bl.component.html',
  styleUrls: ['./select-bl.component.css']
})
export class SelectBlComponent implements OnInit {
  page: number = 1;
  count: number = 0;

  bills_of_lading: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  searchText: string = '';
  public isLoading: boolean = false;

  constructor(private doRadiationPaymentsService: DoRadiationPaymentsService){}

  ngOnInit(): void {
    this.isLoading = true;
    this.getBillsOflading();
  }

  getBillsOflading(): void {
    this.doRadiationPaymentsService.getBLs()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.bills_of_lading = response.data
          this.isLoading = false;
        }else{
          this.errorMessage=response.message;
          this.isLoading = false;
          this.successMessage=null;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.successMessage=null;
        this.isLoading = false;
      }
    });
  }
  
  pageChangeEvent(event: number){
    this.page = event;
    this.getBillsOflading();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }
}
