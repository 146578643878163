import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-print-bl-column-toggle',
  templateUrl: './print-bl-column-toggle.component.html',
  styleUrls: ['./print-bl-column-toggle.component.css']
})
export class PrintBlColumnToggleComponent {
  @Input() columns: string[] = [];
  @Input() visibleColumns: { [key: string]: boolean } = {};
  @Output() columnToggled = new EventEmitter<string>();

  toggleColumn(column: string) {
    this.columnToggled.emit(column);
  }
}
