import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class PaidPortChargesInvoicesService {

  private baseURL = environment.apiURL;

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, private errorHandler: ConfigErrorHandler) { }

   /**
   * Get Paid Invoices
   * @returns Array
   */
   public getPaidInvoices():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/port-charges-paid-invoices`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Get paid port charges invoice details
   * for editing
   * @param invoice_id 
   */
  getInvoiceDetails(invoice_id: string){

    let apiURL:string=`${this.baseURL}/v1/edit-paid-port-charges-invoice/${invoice_id}`;

    return this.http.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Update paid port charges invoice
   * @param form_data 
   * @param attachment 
   * @param invoice_id 
   * @returns 
   */
  updateInvoice(form_data:any,attachment:any,invoice_id: string|null):Observable<ApiResponse>{
    const formData = new FormData();
    formData.append("bl_no", form_data.bl_no);
    formData.append("no_of_units", form_data.no_of_units);
    formData.append("invoice_no", form_data.invoice_no);
    formData.append("amount_kes", form_data.amount_kes);
    formData.append("amount_usd", form_data.amount_usd);
    formData.append("currency", form_data.currency);
    formData.append("ex_rate", form_data.ex_rate);
    formData.append("attachment", attachment);

    let apiURL:string=`${this.baseURL}/v1/update-paid-port-charges-invoice/${invoice_id}`;

    return this.http.post<ApiResponse>(apiURL,formData).pipe(catchError(this.errorHandler.handleError));

  }
}
