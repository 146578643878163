<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Vehicle Reservations <span *ngIf="vehicleDetails.data.status===5" style="color:red;">SOLD</span></h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/vehicles"><i class="fa fa-list-ul"></i>&nbsp;Vehicle List</a></li>
               <li class="breadcrumb-item active">Vehicle Reservations</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
        <div class="col-sm-6" *ngIf="vehicleDetails.data.status!==5">
            <a routerLink="/reserve-vehicle/{{vehicleDetails.data.vehicle_id}}" class="btn btn-success pull-left" class="btn btn-success"><i
            class="fa fa-plus-circle" aria-hidden="true">&nbsp;</i>New Reservation</a>
        </div><!-- /.col -->
        &nbsp;&nbsp;
        <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Vehicle Reservations</h3>
                    <!--Error Message-->
                    <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                        <span [innerHTML]="errorMessage"></span>
                    </div>
                </div>

                <!-- /.card-header -->
                <div class="card-body" style="overflow-x: auto; white-space: nowrap;">
                    <form>
                        <div class="row clearfix">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Make:</label>
                                    <input type="text" class="form-control" value="{{ vehicleDetails.data.make }}" readonly>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Model:</label>
                                    <input type="text" class="form-control" value="{{ vehicleDetails.data.model }}" readonly>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Chassis No.:</label>
                                    <input type="text"  class="form-control" value="{{ vehicleDetails.data.chassis_no }}" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="row clearfix">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Colour:</label>
                                    <input type="text" class="form-control" value="{{ vehicleDetails.data.colour }}" readonly>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Month/Year:</label>
                                    <input type="text" class="form-control" value="{{ vehicleDetails.data.year }}" readonly>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Engine CC:</label>
                                    <input type="text"  class="form-control" value="{{ vehicleDetails.data.engine_cc }}" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="row clearfix">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Fuel:</label>
                                    <input type="text"  class="form-control" value="{{ vehicleDetails.data.fuel }}" readonly>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Mileage:</label>
                                    <input type="text"  class="form-control" value="{{ vehicleDetails.data.mileage }}" readonly>
                                </div>
                            </div>
                        </div>
                    </form>
                    <table id="example1" class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Customer Name</th>
                                <th>Phone Number</th>
                                <th>Deal Type</th>
                                <th>Agreed Amount</th>
                                <th>Deposit/Customer Contribution</th>
                                <th>Deposit/Customer Contribution Bal.</th>
                                <th>No. of Instalments</th>
                                <th>Invoice amount</th>
                                <th>Commitment Fee</th>
                                <th>Other Charges</th>
                                <th>Deal Balance</th>
                                <th>Broker Name</th>
                                <th>Broker Phone Number</th>
                                <th>Commission Agreed</th>
                                <th>Comment</th>
                                <th>Reservation Date</th>
                            </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let reservation of reservations" [ngClass]="{'highlight_reserved': reservation.purch_status===1}">
                                <td>
                                    <div class="dropdown">
                                        <button class="btn btn-default dropdown-toggle" type="button"
                                            data-toggle="dropdown">Action
                                            <span class="caret"></span></button>
                                        <ul class="dropdown-menu">
                                            <li *ngIf="vehicleDetails.data.status!==5"><a class="dropdown-item" routerLink="/add-new-commitment-fee/{{reservation.id}}/{{vehicleDetails.data.vehicle_id}}"><i class="far fa-money-bill-alt"></i>&nbsp;Add Commitment Fee</a></li>
                                            <li *ngIf="vehicleDetails.data.status===5"><a class="dropdown-item" routerLink="/view-commitment-fees/{{reservation.id}}/{{vehicleDetails.data.vehicle_id}}"><i class="far fa-money-bill-alt"></i>&nbsp;Commitment Fees</a></li>
                                            <li *ngIf="vehicleDetails.data.status!==5"><a class="dropdown-item" (click)="markVehicleAsSold(vehicle_id,reservation.id)"><i class="fas fa-money-check"></i>&nbsp;Mark As Sold</a></li>
                                            <li *ngIf="vehicleDetails.data.status!==5"><a class="dropdown-item" (click)="openModal(reservation.id)"><i class="fas fa-edit"></i>&nbsp;Edit Comment</a></li>
                                        </ul>
                                    </div>
                                </td>
                                <td>{{ reservation.first_name }} {{ reservation.middle_name }} {{ reservation.surname }}</td>
                                <td>{{ reservation.phone_no }}</td>
                                <td>{{ reservation.deal_type }}</td>
                                <td>{{ reservation.agreed_amount| currency:'Kshs ' }}</td>
                                <td>{{ reservation.deposit_cust_contrib| currency:'Kshs ' }}</td>
                                <td>{{ reservation.deposit_cust_contrib_balance| currency:'Kshs ' }}</td>
                                <td>{{ reservation.no_of_instalments }}</td>
                                <td>{{ reservation.invoice_amount| currency:'Kshs ' }}</td>
                                <td>{{ reservation.commitment_fee| currency:'Kshs '}}</td>
                                <td>{{ reservation.other_charges| currency:'Kshs ' }}</td>
                                <td>{{ reservation.deal_bal| currency:'Kshs ' }}</td>
                                <td>{{ reservation.broker_name }}</td>
                                <td>{{ reservation.broker_phone_no }}</td>
                                <td>{{ reservation.commission_agreed| currency:'Kshs ' }}</td>
                                <td class="wrap-text">{{ reservation.comment }}</td>
                                <td>{{ reservation.reservation_date| date: 'dd-MM-yyyy' }}</td>
                           </tr>
                        </tbody>
                    </table>
                </div>
                <!-- /.card-body -->
        </div>
   </div>
   </section>
   <!-- Modal -->
<div class="modal fade" #editCommentModal tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit Comment</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="form" (ngSubmit)="updateComment()">
                <textarea formControlName="comment" [(ngModel)]="comment" class="form-control" placeholder="Additional info" rows="6" cols="50">{{comment}}</textarea>
                <span *ngIf="submitted && form.get('comment')?.hasError('required')" style="color:red"> <strong>Please enter comment to proceed!</strong></span>
            </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="updateComment()">Save changes</button>
        </div>
      </div>
    </div>
</div>
