import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MakeService } from 'src/app/services/makes/make.service';
import { ModelService } from 'src/app/services/models/model.service';
import { ColourService } from 'src/app/services/colours/colour.service';
import { BodyTypeService } from 'src/app/services/body-types/body-type.service';
import { GradeService } from 'src/app/services/grades/grade.service';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { VehiclesService } from 'src/app/services/vehicles/vehicles.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-temp-edit-vehicle',
  templateUrl: './temp-edit-vehicle.component.html',
  styleUrls: ['./temp-edit-vehicle.component.css']
})
export class TempEditVehicleComponent implements OnInit{
  public isLoading: boolean = false;
  isProcessing: boolean = false;
  public vehicle_id: string | null = null;
  public errorMessage: string | null = null;
  form!: FormGroup;
  makes: any[] = [];
  models: any[] = [];
  colours: any[] = [];
  body_types: any[] = [];
  grades: any[] = [];
  vehicle:any;
  submitted: boolean = false;
  invalidMakeSelected: boolean = false;
  invalidModelSelected: boolean = false;
  public userPermissions: string[] = [];

  constructor(
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    private makeService: MakeService,
    private modelService: ModelService,
    private colourService: ColourService,
    private formBuilder: FormBuilder,
    private bodyTypeService:BodyTypeService,
    private gradeService:GradeService,
    private vehiclesService:VehiclesService,
    private globalService: GlobalService,
    private authService: AuthService,
  ){
    this.form = this.formBuilder.group({
      make_id: ['', Validators.required],
      model_id: ['', Validators.required],
      grade_id: [''],
      chassis_no: ['', Validators.required],
      engine_cc: ['', Validators.required],
      engine_no: ['', Validators.required],
      transmission: ['', Validators.required],
      body_type: ['', Validators.required],
      drive: ['', Validators.required],
      fuel: ['', Validators.required],
      mileage: ['', Validators.required],
      colour: ['', Validators.required],
      yr_of_mfg: ['', [Validators.required,Validators.minLength(4),Validators.maxLength(7)]],
      weight: ['', Validators.required],
      cbm: ['', Validators.required],
      reg_no: [''],
      condition: ['', Validators.required],
      no_of_doors: [''],
      no_of_seats: [''],
    });
  }

  ngOnInit(): void {
    this.isLoading=true;

    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.vehicle_id = param['get']('vehicle_id')
    })

    if(this.vehicle_id){
      this.getVehicleData(this.vehicle_id);

      //Get user permissions
      this.userPermissions=this.authService.getPermissions();
    }else{
      //Navigate back to vehicle list with error
      this.errorMessage="Unable to select Vehicle for update!";
      this.router.navigate(['update-vehicle-info']);
    }
  }

  /**
   * Get single vehicle info
   * @param vehicle_id 
   */
  getVehicleData(vehicle_id: string): void {
    this.vehiclesService.editVehicle(vehicle_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.isLoading=false;
          this.vehicle = response;
          
          if(this.vehicle.status==='no_data' || this.vehicle.status.length===0){
            this.errorMessage=this.vehicle.message;
          }else{
            this.isLoading=false;
            this.getVehicleMakes();
            this.getColours();
            this. getBodyTypes();
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  /**
   * Get vehicle makes
   */
  getVehicleMakes(): void {
    this.makeService.getAllMakes().subscribe((response: ApiResponse)=>this.makes=response.data);
  }

  /**
   * Get vehicle models associated with
   * the selected make
   * @param makeID 
   */
  getVehicleModels(makeID:string): void {
    this.modelService.getModelsByMake(makeID).subscribe((response: ApiResponse)=>this.models=response.data);
  }

  /**
   * Get colours
   */
  getColours(): void {
    this.colourService.getAllColours().subscribe((response: ApiResponse)=>this.colours=response.data);
  }

  getBodyTypes():void {
    this.bodyTypeService.getAllBodyTypes().subscribe((response: ApiResponse)=>this.body_types=response.data);
  }

  /**
   * Get vehicle grades associated with
   * the selected model
   * @param modelID 
   */
  getVehicleGrades(modelID:string): void {
    this.gradeService.getGradesByModel(modelID).subscribe((response: ApiResponse)=>this.grades=response.data);
  }

  //Load models & grades
  onSelectChange(target: EventTarget|null,option:string) {
    if (target instanceof HTMLSelectElement) {

      if(option==='make'){
        const makeID = target.value;
      
        if(makeID!=='' && makeID!==undefined){
          this.getVehicleModels(makeID);
        }else{
          this.invalidMakeSelected=true;
        }
      }else{
        const modelID = target.value;
      
        if(modelID!=='' && modelID!==undefined){
          this.getVehicleGrades(modelID);
        }else{
          this.invalidModelSelected=true;
        }
      }
    }
  }

  updateVehicle(): void {
    this.submitted = true;
    this.isProcessing = true;

    if (this.form.valid) {

      const formData = {
        ...this.form.value,
      };

      this.vehiclesService.updateVehicle(formData,this.vehicle_id).subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.globalService.setGlobalSuccessMessage(response.message);
            this.router.navigate(['update-vehicle-info']);
          }else{
            this.errorMessage=response.message;
            this.submitted = false;
            this.isProcessing = false;
          }
        },
        error:(error) => {
          this.submitted = false;
          this.isProcessing = false;
          this.errorMessage=error;
        }
      });
    }else{
      this.submitted = false;
      this.isProcessing = false;
      this.errorMessage="Please provide all the required details!";
    }
  }
}
