import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchDutyPayment'
})
export class SearchDutyPaymentPipe implements PipeTransform {

  transform(payments: any, searchText: string) {

    if (!searchText) {
      return payments; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return payments.filter((payments: any) => 
    (payments.pmt_ref_no && payments.pmt_ref_no.toLowerCase().includes(searchText)) ||  
    (payments.bl_no && payments.bl_no.toLowerCase().includes(searchText)) ||
    (payments.chassis_no && payments.chassis_no.toLowerCase().includes(searchText)) ||
    (payments.doc_ref_no && payments.doc_ref_no.toLowerCase().includes(searchText)));
  }

}
