import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchDeliveryNotes'
})
export class SearchDeliveryNotesPipe implements PipeTransform {

  transform(delivery_notes: any, searchText: string) {
    
    if (!searchText) {
      return delivery_notes; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return delivery_notes.filter((delivery_notes: any) => 
      (delivery_notes.make && delivery_notes.make.toLowerCase().includes(searchText))||
      (delivery_notes.model && delivery_notes.model.toLowerCase().includes(searchText))||
      (delivery_notes.chassis_no && delivery_notes.chassis_no.toLowerCase().includes(searchText))||
      (delivery_notes.customer_name && delivery_notes.customer_name.toLowerCase().includes(searchText))||
      (delivery_notes.delivery_by && delivery_notes.delivery_by.toLowerCase().includes(searchText))
    );
  }

}
