import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/shared/global.service';
import { CommitmentFeeRefundsService } from 'src/app/services/sales-management/commitment-fee-refunds.service';

@Component({
  selector: 'app-refund-commitment-fee',
  templateUrl: './refund-commitment-fee.component.html',
  styleUrls: ['./refund-commitment-fee.component.css']
})
export class RefundCommitmentFeeComponent implements OnInit{
  form!: FormGroup;
  submitted: boolean = false;
  public errorMessage: string | null = null;
  public reservation_id: string  = '';
  public customer_id: string | null = null;
  customer_refund: any;
  fileName: string="Choose an image or a PDF file";
  attachments:any;
  isProcessing: boolean = false;
  public formatter: any;
  public amount_to_refund!:string;
  retain_balance: boolean = false;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private globalService:GlobalService,
    private commitmentFeeRefundsService:CommitmentFeeRefundsService,
  ){
    this.form = this.formBuilder.group({
      amount_to_refund:[''],
      amount_refunded: ['', Validators.required],
      date_refunded: ['', Validators.required],
      attachments:[null],
      comment:[''],
      bal_retained:[null]
    });

    this.formatter = new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.customer_id = param['get']('customer_id')
      this.reservation_id = param['get']('reservation_id')
    })

    if(this.reservation_id){
      this.fetchCustomerNameAndRefundableAmount(this.reservation_id);
    }else{
      //Navigate back to refundable list with error
      this.globalService.setGlobalErrorMessage("Unable to select record for refund!");
      this.router.navigate(['commitment-fee-refund']);
    }
  }

  /**
   * Get customer name & refundable amount
   * @param reservation_id 
   */
  fetchCustomerNameAndRefundableAmount(reservation_id: string): void{
    this.commitmentFeeRefundsService.getCustomerNameAndRefundableAmount(reservation_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.customer_refund = response;
          this.amount_to_refund=this.formatter.format(this.customer_refund.data.refundable_amt);
          if(this.customer_refund.status==='no_data' || this.customer_refund.status.length===0){
            this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again.";
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  /**
   * Handle selectd file
   * @param event 
   */
  onFileSelected(event: any) {
    //Show selected files
    const fileInput = event.target as HTMLInputElement;
    const selectedFiles = fileInput.files;
  
    if (selectedFiles && selectedFiles.length > 0) {
      // Display count of selected files or the name if one file
      if(selectedFiles.length > 1){
        this.fileName = selectedFiles.length+" files selected";
      }else{
        const [file] = event.target.files;
        this.fileName =file.name;
      }
  
      // Prepare files for upload or further handling
      this.attachments = Array.from(selectedFiles);
    } else {
      this.fileName = 'Choose an image or a PDF file';
      this.attachments = null;
    }
  }

  /**
   * Show retain balance option
   * if there's balance
   */
  showRetainBalanceOption(){
    if(Number(this.form.value.amount_to_refund.replaceAll(",",""))>Number(this.form.value.amount_refunded.replaceAll(",",""))){
      this.retain_balance=true;
    }else{
      this.retain_balance=false;
    }
  }

  handleSubmit() {
    this.submitted = true;
    this.isProcessing=true;
    
    if (this.form.valid) {
      this.commitmentFeeRefundsService.postRefund(this.form.value,this.attachments,this.reservation_id)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.globalService.setGlobalSuccessMessage(response.message);
           
            // Redirect to refundable list
            this.router.navigate(['commitment-fee-refunds']);
          }else if(response.status==='upload_error'){
            this.submitted = false;
            this.globalService.setGlobalWarningMessage(response.message);
            // Redirect to refundable list
            this.router.navigate(['commitment-fee-refunds']);
          }else{
            this.submitted = false;
            this.isProcessing=false;
            this.globalService.setGlobalErrorMessage(response.message);
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isProcessing=false;
          this.globalService.setGlobalErrorMessage(error);
        }
      });
    }else{
      this.isProcessing=false;
    }
  }
}
