import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
import { PendingTasksService } from 'src/app/services/pending-tasks/pending-tasks.service';
import { PendingTasksHandler } from 'src/app/shared/pending-tasks-handler';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit{
  public at_cfs_chart: any;
  public in_stock_chart: any;
  public total_stock_chart: any;
  public available_makes_chart: any;
  public available_models_chart: any;
  noOfUnpaidDuties:number=0;
  noOfPendingEntries:number=0;
  noOfBlsPendingETAUpdatePmt:number=0;
  noOfVehiclesEnroute:number=0;
  public isLoading: boolean = false;
  public units_at_cfs: any[] | null = null;
  public units_in_stock: any[] | null = null;
  public total_stock: any[] | null = null;
  public stock_available_by_make: any[] = [];
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  branches: any[] = [];
  selectedBranch: string='';
  public branchId:string|null=null;
  public unpaid_duties: any[] = [];
  @ViewChild('AvailableModels') availableModelsModalElement!: ElementRef;
  public make_id:string='';
  public make_name:string='';
  @ViewChild('AvailableUnits') availableUnitsModalElement!: ElementRef;
  public model_id:string='';
  public model_name:string='';
  public stock_available_by_model: any[] = [];
  model_page: number = 1;
  model_count: number = 0;
  modelSearchText: string = '';
  public units_by_model: any[] = [];
  units_page: number = 1;
  units_count: number = 0;
  unitsSearchText: string = '';
  @ViewChild('OverdueUnshippedBLs') overdueUnshippedBLsModalElement!: ElementRef;
  public noOfOverdueUnshippedBLs:any=0;
  public userPermissions: string[] = [];

  constructor(
    private pendingTasksService: PendingTasksService,
    private pendingTasksHandler: PendingTasksHandler,
    private dashboardService: DashboardService,
    private globalService: GlobalService,
    private authService: AuthService,
    private router: Router,
  ){
    
  }

  ngOnInit(): void {
    this.isLoading=true;

    this.userPermissions=this.authService.getPermissions();

    this.pendingTasksService.noOfVehiclesEnroute$.subscribe((count) => {
      this.noOfVehiclesEnroute = count;
    });

    // Subscribe to the count updates
    this.pendingTasksService.noOfUnpaidDuties$.subscribe((count) => {
      this.noOfUnpaidDuties = count;
    });

    this.pendingTasksService.noOfBlsPendingETAUpdatePmt$.subscribe((count) => {
      this.noOfBlsPendingETAUpdatePmt = count;
    });

    this.pendingTasksService.noOfPendingEntries$.subscribe((count) => {
      this.noOfPendingEntries = count;
    });

    //Count vehicles en route
    this.pendingTasksHandler.numberOfVehiclesEnroute();

    this.countUnitsAtCFS();
    this.countUnitInStock();
    this.getTotalStockByLocation();
    this.getAvailableStockByMake();
    this.fetchBranches();
    this.unpaidDutiesByConsignee();

    /**
     * Check user has access to BL list and show
     * overdue unshipped BLs alert
     */
    if(this.userPermissions.includes("view-bl-list")){
      this.overdueUnshippedBLs();
    }
  }

  countUnitsAtCFS():void{
    this.dashboardService.countUnitsAtCFS()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.units_at_cfs = response.data;

          if (this.units_at_cfs.length > 0) {
            const chartLabels =this.units_at_cfs.map((item) => item.consignee_name);
            const chartData = this.units_at_cfs.map((item) => item.units_at_cfs);
  
            // Call the method to create the chart with dynamic data
            this.vehiclesAtCFSChart(chartLabels, chartData);
          }

          this.isLoading=false;
        }else if(response.status==='no_data'){
           this.isLoading=false;
        }else{
          const errorMessage="A problem was encountered while fetching vehicles at CFS. Please refresh the page and try again!";
          this.globalService.setGlobalWarningMessage(errorMessage);
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.globalService.setGlobalWarningMessage("Vehicles At CFS: "+error);
      }
    });
  }

  vehiclesAtCFSChart(labels: string[], data: number[]): void {
    this.at_cfs_chart = new Chart('VehiclesAtCFSChart', {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            borderWidth: 1,
          },
        ],
      },
      options: {
        onClick: (event, elements) => {
          if (elements.length > 0) {
            this.router.navigate(['stock-overview/stock-at-cfs']);
          }
        },
        plugins: {
          legend: {position: 'bottom',},
          title: {
              display: true,
              text: 'At CFS'
          }
        },
      },
    });
  }

  countUnitInStock():void{
    this.dashboardService.countUnitInStock()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.units_in_stock = response.data;

          if (this.units_in_stock.length > 0) {
            const chartLabels =this.units_in_stock.map((item) => item.consignee_name);
            const chartData = this.units_in_stock.map((item) => item.units_in_stock);
  
            // Call the method to create the chart with dynamic data
            this.vehiclesInStockChart(chartLabels, chartData);
          }

          this.isLoading=false;
        }else if(response.status==='no_data'){
           this.isLoading=false;
        }else{
          const errorMessage="A problem was encountered while fetching vehicles in stock. Please refresh the page and try again!";
          this.globalService.setGlobalWarningMessage(errorMessage);
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.globalService.setGlobalWarningMessage("Vehicles In Stock: "+error);
      }
    });
  }

  vehiclesInStockChart(labels: string[], data: number[]): void {
    this.in_stock_chart = new Chart('VehiclesInStock', {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            borderWidth: 1,
          },
        ],
      },
      options: {
        onClick: (event, elements) => {
          if (elements.length > 0) {
            this.router.navigate(['stock-overview/all-available-stock']);
          }
        },
        plugins: {
          legend: {position: 'bottom',},
          title: {
              display: true,
              text: 'In Stock'
          }
        },
      },
    });
  }
  
  getTotalStockByLocation():void{
    this.isLoading=true;

    this.dashboardService.totalStockByLocation()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.total_stock = response.data;

          if (this.total_stock.length > 0) {
            const chartLabels =this.total_stock.map((item) => item.location_name);
            const chartData = this.total_stock.map((item) => item.total_stock);
  
            // Call the method to create the chart with dynamic data
            this.totalStockChart(chartLabels, chartData);
          }

          this.isLoading=false;
        }else if(response.status==='no_data'){
           this.isLoading=false;
        }else{
          const errorMessage="A problem was encountered while fetching total stock. Please refresh the page and try again!";
          this.globalService.setGlobalWarningMessage(errorMessage);
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.globalService.setGlobalWarningMessage("Total Stock: "+error);
      }
    });
  }
    
  totalStockChart(labels: string[], data: number[]):void {
    this.total_stock_chart = new Chart('TotalStockChart', {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Stock Available',
            data: data,
            borderWidth: 1,
          },
        ],
      },
      options: {
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const chart = this.total_stock_chart;
            const index = elements[0].index;
            const label = chart.data.labels[index];
            
            if(label=='EN ROUTE'){
              this.router.navigate(['stock-overview/stock-en-route']);
            }else if(label=='CFS'){
              this.router.navigate(['stock-overview/stock-at-cfs']);
            }else{
              this.router.navigate(['stock-overview/all-available-stock']);
            }
          }
        },
      },
    });
  }

  getAvailableStockByMake():void{
    this.isLoading=true;

    this.dashboardService.countAvailableStockByMake()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.stock_available_by_make = response.data;

          this.isLoading=false;
        }else if(response.status==='no_data'){
           this.isLoading=false;
        }else{
          const errorMessage="A problem was encountered while fetching available stock by make. Please refresh the page and try again!";
          this.globalService.setGlobalWarningMessage(errorMessage);
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.globalService.setGlobalWarningMessage("Available Makes: "+error);
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
    this.page = 1; // Reset to page 1 after applying filter
  }

  onSelectChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {

      if(target.value!=='' && target.value!==undefined){
        this.branchId = target.value;
      }else{
        this.branchId = null;
      }
      
      if(this.branchId!=='' && this.branchId!==undefined){
        this.isLoading=true;
        this.getAvailableStockByMakeForBranch(this.branchId);
      }else{
        this.isLoading=true;
        this.getAvailableStockByMake();
      }
    }
  }

  fetchBranches():void {
    this.isLoading=true;
    
    this.dashboardService.getBranches()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.branches = response.data
          this.isLoading=false;
        }else if(response.status==='no_data'){
          const errorMessage="No branches found!";
          this.globalService.setGlobalWarningMessage(errorMessage);
          this.isLoading=false;
          this.branches = []; // Clear the branches
        }else{
          const errorMessage="Unable to load branches!";
          this.globalService.setGlobalWarningMessage(errorMessage);
          this.isLoading=false;
          this.branches = []; // Clear the branches
        }
      },
      error: (error) => {
        this.globalService.setGlobalErrorMessage("Fetching branches: "+error);
        this.isLoading=false;
        this.branches = []; // Clear the branches
      }
    });
  }

  getAvailableStockByMakeForBranch(branchId: string|null):void{
    this.dashboardService.countAvailableStockByMakeForBranch(branchId)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.stock_available_by_make = response.data;
          this.isLoading=false;
        }else if(response.status==='no_data'){
           this.isLoading=false;
        }else{
          const errorMessage="A problem was encountered while fetching available stock by make. Please refresh the page and try again!";
          this.globalService.setGlobalWarningMessage(errorMessage);
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.globalService.setGlobalWarningMessage("Available Makes: "+error);
      }
    });
  }

  unpaidDutiesByConsignee(){
    this.isLoading=true;

    this.dashboardService.getUnpaidDutiesByConsgnee()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.unpaid_duties = response.data;

          this.isLoading=false;
        }else if(response.status==='no_data'){
           this.isLoading=false;
        }else{
          const errorMessage="A problem was encountered while fetching unpaid duties. Please refresh the page and try again!";
          this.globalService.setGlobalWarningMessage(errorMessage);
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.globalService.setGlobalWarningMessage("Unpaid duties: "+error);
      }
    });
  }

  openAvailableModelsModal(make_id:string,make_name:string){
    this.make_id=make_id;
    this.make_name=make_name;

    this.getAvailableStockByModel(this.make_id,this.branchId);
  }

  closeAvailableModelsModal():void {
    if (this.availableModelsModalElement) {
      (<any>$(this.availableModelsModalElement.nativeElement)).modal('hide');
    }
  }

  openAvailableUnitsModal(model_id:string,model_name:string){
    this.model_id=model_id;
    this.model_name=model_name;

    this.getUnitsAvailableByModel(this.model_id,this.branchId);
  }

  closeAvailableUnitsModal():void {
    if (this.availableUnitsModalElement) {
      (<any>$(this.availableUnitsModalElement.nativeElement)).modal('hide');
    }
  }

  getAvailableStockByModel(make_id:string,branchId:string|null):void{
    this.isLoading=true;

    this.dashboardService.countAvailableStockByModel(make_id,branchId)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.stock_available_by_model = response.data;
          this.isLoading=false;

          //Show modal
          if (this.availableModelsModalElement) {
            (<any>$(this.availableModelsModalElement.nativeElement)).modal('show');
          }
        }else if(response.status==='no_data'){
          this.isLoading=false;
          this.globalService.setGlobalWarningMessage("No models available!");
        }else{
          const errorMessage="A problem was encountered while counting available stock by model. Please refresh the page and try again!";
          this.globalService.setGlobalWarningMessage(errorMessage);
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.globalService.setGlobalWarningMessage("Available Models: "+error);
      }
    });
  }

  modelPageChangeEvent(event: number){
    this.model_page = event;
  }

  modelOnSearchTextChange(event: Event) {
    this.modelSearchText = (event.target as HTMLInputElement).value;
    this.model_page = 1; // Reset to page 1 after applying filter
  }

  getUnitsAvailableByModel(model_id:string,branchId:string|null):void{
    this.isLoading=true;

    this.dashboardService.getUnitsByModel(model_id,branchId)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.units_by_model = response.data;
          this.isLoading=false;

          //Show modal
          if (this.availableUnitsModalElement) {
            (<any>$(this.availableUnitsModalElement.nativeElement)).modal('show');
          }
        }else if(response.status==='no_data'){
          this.isLoading=false;
          this.globalService.setGlobalWarningMessage("No units available under the selected model!");
        }else{
          const errorMessage="A problem was encountered while fetching available stock by model. Please refresh the page and try again!";
          this.globalService.setGlobalWarningMessage(errorMessage);
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.globalService.setGlobalWarningMessage("Available Units: "+error);
      }
    });
  }

  unitsPageChangeEvent(event: number){
    this.units_page = event;
  }

  unitsOnSearchTextChange(event: Event) {
    this.unitsSearchText = (event.target as HTMLInputElement).value;
    this.units_page = 1; // Reset to page 1 after applying filter
  }

  overdueUnshippedBLs():void
  {
    this.isLoading=true;

    this.dashboardService.countOverdueUnshippedBLs()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.noOfOverdueUnshippedBLs = response.data;
          this.isLoading=false;
          
          //Show modal
          if (this.overdueUnshippedBLsModalElement) {
            (<any>$(this.overdueUnshippedBLsModalElement.nativeElement)).modal('show');
          }
        }else{
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.globalService.setGlobalWarningMessage("Overdue Unshipped BLs: "+error);
      }
    });
  }

  closeOverdueUnshippedBLsModal():void {
    if (this.overdueUnshippedBLsModalElement) {
      (<any>$(this.overdueUnshippedBLsModalElement.nativeElement)).modal('hide');
    }
  }

  viewOverdueUnshippedBLs():void {
    //Close Modal
    this.closeOverdueUnshippedBLsModal();
    //Navigate to overdue unshipped BLs
    this.router.navigate(['overdue-unshipped-bls']);
  }
}
