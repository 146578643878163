<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
           
            <div class="col-sm-6">
              <a routerLink="/enter-bl" class="btn btn-success pull-left" class="btn btn-success"><i
                class="fa fa-plus-circle" aria-hidden="true">&nbsp;</i>Enter BL</a>
            </div><!-- /.col -->
           
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item active">Imported Cars</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <hr>
    <div class="row">
      <div class="col-md-8">
          <div class="row">
            <div class="col">
              <div class="col-md-4">
                <form class="form-inline">
                  <div class="form-group">
                      <label>Consignee:</label>&nbsp;
                      <select name="consignee_id"  class="form-control" (change)="onSelectChange($event.target)">
                          <option value="">All</option>
                          <option [value]="consignee.id" *ngFor="let consignee of consignees">{{ consignee.consignee_name }}</option>
                      </select>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <label>Agent:</label>&nbsp;
                      <select name="agents" class="form-control" (change)="onAgentSelectChange($event.target)">
                        <option value="">All</option>
                        <option [value]="agent.id" *ngFor="let agent of agents">{{ agent.company_name }}</option>
                      </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
      <div class="col-md-4">
        <div class="row">
            <div class="col">
                <input type="text" class="form-control" placeholder="Type Chassis No. here to search" (input)="onSearchTextChange($event)">
            </div>
        </div>
      </div>
    </div>
    <hr>
    <small><b>Filter by Date Of Arrival</b></small>
    <form [formGroup]="etaFilter" (ngSubmit)="handleSubmit()">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
              <label>From:<span style="color:red;">*</span></label>
              <input type="date" formControlName="eta_from" class="form-control" placeholder="Date From" value="">

              <span *ngIf="submitted && etaFilter.get('eta_from')?.hasError('required')" style="color:red"> <strong>Please enter or select starting date!</strong></span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
              <label>To:<span style="color:red;">*</span></label>
              <input type="date" formControlName="eta_to" class="form-control" placeholder="Date TO" value="">

              <span *ngIf="submitted && etaFilter.get('eta_to')?.hasError('required')" style="color:red"> <strong>Please enter or select ending date!</strong></span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group" style="margin-top: 30px;">
            <button type="submit" class="btn btn-outline-success"><i class="fas fa-filter" aria-hidden="true">&nbsp;</i>Filter</button>
          </div>
        </div>
      </div>
    </form>
    <hr>
    </section>
      <!-- /.content-header -->
    <section class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Imported Cars&nbsp;&nbsp;&nbsp;Count: {{rowCount}}</h3>
            <!--Error Message-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
              {{errorMessage}}
            </div>
            <!--Success Message-->
            <div class="alert alert-success" role="alert" *ngIf="successMessage">
              {{successMessage}}
            </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <table id="example1" class="table table-bordered table-hover">
              <thead>
                <tr>
                    <th>BL No.</th>
                    <th>Make</th>
                    <th>Model</th>
                    <th>Engine CC</th>
                    <th>Engine No.</th>
                    <th>Chassis No.</th>
                    <th>Colour</th>
                    <th>Date Of Arrival</th>
                    <th>Agent</th>
                    <th></th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let vehicle of importedVehicles | searchVehicle: searchText| paginate: { itemsPerPage: 100, currentPage: page, totalItems: count }">
                    <td>{{vehicle.bl_no}}</td>
                    <td>{{vehicle.make}}</td>
                    <td>{{vehicle.model}}</td>
                    <td>{{vehicle.engine_cc}}</td>
                    <td>{{vehicle.engine_no}}</td>
                    <td>{{vehicle.chassis_no}}</td>
                    <td>{{vehicle.colour}}</td>
                    <td>{{vehicle.vessel_eta| date: 'dd-MM-yyyy'}}</td>
                    <td>{{vehicle.agent_name}}</td>
                    <td><a routerLink="/add-entry/{{ vehicle.vehicle_id }}" class="btn btn-success btn-sm active" role="button" aria-pressed="true"><i
                      class="fa fa-plus-circle" aria-hidden="true">&nbsp;</i>Add Entry</a></td>
                  </tr>
              </tbody>
            </table>
            <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </section>