import { Component, OnInit } from '@angular/core';
import { GradeService } from 'src/app/services/grades/grade.service';
import { ApiResponse } from 'src/app/models/IApiResponse';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-grade-list',
  templateUrl: './grade-list.component.html',
  styleUrls: ['./grade-list.component.css']
})
export class GradeListComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  grades: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public isProcessing: boolean = false;

  constructor(
    private gradeService:GradeService,
  ){}

  ngOnInit(): void {
    this.fechVehicleGrades();
  }

  fechVehicleGrades():void {
    this.gradeService.getAllGrades().subscribe((response: ApiResponse) =>this.grades = response.data);
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fechVehicleGrades();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  deleteModel(event: Event,gradeId:string){
    event.preventDefault();
    this.confirmBox(gradeId);
  }

  confirmBox(gradeId: string){
    Swal.fire({
      title: 'Are you sure you want to delete this user account?',
      text: 'The action is irreversible!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.isProcessing=true;

        this.gradeService.deleteGrade(gradeId)
        .subscribe({
          next: (response) => {
            if(response.status==='success'){
              this.isProcessing=false;
              this.successMessage=response.message;
              this.errorMessage=''; //Clear error message incase it's available
             
              //Reload grades
              this.fechVehicleGrades();
            }else if(response.status==='error'){
              this.isProcessing=false;
              this.errorMessage=response.message;
            }else{
              this.isProcessing=false;
              this.errorMessage=response.message;
            }
          },
          error: (error) => {
            this.isProcessing=false;
            this.errorMessage=error;
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      
      }
    })
  }

}
