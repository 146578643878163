import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute,Params,Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { BankAccountsService } from 'src/app/services/bank-accounts/bank-accounts.service';
import { ConsigneeService } from 'src/app/services/consignees/consignee.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-edit-bank-account',
  templateUrl: './edit-bank-account.component.html',
  styleUrls: ['./edit-bank-account.component.css']
})
export class EditBankAccountComponent implements OnInit{
  form!: FormGroup;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  isProcessing: boolean = false;
  submitted: boolean = false;
  consignees: any[] = [];
  bank_account_id:any;
  bankAccount: any;

  constructor(
    private bankAccountsService:BankAccountsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private globalService: GlobalService,
    private consigneeService: ConsigneeService,
    private activatedRoute: ActivatedRoute,
  ){
    this.form = this.formBuilder.group({
      bank_name: ['', Validators.required],
      account_no: ['', Validators.required],
      consignee_id: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.bank_account_id = param['get']('id')
    })

    if(this.bank_account_id){
      this.fetchBankAccountDetails(this.bank_account_id);
      this.getConsignees();
    }else{
      //Navigate back to list with error
      this.globalService.setGlobalErrorMessage("Unable to select record for editing!");
      this.router.navigate(['settings/bank-accounts-list']);
    }
  }

  fetchBankAccountDetails(bank_account_id:any):void {
    this.bankAccountsService.getBankAccountDetails(bank_account_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.bankAccount = response;
    
          if(this.bankAccount.status==='no_data' || this.bankAccount.status.length===0){
            this.errorMessage=this.bankAccount.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  getConsignees(): void {
    this.consigneeService.getAllConsignees().subscribe((response: ApiResponse)=>this.consignees=response.data);
  }

  handleSubmit():void{
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      const formData = {
        ...this.form.value,
      };

      this.bankAccountsService.updateBankAccount(formData,this.bank_account_id).subscribe(response => {
        if(response.status==='success'){

          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalSuccessMessage(response.message);
         
          // Redirect back to makes list
          this.router.navigate(['settings/bank-accounts-list']);
        }else if(response.status==='error'){
          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalErrorMessage(response.message);
        }else{
          this.submitted=false;
          this.isProcessing = false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
        }
      },
      error => {
        this.isProcessing = false;
        this.errorMessage=error;
      });
    }else{
      this.isProcessing = false;
    }
  }
}
