import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchRole'
})
export class SearchRolePipe implements PipeTransform {

  transform(roles: any, searchText: string){
    
    if (!searchText) {
      return roles; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return roles.filter((roles: any) => roles.name.toLowerCase().includes(searchText));
  }

}
