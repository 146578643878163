import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchBl'
})
export class SearchBlPipe implements PipeTransform {

  transform(billsOfLading: any, searchText: string){
    
    if (!searchText) {
      return billsOfLading; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison

    return billsOfLading.filter((billsOfLading: any) => 
      (billsOfLading.bl_no && billsOfLading.bl_no.toLowerCase().includes(searchText))||
      (billsOfLading.consignee_name && billsOfLading.consignee_name.toLowerCase().includes(searchText))||
      (billsOfLading.agent_name && billsOfLading.agent_name.toLowerCase().includes(searchText))||
      (billsOfLading.vessel && billsOfLading.vessel.toLowerCase().includes(searchText))
    );
  }

}
