import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { ConsigneeService } from 'src/app/services/consignees/consignee.service';
import { CfsService } from 'src/app/services/cfs/cfs.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { BillsOfLadingService } from 'src/app/services/bills-of-lading/bills-of-lading.service';
import { IBillOfLading } from 'src/app/models/IBillOfLading';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-new-bl',
  templateUrl: './add-new-bl.component.html',
  styleUrls: ['./add-new-bl.component.css']
})
export class AddNewBlComponent implements OnInit{
  public billOfLading: IBillOfLading = {} as IBillOfLading;

  public errorMessage: string | null = null;
  public infoMessage: string | null = null;
  consignees: any[] = [];
  cfses: any[] = [];
  form!: FormGroup;
  submitted: boolean = false;
  isProcessing: boolean = false;
  fileName: string="Choose an image or a PDF file";
  attachment: any;

  constructor(
    private consigneeService: ConsigneeService, 
    private cfsService: CfsService, 
    private billsOfLadingService: BillsOfLadingService, 
    private globalService: GlobalService,
    private router: Router,
    private formBuilder: FormBuilder,
    ){
      this.form = this.formBuilder.group({
        bl_no: ['', Validators.required],
        consignee_id: ['', Validators.required],
        no_of_units: ['', Validators.required],
        cfs_id: ['', Validators.required],
        vessel: ['', Validators.required],
        bl_date: ['', Validators.required],
        attachment:[null,Validators.required]
      });
    }

  ngOnInit(): void {
     //Get Consignees
     this.getConsignees();

     //Get CFSes
     this.getCFSes();
  }

   /**
   * Handle selectd file
   * @param event 
   */
   onFileSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.fileName = fileInput.files?.[0]?.name ?? 'Choose an image or a PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.attachment = file
    }
  }

  handleSubmit() {
    this.submitted = true;
    this.isProcessing = true;
    this.errorMessage=null;
    
    if (this.form.valid) {
      this.billsOfLadingService.createBL(this.form.value,this.attachment)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.isProcessing = false;
            this.globalService.setGlobalSuccessMessage(response.message);
            // Redirect to BL list
            this.router.navigate(['suppliers/bl-list']);
          }else{
            this.errorMessage=response.message;
            this.infoMessage=null;
            this.isProcessing = false;
          }
        },
        error: (error) => {
          this.errorMessage=error;
          this.infoMessage=null;
          this.isProcessing = false;
        }
      });
    }
  }

  getConsignees(): void {
    this.consigneeService.getAllConsignees().subscribe((response: ApiResponse)=>this.consignees=response.data);
  }

  getCFSes(): void {
    this.cfsService.getAllCFSes().subscribe((response: ApiResponse)=>this.cfses=response.data);
  }
}
