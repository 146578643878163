import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'endsWith'
})
export class EndsWithPipe implements PipeTransform {

  transform(value: string, ending: string): boolean {
    if (typeof value !== 'string') {
      return false;
    }
    return value.endsWith(ending);
  }

}
