import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { VehiclesService } from 'src/app/services/vehicles/vehicles.service';
import { VehicleReservationService } from 'src/app/services/sales-management/vehicle-reservation.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-view-vehicle-reservations',
  templateUrl: './view-vehicle-reservations.component.html',
  styleUrls: ['./view-vehicle-reservations.component.css']
})
export class ViewVehicleReservationsComponent implements OnInit{
  @ViewChild('editCommentModal') modalElement!: ElementRef;
  form!: FormGroup;
  submitted: boolean = false;

  public vehicle_id: string | null = null;
  public errorMessage: string | null = null;
  public isProcessing: boolean = false;
  public isLoading: boolean = false;
  public vehicleDetails:any;
  public reservations: any[] = [];
  public reservation_id:string | null = null;
  public modalData: any;
  public comment: string | null = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private vehiclesService: VehiclesService,
    private vehicleReservationService: VehicleReservationService,
    private formBuilder: FormBuilder,
  ){
    this.form = this.formBuilder.group({
      comment:['',Validators.required]
    });
  }

  ngOnInit(): void {
    this.isLoading=true;

    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.vehicle_id = param['get']('vehicle_id')
    })

    if(this.vehicle_id){
      this.fetchVehicleDetails(this.vehicle_id);
    }else{
      //Navigate back to vehicle list with error
      this.globalService.setGlobalErrorMessage("Unable to select Vehicle details!");
      this.router.navigate(['vehicles']);
    }
  }

  fetchVehicleDetails(vehicleId:string):void {
    this.vehiclesService.getVehicle(vehicleId)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
          this.isLoading=false;
        }else{
          this.vehicleDetails = response;
          this.fetchPreviousReservations(vehicleId);
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  fetchPreviousReservations(vehicleId:string|null): void {
    this.vehicleReservationService.getPreviousReservations(vehicleId||'')
    .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.isLoading = false;
            this.reservations = response.data
          }else{
            this.isLoading = false;
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.errorMessage=error;
        }
      });
  }

  markVehicleAsSold(vehicle_id: string|null,reservation_id: number) :void {
    
    this.isLoading=true;
    
    this.vehicleReservationService.markReservedVehicleAsSold(vehicle_id,reservation_id)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.isLoading = false;
          this.globalService.setGlobalSuccessMessage(response.message);
          this.router.navigate(['vehicles']);
        }else{
          this.isLoading = false;
          this.errorMessage=response.message;
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.errorMessage=error;
      }
    });
  }

  openModal(reservationId:string): void {
    this.reservation_id=reservationId;
    this.isLoading=true;

    this.vehicleReservationService.getComment(reservationId)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
          this.isLoading=false;
        }else{
          this.isLoading=false;
          this.modalData = response;
          this.comment=this.modalData.data.comment;
          
          if (this.modalElement) {
            (<any>$(this.modalElement.nativeElement)).modal('show');
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  closeModal():void {
    if (this.modalElement) {
      (<any>$(this.modalElement.nativeElement)).modal('hide');
    }
  }

  updateComment(): void {
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      const formData = {
        reservation_id: this.reservation_id,
        ...this.form.value,
      };

      this.vehicleReservationService.updateComment(formData).subscribe(response => {
        if(response.status==='success'){

          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalSuccessMessage(response.message);
          this.closeModal();

          // Reload vehicle reservations
          this.fetchPreviousReservations(this.vehicle_id);
        }else if(response.status==='no_changes'){
          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalWarningMessage(response.message);
          this.closeModal();
        }else{
          this.submitted=false;
          this.isProcessing = false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
          this.closeModal();
        }
      },
      error => {
        this.errorMessage=error;
        this.isProcessing = false;
      });
    }
  }
}
