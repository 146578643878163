<!--Loading Spinner-->
  <div *ngIf="isLoading">
    <app-spinner></app-spinner>
  </div>
  
  <!-- Content Header (Page header) -->
  <section class="content-header">
      <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
             
              <div class="col-sm-6">
                
              </div><!-- /.col -->
             
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                  <li class="breadcrumb-item active">Update Entry Attachments</li>
                </ol>
              </div><!-- /.col -->
            </div><!-- /.row -->
          </div><!-- /.container-fluid -->
      </div>
    
      <div class="row">
        <div class="col-md-8">
            <div class="row">
               
            </div>
        </div>
        <div class="col-md-4">
          <div class="row">
              <div class="col">
                  <input type="text" class="form-control" placeholder="Search by Make, Model, Chassis No., Entry No., Document Ref No., Ref No." (input)="onSearchTextChange($event)">
              </div>
          </div>
        </div>
      </div>
      </section>
        <!-- /.content-header -->
      <section class="content">
        <div class="container-fluid">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Update Entry Attachments</h3>
              <!--Error Message-->
              <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
              </div>
              <!--Success Message-->
              <div class="alert alert-success" role="alert" *ngIf="successMessage">
                {{successMessage}}
              </div>
               <!--Success Message-->
              <div class="alert alert-warning" role="alert" *ngIf="warningMessage">
                {{warningMessage}}
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
              <table id="example1" class="table table-bordered table-hover">
                <thead>
                  <tr>
                      <th>BL No.</th>
                      <th>Make</th>
                      <th>Model</th>
                      <th>Chassis No.</th>
                      <th>Entry No.</th>
                      <th>Entry Date</th>
                      <th>Duty Amount</th>
                      <th>Document Ref No.</th>
                      <th>Reference No.</th>
                      <th>Transaction Type</th>
                      <th></th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let entry of entryAttachments| searchEditableEntries: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                      <td>{{entry.bl_no}}</td>
                      <td>{{entry.make_name}}</td>
                      <td>{{entry.model_name}}</td>
                      <td>{{entry.chassis_no}}</td>
                      <td>{{entry.entry_no}}</td>
                      <td>{{entry.entry_date| date:'dd-MM-yyyy'}}</td>
                      <td>{{entry.amount_paid| currency:' '}}</td>
                      <td>{{entry.doc_ref_no}}</td>
                      <td>{{entry.pmt_ref_no}}</td>
                      <td *ngIf="entry.doc_ref_no | endsWith:'-1'; else notEndingWith">Entry</td>
                      <ng-template #notEndingWith>
                        <td>Additional Duty</td>
                      </ng-template>
                      <td>
                        <button type="button" class="btn btn-success btn-sm" (click)="viewEntryAndPaymentSlip(entry.entry_id,entry.txn_id)"><i
                          class="fas fa-eye" aria-hidden="true">&nbsp;</i>View</button>
                      </td>
                      <td *ngIf="entry.doc_ref_no | endsWith:'-1'; else isAdditionalDuty">
                        <a (click)="openModal(entry.txn_id)" class="btn btn-success btn-sm" role="button" aria-pressed="true"><i
                        class="fa fa-paperclip" aria-hidden="true">&nbsp;</i>Update</a>
                      </td>
                      <ng-template #isAdditionalDuty>
                        <td>
                          <a (click)="openAdditionalDutyModal(entry.txn_id)" class="btn btn-success btn-sm" role="button" aria-pressed="true"><i
                          class="fa fa-paperclip" aria-hidden="true">&nbsp;</i>Update</a>
                        </td>
                      </ng-template>
                    </tr>
                </tbody>
              </table>
              <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </section>
  
       <!-- Modal -->
       <div class="modal fade" #UpdateEntryAttachmentsModal tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Update Entry Attachments</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
  
                  <!--Error message-->
                  <div class="alert alert-danger" role="alert" *ngIf="popupErrorMessage">
                      {{popupErrorMessage}}
                  </div>
                  <!--Success message-->
                  <div class="alert alert-success" role="alert" *ngIf="popupSuccessMessage">
                      {{popupSuccessMessage}}
                  </div>
  
                   <!--Warning message-->
                  <div class="alert alert-warning" role="alert" *ngIf="popupWarningMessage">
                      {{popupWarningMessage}}
                  </div>
  
                  <form [formGroup]="form" (ngSubmit)="handleSubmit()">
                    <div class="modal-body">
                      <div class="container-fluid">
                        <div class="row clearfix">
                          <div class="col-md-6">
                            <div class="form-group">
                                <label>Attach Entry (PDF Only):<span style="color:red;">*</span></label>
                                <div class="input-group mb-3">
                                    <div class="custom-file">
                                      <input type="file" formControlName="entry_attachment" accept="application/pdf" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onEntryFileSelected($event)">
                                      <label class="custom-file-label" for="inputGroupFile01">{{ entryFileName }}</label>
                                    </div>
                                </div>
    
                                <span *ngIf="submitted && form.get('entry_attachment')?.hasError('required')" style="color:red"> <strong>Please attach Entry!</strong></span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Attach Payment Slip (PDF Only):<span style="color:red;">*</span></label>
                                <div class="input-group mb-3">
                                    <div class="custom-file">
                                      <input type="file" formControlName="pmt_slip_attachment" accept="application/pdf" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onPaymentSlipFileSelected($event)">
                                      <label class="custom-file-label" for="inputGroupFile01">{{ pmtSlipFileName }}</label>
                                    </div>
                                </div>
    
                                <span *ngIf="submitted && form.get('pmt_slip_attachment')?.hasError('required')" style="color:red"> <strong>Please attach Payment Slip!</strong></span>
                            </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  <div class="modal-footer">
                      <button type="button" #closeModal class="btn btn-danger" data-dismiss="modal"><i class="fa fa-window-close" aria-hidden="true"></i>&nbsp;Cancel</button>
  
                      <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Processing...
                      </button>
  
                      <ng-template #showSaveBtn>
                          <button type="submit" class="btn btn-success" ><i class="fa fa-upload"
                              aria-hidden="true">&nbsp;</i>Upload</button>
                      </ng-template>
                  </div>
                  </form>
              </div>
          </div>
      </div>

      <!-- Additional Duty Payment Slip Modal -->
      <div class="modal fade" #UpdateAdditionalDutyPaymentSlipModal tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Update Additional Duty Payment Slip</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!--Error message-->
                <div class="alert alert-danger" role="alert" *ngIf="popupErrorMessage">
                    {{popupErrorMessage}}
                </div>
                <!--Success message-->
                <div class="alert alert-success" role="alert" *ngIf="popupSuccessMessage">
                    {{popupSuccessMessage}}
                </div>

                 <!--Warning message-->
                <div class="alert alert-warning" role="alert" *ngIf="popupWarningMessage">
                    {{popupWarningMessage}}
                </div>

                <form [formGroup]="additionalDutyForm" (ngSubmit)="updateAdditionalDutyPmtSlipAttachment()">
                  <div class="modal-body">
                    <div class="container-fluid">
                      <div class="row clearfix">
                      <div class="col-md-6">
                          <div class="form-group">
                              <label>Attach Payment Slip (PDF Only):<span style="color:red;">*</span></label>
                              <div class="input-group mb-3">
                                  <div class="custom-file">
                                    <input type="file" formControlName="pmt_slip_attachment" accept="application/pdf" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onPaymentSlipFileSelected($event)">
                                    <label class="custom-file-label" for="inputGroupFile01">{{ pmtSlipFileName }}</label>
                                  </div>
                              </div>
  
                              <span *ngIf="submitted && additionalDutyForm.get('pmt_slip_attachment')?.hasError('required')" style="color:red"> <strong>Please attach Payment Slip!</strong></span>
                          </div>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                      <button type="button" #closeAdditionalDutyModal class="btn btn-danger" data-dismiss="modal"><i class="fa fa-window-close" aria-hidden="true"></i>&nbsp;Cancel</button>

                      <button class="btn btn-success" type="button" *ngIf="isProcessing; else showAddtlDutySlipUploadBtn" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Processing...
                      </button>

                      <ng-template #showAddtlDutySlipUploadBtn>
                          <button type="submit" class="btn btn-success" ><i class="fa fa-upload"
                              aria-hidden="true">&nbsp;</i>Upload</button>
                      </ng-template>
                  </div>
                </form>
            </div>
        </div>
    </div>