<div class="container-fluid">

    <div class="row vertical-center">
        <form (ngSubmit)="submit()" class="col-xs-8 col-xs-offset-2  col-sm-6 col-sm-offset-3 col-md-4 col-sm-offset-4 col-lg-2 col-lg-offset-5 login-form">
            <h2 class="row justify-content-center" style="color:green;">Al-Husnain Motors Ltd</h2>

            <h4 style="color:red;">Welcome</h4>

            <div class="alert alert-danger" role="alert" *ngIf="error">
                {{error}}
            </div>

            <div class="form-group">
                <label for="username">Username</label>
                <input type="text" name="username" class="form-control" placeholder="My Username" [(ngModel)]="username" />
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input type="password" name="password" class="form-control" placeholder="My Password" [(ngModel)]="password" />
            </div>
            <div class="form-group">
                <button class="btn btn-success btn-block" type="button" *ngIf="isProcessing; else showSignInBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>

                <ng-template #showSignInBtn>
                    <button class="btn btn-success btn-block" type="submit">Sign In</button>
                </ng-template>
            </div>
        </form>
    </div>
</div>