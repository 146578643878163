import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class TaxSettingsService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient, private errorHandler: ConfigErrorHandler) { }

   /**
   * Get tax settings for update
   * @returns Array
   */
   getTaxSettings(){

    let apiURL:string=`${this.baseURL}/v1/get-tax-settings`;

    return this.httpClient.get(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Update tax settings
   * @param taxData 
   * @returns array
   */
  updateTaxSettings(taxData:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/update-tax-settings`;

    return this.httpClient.post(apiURL,taxData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
