import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { VehiclesService } from 'src/app/services/vehicles/vehicles.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { LocationService } from 'src/app/services/locations/location.service';
import { BranchService } from 'src/app/services/branches/branch.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.css']
})
export class VehicleListComponent implements OnInit{
  public userPermissions: string[] = [];
  page: number = 1;
  count: number = 0;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public warningMessage: string | null = null;
  vehicles: any[] = [];
  searchText: string = '';
  public isInProgress: boolean = false;
  locations: any[] = [];
  branches: any[] = [];
  selectedLocation: string='';
  selectedBranch: string='';
  selectedVehicles: any[] = []; // Array to store selected vehicles
  form!: FormGroup;
  submitted: boolean = false;
  @ViewChild('UpdatePriceModal') modalElement!: ElementRef;
  public vehicle_id:string='';

  constructor(
    private vehiclesService: VehiclesService,
    private authService: AuthService,
    private locationService:LocationService,
    private branchService:BranchService,
    private globalService: GlobalService,
    private formBuilder: FormBuilder
  ){
    this.form = this.formBuilder.group({
      new_price:['',Validators.required]
    });
  }

  ngOnInit(): void {
    this.isInProgress=true;
    this.fetchVehicles();
    this.fetchLocations();
    this.userPermissions=this.authService.getPermissions();
  }

  fetchVehicles():void{
    this.vehiclesService.getAllVehicles()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicles = response.data;
          this.isInProgress=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
    this.page = 1; // Reset to page 1 after applying filter
  }

  markVehicleAsSold(vehicle_id: string) :void {
    this.isInProgress=true;
    
    this.vehiclesService.markVehicleAsSold(vehicle_id)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.isInProgress = false;
          this.successMessage=response.message;
        }else{
          this.isInProgress = false;
          this.errorMessage=response.message;
        }
      },
      error: (error) => {
        this.isInProgress = false;
        this.errorMessage=error;
      }
    });
  }

  //Load branches when the location is selected
  onSelectChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {

      const locationId = target.value;
      
      if(locationId!=='' && locationId!==undefined){
        this.isInProgress=true;
        this.fetchBranchesByLocation(locationId);
      }else{
        this.branches = []; // Clear the branches
      }
    }
  }

  fetchLocations():void{
    this.locationService.getAllLocations().subscribe((response: ApiResponse) =>this.locations = response.data);
  }

  fetchBranchesByLocation(locationId: string):void {
    this.branchService.getBranchesByLocation(locationId)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.branches = response.data
          this.isInProgress=false;
          this.errorMessage=null;
        }else if(response.status==='no_data'){
          this.errorMessage="No branches found within the selected location!";
          this.isInProgress=false;
          this.branches = []; // Clear the branches
        }else{
          this.errorMessage="Unable to load branches within the selected location. Please try again or consult your system administrator!";
          this.isInProgress=false;
          this.branches = []; // Clear the branches
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
        this.branches = []; // Clear the branches
      }
    });
  }

  // Function to handle checkbox change event
  checkboxChange(event: any, item: any) {
    if (event.target.checked) {
      // Add checked checkbox to the array
      this.selectedVehicles.push(item);
    } else {
      // Remove unchecked checkbox from the array
      const index = this.selectedVehicles.indexOf(item);
      if (index !== -1) {
        this.selectedVehicles.splice(index, 1);
      }
    }
  }

  transferVehicles(): void {
    const locationId = this.selectedLocation;
    const branchId = this.selectedBranch;

    if(locationId=="" || locationId==undefined){

      this.errorMessage="Please select location to proceed!";
    
    }else if(branchId=="" || branchId==undefined){
      
      this.errorMessage="Please select branch to proceed!";

    }else if(this.selectedVehicles.length===0){

      this.errorMessage="Please select vehicles to transfer!";

    }else{
      this.errorMessage=null;
      this.isInProgress=true;
      this.vehiclesService.transferVehicles(locationId, branchId, this.selectedVehicles)
      .subscribe(
        response => {
          if(response.status==='success'){
            this.successMessage=response.message;
            //Clear vehicles array
            this.selectedVehicles.length = 0;
            // Refresh to Vehicle list to remove checks
            this.fetchVehicles();
          }else if(response.status==='warning'){
            this.warningMessage=response.message;
            this.isInProgress=false;
          }else{
            this.errorMessage=response.message;
            this.isInProgress=false;
          }
        },
        error => {
          // Handle the error
          this.isInProgress=false;
          this.errorMessage="An Error occurred while transferring vehicle(s). Please try again!";
        }
      );
    }
  }

  openModal(vehicle_id:string,price:string){
    this.vehicle_id=vehicle_id;
   
    // Use patchValue to set the value of new_price form control
    this.form.patchValue({
      new_price: price
    });
    
    if (this.modalElement) {
      (<any>$(this.modalElement.nativeElement)).modal('show');
    }
  }

  closeModal():void {
    if (this.modalElement) {
      (<any>$(this.modalElement.nativeElement)).modal('hide');
    }
  }

  updatePrice():void {
    this.submitted = true;
    this.isInProgress=true;

    if (this.form.valid) {
      this.vehiclesService.updateVehiclePrice(this.form.value,this.vehicle_id)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.isInProgress=false;
            this.globalService.setGlobalSuccessMessage(response.message);
            this.closeModal();
            // Refresh to Vehicle list
            this.fetchVehicles();
          }else{
            this.submitted = false;
            this.isInProgress=false;
            this.errorMessage=response.message;
            this.closeModal();
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isInProgress=false;
          this.errorMessage=error;
          this.closeModal();
        }
      });
    }else{
      this.isInProgress=false;
    }
  }
}
