<div class="row">
    <div class="col-md-11">
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Print Stock</h3>
                    </div>
                      <!-- /.card-header -->
                    <div class="card-body" style="overflow-x: auto; white-space: nowrap;">
                        <table class="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th *ngFor="let column of columns" [hidden]="visibleColumns[column]">
                                        {{ column }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of data">
                                    <td *ngFor="let column of columns" [hidden]="visibleColumns[column]">
                                        {{ row[column] }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="col-md-1 no-print">
        <div>
            <app-column-toggle [columns]="columns" (columnToggled)="toggleColumn($event)"></app-column-toggle>
        </div>
    </div>
</div>