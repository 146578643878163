<!--Loading Spinner-->
<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
<!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Add Other Vehicle Charges</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/vehicles"><i class="fa fa-list-ul"></i>&nbsp;Vehicles</a></li>
               <li class="breadcrumb-item active">Add Other Vehicle Charges</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
        <div class="card-header">
            <h3 class="card-title">Add Other Vehicle Charges</h3>
            <!--Error message-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
            </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="handleSubmit()">
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Make:</label>
                        <input  type="text" name="make" class="form-control" placeholder="Make" [value]="this.vehicle?.data?.make" readonly>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Model:</label>
                        <input type="text" name="model" class="form-control" placeholder="Model" [value]="this.vehicle?.data?.model" readonly>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Chassis No.:</label>
                        <input type="text" name="chassis_no" class="form-control" placeholder="Chassis No." [value]="this.vehicle?.data?.chassis_no" readonly>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Payment Date:<span style="color:red;">*</span></label>
                        <input type="date" formControlName="payment_date" class="form-control" placeholder="Payment Date" value="">

                        <span *ngIf="submitted && form.get('payment_date')?.hasError('required')" style="color:red"> <strong>Please enter or select payment date!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                      <label>Payment Mode:<span style="color:red;">*</span></label>
                      <select formControlName="payment_mode" class="form-control">
                          <option value="">Select</option>
                          <option value="Cash">Cash</option>
                          <option value="MPESA">MPESA</option>
                          <option value="Bank">Bank</option>
                      </select>
          
                      <span *ngIf="submitted && form.get('payment_mode')?.hasError('required')" style="color:red"> <strong>Please select payment mode!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Payment Ref.:<span style="color:red;">*</span></label>
                        <input type="text"  formControlName="payment_ref" class="form-control" placeholder="Payment Ref." value="">

                        <span *ngIf="submitted && form.get('payment_ref')?.hasError('required')" style="color:red"> <strong>Payment reference is required!</strong></span>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="!disallowAddingRow">
                <div class="col-md-12 text-right mb-2">
                    <button type="button" class="btn btn-success" (click)="addPaymentRow()">
                        <i class="fa fa-plus"></i> Add Line
                    </button>
                </div>
            </div>

                <table id="example1" class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Payment Description</th>
                            <th>Amount</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody formArrayName="payments">
                        <tr *ngFor="let payment of payments.controls; let i = index" [formGroupName]="i">
                            <td>
                                <input type="text" formControlName="txn_desc" class="form-control">
                                <span *ngIf="submitted && payment.get('txn_desc')?.hasError('required')" style="color:red">
                                    <strong>Description is required!</strong>
                                </span>
                            </td>
                            <td>
                                <input type="text" formControlName="amount" class="form-control">
                                <span *ngIf="submitted && payment.get('amount')?.hasError('required')" style="color:red">
                                    <strong>Amount is required!</strong>
                                </span>
                                <span *ngIf="submitted && payment.get('amount')?.hasError('pattern')" style="color:red">
                                    <strong>Invalid amount format! (e.g., 100 or 100.50)</strong>
                                </span>
                            </td>
                            <td>
                                <button type="button" class="btn btn-danger" (click)="removePaymentRow(i)" *ngIf="payments.length > 1">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>

                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Save</button>
                </ng-template>
            </form>
        </div>
        <!-- /.card-body -->
   </div>
   </div>
   </section>