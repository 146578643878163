<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Vehicle Reservation</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/vehicles"><i class="fa fa-list-ul"></i>&nbsp;Vehicle List</a></li>
               <li class="breadcrumb-item active">Vehicle Reservation</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
        <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Vehicle Reservation</h3>
                    <!--Error Message-->
                    <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                        <span [innerHTML]="errorMessage"></span>
                    </div>
                </div>
                <!-- /.card-header -->
                <div class="card-body" style="overflow-x: auto; white-space: nowrap;">
                    <form [formGroup]="form" (ngSubmit)="handleSubmit()">
                        <div class="row clearfix">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Make:</label>
                                    <input type="text" class="form-control" value="{{ vehicleDetails.data.make }}" readonly>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Model:</label>
                                    <input type="text" class="form-control" value="{{ vehicleDetails.data.model }}" readonly>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Chassis No.:</label>
                                    <input type="text"  class="form-control" value="{{ vehicleDetails.data.chassis_no }}" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="row clearfix">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Colour:</label>
                                    <input type="text" class="form-control" value="{{ vehicleDetails.data.colour }}" readonly>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Month/Year:</label>
                                    <input type="text" class="form-control" value="{{ vehicleDetails.data.month_year }}" readonly>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Engine CC:</label>
                                    <input type="text"  class="form-control" value="{{ vehicleDetails.data.engine_cc }}" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="row clearfix">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Fuel:</label>
                                    <input type="text"  class="form-control" value="{{ vehicleDetails.data.fuel }}" readonly>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Mileage:</label>
                                    <input type="text"  class="form-control" value="{{ vehicleDetails.data.mileage }}" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="separator">Customer Details</div>
                        <div class="row clearfix">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>First Name:<span style="color:red;">*</span></label>
                                    <input type="text" formControlName="first_name"  class="form-control" placeholder="First Name" value="">
        
                                    <span *ngIf="submitted && form.get('first_name')?.hasError('required')" style="color:red"> <strong>Please enter customer's first name!</strong></span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Middle Name:</label>
                                    <input type="text" formControlName="middle_name" class="form-control" placeholder="Middle Name" value="">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Surname:<span style="color:red;">*</span></label>
                                    <input type="text" formControlName="surname"  class="form-control" placeholder="Surname" value="">
        
                                    <span *ngIf="submitted && form.get('surname')?.hasError('required')" style="color:red"> <strong>Please enter customer's surname!</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="row clearfix">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Phone Number:<span style="color:red;">*</span></label>
                                    <input type="text" formControlName="phone_no" class="form-control" placeholder="Phone Number" value="">
        
                                    <span *ngIf="submitted && form.get('phone_no')?.hasError('required')" style="color:red"> <strong>Please enter customer's phone number!</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="separator">Sale Details</div>
                        <div class="row clearfix">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Deal Type:<span style="color:red;">*</span></label>
                                    <select formControlName="deal_type" class="form-control" (change)="onSelectChange($event.target)">
                                        <option value="">Select</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Hire Purchase">Hire Purchase</option>
                                        <option value="Bank Finance">Bank Finance</option>
                                    </select>
        
                                    <span *ngIf="submitted && form.get('deal_type')?.hasError('required')" style="color:red"> <strong>Please select the type of deal!</strong></span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Agreed Amount:<span style="color:red;">*</span></label>
                                    <input type="text" formControlName="agreed_amount" [(ngModel)]="agreed_amount" (blur)="numberFormat()" class="form-control" placeholder="Agreed Amount" value="">
        
                                    <span *ngIf="submitted && form.get('agreed_amount')?.hasError('required')" style="color:red"> <strong>Please enter amount agreed!</strong></span>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="isHiredPurchse">
                                <div class="form-group">
                                    <label>Deposit to be Paid:</label>
                                    <input type="text" formControlName="deposit_to_be_paid" [(ngModel)]="deposit_to_be_paid" (blur)="numberFormat()" class="form-control" placeholder="Deposit to be Paid" value="">
                                </div>
                            </div>
                        </div>
                        <div class="row clearfix">
                            <div class="col-md-4" *ngIf="isHiredPurchse">
                                <div class="form-group">
                                    <label>No. of Instalments:</label>
                                    <input type="text" formControlName="no_of_instalments" class="form-control" placeholder="No. of Instalments" value="">
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="isBankFinance">
                                <div class="form-group">
                                    <label>Invoice amount:</label>
                                    <input type="text" formControlName="invoice_amount" [(ngModel)]="invoice_amount" (blur)="numberFormat()" class="form-control" placeholder="Invoice amount" value="">
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="isBankFinance">
                                <div class="form-group">
                                    <label>Customer Contribution:<span style="color:red;">*</span></label>
                                    <input type="text" formControlName="cust_contrib" [(ngModel)]="cust_contrib" (blur)="numberFormat()" class="form-control" placeholder="Customer Contribution" value="">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Commitment Fee:</label>
                                    <input type="text" formControlName="commitment_fee" [(ngModel)]="commitment_fee" (blur)="numberFormat()" class="form-control" placeholder="Commitment fee" value="">
                                </div>
                            </div>
                        </div>
                        <div class="row clearfix">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Other Charges:</label>
                                    <input type="text" formControlName="other_charges" [(ngModel)]="other_charges" (blur)="numberFormat()" class="form-control" placeholder="Other Charges" value="">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Date Reserved:</label>
                                    <input type="date" formControlName="date_reserved" class="form-control" value="">
                                </div>
                            </div>
                        </div>
                        <div class="separator">Broker Details</div>
                        <div class="row clearfix">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Broker Name:</label>
                                    <input type="text" formControlName="broker_name" class="form-control" placeholder="Broker Name" value="">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Broker Phone Number:</label>
                                    <input type="text" formControlName="broker_phone_no" class="form-control" placeholder="Broker Phone Number" value="">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Commission Agreed:</label>
                                    <input type="text" formControlName="comm_agreed"  [(ngModel)]="comm_agreed" (blur)="numberFormat()" class="form-control" placeholder="Commission Agreed" value="">
                                </div>
                            </div>
                        </div>
                        <div class="separator">Extras</div>
                        <div class="row clearfix">
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label>Comment:</label>
                                    <textarea formControlName="comment" class="form-control" placeholder="Additional info" rows="6" cols="50"></textarea>
                                </div>
                            </div>
                        </div>

                        <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Processing...
                        </button>
                        <ng-template #showSaveBtn>
                            <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Save</button>
                        </ng-template>
                    </form>

                    <div class="separator">Previous Reservations</div>
                    <table id="example1" class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Customer Name</th>
                                <th>Phone Number</th>
                                <th>Deal Type</th>
                                <th>Agreed Amount</th>
                                <th>Deposit/Customer Contribution</th>
                                <th>Deposit/Customer Contribution Bal.</th>
                                <th>No. of Instalments</th>
                                <th>Invoice amount</th>
                                <th>Commitment Fee</th>
                                <th>Other Charges</th>
                                <th>Deal Balance</th>
                                <th>Broker Name</th>
                                <th>Broker Phone Number</th>
                                <th>Commission Agreed</th>
                                <th>Comment</th>
                                <th>Reservation Date</th>
                            </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let reservation of reservations">
                                <td>{{ reservation.first_name }} {{ reservation.middle_name }} {{ reservation.surname }}</td>
                                <td>{{ reservation.phone_no }}</td>
                                <td>{{ reservation.deal_type }}</td>
                                <td>{{ reservation.agreed_amount| currency:'Kshs ' }}</td>
                                <td>{{ reservation.deposit_cust_contrib| currency:'Kshs ' }}</td>
                                <td>{{ reservation.deposit_cust_contrib_balance| currency:'Kshs ' }}</td>
                                <td>{{ reservation.no_of_instalments }}</td>
                                <td>{{ reservation.invoice_amount| currency:'Kshs ' }}</td>
                                <td>{{ reservation.commitment_fee| currency:'Kshs '}}</td>
                                <td>{{ reservation.other_charges| currency:'Kshs ' }}</td>
                                <td>{{ reservation.deal_bal| currency:'Kshs ' }}</td>
                                <td>{{ reservation.broker_name }}</td>
                                <td>{{ reservation.broker_phone_no }}</td>
                                <td>{{ reservation.commission_agreed| currency:'Kshs ' }}</td>
                                <td class="wrap-text">{{ reservation.comment }}</td>
                                <td>{{ reservation.reservation_date| date: 'dd-MM-yyyy' }}</td>
                           </tr>
                        </tbody>
                    </table>
                </div>
                <!-- /.card-body -->
        </div>
   </div>
   </section>
