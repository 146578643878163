import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchEditableEntries'
})
export class SearchEditableEntriesPipe implements PipeTransform {

  transform(entries: any, searchText: string) {
    if (!searchText) {
      return entries; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return entries.filter((entry: any) => 
    (entry.make && entry.make.toLowerCase().includes(searchText)) ||
    (entry.model && entry.model.toLowerCase().includes(searchText)) ||
    (entry.chassis_no && entry.chassis_no.toLowerCase().includes(searchText)) ||
    (entry.entry_no && entry.entry_no.toLowerCase().includes(searchText)) ||
    (entry.doc_ref_no && entry.doc_ref_no.toLowerCase().includes(searchText)) ||
    (entry.pmt_ref_no && entry.pmt_ref_no.toLowerCase().includes(searchText)) ||
    (entry.bl_no && entry.bl_no.toLowerCase().includes(searchText))
    );
  }

}
