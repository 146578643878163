import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { VehiclesService } from 'src/app/services/vehicles/vehicles.service';
import { StockService } from 'src/app/services/stock/stock.service';

@Component({
  selector: 'app-view-vehicle-exp-details',
  templateUrl: './view-vehicle-exp-details.component.html',
  styleUrls: ['./view-vehicle-exp-details.component.css']
})
export class ViewVehicleExpDetailsComponent implements OnInit{
  public vehicle_id: string | null = null;
  vehicle: any;
  public isLoading: boolean = false;
  public errorMessage: string | null = null;
  expenses: any[] = [];

  constructor(
    private activatedRoute:ActivatedRoute,
    private vehiclesService: VehiclesService,
    private stockService: StockService,
  ){}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.vehicle_id = param['get']('vehicle_id')
    })

    if(this.vehicle_id){
      this.isLoading=true;
      this.getVehicleData(this.vehicle_id);
    }else{
      this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
    }
  }

   /**
   * Get vehicle details
   * @param vehicle_id 
   */
   getVehicleData(vehicle_id: string): void{
    this.vehiclesService.getVehicle(vehicle_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.isLoading=false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.vehicle = response;
          if(this.vehicle.status==='no_data' || this.vehicle.status.length===0){
            this.isLoading=false;
            this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again.";
          }else{
            this.fetchVehicleExpenseDetails(vehicle_id);
          }
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.errorMessage=error;
      }
    });
  }

  fetchVehicleExpenseDetails(vehicle_id:string):void{
    this.stockService.getVehicleExpenseDetails(vehicle_id)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.expenses = response.data;
          this.isLoading=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isLoading=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

}
