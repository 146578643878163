import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class CommitmentFeeRefundsService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient, private errorHandler: ConfigErrorHandler) { }

  /**
   * Get refundable commitment fees
   * @returns Array
   */
  getRefundableCommitmentFees(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-refundable-commitment-fees`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get refund info
   * @param reservation_id 
   */
  getCustomerNameAndRefundableAmount(reservation_id: string){

    let apiURL:string=`${this.baseURL}/v1/customer/refundable-amount/${reservation_id}`;

    return this.httpClient.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Post refund
   * @param refundData
   * @returns array
   */
  postRefund(refundData:any,attachments:any,reservation_id:string):Observable<any>{
    const formData = new FormData();
    formData.append("reservation_id", reservation_id);
    formData.append("amount_refunded", refundData.amount_refunded);
    formData.append("date_refunded", refundData.date_refunded);
    formData.append("comment", refundData.comment);
    formData.append("bal_retained", refundData.bal_retained);
    
    if(attachments!==undefined && attachments !==''){
      attachments.forEach((file: string | Blob) => {
        formData.append('attachments[]', file);
      });
    }

    let apiURL:string=`${this.baseURL}/v1/refund-commitment-fee`;

    return this.httpClient.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get refunded commitment fees
   * @returns Array
   */
  getRefundedCommitmentFees(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-refunded-commitment-fees`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Filter refunded commitment fees
   * @param dateRange
   * @returns array
   */
  filterRefundedCommitmentFees(dateRange:any):Observable<any>{
    console.log(dateRange);
    let apiURL:string=`${this.baseURL}/v1/filter-refunded-commitment-fees`;

    return this.httpClient.post(apiURL,dateRange, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Get refunded commitment fees
   * @returns Array
   */
   getRefundedCommitmentFeeAttachments(refund_id: string): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/commitment-fee-refund/attachments/${refund_id}`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get attachment URL
   * @param attachment_id 
   */
  getAttachmentUrl(attachment_id: string){

    let apiURL:string=`${this.baseURL}/v1/commitment-fee-refund/attachment-url/${attachment_id}`;

    return this.httpClient.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }
}
