<div class="wrapper" *ngIf="isLoggedIn">    
  <app-topnav *ngIf="isLoggedIn"></app-topnav>    
      <app-sidebar *ngIf="isLoggedIn"></app-sidebar>    
      <div class="content-wrapper">
          <div class="container-fluid">
            <!--Success message-->
            <div class="alert alert-success" *ngIf="showSuccessAlert">
              <strong>Success!</strong> {{ globalSuccessMessage }}
            </div>
            <!--Error message-->
            <div class="alert alert-danger" *ngIf="globalErrorMessage">
              <strong>Error:</strong> {{ globalErrorMessage }}
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!--Warning message-->
            <div class="alert alert-warning" *ngIf="globalwarningMessage">
              <strong>Warning!</strong> {{ globalwarningMessage }}
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>  
          <router-outlet></router-outlet>
      </div>    
  <app-footer></app-footer>    
</div>

<div *ngIf="!isLoggedIn">
  <app-login></app-login>
</div>