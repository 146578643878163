import { Component, OnInit } from '@angular/core';
import { RoleService } from 'src/app/services/roles/role.service';
import { ApiResponse } from 'src/app/models/IApiResponse';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.css']
})
export class RolesListComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  roles: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public isProcessing: boolean = false;

  constructor(
    private roleService:RoleService
  ){}

  ngOnInit(): void {
    this.fetchRoles();
  }

  fetchRoles():void{
    this.roleService.getAllRoles().subscribe((response: ApiResponse) =>this.roles = response.data);
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fetchRoles();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  deleteRole(event: Event,roleId:string){
    event.preventDefault();
    this.confirmBox(roleId);
  }

  confirmBox(roleId: string){
    Swal.fire({
      title: 'Are you sure you want to delete this role?',
      text: 'The action is irreversible!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.isProcessing=true;

        this.roleService.deleteRole(roleId)
        .subscribe({
          next: (response) => {
            if(response.status==='success'){
              this.isProcessing=false;
              this.successMessage=response.message;
              this.errorMessage=''; //Clear error message incase it's available
             
              //Reload users
              this.fetchRoles();
            }else if(response.status==='error'){
              this.isProcessing=false;
              this.errorMessage=response.message;
            }else{
              this.isProcessing=false;
              this.errorMessage=response.message;
            }
          },
          error: (error) => {
            this.isProcessing=false;
            this.errorMessage=error;
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      
      }
    })
  }

}
