<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
           
            <div class="col-sm-6">
              <a routerLink="/settings/add-branch" class="btn btn-success pull-left" class="btn btn-success"><i
                class="fa fa-plus-circle" aria-hidden="true">&nbsp;</i>New Branch</a>
            </div><!-- /.col -->
           
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item active">Branches</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
        <div class="row">
            <div class="col-md-8">
                <div class="row">
                   
                </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                  <div class="col">
                      <input type="text" class="form-control" placeholder="Type location or branch name here to search..." (input)="onSearchTextChange($event)">
                  </div>
              </div>
            </div>
        </div>
    </div>
    </section>
    <!-- /.content-header -->
    <section class="content">
      <div class="container-fluid">
      <div class="card">
          <div class="card-header">
            <h3 class="card-title">Branches</h3>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <!--Error Message-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
            </div>
            <!--Success Message-->
            <div class="alert alert-success" role="alert" *ngIf="successMessage">
            {{successMessage}}
            </div>
            <table id="example1" class="table table-bordered table-hover">
              <thead>
                <tr>
                    <th>Location</th>
                    <th>Branch Name</th>
                    <th>Created On</th>
                    <th>Updated On</th>
                    <th></th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let branch of branches | searchBranch: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                      <td>{{branch.location_name}}</td>
                      <td>{{branch.branch_name}}</td>
                      <td>{{branch.created_at | date: 'dd-MM-yyyy'}}</td>
                      <td>{{branch.updated_at | date: 'dd-MM-yyyy'}}</td>
                      <td>
                        <div class="dropdown">
                            <button class="btn btn-default dropdown-toggle" type="button"
                                data-toggle="dropdown">Action
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" routerLink="/settings/edit-branch/{{branch.id}}"><i class="fa fa-edit"></i>&nbsp;Edit</a></li>
                                <li><a class="dropdown-item" href="#" (click)="deleteBranch($event,branch.id)"><i class="fa fa-trash"></i>&nbsp;Delete</a></li>
                                <li><a class="dropdown-item" routerLink="/settings/print-qr-codes/{{branch.id}}"><i class="fa fa-print"></i>&nbsp;Print QR Codes</a></li>
                            </ul>
                        </div>
                      </td>
                  </tr>
              </tbody>
            </table>
            <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </section>