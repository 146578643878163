  <!--Loading Spinner-->
<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Edit Vehicle</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/vehicles" *ngIf="userPermissions.includes('view-vhcl-list')"><i class="fa fa-list-ul"></i>&nbsp;Vehicle List</a></li>
               <li class="breadcrumb-item active">Edit Vehicle</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
            <h3 class="card-title">Edit Vehicle</h3>
            <!--Error messages-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
            </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="updateVehicle()">
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Make:<span style="color:red;">*</span></label>
                        <select formControlName="make_id" class="form-control" (change)="onSelectChange($event.target,'make')" [(ngModel)]="vehicle.data.make_id">
                            <option value="">Select</option>
                            <option [value]="make.id" *ngFor="let make of makes">{{ make.make_name }}</option>
                        </select>

                        <span *ngIf="submitted && form.get('make_id')?.hasError('required')" style="color:red"> <strong>Please select vehicle make!</strong></span>
                        <span *ngIf="invalidMakeSelected" style="color:red"> <strong>Invalid vehicle make selection!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Model:<span style="color:red;">*</span></label>
                        <select formControlName="model_id" class="form-control" (change)="onSelectChange($event.target,'model')" [(ngModel)]="vehicle.data.model_id">
                            <option [value]="vehicle.data.model_id">{{vehicle.data.model}}</option>
                            <option [value]="model.id" *ngFor="let model of models">{{ model.model_name }}</option>
                        </select>

                        <span *ngIf="submitted && form.get('model_id')?.hasError('required')" style="color:red"><strong>Please select vehicle model</strong></span>
                        <span *ngIf="invalidModelSelected" style="color:red"> <strong>Invalid vehicle model selection!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Grade:</label>
                        <select formControlName="grade_id" class="form-control" [(ngModel)]="vehicle.data.grade_id">
                            <option [value]="vehicle.data.grade_id">{{vehicle.data.grade_name}}</option>
                            <option [value]="grade.id" *ngFor="let grade of grades">{{ grade.grade }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Chassis No.:<span style="color:red;">*</span></label>
                        <input type="text" formControlName="chassis_no" class="form-control" value="" [(ngModel)]="vehicle.data.chassis_no">

                        <span *ngIf="submitted && form.get('chassis_no')?.hasError('required')" style="color:red"><strong>Chassis No. is required!</strong> </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Engine No.:<span style="color:red;">*</span></label>
                        <input type="text" formControlName="engine_no" class="form-control" value="" [(ngModel)]="vehicle.data.engine_no">

                        <span *ngIf="submitted && form.get('engine_no')?.hasError('required')" style="color:red"><strong>Engine No. is required!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Engine CC:<span style="color:red;">*</span></label>
                        <input type="text" formControlName="engine_cc" class="form-control" value="" [(ngModel)]="vehicle.data.engine_cc">

                        <span *ngIf="submitted && form.get('engine_cc')?.hasError('required')" style="color:red"><strong>Engine CC is required!</strong></span>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Transmission:<span style="color:red;">*</span></label>
                        <select formControlName="transmission" class="form-control" [(ngModel)]="vehicle.data.transmission">
                            <option value="">Select</option>
                            <option value="MANUAL">MANUAL</option>
                            <option value="AUTOMATIC">AUTOMATIC</option>
                        </select>
                    </div>

                    <span *ngIf="submitted && form.get('transmission')?.hasError('required')" style="color:red"><strong>Please select transmission</strong></span>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Body Type:<span style="color:red;">*</span></label>
                        <select formControlName="body_type" class="form-control" [(ngModel)]="vehicle.data.body_type">
                            <option value="">Select</option>
                            <option [value]="body_type.name" *ngFor="let body_type of body_types">{{ body_type.name }}</option>
                        </select>

                        <span *ngIf="submitted && form.get('body_type')?.hasError('required')" style="color:red"><strong>Please select vehicle body type!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Drive:<span style="color:red;">*</span></label>
                        <select formControlName="drive" class="form-control" [(ngModel)]="vehicle.data.drive">
                            <option value="">Select</option>
                            <option value="2WD">2WD</option>
                            <option value="4WD">4WD</option>
                        </select>

                        <span *ngIf="submitted && form.get('drive')?.hasError('required')" style="color:red"><strong>Please select drive!</strong></span>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Fuel:<span style="color:red;">*</span></label>
                        <select formControlName="fuel" class="form-control" [(ngModel)]="vehicle.data.fuel">
                            <option value="">Select</option>
                            <option value="PETROL">PETROL</option>
                            <option value="PETROL (Hybrid)">PETROL (Hybrid)</option>
                            <option value="DIESEL">DIESEL</option>
                            <option value="DIESEL (Hybrid)">DIESEL (Hybrid)</option>
                            <option value="ELECTRIC">ELECTRIC</option>
                        </select>

                        <span *ngIf="submitted && form.get('fuel')?.hasError('required')" style="color:red"><strong>Please select fuel type!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Mileage:<span style="color:red;">*</span></label>
                        <input type="text" formControlName="mileage" class="form-control" value="" [(ngModel)]="vehicle.data.mileage">

                        <span *ngIf="submitted && form.get('mileage')?.hasError('required')" style="color:red"><strong>Mileage is required!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Colour:<span style="color:red;">*</span></label>
                        <select formControlName="colour" class="form-control" [(ngModel)]="vehicle.data.colour">
                            <option value="">Select</option>
                            <option [value]="colour.colour_name" *ngFor="let colour of colours">{{ colour.colour_name }}</option>
                        </select>

                        <span *ngIf="submitted && form.get('colour')?.hasError('required')" style="color:red"><strong>Please select vehicle colour!</strong></span>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Yr of Mfg:<span style="color:red;">*</span></label>
                        <input type="text" formControlName="yr_of_mfg" class="form-control" value="" [(ngModel)]="vehicle.data.year">

                        <span *ngIf="submitted && form.get('yr_of_mfg')?.hasError('required')" style="color:red"><strong>Year of manufcture is required!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Weight:<span style="color:red;">*</span></label>
                        <input type="text" formControlName="weight" class="form-control" value="" [(ngModel)]="vehicle.data.weight">

                        <span *ngIf="submitted && form.get('weight')?.hasError('required')" style="color:red"><strong>Weight is required!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>CBM:<span style="color:red;">*</span></label>
                        <input type="text" formControlName="cbm" class="form-control" value="" [(ngModel)]="vehicle.data.cbm">

                        <span *ngIf="submitted && form.get('cbm')?.hasError('required')" style="color:red"><strong>Cubic Meter is required!</strong></span>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Reg No.:</label>
                        <input type="text" formControlName="reg_no" class="form-control" value="" [(ngModel)]="vehicle.data.reg_no">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Condition:<span style="color:red;">*</span></label>
                        <select formControlName="condition" class="form-control" [(ngModel)]="vehicle.data.vhcl_cond">
                            <option value="1">Brand New</option>
                            <option value="2">Foreign Used</option>
                            <option value="3">Trade In/Locally Used</option>
                        </select>

                        <span *ngIf="submitted && form.get('condition')?.hasError('required')" style="color:red"><strong>Please select drive!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>No. of doors:</label>
                        <input type="text" formControlName="no_of_doors" class="form-control" value="" [(ngModel)]="vehicle.data.no_of_doors">
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>No. of seats:</label>
                        <input type="text" formControlName="no_of_seats" class="form-control" value="" [(ngModel)]="vehicle.data.no_of_seats">
                    </div>
                </div>
            </div>
            <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Processing...
            </button> 
            
            <ng-template #showSaveBtn>
                <button type="submit" class="btn btn-success" [disabled]="!form.valid"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Save</button>
            </ng-template>
            </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>