import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient, private errorHandler: ConfigErrorHandler) { }

   /**
   * Get All Agents
   * @returns Array
   */
  public getAllAgents():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/agents`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

   /**
   * Get agent record for editing
   * @param agentId 
   */
   getAgentData(agentId: string){

    let apiURL:string=`${this.baseURL}/v1/agents/${agentId}/edit`;

    return this.httpClient.get(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Edit Agent
   * @param agentData 
   * @param agentId 
   * @returns array
   */
  updateAgent(agentData:any,agentId: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/agents/${agentId}`;

    return this.httpClient.put(apiURL,agentData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Create new agent
   * @param formData
   * @returns array
   */
   saveAgent(formData:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/agents`;

    return this.httpClient.post(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get BLs assigned to the Agent
   * @returns Array
   */
  public getBLs(agentId:any):Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-bls-by-agent/${agentId}`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

   /**
   * Get Open Invoices
   * @returns Array
   */
  public getOpenAgentInvoices():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-open-agent-invoices`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Get agency fee invoice attachment URL
   * @param invoice_id 
   */
  getAgencyFeeInvoiceAttachmentUrl(invoice_id: string){

    let apiURL:string=`${this.baseURL}/v1/agency-fee-invoice/attachment-url/${invoice_id}`;

    return this.httpClient.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Get open agency fee invoice details
   * @param invoice_id 
   */
  getOpenAgentInvoiceDetails(invoice_id: string){

    let apiURL:string=`${this.baseURL}/v1/get-open-agent-invoice-details/${invoice_id}`;

    return this.httpClient.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Pay agent invoice
   * @param paymentData
   * @returns 
   */
  payAgent(paymentData:any,attachment:any): Observable<any> {

    const formData = new FormData();
    formData.append("bl_no", paymentData.bl_no);
    formData.append("no_of_units", paymentData.no_of_units);
    formData.append("invoice_no", paymentData.invoice_no);
    formData.append("amount_paid", paymentData.amount_paid);
    formData.append("pmt_mode", paymentData.pmt_mode);
    formData.append("pmt_ref", paymentData.pmt_ref);
    formData.append("pmt_date", paymentData.pmt_date);
    formData.append("attachment", attachment);

    let apiURL:string=`${this.baseURL}/v1/pay-agents`;

    return this.httpClient.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Set agent portal password
   * @param formData
   * @returns array
   */
  setPassword(formData:any,agentId:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/set-agent-portal-password/${agentId}`;

    return this.httpClient.post(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
