import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params,Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { MakeService } from 'src/app/services/makes/make.service';

@Component({
  selector: 'app-edit-make',
  templateUrl: './edit-make.component.html',
  styleUrls: ['./edit-make.component.css']
})
export class EditMakeComponent implements OnInit{
  form!: FormGroup;
  public errorMessage: string | null = null;
  isProcessing: boolean = false;
  submitted: boolean = false;
  public Id: string | null = null;
  public makeData:any;

  constructor(
    private makeService:MakeService,
    private formBuilder: FormBuilder,
    private router: Router,
    private globalService: GlobalService,
    private activatedRoute: ActivatedRoute,
  ){
    this.form = this.formBuilder.group({
      make_name: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.Id = param['get']('id')
    })

    if(this.Id){
      this.fetchMakeData(this.Id);
    }else{
      //Navigate back to users list with error
      this.globalService.setGlobalErrorMessage("Unable to select make for update!");
      this.router.navigate(['settings/makes-list']);
    }
  }
  
   /**
   * Get user data
   * @param userId 
   */
   fetchMakeData(Id: string): void {
    this.makeService.edit(Id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.makeData = response;
          if(this.makeData.status==='no_data' || this.makeData.status.length===0){
            this.errorMessage=this.makeData.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  handleSubmit():void{
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      const formData = {
        ...this.form.value,
      };

      this.makeService.update(formData,this.Id).subscribe(response => {
        if(response.status==='success'){

          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalSuccessMessage(response.message);
         
          // Redirect back to makes list
          this.router.navigate(['settings/makes-list']);
        }else if(response.status==='error'){
          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalErrorMessage(response.message);
        }else{
          this.submitted=false;
          this.isProcessing = false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
        }
      },
      error => {
        this.isProcessing = false;
        this.errorMessage=error;
      });
    }else{
      this.isProcessing = false;
    }
  }
}
