import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/users/user.service';
import { LocationService } from 'src/app/services/locations/location.service';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { BranchService } from 'src/app/services/branches/branch.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit{
  roles: any[] = [];
  locations: any[] = [];
  branches: any[] = [];
  invalidLocationSelected: boolean = false;
  form!: FormGroup;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  isProcessing: boolean = false;
  submitted: boolean = false;

  constructor(
    private userService: UserService,
    private locationService: LocationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private globalService: GlobalService,
    private branchService:BranchService,
  ){
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      username: ['', [Validators.required,Validators.minLength(6),Validators.maxLength(20)]],
      password: ['', [Validators.required,Validators.minLength(8),Validators.maxLength(25)]],
      role_id: ['', Validators.required],
      location_id: ['', Validators.required],
      branch_id: ['', Validators.required],
      stk_access_level: ['', Validators.required],
    });
  }

  ngOnInit(): void {
   this.fetchRoles();
   this.fetchLocations();
  }

  fetchRoles():void{
    this.userService.getAssignableRoles().subscribe((response: ApiResponse) =>this.roles = response.data);
  }

  fetchLocations():void{
    this.locationService.getAllLocations().subscribe((response: ApiResponse) =>this.locations = response.data);
  }

  //Load branches when location is selected
  onSelectChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {
      const locationId = target.value;
      
      if(locationId!=='' && locationId!==undefined){
        this.fetchBranchesByLocation(locationId);
      }else{
        this.invalidLocationSelected=true;
      }
     
    }
  }

  fetchBranchesByLocation(locationId: string):void{
    this.branchService.getBranchesByLocation(locationId).subscribe((response: ApiResponse) =>this.branches = response.data);
  }

  handleSubmit():void {
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      const formData = {
        ...this.form.value,
      };

      this.userService.saveUser(formData).subscribe(response => {
        if(response.status==='success'){

          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalSuccessMessage(response.message);
         
          // Redirect back to users list
          this.router.navigate(['settings/users-list']);
        }else if(response.status==='error'){
          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalErrorMessage(response.message);
        }else{
          this.submitted=false;
          this.isProcessing = false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
        }
      },
      error => {
        this.isProcessing = false;
        this.errorMessage=error;
      });
    }else{
      this.isProcessing = false;
      this.errorMessage="Please provide all the required details!";
    }
  }

}
