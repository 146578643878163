import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class ActivityLogsService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient, private errorHandler: ConfigErrorHandler) { }

   /**
   * Get activity logs
   * @returns Array
   */
   public getActivityLogs():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/activity-logs`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Filter activity logs by user type
   * @returns Array
   */
  public filterByUserType(userType:number):Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/activity-logs/filter-by-user-type/${userType}`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Filter activity logs by user or agent
   * @returns Array
   */
  public filterByUserOrAgent(userType:any,userId:number):Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/activity-logs/filter-by-user-agent/${userType}/${userId}`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Filter activity logs by system module
   */
  filterByModule(userType:any,userId:any,sysModule:string): Observable<ApiResponse>{
    const formData = new FormData();
    formData.append("user_type", userType);
    formData.append("user_id", userId);
    formData.append("sys_module", sysModule);

    let apiURL:string=`${this.baseURL}/v1/activity-logs/filter-by-module`;
    
    return this.httpClient.post<ApiResponse>(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Filter activity logs by date
   */
  filterByDate(startDate:any,endDate:any,userType:any,userId:any,sysModule:string): Observable<ApiResponse>{
    const formData = new FormData();
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    formData.append("user_type", userType);
    formData.append("user_id", userId);
    formData.append("sys_module", sysModule);

    let apiURL:string=`${this.baseURL}/v1/activity-logs/filter-by-date`;
    
    return this.httpClient.post<ApiResponse>(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }
}
