import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EntryService } from 'src/app/services/entries/entry.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-entry',
  templateUrl: './edit-entry.component.html',
  styleUrls: ['./edit-entry.component.css']
})
export class EditEntryComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  editableEntries: any[] = [];
  searchText: string = '';
  public userPermissions: string[] = [];
  public popupErrorMessage: string | null = null;
  public popupSuccessMessage: string | null = null;
  public popupWarningMessage: string | null = null;
  public isLoading: boolean = false;
  isProcessing: boolean = false;
  submitted: boolean = false;
  form!: FormGroup;
  unofficialDutyform!: FormGroup;
  public entry_id:string='';
  public txn_id:string='';
  @ViewChild('EditEntryModal') modalElement!: ElementRef;
  @ViewChild('EditEntryUnofficialModal') unofficialModalElement!: ElementRef;

  public entry: any={
    data: {
      entry_no: null,
      entry_date: null,
      duty_amount: null,
      doc_ref_no: null,
      payment_ref_no: null
    }
  };

  constructor(
    private entryService: EntryService,
    private globalService: GlobalService,
    private router: Router,
    private formBuilder: FormBuilder
  ){
    this.form = this.formBuilder.group({
      chassis_no: ['', Validators.required],
      entry_no: ['', Validators.required],
      entry_date: ['', Validators.required],
      duty_amount: ['', Validators.required],
      doc_ref_no: ['', Validators.required],
      payment_ref_no: ['', Validators.required],
    });

    this.unofficialDutyform = this.formBuilder.group({
      chassis_no: ['', Validators.required],
      duty_amount: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.isLoading=true;
    this.getEditableEntries();
  }

  getEditableEntries(): void {
    this.entryService.getEditableEntries()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.editableEntries = response.data;
          this.isLoading=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isLoading=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
    this.page = 1; // Reset to page 1 after applying filter
  }

  openModal(entry_id:string,txn_id:string){
    this.entry_id=entry_id; //Used when updating (on handleSubmit)
    this.txn_id=txn_id;
    this.popupErrorMessage=null; // Clear previous popup error messages if any

    if(this.txn_id){
      this.isLoading=true;
      this.getEntryData(this.txn_id,"official");
    }else{
      this.errorMessage="Oops! A problem was encountered while processing your request. Please try again!";
    }
  }

  closeModal():void {
    if (this.modalElement) {
      (<any>$(this.modalElement.nativeElement)).modal('hide');
    }
  }

  openUnofficialModal(txn_id:string){
    this.txn_id=txn_id;
    this.popupErrorMessage=null; // Clear previous popup error messages if any

    if(this.txn_id){
      this.isLoading=true;
      this.getEntryData(this.txn_id,"unofficial");
    }else{
      this.errorMessage="Oops! A problem was encountered while processing your request. Please try again!";
    }
  }

  closeUnofficialModal():void {
    if (this.unofficialModalElement) {
      (<any>$(this.unofficialModalElement.nativeElement)).modal('hide');
    }
  }

   /**
   * Get entry details
   * @param entry_id 
   */
   getEntryData(txn_id: string,modalOption:string): void{
    this.entryService.getEntry(txn_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
          this.isLoading=false;
        }else{
          this.entry = response;

          if(this.entry.status==='no_data' || this.entry.status.length===0){
            this.errorMessage=this.entry.message;
          }else{
            //Open edit modal
            if(modalOption==='official'){
              if (this.modalElement) {
                (<any>$(this.modalElement.nativeElement)).modal('show');
              }
            }else if(modalOption==='unofficial'){
              if (this.unofficialModalElement) {
                (<any>$(this.unofficialModalElement.nativeElement)).modal('show');
              }
            }
          }

          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  handleSubmit(){
    this.submitted = true;
    this.isProcessing=true;

    if (this.form.valid) {
      this.entryService.updateEntry(this.form.value,this.entry_id,this.txn_id)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.isProcessing=false;
            this.errorMessage=null;
            this.globalService.setGlobalSuccessMessage(response.message);
            this.closeModal();
           //Refresh editable entries
           this.getEditableEntries();
          }else if(response.status==='error'){
            this.submitted = false;
            this.isProcessing=false;
            this.popupErrorMessage=response.message;
            this.popupWarningMessage=null;
          }else if(response.status==='warning'){
            this.submitted = false;
            this.isProcessing=false;
            this.popupWarningMessage=response.message;
            this.popupErrorMessage=null;
          }else{
            this.submitted = false;
            this.isProcessing=false;
            this.popupErrorMessage=response.message;
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isProcessing=false;
          this.popupErrorMessage=error;
        }
      });
    }else{
      this.isProcessing=false;
    }
  }

  updateUnofficialDuty(){
    this.submitted = true;
    this.isProcessing=true;

    if (this.unofficialDutyform.valid) {
      this.entryService.updateUnofficialDuty(this.unofficialDutyform.value,this.txn_id)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.isProcessing=false;
            this.errorMessage=null;
            this.globalService.setGlobalSuccessMessage(response.message);
            this.closeUnofficialModal();
           //Refresh editable entries
           this.getEditableEntries();
          }else if(response.status==='error'){
            this.submitted = false;
            this.isProcessing=false;
            this.popupErrorMessage=response.message;
            this.popupWarningMessage=null;
          }else if(response.status==='warning'){
            this.submitted = false;
            this.isProcessing=false;
            this.popupWarningMessage=response.message;
            this.popupErrorMessage=null;
          }else{
            this.submitted = false;
            this.isProcessing=false;
            this.popupErrorMessage=response.message;
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isProcessing=false;
          this.popupErrorMessage=error;
        }
      });
    }else{
      this.isProcessing=false;
    }
  }
}
