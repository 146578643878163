 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>New User</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/settings/users-list"><i class="fa fa-list-ul"></i>&nbsp;Users List</a></li>
               <li class="breadcrumb-item active">New User</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
         <h3 class="card-title">New User</h3>
          <!--Error Message-->
          <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            <span [innerHTML]="errorMessage"></span>
          </div>
          <!--Success Message-->
          <div class="alert alert-success" role="alert" *ngIf="successMessage">
            {{successMessage}}
          </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
           <form [formGroup]="form" (ngSubmit)="handleSubmit()">
            <div class="row clearfix">
                <div class="col-md-8">
                    <div class="form-group">
                        <label>Name:<span style="color:red;">*</span></label>
                        <input type="text" formControlName="name" class="form-control" placeholder="User's Full Name" value="">
                        <span *ngIf="submitted && form.get('name')?.hasError('required')" style="color:red"> <strong>Please enter user's full name!</strong></span>
                    </div>
                </div>
            </div>
               <div class="row clearfix">
                   <div class="col-md-4">
                        <div class="form-group">
                            <label>Email:<span style="color:red;">*</span></label>
                            <input type="email" formControlName="email" class="form-control" placeholder="Email" value="">
                            <span *ngIf="submitted && form.get('email')?.hasError('required')" style="color:red"> <strong>Email is required!</strong></span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Username:<span style="color:red;">*</span></label>
                            <input type="text" formControlName="username" class="form-control" placeholder="Username" value="">
                            <span *ngIf="submitted && form.get('username')?.hasError('required')" style="color:red"> <strong>Please assign user a username!</strong></span>
                            <span *ngIf="form.get('username')?.hasError('minlength') && form.get('username')?.touched" style="color:red">
                                <strong>Username must be at least 6 characters long!</strong>
                            </span>
                            <span *ngIf="form.get('username')?.hasError('maxlength') && form.get('username')?.touched" style="color:red">
                                <strong>Username must not exceed 10 characters!</strong>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Password:<span style="color:red;">*</span></label>
                            <input type="password" formControlName="password" class="form-control" placeholder="Password" value="">
                            <span *ngIf="submitted && form.get('password')?.hasError('required')" style="color:red"> <strong>Password is required!</strong></span>
                            <span *ngIf="form.get('password')?.hasError('minlength') && form.get('password')?.touched" style="color:red">
                                <strong>Password must be at least 8 characters long!</strong>
                            </span>
                            <span *ngIf="form.get('password')?.hasError('maxlength') && form.get('password')?.touched" style="color:red">
                                <strong>Password must not exceed 15 characters!</strong>
                            </span>
                        </div>
                    </div>
               </div>
               <div class="row clearfix">
                   <div class="col-md-4">
                       <div class="form-group">
                           <label>Role:<span style="color:red;">*</span></label>
                           <select formControlName="role_id" class="form-control">
                                <option value="">Select</option>
                                <option  [value]="role.id" *ngFor="let role of roles">{{ role.name }}</option>
                           </select>
                           <span *ngIf="submitted && form.get('role_id')?.hasError('required')" style="color:red"> <strong>Please assign user a role!</strong></span>
                       </div>
                   </div>
                   <div class="col-md-4">
                       <div class="form-group">
                           <label>Location:<span style="color:red;">*</span></label>
                           <select formControlName="location_id" class="form-control" (change)="onSelectChange($event.target)">
                                <option value="">Select</option>
                                <option  [value]="location.id" *ngFor="let location of locations">{{ location.location_name }}</option>
                           </select>
                           <span *ngIf="invalidLocationSelected" style="color:red"> <strong>Invalid location selected!</strong></span>
                           <span *ngIf="submitted && form.get('location_id')?.hasError('required')" style="color:red"> <strong>Location is required!</strong></span>
                       </div>
                   </div>
                   <div class="col-md-4">
                        <div class="form-group">
                            <label>Branch:<span style="color:red;">*</span></label>
                            <select formControlName="branch_id" class="form-control">
                                <option value="">Select</option>
                                <option  [value]="branch.id" *ngFor="let branch of branches">{{ branch.branch_name }}</option>
                            </select>
                            <span *ngIf="submitted && form.get('branch_id')?.hasError('required')" style="color:red"> <strong>Branch is required!</strong></span>
                        </div>
                    </div>
               </div>
               <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Stock Access Level:<span style="color:red;">*</span></label>
                            <select formControlName="stk_access_level" class="form-control">
                                <option value="">Select</option>
                                <option value="3">All Locations</option>
                                <option value="2">User's Country</option>
                                <option value="1">User's Location</option>
                                <option value="0">User's Branch</option>
                            </select>
                            <span *ngIf="submitted && form.get('stk_access_level')?.hasError('required')" style="color:red"> <strong>Please select Stock Access Level!</strong></span>
                        </div>
                    </div>
                </div>

               <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Processing...
              </button>
              <ng-template #showSaveBtn>
                <button type="submit" class="btn btn-success"><i class="fa fa-save"
                    aria-hidden="true">&nbsp;</i>Save</button>
              </ng-template>
              
           </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>