<!--Loading Spinner-->
<div *ngIf="isLoading">
    <app-spinner></app-spinner>
  </div>
  
  <!-- Content Header (Page header) -->
  <section class="content-header">
      <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
             
              <div class="col-sm-6">
              
              </div><!-- /.col -->
             
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                  <li class="breadcrumb-item active">Completed BLs</li>
                </ol>
              </div><!-- /.col -->
            </div><!-- /.row -->
          </div><!-- /.container-fluid -->
      </div>
     
      <div class="row">
          <div class="col">
            <form>
                  <div class="row">
                      <div class="col-md-8">
                         
                      </div>
                      <div class="col-md-4">
                        <div class="row">
                            <div class="col">
                                <input type="text" class="form-control" placeholder="Type BL No. here to search" (input)="onSearchTextChange($event)">
                            </div>
                        </div>
                    </div>
                  </div>
              </form>
          </div>
      </div>
      </section>
        <!-- /.content-header -->
      <section class="content">
        <div class="container-fluid">
        <div class="card">
            <div class="card-header">
              <h3 class="card-title">Completed BLs</h3>
              <!--Error Message-->
              <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
              </div>
              <!--Success Message-->
              <div class="alert alert-success" role="alert" *ngIf="successMessage">
                {{successMessage}}
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                 <!--Error messages here-->
              <table id="example1" class="table table-bordered table-hover">
                <thead>
                <tr>
                    <th>BL Date</th>
                    <th>BL No.</th>
                    <th>Consignee</th>
                    <th>No. Of Units ({{total_units}})</th>
                    <th>Units Added ({{total_units_added}})</th>
                    <th>Agent</th>
                    <th>Vessel ETA</th>
                    <th *ngIf="userPermissions.includes('edit-bl') || userPermissions.includes('delete-bl') || userPermissions.includes('update-bl') || userPermissions.includes('print-qr-codes')"></th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let bl of bills_of_lading | searchBl: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                        <td>{{ bl.bl_date| date: 'dd-MM-yyyy' }}</td>
                        <td>{{ bl.bl_no }}</td>
                        <td>{{ bl.consignee_name }}</td>
                        <td>{{ bl.no_of_units }}</td>
                        <td>{{ bl.units_added }}</td>
                        <td>{{ bl.agent_name }}</td>
                        <td>{{ bl.vessel_eta| date: 'dd-MM-yyyy' }}</td>
                        <td *ngIf="userPermissions.includes('edit-bl') || userPermissions.includes('delete-bl') || userPermissions.includes('update-bl') || userPermissions.includes('print-qr-codes')">
                          <div class="dropdown">
                              <button class="btn btn-default dropdown-toggle" type="button"
                                  data-toggle="dropdown">Action
                                  <span class="caret"></span></button>
                              <ul class="dropdown-menu">
                                  <li *ngIf="userPermissions.includes('update-bl')"><a class="dropdown-item" routerLink="/update-bl/{{ bl.bl_id }}">View</a></li>
                                  <li *ngIf="userPermissions.includes('print-qr-codes') && bl.units_added>0"><a class="dropdown-item" routerLink="/print-qr-codes/{{ bl.bl_no }}">Print QR Codes</a></li>
                              </ul>
                          </div>
                        </td>
                    </tr>
                </tbody>
              </table>
              <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </section>