import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { VehicleReservationService } from 'src/app/services/sales-management/vehicle-reservation.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-new-commitment-fee-payment',
  templateUrl: './add-new-commitment-fee-payment.component.html',
  styleUrls: ['./add-new-commitment-fee-payment.component.css']
})
export class AddNewCommitmentFeePaymentComponent implements OnInit{
  form!: FormGroup;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public reservation_id: string | null = null;
  public vehicle_id: string | null = null;
  public payments: any[] = [];
  public isLoading: boolean = false;
  public isProcessing: boolean = false;
  submitted: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private vehicleReservationService:VehicleReservationService,
    private formBuilder: FormBuilder,
  ){
    this.form = this.formBuilder.group({
      amount_paid: ['', Validators.required],
      payment_date: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.reservation_id = param['get']('reservation_id')
      this.vehicle_id = param['get']('vehicle_id')
    })

    if(this.reservation_id){
      this.fetchCommitmentFeePayments(this.reservation_id);
    }else{ 
      //Navigate back to vehicle reservation records with error
      this.globalService.setGlobalErrorMessage("Unable to load commitment fee payments!");
      this.router.navigate(['view-vehicle-reservations',this.vehicle_id]);
    }
  }

  fetchCommitmentFeePayments(reservation_id:string): void {
    this.vehicleReservationService.getCommitmentFeePayments(reservation_id)
    .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.isLoading = false;
            this.payments = response.data
          }else{
            this.isLoading = false;
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.errorMessage=error;
        }
      });
  }

  handleSubmit():void {

    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {

      const formData = {
        reservation_id: this.reservation_id,
        ...this.form.value,
      };

      if(Number.isNaN(Number(this.form.value.amount_paid.replaceAll(",","")))) {
        this.submitted = false;
        this.errorMessage="Invalid payment amount. Only numbers are allowed!";
        this.isProcessing = false;
      }else{
        this.vehicleReservationService.postPayment(formData).subscribe(response => {
          if(response.status==='success'){

            this.submitted=false;
            this.isProcessing = false;
            this.globalService.setGlobalSuccessMessage(response.message);
          
            // Redirect back to vehicle-reservations
            this.router.navigate(['view-vehicle-reservations',this.vehicle_id]);
          }else if(response.status==='error'){
            this.submitted=false;
            this.isProcessing = false;
            this.globalService.setGlobalErrorMessage(response.message);
          }else{
            this.submitted=false;
            this.isProcessing = false;
            this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
          }
        },
        error => {
          this.errorMessage=error;
          this.isProcessing = false;
        });
      }
    }else{
      this.isProcessing = false;
    }
  }
}
