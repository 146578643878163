import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class VehicleReservationService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient, private errorHandler: ConfigErrorHandler) { }

   /**
   * Get previous reservations
   * @returns Array
   */
   getPreviousReservations(vehicle_id:string): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-previous-vehicle-reservations/${vehicle_id}`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Reserve vehicle
   * @param reservationData
   * @returns array
   */
  create(reservationData:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/reserve-vehicle`;

    return this.httpClient.post(apiURL,reservationData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get commitment fee payments
   * @returns Array
   */
  getCommitmentFeePayments(reservation_id:string): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-commitment-fee-payments/${reservation_id}`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Post additional commitment fee payments
   * @param paymentData
   * @returns array
   */
  postPayment(paymentData:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/post-commitment-fee`;

    return this.httpClient.post(apiURL,paymentData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Mark Reserved Vehicle as sold
   * @param vehicle_id 
   * @returns array
   */
  markReservedVehicleAsSold(vehicle_id:string|null,reservation_id:number):Observable<any>{
    
    let apiURL:string=`${this.baseURL}/v1/mark-reserved-vehicle-as-sold`;

    return this.httpClient.post(apiURL,{vehicle_id:vehicle_id,reservation_id:reservation_id}, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get reservation comment
   * @param reservation_id 
   */
  getComment(reservation_id: string){

    let apiURL:string=`${this.baseURL}/v1/get-reservation-comment/${reservation_id}`;

    return this.httpClient.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

   /**
   * Update reservation comment
   * @param formData
   * @returns array
   */
   updateComment(formData:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/update-reservation-comment`;

    return this.httpClient.post(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
