import { Component } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { GlobalService } from 'src/app/services/shared/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public isLoggedIn: boolean=false;
  public globalErrorMessage!: string;
  public globalSuccessMessage!: string;
  public globalwarningMessage!: string;
  showSuccessAlert: boolean = false;
  
  constructor(
    private authService: AuthService,
    private globalService: GlobalService
    ) {
      //Show global success message
      this.globalService.globalSuccessMessageUpdated.subscribe(() => {
        this.globalSuccessMessage = this.globalService.getGlobalSuccessMessage();
        this.showSuccessAlert=true;
        setTimeout(() => {
          this.showSuccessAlert=false;
        }, 5000); // Hide the alert after 5 seconds
      });

      //Show global error message
      this.globalService.globalErrorMessageUpdated.subscribe(() => {
        this.globalErrorMessage = this.globalService.getGlobalErrorMessage();
      });

      //Show global warning message
      this.globalService.globalWarningMessageUpdated.subscribe(() => {
        this.globalwarningMessage = this.globalService.getGlobalWarningMessage();
      });
      
  }

  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn;
  }
}
