import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class UpdateEntryService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  
  constructor(
    private http: HttpClient, 
    private errorHandler: ConfigErrorHandler
    ) 
  { 
   
  }

  /**
   * Get recently cleared vehicles
   * @returns Array
   */
  fetchRecentlyClearedVehicles(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/recently-cleared-vehicles`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get entry details
   * @param vehicle_id 
   */
  fetchEntryDetails(vehicle_id: string){

    let apiURL:string=`${this.baseURL}/v1/get-entry-details/${vehicle_id}`;

    return this.http.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Update entry
   */
  updateEntry(duty_data:any,attachment:any):Observable<any>{
    const formData = new FormData();
    formData.append("entry_id", duty_data.entry_id);
    formData.append("entry_no", duty_data.entry_no);
    formData.append("chassis_no", duty_data.chassis_no);
    formData.append("duty_amount", duty_data.duty_amount);
    formData.append("doc_ref_no", duty_data.doc_ref_no);
    formData.append("payment_ref_no", duty_data.payment_ref_no);
    formData.append("attachment", attachment);
    formData.append("payment_type", duty_data.payment_type);

    let apiURL:string=`${this.baseURL}/v1/update-entry`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }
}
