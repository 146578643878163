import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class DutyPaymentsService {

  private baseURL = environment.apiURL;

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient, 
    private errorHandler: ConfigErrorHandler
    ) { }

  /**
   * Get unpaid duties
   */
  getUnpaidDuties(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-unpaid-duties`;
    
    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Filter unpaid duties by consignee
   */
  getUnpaidDutiesByConsignee(consigneeId: string,agentId:string): Observable<ApiResponse>{
    const formData = new FormData();
    formData.append("consignee_id", consigneeId);
    formData.append("agent_id", agentId);

    let apiURL:string=`${this.baseURL}/v1/get-unpaid-duties-by-consignee`;
    
    return this.http.post<ApiResponse>(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Filter unpaid duties by agent
   */
   getUnpaidDutiesByAgent(consigneeId: string,agentId:string): Observable<ApiResponse>{
    const formData = new FormData();
    formData.append("consignee_id", consigneeId);
    formData.append("agent_id", agentId);

    let apiURL:string=`${this.baseURL}/v1/get-unpaid-duties-by-agent`;
    
    return this.http.post<ApiResponse>(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get unpaid duties total
   */
  getUnpaidDutiesTotal(){

    let apiURL:string=`${this.baseURL}/v1/get-unpaid-duties-total`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

   /**
   * Get unpaid duties total by consignee
   */
   getUnpaidDutiesTotalByConsignee(consigneeId: string,agentId:string){
    const formData = new FormData();
    formData.append("consignee_id", consigneeId);
    formData.append("agent_id", agentId);

    let apiURL:string=`${this.baseURL}/v1/get-unpaid-duties-total-by-consignee`;

    return this.http.post<ApiResponse>(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get unpaid duties total by agent
   */
  getUnpaidDutiesTotalByAgent(consigneeId: string,agentId:string){
    const formData = new FormData();
    formData.append("consignee_id", consigneeId);
    formData.append("agent_id", agentId);

    let apiURL:string=`${this.baseURL}/v1/get-unpaid-duties-total-by-agent`;

    return this.http.post<ApiResponse>(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get duty details
   * @param txn_id 
   */
  getDutyDetails(txn_id: string){

    let apiURL:string=`${this.baseURL}/v1/get-duty-details/${txn_id}`;

    return this.http.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Save duty payment
   */
  addDutyPayment(duty_payment_data:any,selected_duties: any,total_amount_paid:any):Observable<any>{
    const formData = new FormData();
    formData.append("total_amount_paid", total_amount_paid);
    formData.append("payment_date", duty_payment_data.payment_date);
    formData.append("bank_account", duty_payment_data.bank_account);
    formData.append("selected_duties", selected_duties);

    let apiURL:string=`${this.baseURL}/v1/add-duty-payment`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get paid duties pending
   * receipt attachments
   */
  getPaidDutiesPendingReceiptAttachments(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-paid-duties-pending-receipt-attachments`;
    
    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Attach payment receipt
   */
  attachPaymentReceipt(attachment:any,txn_id: string|null):Observable<any>{
    const formData = new FormData();
    formData.append("attachment", attachment);

    let apiURL:string=`${this.baseURL}/v1/attach-payment-receipt/${txn_id}`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get duties paid within
   * the las 30 days
   */
   fetchDutiesPaidLast30Days(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/transactions/get-duties-paid-last-30-days`;
    
    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Reverse paymentsId 
   * @param selectedPayments 
   * @returns 
   */
   reverseDutyPayments(selectedPayments: any[]): Observable<any> {

    let apiURL:string=`${this.baseURL}/v1/transactions/reverse-paid-duties`;

    const data = {
      selected_payments: selectedPayments
    };

    return this.http.post<any>(apiURL, data);
  }
}
