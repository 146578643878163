import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BillsOfLadingService } from 'src/app/services//suppliers/bills-of-lading.service';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/shared/global.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bills-of-lading',
  templateUrl: './bills-of-lading.component.html',
  styleUrls: ['./bills-of-lading.component.css']
})
export class BillsOfLadingComponent implements OnInit{
  @ViewChild('UpdateAttachmentModal') modalElement!: ElementRef;
  @ViewChild('MarkBLsAsShippedModal') markBLsAsShipped!: ElementRef;

  public userPermissions: string[] = [];
  page: number = 1;
  count: number = 0;
  bills_of_lading: any[] = [];
  agents: any[] = [];
  selectedBLs: any[] = []; // Array to store selected BLs
  selectedAgentId: string='';
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  searchText: string = '';
  public isLoading: boolean = false;
  total_units: any = 0;
  total_units_added: any = 0;
  form!: FormGroup;
  shippedBLsForm!: FormGroup;
  fileName: string="Choose an image or a PDF file";
  attachment: any;
  isProcessing: boolean = false;
  submitted: boolean = false;
  public bl_no:string='';
  public modalErrorMessage: string | null = null;
  waybill_fileName: string="Choose an image or a PDF file";
  waybill_attachment: any;

  constructor(
      private billsOfLadingService: BillsOfLadingService, 
      private authService: AuthService,
      private globalService: GlobalService,
      private formBuilder: FormBuilder
  ){
    this.form = this.formBuilder.group({
      attachment:[null,Validators.required]
    });

    this.shippedBLsForm = this.formBuilder.group({
      date_shipped: ['',Validators.required],
      waybill_no: [''],
      waybill_attachment:[null]
    });
  }

  ngOnInit(): void {
    this.isLoading=true;
    this.userPermissions=this.authService.getPermissions();
    this.getBillsOflading();
  }

  getBillsOflading(): void {
    this.billsOfLadingService.getUnshippedBLs()
    .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.isLoading = false;
            this.bills_of_lading = response.data
            this.getTotalUnits();
            this.getTotalUnitsAdded();
          }else{
            this.isLoading = false;
            this.errorMessage=response.message;
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.errorMessage=error;
        }
      });
  }

  // Function to handle checkbox change event
  checkboxChange(event: any, item: any) {
    if (event.target.checked) {
      // Add checked checkbox to the array
      this.selectedBLs.push(item);
    } else {
      // Remove unchecked checkbox from the array
      const index = this.selectedBLs.indexOf(item);
      if (index !== -1) {
        this.selectedBLs.splice(index, 1);
      }
    }
  }

  /**
   * Select shipped BLs
   */
  selectShippedBLs(){
    if(this.selectedBLs.length===0){
      this.errorMessage="Please select shipped BLs!";
    }else{
      if (this.markBLsAsShipped) {
        (<any>$(this.markBLsAsShipped.nativeElement)).modal('show');
      }
    }
  }

   /**
   * Handle selected waybill attachment
   * @param event 
   */
   onWaybillFileSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.waybill_fileName = fileInput.files?.[0]?.name ?? 'Choose an image or a PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.waybill_attachment = file
    }
  }

  /**
   * Mark BL(s) as shipped
   */
  markSelectedBLsAsShipped()
  {
    this.submitted=true;
    this.isProcessing=true;

    if (this.shippedBLsForm.valid) {

      if(this.selectedBLs.length===0){

        this.errorMessage="Please select shipped BLs!";
  
      }else{
        this.billsOfLadingService.markBLsAsShipped(this.shippedBLsForm.value,this.waybill_attachment, this.selectedBLs)
        .subscribe({
          next: (response) => {
            if(response.status==='success'){
              this.isProcessing=false;
              this.submitted = false;

              //Close modal
              if(this.markBLsAsShipped) {
                (<any>$(this.markBLsAsShipped.nativeElement)).modal('hide');
              }
              //Show success message
              this.successMessage=response.message;
              this.errorMessage=null;
              //Clear error messages
              this.modalErrorMessage=null;
              //Clear BLs array
              this.selectedBLs.length = 0;
              //Clear selected waybill attachment
              this.waybill_attachment=null;
              this.waybill_fileName="Choose an image or a PDF file";
              //Reset form
              this.shippedBLsForm.reset();
              // Refresh to BL list
              this.isLoading=true;
              this.getBillsOflading();
            }else{
              this.isProcessing=false;
              //Show error message
              this.modalErrorMessage=response.message;
            }
          },
          error: (error) => {
            this.isProcessing=false;
            // Handle the error
            if(error.status==413){
              this.modalErrorMessage="The attached file is too large. Maximum allowed size limit is 5MB!";
            }else{
              this.modalErrorMessage="An Error occurred while updating BLs shipment status. Please try again!";
            }

            console.error('Error marking BLs as shipped:', error);
          }
        });
      }
    }else{
      this.isProcessing=false;
    }
  }

  resetBLSelection(){
    //Clear BLs array
    this.selectedBLs.length = 0;
    // Refresh to BL list
    this.getBillsOflading();
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.getBillsOflading();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  getTotalUnits(): void{
    this.billsOfLadingService.getUnshippedTotalUnits().subscribe((response: ApiResponse) =>this.total_units = response.data);
  }

  getTotalUnitsAdded(): void{
    this.billsOfLadingService.getUnshippedTotalUnitsAdded().subscribe((response: ApiResponse) =>this.total_units_added = response.data);
  }

  openModal(bl_no:string){
    this.bl_no=bl_no;

    if (this.modalElement) {
      (<any>$(this.modalElement.nativeElement)).modal('show');
    }
  }

  closeModal():void {
    if (this.modalElement) {
      (<any>$(this.modalElement.nativeElement)).modal('hide');
    }
  }

   /**
   * Handle selectd file
   * @param event 
   */
   onFileSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.fileName = fileInput.files?.[0]?.name ?? 'Choose an image or a PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.attachment = file
    }
  }

  uploadAttachment(){
    this.submitted = true;
    this.isProcessing=true;

    if (this.form.valid) {
      this.billsOfLadingService.uploadAttachment(this.attachment,this.bl_no)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.globalService.setGlobalSuccessMessage(response.message);
            this.closeModal();
            this.getBillsOflading();
          }else if(response.status==='upload_error'){
            this.submitted = false;
            this.globalService.setGlobalWarningMessage(response.message);
            this.closeModal();
          }else if(response.status==='warning'){
            this.submitted = false;
            this.globalService.setGlobalWarningMessage(response.message);
            this.closeModal();
          }else{
            this.submitted = false;
            this.isProcessing=false;
            this.errorMessage=response.message;
            this.closeModal();
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isProcessing=false;
          this.errorMessage=error;
          this.closeModal();
        }
      });
    }else{
      this.isProcessing=false;
    }
  }

  deleteBL(event: Event,bl_id:string,bl_no:string){
    event.preventDefault();
    this.confirmDelete(bl_id,bl_no);
  }

  confirmDelete(bl_id: string,bl_no: string){
    Swal.fire({
      title: 'Confirm Delete',
      text: 'Are you sure you want to delete the BL identified by BL No '+bl_no+'?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.isProcessing=true;

        this.billsOfLadingService.deleteBL(bl_id,bl_no)
        .subscribe({
          next: (response) => {
            if(response.status==='success'){
              this.isProcessing=false;
              this.successMessage=response.message;
              this.errorMessage=''; //Clear error message incase it's available
             
              //Refresh BL List
              this.getBillsOflading();
            }else if(response.status==='error'){
              this.isProcessing=false;
              this.errorMessage=response.message;
            }else{
              this.isProcessing=false;
              this.errorMessage=response.message;
            }
          },
          error: (error) => {
            this.isProcessing=false;
            this.errorMessage=error;
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      
      }
    })
  }
}
