 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>New Role</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/settings/roles-list"><i class="fa fa-list-ul"></i>&nbsp;View Roles</a></li>
               <li class="breadcrumb-item active">New Role</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
         <h3 class="card-title">New Role</h3>
          <!--Error Message-->
          <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            <span [innerHTML]="errorMessage"></span>
          </div>
          <!--Success Message-->
          <div class="alert alert-success" role="alert" *ngIf="successMessage">
            {{successMessage}}
          </div>
       </div>
       <!-- /.card-header -->
       <div class="card-body">
           <form [formGroup]="form" (ngSubmit)="handleSubmit()">
               <div class="row clearfix">
                   <div class="col-md-4">
                       <div class="form-group">
                           <label>Role Name:<span style="color:red;">*</span></label>
                           <input type="text" formControlName="role_name" class="form-control" placeholder="Role Name" value="">
                           <span *ngIf="submitted && form.get('role_name')?.hasError('required')" style="color:red"> <strong>Please enter role name!</strong></span>
                       </div>
                   </div>
               </div>
           <div class="table-responsive">
                <table class="table">
                    <tr>
                        <td><b><i>Permissions:</i></b></td>
                    </tr>
                    <tr>
                        <td><b>Exports:</b></td>
                        <td>
                            <input type="checkbox" formControlName="exp-add-unit" value="exp-add-unit">
                            Add Unit
                        </td>
                        <td>
                            <input type="checkbox" formControlName="exp-enter-bl" value="exp-enter-bl">
                            Enter BL
                        </td>
                        <td>
                            <input type="checkbox" formControlName="assign-units-to-bl" value="assign-units-to-bl">
                            Assign Units to BL
                        </td>
                        <td>
                            <input type="checkbox" formControlName="exp-view-bl-list" value="exp-view-bl-list">
                            View BL List
                        </td>
                        <td>
                            <input type="checkbox" formControlName="exp-edit-bl" value="exp-edit-bl">
                            Edit BL
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="mark-bl-as-shipped" value="mark-bl-as-shipped">
                            Mark BLs As Shipped
                        </td>
                        <td>
                            <input type="checkbox" formControlName="exp-delete-bl" value="exp-delete-bl">
                            Delete BL
                        </td>
                        <td>
                            <input type="checkbox" formControlName="exp-view-bl-units" value="exp-view-bl-units">
                            View Units In BL
                        </td>
                        <td>
                            <input type="checkbox" formControlName="exp-view-stk-list" value="exp-view-stk-list">
                            View Stock List
                        </td>
                        <td>
                            <input type="checkbox" formControlName="exp-edit-unit" value="exp-edit-unit">
                            Edit Unit
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="exp-upload-vhcl-photos" value="exp-upload-vhcl-photos">
                            View/Upload Vehicle Photos
                        </td>
                        <td>
                            <input type="checkbox" formControlName="exp-view-vhcl-atts" value="exp-view-vhcl-atts">
                            View/Upload Vehicle Attachments
                        </td>
                        <td>
                            <input type="checkbox" formControlName="exp-update-vsl-eta" value="exp-update-vsl-eta">
                            Update Vessel ETA
                        </td>
                        <td>
                            <input type="checkbox" formControlName="exp-upload-bl-att" value="exp-upload-bl-att">
                            Upload BL Attachment
                        </td>
                        <td>
                            <input type="checkbox" formControlName="add-imp-docs" value="add-imp-docs">
                            Add Import Documents
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="exp-del-vhcl-photos" value="exp-del-vhcl-photos">
                            Delete Vehicle Photos
                        </td>
                        <td>
                            <input type="checkbox" formControlName="exp-delete-unit" value="exp-delete-unit">
                            Delete Unit
                        </td>
                    </tr>
                    <tr>
                        <td><b>Customs & Clearing:</b></td>
                        <td>
                            <input type="checkbox" formControlName="enter-bl" value="enter-bl">
                            Enter BL
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-bl-list" value="view-bl-list">
                            View BL List
                        </td>
                        <td>
                            <input type="checkbox" formControlName="edit-bl" value="edit-bl">
                            Edit BL
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-bl" value="view-bl">
                            View BL
                        </td>
                        <td>
                            <input type="checkbox" formControlName="update-bl" value="update-bl">
                            Update BL (Add Units)
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="delete-bl" value="delete-bl">
                            Delete BL
                        </td>
                        <td>
                            <input type="checkbox" formControlName="assign-bl" value="assign-bl">
                            Assign BL to Agent
                        </td>
                        <td>
                            <input type="checkbox" formControlName="print-qr-codes" value="print-qr-codes">
                            Print QR Codes
                        </td>
                        <td>
                            <input type="checkbox" formControlName="print-bl" value="print-bl">
                            Print BL
                        </td>
                        <td>
                            <input type="checkbox" formControlName="add-port-chgs" value="add-port-chgs">
                            Add Port Charges
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="add-entry" value="add-entry">
                            Add Entry
                        </td>
                        <td>
                            <input type="checkbox" formControlName="update-entry" value="update-entry">
                            Update Entry (Add additional duty)
                        </td>
                        <td>
                            <input type="checkbox" formControlName="add-do-rad-pmt" value="add-do-rad-pmt">
                            Add D.O & Radiation Payments
                        </td>
                        <td>
                            <input type="checkbox" formControlName="add-duty-pmt" value="add-duty-pmt">
                            Add Duty Payment
                        </td>
                        <td>
                            <input type="checkbox" formControlName="attach-duty-pmt-rcpt" value="attach-duty-pmt-rcpt">
                            Attach Duty Payment Receipts
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="pay-agents" value="pay-agents">
                            Pay Agents
                        </td>
                        <td>
                            <input type="checkbox" formControlName="add-cnf-pmt" value="add-cnf-pmt">
                            Add C & F Payments
                        </td>
                        <td>
                            <input type="checkbox" formControlName="update-vsl-eta" value="update-vsl-eta">
                            Update Vessel ETA
                        </td>
                        <td>
                            <input type="checkbox" formControlName="upload-bl-att" value="upload-bl-att">
                            Upload BL Attachments
                        </td>
                        <td>
                            <input type="checkbox" formControlName="edit-entry" value="edit-entry">
                            Edit Entry
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="edit-port-chgs-inv" value="edit-port-chgs-inv">
                            Edit Port Charges Invoice
                        </td>
                        <td>
                            <input type="checkbox" formControlName="del-port-chgs-inv" value="del-port-chgs-inv">
                            Delete Port Charges Invoice
                        </td>
                    </tr>
                    <tr>
                        <td><b>Stock Management:</b></td>
                        <td>
                            <input type="checkbox" formControlName="view-vhcls-in-transit" value="view-vhcls-in-transit">
                            View Vehicles in Tansit
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-vhcl-transfer-hist" value="view-vhcl-transfer-hist">
                            View Vehicle Transfer History
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-vhcl-dtls-exp-sum" value="view-vhcl-dtls-exp-sum">
                            View Vehicle Details & Exp. Summary
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-all-avail-stk" value="view-all-avail-stk">
                            View All Available Stock
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-stk-enr" value="view-stk-enr">
                            View Stock En route
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="view-stk-at-cfs" value="view-stk-at-cfs">
                            View Stock At CFS
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-sld-cars" value="view-sld-cars">
                            View Sold Cars
                        </td>
                        <td>
                            <input type="checkbox" formControlName="print-stk" value="print-stk">
                            Print Stock
                        </td>
                    </tr>
                    <tr>
                        <td><b>Vehicle Management:</b></td>
                        <td>
                            <input type="checkbox" formControlName="view-vhcl-list" value="view-vhcl-list">
                            View Vehicle List
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-vhcl-details" value="view-vhcl-details">
                            View Vehicle Details
                        </td>
                        <td>
                            <input type="checkbox" formControlName="add-vhcl-photos" value="add-vhcl-photos">
                            Add Vehicle Photos
                        </td>
                        <td>
                            <input type="checkbox" formControlName="edit-vhcl" value="edit-vhcl">
                            Edit Vehicle
                        </td>
                        <td>
                            <input type="checkbox" formControlName="add-vhcl-atts" value="add-vhcl-atts">
                            Add Vehicle Attachments
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="view-vhcl-atts" value="view-vhcl-atts">
                            View Vehicle Attachments
                        </td>
                        <td>
                            <input type="checkbox" formControlName="rplc-vhcl-atts" value="rplc-vhcl-atts">
                            Replace Vehicle Attachments
                        </td>
                        <td>
                            <input type="checkbox" formControlName="import-vhcl-regnos" value="import-vhcl-regnos">
                            Import Reg. Numbers
                        </td>
                        <td>
                            <input type="checkbox" formControlName="add-trade-in-vhcl" value="add-trade-in-vhcl">
                            Add Trade In Vehicle
                        </td>
                        <td>
                            <input type="checkbox" formControlName="ver-spare-parts" value="ver-spare-parts">
                            Verify Spare Parts
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="update-sp" value="update-sp">
                            Update Price
                        </td>
                        <td>
                            <input type="checkbox" formControlName="transfer-vhcl-sys" value="transfer-vhcl-sys">
                            Transfer Vehicle via System
                        </td>
                        <td>
                            <input type="checkbox" formControlName="del-vhcl-photos" value="del-vhcl-photos">
                            Delete Vehicle Photos
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-port-photos-videos" value="view-port-photos-videos">
                            View Port Photos & Videos
                        </td>
                        <td>
                            <input type="checkbox" formControlName="add-vhcl-other-charges" value="add-vhcl-other-charges">
                            Add Other Charges
                        </td>
                    </tr>
                    <tr>
                        <td><b>System Administration:</b></td>
                        <td>
                            <input type="checkbox" formControlName="mng-vhcl-makes" value="mng-vhcl-makes">
                            Manage Vehicle Makes
                        </td>
                        <td>
                            <input type="checkbox" formControlName="mng-vhcl-models" value="mng-vhcl-models">
                            Manage Vehicle Models
                        </td>
                        <td>
                            <input type="checkbox" formControlName="mng-vhcl-grds" value="mng-vhcl-grds">
                            Manage Vehicle Grades
                        </td>
                        <td>
                            <input type="checkbox" formControlName="mng-vhcl-body-types" value="mng-vhcl-body-types">
                            Manage Vehicle Body Types
                        </td>
                        <td>
                            <input type="checkbox" formControlName="mng-vhcl-colours" value="mng-vhcl-colours">
                            Manage Colours
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="mng-vhcl-spare-parts" value="mng-vhcl-spare-parts">
                            Manage Vehicle Spare Parts
                        </td>
                        <td>
                            <input type="checkbox" formControlName="mng-consignees" value="mng-consignees">
                            Manage Consignees
                        </td>
                        <td>
                            <input type="checkbox" formControlName="mng-agents" value="mng-agents">
                            Manage Agents
                        </td>
                        <td>
                            <input type="checkbox" formControlName="mng-cfs" value="mng-cfs">
                            Manage CFSes
                        </td>
                        <td>
                            <input type="checkbox" formControlName="mng-bnk-accts" value="mng-bnk-accts">
                            Manage Bank Accounts
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="mng-locations" value="mng-locations">
                            Manage Locations
                        </td>
                        <td>
                            <input type="checkbox" formControlName="mng-branches" value="mng-branches">
                            Manage Branches
                        </td>
                        <td>
                            <input type="checkbox" formControlName="mng-users" value="mng-users">
                            Manage Users
                        </td>
                        <td>
                            <input type="checkbox" formControlName="mng-roles" value="mng-roles">
                            Manage Roles
                        </td>
                        <td>
                            <input type="checkbox" formControlName="update-tax-settings" value="update-tax-settings">
                            Update Tax Settings
                        </td>
                    </tr>
                    <tr>
                        <td><b>Transactions:</b></td>
                        <td>
                            <input type="checkbox" formControlName="view-completed-bls" value="view-completed-bls">
                            View Completed BLs
                        </td>
                    </tr>
                    <tr>
                        <td><b>Cash Management:</b></td>
                        <td>
                            <input type="checkbox" formControlName="rcv-cash" value="rcv-cash">
                            Receive Cash
                        </td>
                        <td>
                            <input type="checkbox" formControlName="process-pmt" value="process-pmt">
                            Process Cash Payment
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-cash-trans" value="view-cash-trans">
                            View Cash Transactions
                        </td>
                        <td>
                            <input type="checkbox" formControlName="mng-cash-categories" value="mng-cash-categories">
                            Manage Cash Transaction Categories
                        </td>
                    </tr>
                    <tr>
                        <td><b>Integrations:</b></td>
                        <td>
                            <input type="checkbox" formControlName="create-app" value="create-app">
                            Create App & Access Tokens
                        </td>
                    </tr>
                    <tr>
                        <td><b>Stock Lists Columns:</b></td>
                        <td>
                            <input type="checkbox" formControlName="view-bl_no" value="view-bl_no">
                            BL Number
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-entry_no" value="view-entry_no">
                            Entry Number
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-doc_ref_no" value="view-doc_ref_no">
                            Doc Ref. Number
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-engine_no" value="view-engine_no">
                            Engine Number
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-engine_cc" value="view-engine_cc">
                            Engine CC
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="view-mileage" value="view-mileage">
                            Mileage
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-reg_no" value="view-reg_no">
                            Reg No.
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-duty_paid" value="view-duty_paid">
                            Duty Paid
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-cnf" value="view-cnf">
                            C & F
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-do_charges" value="view-do_charges">
                            D.O Charges
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="view-rad_charges" value="view-rad_charges">
                            Radiation Charges
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-port_charges" value="view-port_charges">
                            Port Charges
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-whse_charges" value="view-whse_charges">
                            Warehouse Charges
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-agency_fees" value="view-agency_fees">
                            Agency Fees
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-other_charges" value="view-other_charges">
                            Other Charges
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="view-total_expe" value="view-total_expe">
                            Total Expenses
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-selling_rice" value="view-selling_rice">
                            Selling Price
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-agency" value="view-agency">
                            Agency
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-duty_pmt_details" value="view-duty_pmt_details">
                            Duty Pmt. Details
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-company" value="view-company">
                            Company
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="view-location" value="view-location">
                            Vehicle Location
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-status" value="view-status">
                            Vehicle Status
                        </td>
                    </tr>
                    <tr>
                        <td><b>Mobile App:</b></td>
                        <td>
                            <input type="checkbox" formControlName="access-mobile-app" value="access-mobile-app">
                            Access the App
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-sp" value="view-sp">
                            View Selling Price
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-duty" value="view-duty">
                            View Duty
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-cnf-app" value="view-cnf-app">
                            View CNF
                        </td>
                        <td>
                            <input type="checkbox" formControlName="view-ot-chg" value="view-ot-chg">
                            View Other Charges
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="view-ttl-cst" value="view-ttl-cst">
                            View Total Cost
                        </td>
                        <td>
                            <input type="checkbox" formControlName="mob-upd-spare-parts" value="mob-upd-spare-parts">
                            Update Spare Parts
                        </td>
                        <td>
                            <input type="checkbox" formControlName="mob-view-vhcl-atts" value="mob-view-vhcl-atts">
                            View Vehicle Documents
                        </td>
                        <td>
                            <input type="checkbox" formControlName="take-web-photos" value="take-web-photos">
                            Take Website Photos
                        </td>
                        <td>
                            <input type="checkbox" formControlName="add-web-vhcl-features" value="add-web-vhcl-features">
                            Add Vehicle Features
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="upload-web-photos" value="upload-web-photos">
                            Upload Web Photos
                        </td>
                        <td>
                            <input type="checkbox" formControlName="transfer-vhcl" value="transfer-vhcl">
                            Transfer Vehicle
                        </td>
                        <td>
                            <input type="checkbox" formControlName="receive-vhcl" value="receive-vhcl">
                            Receive Vehicle
                        </td>
                        <td>
                            <input type="checkbox" formControlName="mob-view-stk-enr" value="mob-view-stk-enr">
                            View En route Stock
                        </td>
                        <td>
                            <input type="checkbox" formControlName="mob-view-stk-port-cfs" value="mob-view-stk-port-cfs">
                            View Port/CFS Stock
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input type="checkbox" formControlName="take-port-photos" value="take-port-photos">
                            Take Port Photos
                        </td>
                        <td>
                            <input type="checkbox" formControlName="take-port-videos" value="take-port-videos">
                            Record Port Video
                        </td>
                    </tr>
                    <tr>
                        <td><b>Sales Management:</b></td>
                        <td>
                            <input type="checkbox" formControlName="process-sale" value="process-sale">
                            Process Sale
                        </td>
                        <td>
                            <input type="checkbox" formControlName="print-del-nt" value="print-del-nt">
                            Print Delivery Note
                        </td>
                        <td>
                            <input type="checkbox" formControlName="attach-sgn-del-nt" value="attach-sgn-del-nt">
                            Attach Signed Delivery Note
                        </td>
                        <td>
                            <input type="checkbox" formControlName="edit-del-nt-cust-info" value="edit-del-nt-cust-info">
                            Edit Delivery Note: Customer Info
                        </td>
                    </tr>
                </table>
           </div>
           <div class="box-footer">
                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>
                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Save</button>
                </ng-template>
           </div>
        </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>