import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class EntryService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  
  constructor(
    private http: HttpClient, 
    private errorHandler: ConfigErrorHandler
    ) 
  { 
   
  }

  /**
   * Get imported vehicles
   * @returns Array
   */
  getImportedVehicles(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/imported-vehicles`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get single vehicle record
   * @param vehicle_id 
   */
  getVehicle(vehicle_id: string){

    let apiURL:string=`${this.baseURL}/v1/get-vehicle/${vehicle_id}`;

    return this.http.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Save entry
   */
  addEntry(entry_data:any,entry_attachment:any,pmt_slip_attachment:any,vehicle_id: string|null):Observable<any>{
    const formData = new FormData();
    formData.append("chassis_no", entry_data.chassis_no);
    formData.append("entry_no", entry_data.entry_no);
    formData.append("entry_date", entry_data.entry_date);
    formData.append("duty_amount", entry_data.duty_amount);
    formData.append("doc_ref_no", entry_data.doc_ref_no);
    formData.append("payment_ref_no", entry_data.payment_ref_no);
    formData.append('entry_attachment', entry_attachment);
    formData.append('pmt_slip_attachment', pmt_slip_attachment);

    let apiURL:string=`${this.baseURL}/v1/add-entry/${vehicle_id}`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get editable entries
   * @returns Array
   */
  getEditableEntries(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/editable-entries`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get single entry record for edting
   * @param txn_id 
   */
  getEntry(txn_id: string){

    let apiURL:string=`${this.baseURL}/v1/edit-entry/${txn_id}`;

    return this.http.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Update entry
   */
  updateEntry(entry_data:any,entry_id: string|null,txn_id: string|null):Observable<any>{
    const formData = new FormData();
    formData.append("chassis_no", entry_data.chassis_no);
    formData.append("entry_no", entry_data.entry_no);
    formData.append("entry_date", entry_data.entry_date);
    formData.append("duty_amount", entry_data.duty_amount);
    formData.append("doc_ref_no", entry_data.doc_ref_no);
    formData.append("payment_ref_no", entry_data.payment_ref_no);

    let apiURL:string=`${this.baseURL}/v1/edit-entry/${entry_id}/${txn_id}`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Update entry
   */
  updateUnofficialDuty(entry_data:any,txn_id: string|null):Observable<any>{
    const formData = new FormData();
    formData.append("chassis_no", entry_data.chassis_no);
    formData.append("duty_amount", entry_data.duty_amount);

    let apiURL:string=`${this.baseURL}/v1/unofficial-duty/edit-entry/${txn_id}`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get entry attachments
   * @returns Array
   */
  getEntryAttachments(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-entry-attachments`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Update entry attachments
   */
  updateEntryAttachments(txn_id: string,entry_attachment:any,pmt_slip_attachment:any):Observable<any>{
    const formData = new FormData();
    formData.append("txn_id", txn_id);
    formData.append('entry_attachment', entry_attachment);
    formData.append('pmt_slip_attachment', pmt_slip_attachment);
    
    let apiURL:string=`${this.baseURL}/v1/update-entry-attachments`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Update additional duty
   * payment slip attachment
   */
   updatePaymentSlipAttachment(txn_id: string,pmt_slip_attachment:any):Observable<any>{
    const formData = new FormData();
    formData.append("txn_id", txn_id);
    formData.append('attachment', pmt_slip_attachment);
    
    let apiURL:string=`${this.baseURL}/v1/update-pmtslip-attachment`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Filter imported vehicles
   */
  filterImportedVehicles(consigneeId: string,agentId:string,startDate:any,endDate:any):Observable<any>{
    const formData = new FormData();
    formData.append("consignee_id", consigneeId);
    formData.append("agent_id", agentId);
    formData.append("eta_start_date", startDate);
    formData.append("eta_end_date", endDate);

    let apiURL:string=`${this.baseURL}/v1/filter-imported-vehicles`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get Entry & Payment Slip or Additional Duty Payment Slip URL
   * @param txn_id 
   */
  getEntryAndPaymentSlip(entry_id:string,txn_id: string){

    let apiURL:string=`${this.baseURL}/v1/get-entry-url/${entry_id}/${txn_id}`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
