import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/shared/global.service';
import { ActivatedRoute, Params,Router } from '@angular/router';
import { BillsOfLadingService } from 'src/app/services/bills-of-lading/bills-of-lading.service';

@Component({
  selector: 'app-enter-do-payments',
  templateUrl: './enter-do-payments.component.html',
  styleUrls: ['./enter-do-payments.component.css']
})
export class EnterDoPaymentsComponent implements OnInit {

  form!: FormGroup;
  submitted: boolean = false;
  public errorMessage: string | null = null;
  public bl_id: string | null = null;
  public bl_no: string | null = null;
  public paymentStatus: any;
  public isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalService: GlobalService,
    private billsOfLadingService: BillsOfLadingService,
  ){
    this.form = this.formBuilder.group({
      bl_no: ['', Validators.required],
      do_amount: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.bl_id = param['get']('bl_id')
      this.bl_no = param['get']('bl_no')
    })

    if(!this.bl_id || !this.bl_no){
      //Navigate back to the list with error
      this.errorMessage="Unable to select BL for adding payment!";
      this.router.navigate(['do-rad-payment/select-bl']);
    }else{
      this.isLoading=true;
      this.getPaymentStatus(this.bl_id);
    }
  }

  /**
   * Get payment status
   * @param bl_id 
   */
  getPaymentStatus(bl_id: string): void {
    this.billsOfLadingService.getDO_RadiationPaymentStatus(bl_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.isLoading=false;
          this.errorMessage="Oops! Unable to determine payment status!";
        }else{
          this.isLoading=false;
          this.paymentStatus = response;
          
          if(this.paymentStatus.pmt_status===2 || this.paymentStatus.pmt_status===3 || this.paymentStatus.pmt_status===4){ //D.O already paid go to radiation and IDF payment
            this.router.navigate([`enter-radiation-payment/${bl_id}`]);
          }
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.errorMessage=error;
      }
    });
  }

  handleSubmit() {
    this.submitted = true;
   
    if(this.form.valid) {
      if(Number.isNaN(Number(this.form.value.do_amount))) {
        this.submitted = false;
        this.errorMessage="Invalid D.O amount. Only numbers are allowed!";
      }else{
        this.submitted = false;
        this.globalService.setdoAmountPaid(this.form.value.do_amount);
        this.router.navigate([`enter-radiation-payment/${this.bl_id}`]);
      }
    }
  }
}
