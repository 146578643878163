import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-column-toggle',
  templateUrl: './column-toggle.component.html',
  styleUrls: ['./column-toggle.component.css']
})
export class ColumnToggleComponent {
  @Input() columns: string[] = [];
  @Input() visibleColumns: { [key: string]: boolean } = {};
  @Output() columnToggled = new EventEmitter<string>();

  toggleColumn(column: string) {
    this.columnToggled.emit(column);
  }
}
