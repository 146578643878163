import { Component, OnInit } from '@angular/core';
import { DutyPaymentsService } from 'src/app/services/payments/duty-payments.service';
import { GlobalService } from 'src/app/services/shared/global.service';

@Component({
  selector: 'app-reverse-duty-payments',
  templateUrl: './reverse-duty-payments.component.html',
  styleUrls: ['./reverse-duty-payments.component.css']
})
export class ReverseDutyPaymentsComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  paid_duties:any[] = [];
  searchText: string = '';
  isLoading: boolean = false;
  errorMessage: string | null = null;
  successMessage: string | null = null;
  warningMessage: string | null = null;
  selectedPayments: any[] = []; // Array to store selected payments to reverse

  constructor(
    private dutyPaymentsService: DutyPaymentsService,
    private globalService: GlobalService,
    ){}

  ngOnInit(): void {
    this.isLoading=true;
    this.getDutiesPaidLast30Days();
  }

  /**
   * Get all paid within last 30 days
   */
  getDutiesPaidLast30Days()
  {
    this.dutyPaymentsService.fetchDutiesPaidLast30Days().subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.isLoading = false;
          this.paid_duties=response.data
        }else{
          this.isLoading = false;
          this.errorMessage=response.message;
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.errorMessage=error;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
    this.page=1;
  }

  // Function to handle checkbox change event
  checkboxChange(event: any, item: any) {
    if (event.target.checked) {
      // Add checked checkbox to the array
      this.selectedPayments.push(item);
    } else {
      // Remove unchecked checkbox from the array
      const index = this.selectedPayments.indexOf(item);
      if (index !== -1) {
        this.selectedPayments.splice(index, 1);
      }
    }
  }

  /**
   * Reverse selected payments
   */
  reversePayments(){
    if(this.selectedPayments.length===0){
      this.errorMessage="Please select duty payments to reverse!";
    }else{
      this.errorMessage=null;
      this.isLoading=true;
      this.dutyPaymentsService.reverseDutyPayments(this.selectedPayments)
      .subscribe(
        response => {
          if(response.status==='success'){
            this.globalService.setGlobalSuccessMessage(response.message);
            //Clear payments array
            this.selectedPayments.length = 0;
            // Refresh list to remove checks
            this.getDutiesPaidLast30Days();
          }else{
            this.errorMessage=response.message;
            this.isLoading=false;
          }
        },
        error => {
          // Handle the error
          this.isLoading=false;
          this.errorMessage="An Error occurred while reversing duty payment(s). Please try again!";
        }
      );
    }
  }
}
