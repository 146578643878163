<!--Loading Spinner-->
<div *ngIf="isLoading">
  <app-spinner></app-spinner>
</div>

<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
           
            <div class="col-sm-6">
              <a routerLink="/enter-bl" class="btn btn-success pull-left" class="btn btn-success" *ngIf="userPermissions.includes('enter-bl')"><i
                class="fa fa-plus-circle" aria-hidden="true">&nbsp;</i>New BL</a>
            </div><!-- /.col -->
           
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item active">Bills Of Lading</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
   
    <div class="row">
        <div class="col">
          <form>
                <div class="row">
                    <div class="col-md-8">
                        <div class="row" *ngIf="userPermissions.includes('assign-bl')">
                            <div class="col">
                                <select name="agents" class="form-control" [(ngModel)]="selectedAgentId">
                                  <option value="">Select</option>
                                  <option [value]="agent.id" *ngFor="let agent of agents">{{ agent.company_name }}</option>
                                </select>
                            </div>
                            <div class="col">
                                <input type="submit" (click)="assignBLstoAgent()" class="btn btn-outline-dark" value="Done">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                      <div class="row">
                          <div class="col">
                              <input type="text" class="form-control" placeholder="Type BL No., Consignee, Agent Name or Vessel Name here to search" (input)="onSearchTextChange($event)">
                          </div>
                      </div>
                  </div>
                </div>
            </form>
        </div>
    </div>
    </section>
      <!-- /.content-header -->
    <section class="content">
      <div class="container-fluid">
      <div class="card">
          <div class="card-header">
            <h3 class="card-title">Bills Of Lading</h3>
            <!--Error Message-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
              {{errorMessage}}
            </div>
            <!--Success Message-->
            <div class="alert alert-success" role="alert" *ngIf="successMessage">
              {{successMessage}}
            </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
               <!--Error messages here-->
            <table id="example1" class="table table-bordered table-hover">
              <thead>
              <tr>
                  <th *ngIf="userPermissions.includes('assign-bl')"></th>
                  <th>BL Date</th>
                  <th>Created On</th>
                  <th>BL No.</th>
                  <th>Consignee</th>
                  <th>No. Of Units ({{total_units}})</th>
                  <th>Units Added ({{total_units_added}})</th>
                  <th>Agent</th>
                  <th>Vessel Name</th>
                  <th>Vessel ETA</th>
                  <th *ngIf="userPermissions.includes('edit-bl') || userPermissions.includes('delete-bl') || userPermissions.includes('update-bl') || userPermissions.includes('print-qr-codes')"></th>
              </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let bl of bills_of_lading | searchBl: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                      <td *ngIf="userPermissions.includes('assign-bl')"><input type="checkbox" [(ngModel)]="bl.checked" (change)="checkboxChange($event, bl.bl_id)" id="bl" name="bl" class="form-control" *ngIf="bl.agent_name === null"></td>
                      <td>{{ bl.bl_date| date: 'dd-MM-yyyy' }}</td>
                      <td>{{ bl.date_created| date: 'dd-MM-yyyy' }}</td>
                      <td>{{ bl.bl_no }}</td>
                      <td>{{ bl.consignee_name }}</td>
                      <td>{{ bl.no_of_units }}</td>
                      <td>{{ bl.units_added }}</td>
                      <td>{{ bl.agent_name }}</td>
                      <td>{{ bl.vessel }}</td>
                      <td>{{ bl.vessel_eta| date: 'dd-MM-yyyy' }}</td>
                      <td *ngIf="userPermissions.includes('edit-bl') || userPermissions.includes('delete-bl') || userPermissions.includes('update-bl') || userPermissions.includes('print-qr-codes') || userPermissions.includes('upload-bl-att') || userPermissions.includes('print-bl')">
                        <div class="dropdown">
                            <button class="btn btn-default dropdown-toggle" type="button"
                                data-toggle="dropdown">Action
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li *ngIf="userPermissions.includes('edit-bl')"><a class="dropdown-item" routerLink="/edit-bl/{{ bl.bl_id }}">Edit</a></li>
                                <li *ngIf="userPermissions.includes('delete-bl')"><a class="dropdown-item" (click)="deleteBL($event,bl.bl_id,bl.bl_no)">Delete</a></li>
                                <li *ngIf="userPermissions.includes('update-bl')"><a class="dropdown-item" routerLink="/update-bl/{{ bl.bl_id }}">Update/View</a></li>
                                <li *ngIf="userPermissions.includes('print-qr-codes') && bl.units_added>0"><a class="dropdown-item" routerLink="/print-qr-codes/{{ bl.bl_no }}">Print QR Codes</a></li>
                                <li *ngIf="userPermissions.includes('print-bl') && bl.units_added>0"><a class="dropdown-item" routerLink="/print-bl/{{ bl.bl_id }}">Print BL</a></li>
                                <li *ngIf="userPermissions.includes('upload-bl-att') && bl.units_added === bl.no_of_units && bl.upload_flg==0"><a class="dropdown-item" (click)="openModal(bl.bl_no)"><i class="fas fa-paperclip"></i>&nbsp;Attachment</a></li>
                            </ul>
                        </div>
                      </td>
                  </tr>
              </tbody>
            </table>
            <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </section>

<!-- Modal -->
<div class="modal fade" #UpdateAttachmentModal tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Upload BL Attachment</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="form" (ngSubmit)="uploadAttachment()">
        <div class="modal-body">
            <div class="form-group">
                <label>Attachment:<span style="color:red;">*</span></label>
                <div class="input-group mb-3">
                    <div class="custom-file">
                      <input type="file" formControlName="attachment" accept="application/pdf,image/*" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onFileSelected($event)">
                      <label class="custom-file-label" for="inputGroupFile01">{{ fileName }}</label>
                    </div>
                </div>

                <span *ngIf="submitted && form.get('attachment')?.hasError('required')" style="color:red"> <strong>Please select BL attachment!</strong></span>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button class="btn btn-success" type="button" *ngIf="isProcessing; else showUploadBtn" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Processing...
          </button>
    
          <ng-template #showUploadBtn>
              <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Upload</button>
          </ng-template>
        </div>
      </form>
    </div>
  </div>
</div>