import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private baseURL = environment.apiURL;
  private tokenBaseURL = environment.tokenURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient, private errorHandler: ConfigErrorHandler) { }

  /**
   * Get apps
   * @returns Array
   */
  getAll(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/apps`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Create a new app
   * @param formData 
   * @returns array
   */
  create(formData:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/apps`;

    return this.httpClient.post(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get app name app details
   * @returns Array
   */
  show(id:string){

    let apiURL:string=`${this.baseURL}/v1/apps/${id}`;

    return this.httpClient.get(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Get access tokens by app
   * @returns Array
   */
  getAccessTokensByApp(appId:string): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/integration/access-tokens/${appId}`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Generate access token
   * @param credentials 
   * @returns array
   */
  generateAccessToken(credentials:any):Observable<any>{
    const data = {
      'grant_type':'client_credentials',
      'client_id':credentials.client_id,
      'client_secret':credentials.client_secret,
      'scope':'sync-data',
    };

    let apiURL:string=`${this.tokenBaseURL}/oauth/token`;

    return this.httpClient.post(apiURL,data,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Delete App
   * @param appId 
   * @returns array
   */
  deleteApp(appId: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/apps/${appId}`;

    return this.httpClient.delete(apiURL, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Revoke access token
   * @param tokenId 
   * @returns array
   */
  revokeToken(tokenId: string):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/integration/revoke-access-token/${tokenId}`;

    return this.httpClient.post(apiURL, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
 
}
