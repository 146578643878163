import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private httpClient: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }

  /**
   * Get all locations
   * @returns Array
   */
  getAllLocations(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/locations`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get BLs
   */
  getBLs(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/add-edit-location/get-bls`;
    
    return this.httpClient.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Create new location
   * @param formData 
   * @returns array
   */
  saveLocation(formData:any,bl_no:string):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/locations`;

    const post_data = {
      country_id: formData.country_id,
      location_name: formData.location_name,
      bl_no:bl_no,
    };

    return this.httpClient.post(apiURL,post_data, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get location for editing
   * @returns Array
   */
  edit(id:string){

    let apiURL:string=`${this.baseURL}/v1/locations/${id}/edit`;

    return this.httpClient.get(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Update location
   * @param formData
   * @param bl_no
   * @param Id
   * @returns array
   */
  update(formData:any,bl_no:string,Id: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/locations/${Id}`;

    const post_data = {
      country_id: formData.country_id,
      location_name: formData.location_name,
      bl_no:bl_no,
    };

    return this.httpClient.put(apiURL,post_data, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Delete location
   * @param userId 
   * @returns array
   */
   delete(locationId: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/locations/${locationId}`;

    return this.httpClient.delete(apiURL, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
