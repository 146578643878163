<!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                    <li class="breadcrumb-item"><a routerLink="/vehicles"><i class="fa fa-list-ul"></i>&nbsp;Vehicles</a></li>
                    <li class="breadcrumb-item active">View Vehicle Details</li>
                  </ol>
                </div><!-- /.col -->
              </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
    </section>

        <!--Loading Spinner-->
        <div *ngIf="isLoading">
            <app-spinner></app-spinner>
        </div>
          <!-- /.content-header -->
        <section class="content">
          <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">View Vehicle Details</h3>
                        <div style="float: right;">
                            <a (click)="openAddAttachmentModal()" class="btn btn-success pull-left" class="btn btn-success" *ngIf="userPermissions.includes('add-vhcl-atts')"><i
                                class="fa fa-paperclip" aria-hidden="true">Add Attachment&nbsp;</i></a>
                        </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <h3 class="my-3">{{vehicle?.data?.make}} {{vehicle?.data?.model}}</h3>
             
                                <form method="post" action="#">
                                    <div class="row clearfix">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Chassis No.:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.chassis_no}}" readonly>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Engine CC:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.engine_cc}}" readonly>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Engine No.:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.engine_no}}" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row clearfix">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Transmission:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.transmission}}" readonly>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Body Type:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.body_type}}" readonly>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Drive:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.drive}}" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row clearfix">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Fuel:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.fuel}}" readonly>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Mileage:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.mileage}}" readonly>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Colour:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.colour}}" readonly>
                                            </div>
                                        </div>
                                        </div>
                                    <div class="row clearfix">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Year:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.year}}" readonly>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Weight:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.weight}}" readonly>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>CBM:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.cbm}}" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row clearfix">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Reg No.:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.reg_no}}" readonly>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Status:</label>
                                                <input type="text" class="form-control" value="{{vehicle_status}}" readonly>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Location:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.location}} - {{vehicle.data.branch}}" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row clearfix">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>BL No:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.bl_no}}" readonly>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Duty Paid Total:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.duty_paid| currency:'Kshs '}}" readonly>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Port Charges:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.port_charges| currency:'Kshs '}}" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row clearfix">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Total Cost:</label>
                                                <input type="text" class="form-control" value="{{vehicle.data.total_cost| currency:'Kshs '}}" readonly>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="separator">Duty Payment Details</div>
                                <table id="duty_details" class="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>Doc Ref. No.</th>
                                            <th>Payment Ref. No.</th>
                                            <th>Amount Paid</th>
                                            <th>Payment Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let duty_payment of duty_payments">
                                            <td>{{duty_payment.doc_ref_no}}</td>
                                            <td>{{duty_payment.pmt_ref_no}}</td>
                                            <td>{{duty_payment.amount_paid| currency:'Kshs '}}</td>
                                            <td>{{duty_payment.pmt_date| date: 'dd-MM-yyyy'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h6><b>Available Spare Parts</b></h6>
                                <ul *ngIf="spare_parts && spare_parts.length > 0">
                                    <li *ngFor="let spare_part of spare_parts">{{spare_part.name}}</li>
                                </ul>
                                <p *ngIf="!spare_parts || spare_parts.length === 0" style="color: red;">No spare parts available!</p>                                
                            </div>
                            <div class="col-12 col-sm-6">
                                <table id="example1" class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Document Type</th>
                                        <th>Attached On</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let attachment of attachments">
                                        <td>{{attachment.doc_type}}</td>
                                        <td>{{attachment.attached_on| date: 'dd-MM-yyyy hh:mm a'}}</td>
                                        <td *ngIf="attachment.file_type==='image'"><a routerLink="/preview-vehicle-attachment/{{attachment.id}}/{{vehicle_id}}" class="btn btn-success" role="button" *ngIf="userPermissions.includes('view-vhcl-atts')"><i class="fa fa-eye" aria-hidden="true">&nbsp;</i>View</a></td>
                                        <td *ngIf="attachment.file_type==='pdf'">
                                            <a href="{{attachment.file_path}}" target="_blank" class="btn btn-success" role="button" *ngIf="userPermissions.includes('view-vhcl-atts')"> <i class="fa fa-eye" aria-hidden="true">&nbsp;</i>View</a>
                                        </td>
                                        <td *ngIf="attachment.updatable=='1'">
                                            <a (click)="openModal(attachment.upload_type,attachment.upload_ref,attachment.file_path)" class="btn btn-success" role="button" *ngIf="userPermissions.includes('rplc-vhcl-atts')"> <i class="fa fa-upload" aria-hidden="true">&nbsp;</i>Replace</a>
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div> 
                    </div>
                <!-- /.card-body -->
                </div>
          </div>
        </section>
         <!-- Modal -->
       <div class="modal fade" #UpdateVehicleAttachmentsModal tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Replace Vehicle Attachment</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!--Error message-->
                <div class="alert alert-danger" role="alert" *ngIf="popupErrorMessage">
                    {{popupErrorMessage}}
                </div>
                <!--Success message-->
                <div class="alert alert-success" role="alert" *ngIf="popupSuccessMessage">
                    {{popupSuccessMessage}}
                </div>

                 <!--Warning message-->
                <div class="alert alert-warning" role="alert" *ngIf="popupWarningMessage">
                    {{popupWarningMessage}}
                </div>

                <form [formGroup]="form" (ngSubmit)="updateAttachment()">
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="form-group">
                                <label>Attachment (Image or PDF):<span style="color:red;">*</span></label>
                                <div class="input-group mb-3">
                                    <div class="custom-file">
                                    <input type="file" formControlName="attachment" accept="application/pdf,image/*" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onReplacementAttachmentSelected($event)">
                                    <label class="custom-file-label" for="inputGroupFile01">{{ replacementFileName }}</label>
                                    </div>
                                </div>

                                <span *ngIf="submitted && form.get('attachment')?.hasError('required')" style="color:red"> <strong>Please choose a file to upload!</strong></span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" #closeModal class="btn btn-danger" data-dismiss="modal"><i class="fa fa-window-close" aria-hidden="true"></i>&nbsp;Cancel</button>

                        <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Processing...
                        </button>

                        <ng-template #showSaveBtn>
                            <button type="submit" class="btn btn-success" ><i class="fa fa-upload"
                                aria-hidden="true">&nbsp;</i>Upload</button>
                        </ng-template>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="modal fade" #AddAttachmentModal tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add Attachment</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

             <!--Error message-->
             <div class="alert alert-danger" role="alert" *ngIf="newAttPopupErrorMessage">
                {{newAttPopupErrorMessage}}
            </div>
            <!--Success message-->
            <div class="alert alert-success" role="alert" *ngIf="newAttPopupSuccessMessage">
                {{newAttPopupSuccessMessage}}
            </div>

             <!--Warning message-->
            <div class="alert alert-warning" role="alert" *ngIf="newAttPopupWarningMessage">
                {{newAttPopupWarningMessage}}
            </div>

            <form [formGroup]="addAttachmentForm" (ngSubmit)="uploadNewAttachment()">
              <div class="modal-body">
                <div class="form-group">
                    <label>Document Type:<span style="color:red;">*</span></label>
                    <select formControlName="doc_type" class="form-control" (change)="onSelectChange($event.target)">
                        <option value="">Select</option>
                        <option value="Jevic">Jevic</option>
                        <option value="Japan LogBook">Japan LogBook</option>
                        <option value="Japan LogBook Translation">Japan LogBook Translation</option>
                        <option value="Other Import Document">Other Import Document</option>
                        <option value="Other">Other</option>
                    </select>

                    <span *ngIf="submitted && addAttachmentForm.get('doc_type')?.hasError('required')" style="color:red"><strong>Please select document type!</strong></span>
                </div>
                <div class="form-group" *ngIf="docNameIsRequired">
                    <label>Document Name:<span style="color:red;">*</span></label>
                    <input type="text" formControlName="doc_name" class="form-control"  value="" placeholder="Enter the best description of the document">
                </div>
                <div class="form-group">
                    <label>Attachment (Image or PDF):<span style="color:red;">*</span></label>
                    <div class="input-group mb-3">
                        <div class="custom-file">
                            <input type="file" formControlName="new_attachment" accept="application/pdf,image/*" class="custom-file-input" id="new_attachment" aria-describedby="inputGroupFileAddon01" (change)="onNewAttachmentSelected($event)">
                            <label class="custom-file-label" for="inputGroupFile01">{{ newAttachmentFileName }}</label>
                        </div>
                    </div>
    
                    <span *ngIf="submitted && addAttachmentForm.get('new_attachment')?.hasError('required')" style="color:red"> <strong>Please choose a file to upload!</strong></span>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showUploadBtn" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Processing...
                </button>
          
                <ng-template #showUploadBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Upload</button>
                </ng-template>
              </div>
            </form>
          </div>
        </div>
    </div>