import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from 'src/app/services/shared/global.service';
import { BillsOfLadingService } from 'src/app/services/bills-of-lading/bills-of-lading.service';
import { ActivatedRoute, Params,Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { PortChargesService } from 'src/app/services/payments/port-charges.service';
import { PendingTasksHandler } from 'src/app/shared/pending-tasks-handler';

export interface Vehicle {
  vehicle_id: string;
  make: string;
  model: string;
  chassis_no:string;
  engine_cc: string;
  colour:string;
  body_type:string;
  weight:number;
  cbm:number;
  amount: number;
}

@Component({
  selector: 'app-add-port-charges',
  templateUrl: './add-port-charges.component.html',
  styleUrls: ['./add-port-charges.component.css']
})
export class AddPortChargesComponent implements OnInit{

  @ViewChild('bl_no', { static: true })
  bl_no!: ElementRef;

  @ViewChild('no_of_units', { static: true })
  no_of_units!: ElementRef;

  @ViewChild('total_amount', { static: true })
  total_amount!: ElementRef;

  @ViewChild('invoice_no', { static: true })
  invoice_no!: ElementRef;

  public billOfLading: any;
  public bl_id: string | null = null;
  public errorMessage: string | null = null;
  public warningMessage: string | null = null;
  public formatter: any;
  public portChargesAmountPaid!: number;
  private noOfUnits!:number;
  public portChargesAmountPaidPerUnit!:string
  vehicleList: Vehicle[] = [];
  paymentForm!: FormGroup;
  submitted: boolean = false;
  isProcessing: boolean = false;

  constructor(
    private globalService: GlobalService,
    private billsOfLadingService: BillsOfLadingService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private portChargesService: PortChargesService,
    private pendingTasksHandler: PendingTasksHandler,
    private router: Router
  ){
    this.paymentForm = this.formBuilder.group({
      BlNo: [''],
      vehicles: this.formBuilder.array([])
    });

    this.formatter = new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  get vehiclesArray() {
    return <FormArray>this.paymentForm.get('vehicles');
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.bl_id = param['get']('bl_id')
    })

    if(this.bl_id){
      this.getBLData(this.bl_id);
    }else{
      this.errorMessage="Unable to load BL information please try again!";
    }
  }

  ngAfterViewInit() {
    //Get vehicles
    const bl_number = this.bl_no.nativeElement.value;
    this.getVehiclesByBL(bl_number);

     //Calculate port charges amount per unit
     this.noOfUnits=this.no_of_units.nativeElement.value;
     this.portChargesAmountPaid=this.globalService.getportChargesAmountPaid();
 
     let amountPerUnit=this.portChargesAmountPaid/this.noOfUnits;
     this.portChargesAmountPaidPerUnit=this.formatter.format(amountPerUnit);
  }

   /**
   * Get single BL info
   * @param bl_id 
   */
  getBLData(bl_id: string): void {
    this.billsOfLadingService.getBL(bl_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.billOfLading = response;
    
          if(this.billOfLading.status==='no_data' || this.billOfLading.status.length===0){
            this.errorMessage=this.billOfLading.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  /**
   * Get vehicles associated with the selected BL
   * @param bl_no 
   */
  getVehiclesByBL(bl_no: string):void {
    this.billsOfLadingService.getVehicles(bl_no).subscribe((response: ApiResponse) =>{

      if(response.status==='no_data'){
        this.warningMessage="No vehicles associated with this BL were found!";
      }else{
        this.vehicleList=[...response.data];
        this.displayRows();
      }
    });
  }

  createRow(vehicle: any) {
    return this.formBuilder.group({
      vehicle_id: [vehicle.vehicle_id],
      make: [vehicle.make],
      model: [vehicle.model],
      chassis_no: [vehicle.chassis_no],
      engine_cc: [vehicle.engine_cc],
      colour: [vehicle.colour],
      body_type: [vehicle.body_type],
      weight: [vehicle.weight],
      cbm: [vehicle.cbm],
      amount: [vehicle.amount, [Validators.required]],
    });
  }

  displayRows() {
    let transformedItems = this.vehicleList.map((vehicle: any) =>
      this.createRow(vehicle)
    );
    this.paymentForm.setControl('vehicles', this.formBuilder.array(transformedItems));
  }

  /**
   * Post payments
   * @param formData 
   */
  handleSubmit() {
    this.submitted = true;
    this.isProcessing = true;

    if(this.paymentForm.valid) {
      this.submitted = false;
      this.isProcessing = false;

      const BlNo = this.bl_no.nativeElement.value;
      const invoiceNo = this.invoice_no.nativeElement.value;
      const totalAmount = this.total_amount.nativeElement.value;
      const vehicles = this.paymentForm.value.vehicles;

      if(invoiceNo==""){
        this.errorMessage="Invoice number is required!";
      }else{
        const payload = {
          bl_no: BlNo,
          invoice_no:invoiceNo,
          total_amount:totalAmount,
          vehicles: vehicles.map((vehicle:any) => ({
            vehicle_id: vehicle.vehicle_id,
            chassis_no: vehicle.chassis_no,
            amount: vehicle.amount
          }))
        };
  
        this.portChargesService.postPayments(payload).subscribe((response: ApiResponse) =>{
          
          if(response.status==='success'){
            this.isProcessing = false;
            this.globalService.setGlobalSuccessMessage(response.message);
            //Count No. of BLs pending Port Charges
            this.pendingTasksHandler.numberOfBLsPendingPortChargesPmt();
            // Redirect to BL list
            this.router.navigate(['bl-list']);
          }else{
            this.isProcessing = false;
            this.errorMessage=response.message;
          }
        });
      }
    }else{
      this.submitted = false;
      this.isProcessing = false;
      this.errorMessage="Please fill in amount paid for all the vehicles!";
    }

  }
}
