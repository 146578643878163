import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { SparePartService } from 'src/app/services/spare-parts/spare-part.service';


@Component({
  selector: 'app-edit-spare-part',
  templateUrl: './edit-spare-part.component.html',
  styleUrls: ['./edit-spare-part.component.css']
})
export class EditSparePartComponent implements OnInit{

  form!: FormGroup;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  isProcessing: boolean = false;
  submitted: boolean = false;
  public partId: string | null = null;
  public partData:any;

  constructor(
    private sparePartService:SparePartService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
  ){
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.partId = param['get']('id')
    })

    if(this.partId){
      this.fetchData(this.partId);
    }else{
      //Navigate back to spare parts list with error
      this.globalService.setGlobalErrorMessage("Unable to select spare part for editing!");
      this.router.navigate(['settings/spare-parts-list']);
    }
  }

  /**
   * Get spare part record
   * @param partId 
   */
  fetchData(partId: string): void {
    this.sparePartService.edit(partId)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.partData = response;
          if(this.partData.status==='no_data' || this.partData.status.length===0){
            this.errorMessage=this.partData.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  handleSubmit():void{
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      const formData = {
        ...this.form.value,
      };

      this.sparePartService.update(formData,this.partId).subscribe(response => {
        if(response.status==='success'){

          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalSuccessMessage(response.message);
         
          // Redirect back to makes list
          this.router.navigate(['settings/spare-parts-list']);
        }else if(response.status==='error'){
          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalErrorMessage(response.message);
        }else{
          this.submitted=false;
          this.isProcessing = false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
        }
      },
      error => {
        this.isProcessing = false;
        this.errorMessage=error;
      });
    }else{
      this.isProcessing = false;
    }
  }

}
