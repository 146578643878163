import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json','Accept':'application/json' });

  constructor(
    private httpClient: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }

  /**
   * Get roles
   * @returns Array
   */
  getAllRoles(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/roles`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Create new role
   * @param role_data
   * @returns array
   */
  saveRole(role_data:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/roles`;

    return this.httpClient.post(apiURL,role_data, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

   /**
 * Get role record
 * @param roleId 
 */
   getRoleData(roleId: string){

    let apiURL:string=`${this.baseURL}/v1/roles/${roleId}`;

    return this.httpClient.get(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Edit User
   * @param user_data 
   * @param roleId 
   * @returns array
   */
  updateRole(role_data:any,roleId: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/roles/${roleId}`;

    return this.httpClient.put(apiURL,role_data, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Delete role
   * @param roleId 
   * @returns array
   */
  deleteRole(roleId: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/roles/${roleId}`;

    return this.httpClient.delete(apiURL, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
