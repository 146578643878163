import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommitmentFeeRefundsService } from 'src/app/services/sales-management/commitment-fee-refunds.service';

@Component({
  selector: 'app-view-refunded-commitment-fees',
  templateUrl: './view-refunded-commitment-fees.component.html',
  styleUrls: ['./view-refunded-commitment-fees.component.css']
})
export class ViewRefundedCommitmentFeesComponent implements OnInit{
  form!: FormGroup;
  submitted: boolean = false;
  searchText: string = '';
  page: number = 1;
  count: number = 0;
  public errorMessage: string | null = null;
  public isLoading: boolean = false;
  public refunded_fees: any[] = [];

  constructor(
    private commitmentFeeRefundsService:CommitmentFeeRefundsService,
    private formBuilder: FormBuilder,
  ){
    this.form = this.formBuilder.group({
      date_from: ['', Validators.required],
      date_to: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.fetchRefundedCommitmentFees();
  }

  fetchRefundedCommitmentFees(): void {
    this.commitmentFeeRefundsService.getRefundedCommitmentFees()
    .subscribe({ 
        next: (response) => {
          if(response.status==='success'){
            this.isLoading = false;
            this.refunded_fees = response.data
          }else{
            this.isLoading = false;
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.errorMessage=error;
        }
      });
  }

  onSearchTextChange(event: Event):void {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  handleSubmit(){
    this.submitted = true;
    this.isLoading=true;
    
    if (this.form.valid) {
      this.commitmentFeeRefundsService.filterRefundedCommitmentFees(this.form.value)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.isLoading=false;
            this.refunded_fees = response.data
            this.errorMessage=null;
          }else{
            this.submitted = false;
            this.isLoading=false;
            this.errorMessage=response.message;
            this.refunded_fees = [];
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isLoading=false;
          this.refunded_fees = [];
          this.errorMessage=error;
        }
      });
    }else{
      this.isLoading=false;
    }
  }
}
