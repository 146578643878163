import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MakeService } from 'src/app/services/makes/make.service';
import { ModelService } from 'src/app/services/models/model.service';
import { ColourService } from 'src/app/services/colours/colour.service';
import { BodyTypeService } from 'src/app/services/body-types/body-type.service';
import { GradeService } from 'src/app/services/grades/grade.service';
import { StockManagementService } from 'src/app/services/suppliers/stock-management.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-add-unit',
  templateUrl: './add-unit.component.html',
  styleUrls: ['./add-unit.component.css']
})
export class AddUnitComponent implements OnInit{
  public isLoading: boolean = false;
  isProcessing: boolean = false;
  public errorMessage: string | null = null;
  public warningMessage: string | null = null;
  form!: FormGroup;
  makes: any[] = [];
  models: any[] = [];
  colours: any[] = [];
  body_types: any[] = [];
  grades: any[] = [];
  submitted: boolean = false;
  invalidMakeSelected: boolean = false;
  invalidModelSelected: boolean = false;

  jevicPlaceHolder: string="Image or PDF file";
  logBookPlaceHolder: string="Image or PDF file";
  logBookTransPlaceHolder: string="Image or PDF file";
  photosPlaceholder: string="Images only";
  otherDocsPlaceholder: string="Images or PDF files";
  jevic_attachment: any;
  logbook_attachment: any;
  logbook_trans_attachment: any;
  vehicle_photos: File[] = [];
  other_documents: File[] = [];
  user_language: any='en';

  constructor(
    private router: Router,
    private makeService: MakeService,
    private modelService: ModelService,
    private colourService: ColourService,
    private formBuilder: FormBuilder,
    private bodyTypeService:BodyTypeService,
    private gradeService:GradeService,
    private stockManagementService:StockManagementService,
    private globalService: GlobalService,
    private translate: TranslateService
  ){
    this.form = this.formBuilder.group({
      make_id: ['', Validators.required],
      model_id: ['', Validators.required],
      grade_id: [''],
      chassis_no: ['', Validators.required],
      engine_cc: ['', Validators.required],
      engine_no: ['', Validators.required],
      transmission: ['', Validators.required],
      body_type: ['', Validators.required],
      drive: ['', Validators.required],
      fuel: ['', Validators.required],
      mileage: ['', Validators.required],
      colour: ['', Validators.required],
      yr_of_mfg: ['', [Validators.required,Validators.minLength(4),Validators.maxLength(7)]],
      weight: [''],
      cbm: [''],
      reg_no: [''],
      condition: ['', Validators.required],
      no_of_doors: [''],
      no_of_seats: [''],
      jevic_attachment:[null],
      logbook_attachment:[null],
      logbook_trans_attachment:[null],
      other_documents:[null],
      photos:[null]
    });

    this.user_language=localStorage.getItem('language');
    translate.setDefaultLang('en');
    translate.use(this.user_language);
  }

  ngOnInit(): void {
    this.isLoading=true;
    this.getVehicleMakes();
  }

  /**
   * Get vehicle makes
   */
  getVehicleMakes(): void {
    this.makeService.getAllMakes().subscribe({
      next: (response) => {
        if(response.status=="success"){
          this.makes=response.data
          //Load colours
          this.getColours();
        }else{
          this.errorMessage=response.message;
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  /**
   * Get vehicle models associated with
   * the selected make
   * @param makeID 
   */
  getVehicleModels(makeID:string): void {
    this.modelService.getModelsByMake(makeID).subscribe({
      next: (response) => {
        if(response.status=="success"){
          this.models=response.data
        }else{
          this.errorMessage=response.message;
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  /**
   * Get colours
   */
  getColours(): void {
    this.colourService.getAllColours().subscribe({
      next: (response) => {
        if(response.status=="success"){
          this.colours=response.data
          //Load body types
          this. getBodyTypes();
        }else{
          this.errorMessage=response.message;
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  getBodyTypes():void {
    this.bodyTypeService.getAllBodyTypes().subscribe({
      next: (response) => {
        if(response.status=="success"){
          this.body_types=response.data
          this.isLoading=false;
        }else{
          this.errorMessage=response.message;
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  /**
   * Get vehicle grades associated with
   * the selected model
   * @param modelID 
   */
  getVehicleGrades(modelID:string): void {
    this.gradeService.getGradesByModel(modelID).subscribe({
      next: (response) => {
        if(response.status=="success"){
          this.grades=response.data
        }else{
          this.warningMessage=response.message;
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  //Load models & grades
  onSelectChange(target: EventTarget|null,option:string) {
    if (target instanceof HTMLSelectElement) {

      if(option==='make'){
        const makeID = target.value;
      
        if(makeID!=='' && makeID!==undefined){
          this.getVehicleModels(makeID);
        }else{
          this.invalidMakeSelected=true;
        }
      }else{
        const modelID = target.value;
      
        if(modelID!=='' && modelID!==undefined){
          this.getVehicleGrades(modelID);
        }else{
          this.invalidModelSelected=true;
        }
      }
    }
  }

  /**
   * Handle selected Jevic
   * @param event 
   */
  onJevicSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.jevicPlaceHolder = fileInput.files?.[0]?.name ?? 'Image or PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.jevic_attachment = file
    }
  }

   /**
   * Handle selected LogBook
   * @param event 
   */
   onLogBookSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.logBookPlaceHolder = fileInput.files?.[0]?.name ?? 'Image or PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.logbook_attachment = file
    }
  }

   /**
   * Handle selected LogBook Translation
   * @param event 
   */
   onLogBookTranslationSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.logBookTransPlaceHolder = fileInput.files?.[0]?.name ?? 'Image or PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.logbook_trans_attachment = file
    }
  }

   /**
   * Handle selected other documents
   * @param event 
   */
   onOtherDocsSelected(event: any) {
    //Show selected file
    this.otherDocsPlaceholder = event.target.files.length+" file(s) selected" ?? 'Images or PDF files';
    
    //Prepare photos for upload
    if (event.target.files && event.target.files.length) {

      this.other_documents.push(...event.target.files);
    }
  }

  /**
   * Handle selectd photos
   * @param event 
   */
  onPhotosSelected(event: any) {
    //Show selected file
    this.photosPlaceholder = event.target.files.length+" photo(s) selected" ?? 'Images Only';
    
    //Prepare photos for upload
    if (event.target.files && event.target.files.length) {

      this.vehicle_photos.push(...event.target.files);
    }
  }


  handleSubmit(): void {
    this.submitted = true;
    this.isProcessing = true;

    if (this.form.valid) {

      const formData = {
        ...this.form.value,
      };

      this.stockManagementService.addUnit(formData,this.jevic_attachment,this.logbook_attachment,this.logbook_trans_attachment,this.vehicle_photos,this.other_documents).subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.globalService.setGlobalSuccessMessage(response.message);
           //Clear form
           this.form.reset();
           this.jevic_attachment=null;
           this.logbook_attachment=null
           this.logbook_trans_attachment=null;
           this.jevicPlaceHolder='Image or PDF file';
           this.logBookPlaceHolder='Image or PDF file';
           this.logBookTransPlaceHolder='Image or PDF file';
           this.otherDocsPlaceholder ="Images or PDF files";
           this.photosPlaceholder="Images Only";
           this.vehicle_photos.length=0;
           this.other_documents.length=0;
           this.submitted = false;
           this.isProcessing = false;
           this.errorMessage=null;
           this.warningMessage=null;
          }else{
            this.errorMessage=response.message;
            this.submitted = false;
            this.isProcessing = false;
          }
        },
        error:(error) => {
          this.submitted = false;
          this.isProcessing = false;
          this.errorMessage=error;
        }
      });
    }else{
      this.submitted = false;
      this.isProcessing = false;
      this.errorMessage="Please provide all the required details!";
    }
  }
}
