import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { MakeService } from 'src/app/services/makes/make.service';
import { ModelService } from 'src/app/services/models/model.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-edit-model',
  templateUrl: './edit-model.component.html',
  styleUrls: ['./edit-model.component.css']
})
export class EditModelComponent implements OnInit{
  form!: FormGroup;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  isProcessing: boolean = false;
  submitted: boolean = false;
  makes: any[] = [];
  public modelId: string | null = null;
  public modelData:any;

  constructor(
    private makeService:MakeService,
    private formBuilder: FormBuilder,
    private router: Router,
    private globalService: GlobalService,
    private modelService:ModelService,
    private activatedRoute: ActivatedRoute,
  ){
    this.form = this.formBuilder.group({
      make_id: ['', Validators.required],
      model_name: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.modelId = param['get']('id')
    })

    if(this.modelId){
      this.fetchVehicleModelData(this.modelId);
    }else{
      //Navigate back to models list with error
      this.globalService.setGlobalErrorMessage("Unable to select vehicle model for editing!");
      this.router.navigate(['settings/models-list']);
    }

    this.fetchVehicleMakes();
  }

  /**
   * Get user data
   * @param userId 
   */
  fetchVehicleModelData(modelId: string): void {
    this.modelService.edit(modelId)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.modelData = response;
          if(this.modelData.status==='no_data' || this.modelData.status.length===0){
            this.errorMessage=this.modelData.message;
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  fetchVehicleMakes():void{
    this.makeService.getAllMakes().subscribe((response: ApiResponse) =>this.makes = response.data);
  }

  handleSubmit():void{
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      const formData = {
        ...this.form.value,
      };

      this.modelService.update(formData,this.modelId).subscribe(response => {
        if(response.status==='success'){

          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalSuccessMessage(response.message);
         
          // Redirect back to makes list
          this.router.navigate(['settings/models-list']);
        }else if(response.status==='error'){
          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalErrorMessage(response.message);
        }else{
          this.submitted=false;
          this.isProcessing = false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
        }
      },
      error => {
        this.isProcessing = false;
        this.errorMessage=error;
      });
    }else{
      this.isProcessing = false;
    }
  }
}
