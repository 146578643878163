import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchBodyType'
})
export class SearchBodyTypePipe implements PipeTransform {

  transform(body_types: any, searchText: string){
    
    if (!searchText) {
      return body_types; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return body_types.filter((body_types: any) => body_types.name.toLowerCase().includes(searchText));
  }

}
