import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ReceivedVehicleService } from 'src/app/services/vehicles/received-vehicle.service';

@Component({
  selector: 'app-view-received-vehicle',
  templateUrl: './view-received-vehicle.component.html',
  styleUrls: ['./view-received-vehicle.component.css']
})
export class ViewReceivedVehicleComponent implements OnInit{
  public isLoading: boolean = false;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public receive_log_id: string | null = null;
  vehicle: any;
  photos: any[] = [];
  spare_parts: any[] = [];
  activeImage: string | null=null; // Track the currently active image
  files: File[] = [];

  constructor(
    private activatedRoute:ActivatedRoute,
    private receivedVehicleService: ReceivedVehicleService,
  ){}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.receive_log_id = param['get']('receive_log_id')
    })

    if(this.receive_log_id){
      this.isLoading=true;
      this.getVehicleData(this.receive_log_id);
    }else{
      this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
    }
  }

  /**
   * Get vehicle details
   * @param receive_log_id 
   */
  getVehicleData(receive_log_id: string): void{
    this.receivedVehicleService.getReceivedVehicleDetails(receive_log_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.isLoading=false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.vehicle = response;
          if(this.vehicle.status==='no_data' || this.vehicle.status.length===0){
            this.isLoading=false;
            this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again.";
          }else{
            this.getVehicleSpareParts(receive_log_id); //Load spare parts
            this.getVehiclePhotos(receive_log_id); //Load photos
          }
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.errorMessage=error;
      }
    });
  }

  /**
   * Get vehicle photos
   * @param vehicle_id 
   */
  getVehiclePhotos(receive_log_id: string|null):void
  {
    this.receivedVehicleService.fetchVehiclePhotos(receive_log_id)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.isLoading=false;
          this.photos = response.data;
          this.setActiveImage(this.photos[0].url); // Set the first image as active initially
        }else if(response.status==='no_data'){
          this.isLoading=false;
          this.errorMessage=response.message;
        }else{
          this.isLoading=false;
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.errorMessage=error;
      }
    });
  }

  getVehicleSpareParts(receive_log_id: string|null):void {
    this.receivedVehicleService.fetchVehicleSpareParts(receive_log_id)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.spare_parts = response.data;
        }else if(response.status==='no_data'){
          this.errorMessage=response.message;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }
  
  //Image on preview
  setActiveImage(imageUrl: string): void {
    this.activeImage = imageUrl;
  }

}
