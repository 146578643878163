import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ViewAttachmentsService } from 'src/app/services/vehicles/view-attachments.service';
import { VehiclesService } from 'src/app/services/vehicles/vehicles.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-view-attachments',
  templateUrl: './view-attachments.component.html',
  styleUrls: ['./view-attachments.component.css']
})
export class ViewAttachmentsComponent implements OnInit{
  public isLoading: boolean = false;
  public errorMessage: string | null = null;
  public vehicle_id: string ='';
  vehicle: any;
  duty_payments: any[] = [];
  attachments: any[] = [];
  public popupErrorMessage: string | null = null;
  public popupSuccessMessage: string | null = null;
  public popupWarningMessage: string | null = null;
  public newAttPopupErrorMessage: string | null = null;
  public newAttPopupSuccessMessage: string | null = null;
  public newAttPopupWarningMessage: string | null = null;
  replacementFileName: string="Choose an image or a PDF file";
  newAttachmentFileName: string="Choose an image or a PDF file";
  isProcessing: boolean = false;
  submitted: boolean = false;
  docNameIsRequired: boolean = false;
  form!: FormGroup;
  addAttachmentForm!: FormGroup;
  attachment: any;
  new_attachment: any;
  public file_url:string='';
  public upload_type:string='';
  public upload_ref:string='';
  public vehicle_status:string='';
  spare_parts: any[] = [];
  @ViewChild('UpdateVehicleAttachmentsModal') modalElement!: ElementRef;
  @ViewChild('AddAttachmentModal') modalAddAttachment!: ElementRef;
  public userPermissions: string[] = [];

  constructor(
    private activatedRoute:ActivatedRoute,
    private viewAttachmentsService:ViewAttachmentsService,
    private vehiclesService: VehiclesService,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private authService:AuthService,
  ){
    this.form = this.formBuilder.group({
      attachment: [null, Validators.required],
    });

    this.addAttachmentForm = this.formBuilder.group({
      doc_type: ['', Validators.required],
      doc_name: [''],
      new_attachment: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.vehicle_id = param['get']('vehicle_id')
    })

    if(this.vehicle_id){
      this.isLoading=true;
      this.getVehicleData(this.vehicle_id);
      this.userPermissions=this.authService.getPermissions();
    }else{
      this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
    }
  }

  /**
   * Get vehicle attachments
   * @param vehicle_id 
   */
  getVehicleAttachments(vehicle_id: string): void{
    this.viewAttachmentsService.getVehicleAttachments(vehicle_id)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.attachments = response.data;
          this.getVehicleDutyPaymentDetails(vehicle_id);
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.getVehicleDutyPaymentDetails(vehicle_id);
        }else{
          this.errorMessage="Sorry, a problem was encountered while loading vehicle attachments. Please refresh the page and try again!";
          this.globalService.setGlobalErrorMessage(this.errorMessage);
          this.getVehicleDutyPaymentDetails(vehicle_id);
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

   /**
   * Get vehicle details
   * @param vehicle_id 
   */
   getVehicleData(vehicle_id: string): void{
    this.vehiclesService.getVehicle(vehicle_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.isLoading=false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
          this.globalService.setGlobalErrorMessage(this.errorMessage);
        }else{
          this.vehicle = response;
          if(this.vehicle.status==='no_data' || this.vehicle.status.length===0){
            this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again.";
            this.globalService.setGlobalErrorMessage(this.errorMessage);
            this.isLoading=false;
          }else{
            //Determine vehicle status
            if(this.vehicle.data.status==0){
              this.vehicle_status="En route";
            }else if(this.vehicle.data.status==1){
              this.vehicle_status="At CFS";
            }else if(this.vehicle.data.status==2){
              this.vehicle_status="In Stock";
            }else if(this.vehicle.data.status==3){
              this.vehicle_status="Being Transferred";
            }else if(this.vehicle.data.status==4){
              this.vehicle_status="On Road Test";
            }else if(this.vehicle.data.status==6){
              this.vehicle_status="In Japan Stock";
            }

            //Get vehicle attachments
            this.getVehicleAttachments(vehicle_id);
          }
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.errorMessage=error;
      }
    });
  }

  /**
   * Get vehicle duty payment details
   * @param vehicle_id 
   */
  getVehicleDutyPaymentDetails(vehicle_id: string): void{
    this.vehiclesService.fetchVehicleDutyPmtDetails(vehicle_id)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.duty_payments = response.data;
          this.getVehicleSpareParts(this.vehicle_id);
        }else if(response.status==='no_data'){
           this.globalService.setGlobalErrorMessage(response.message);
           this.getVehicleSpareParts(this.vehicle_id);
        }else{
          this.errorMessage="Sorry, a problem was encountered while loading duty payment details. Please refresh the page and try again!";
          this.globalService.setGlobalErrorMessage(this.errorMessage);
          this.getVehicleSpareParts(this.vehicle_id);
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  getVehicleSpareParts(vehicle_id: string|null):void {
    this.vehiclesService.fetchVehicleSpareParts(vehicle_id)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.spare_parts = response.data;
          this.isLoading=false;
        }else if(response.status==='no_data'){
          this.errorMessage=response.message;
          this.isLoading=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

   /**
   * Handle selectd file
   * @param event 
   */
   onReplacementAttachmentSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.replacementFileName = fileInput.files?.[0]?.name ?? 'Choose an image or a PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.attachment = file
    }
  }

  /**
   * Handle new selected file
   * @param event 
   */
  onNewAttachmentSelected(event: any) {
    //Show selected file
    const fileInput = event.target as HTMLInputElement;
    this.newAttachmentFileName = fileInput.files?.[0]?.name ?? 'Choose an image or a PDF file';

    //Prepare file for upload
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.new_attachment = file
    }
  }

  openModal(uploadType:string,uploadRef:string,fileURL:string){
    this.file_url=fileURL;
    this.upload_type=uploadType;
    this.upload_ref=uploadRef;
   
    if (this.modalElement) {
      (<any>$(this.modalElement.nativeElement)).modal('show');
    }
  }

  closeModal():void {
    if (this.modalElement) {
      (<any>$(this.modalElement.nativeElement)).modal('hide');

      //Refresh attachments list
      this.isLoading=true;
      this.getVehicleAttachments(this.vehicle_id);
    }
  }

  openAddAttachmentModal(){
    if (this.modalAddAttachment) {
      (<any>$(this.modalAddAttachment.nativeElement)).modal('show');
    }
  }

  onSelectChange(target: EventTarget|null) {
    if (target instanceof HTMLSelectElement) {
      const selectedOption = target.value;
      
      if(selectedOption ==='Other'){
        this.docNameIsRequired=true;
      }else{
        this.docNameIsRequired=false;
      }
    }
  }

  updateAttachment(){
    this.submitted = true;
    this.isProcessing=true;

    if (this.form.valid) {
      this.viewAttachmentsService.updateVehicleAttachment(this.upload_type,this.upload_ref,this.vehicle_id,this.file_url,this.attachment)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.isProcessing=false;
            this.attachment = null;
            this.replacementFileName ="Choose an image or a PDF file";
            this.globalService.setGlobalSuccessMessage(response.message);
            this.closeModal();
          }else if(response.status==='error'){
            this.submitted = false;
            this.isProcessing=false;
            this.popupErrorMessage=response.message;
          }else if(response.status==='warning'){
            this.submitted = false;
            this.isProcessing=false;
            this.popupWarningMessage=response.message;
          }else{
            this.submitted = false;
            this.isProcessing=false;
            this.popupErrorMessage=response.message;
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isProcessing=false;
          this.popupErrorMessage=error;
        }
      });
    }else{
      this.isProcessing=false;
    }
  }

  uploadNewAttachment(){
    this.submitted = true;
    this.isProcessing=true;

    if (this.addAttachmentForm.valid) {
      this.viewAttachmentsService.addNewVehicleAttachment(this.vehicle_id,this.addAttachmentForm.value,this.new_attachment)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.isProcessing=false;
            this.attachment = null;
            this.newAttachmentFileName ="Choose an image or a PDF file";
            this.globalService.setGlobalSuccessMessage(response.message);
            //Reset modal form
            this.addAttachmentForm.reset();
            //Close modal
            (<any>$(this.modalAddAttachment.nativeElement)).modal('hide');
            //Refresh attachments list
            this.isLoading=true;
            this.getVehicleAttachments(this.vehicle_id);
          }else if(response.status==='error'){
            this.submitted = false;
            this.isProcessing=false;
            this.newAttPopupErrorMessage=response.message;
          }else if(response.status==='warning'){
            this.submitted = false;
            this.isProcessing=false;
            this.newAttPopupWarningMessage=response.message;
          }else{
            this.submitted = false;
            this.isProcessing=false;
            this.newAttPopupErrorMessage=response.message;
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isProcessing=false;
          this.newAttPopupErrorMessage=error;
        }
      });
    }else{
      this.isProcessing=false;
    }
  }
}
