<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-md-10">
                    <form class="form-inline">
                        <div class="form-group">
                            <label>User Type</label>&nbsp;&nbsp;
                            <select class="form-control" (change)="onUserTypeSelectChange($event.target)">
                                <option value="">All</option>
                                <option value="1">Internal</option>
                                <option value="2">Agent</option>
                            </select>
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div class="form-group"  *ngIf="userTypeIsInternal">
                            <label>User:</label>&nbsp;
                            <select name="users" class="form-control" (change)="onUserSelectChange($event.target)">
                                <option value="">All</option>
                                <option [value]="user.id" *ngFor="let user of users">{{ user.name }}</option>
                            </select>
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div class="form-group" *ngIf="userTypeIsAgent">
                            <label>Agent:</label>&nbsp;
                            <select name="agents" class="form-control" (change)="onAgentSelectChange($event.target)">
                                <option value="">All</option>
                                <option [value]="agent.id" *ngFor="let agent of agents">{{ agent.company_name }}</option>
                            </select>
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div class="form-group">
                            <label>Module/App</label>&nbsp;&nbsp;
                            <select class="form-control" (change)="onModuleSelectChange($event.target)">
                                <option value="">All</option>
                                <option value="Login">Login</option>
                                <option value="Logout">Logout</option>
                                <option value="Exports">Exports</option>
                                <option value="Imports & Customs">Imports & Customs</option>
                                <option value="Vehicle Management">Vehicle Management</option>
                            </select>
                        </div>
                    </form>
                </div><!-- /.col -->
                <div class="col-md-2">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                        <li class="breadcrumb-item active">Activity Logs</li>
                    </ol>
                </div><!-- /.col -->
            </div><br><!-- /.row -->
            <div class="row">
                <div class="col-md-12">
                    <form [formGroup]="form" (ngSubmit)="filterByDate()" class="form-inline">
                        <div class="form-group">
                            <label>From:<span style="color:red;">*</span></label>&nbsp;&nbsp;
                            <input type="date" formControlName="date_from" class="form-control" placeholder="Date From" value="">
                            <span *ngIf="submitted && form.get('date_from')?.hasError('required')" style="color:red"> <strong>Please enter or select starting date!</strong></span>
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div class="form-group">
                            <label>To:<span style="color:red;">*</span></label>&nbsp;&nbsp;
                            <input type="date" formControlName="date_to" class="form-control" placeholder="Date To" value="">
                            <span *ngIf="submitted && form.get('date_to')?.hasError('required')" style="color:red"> <strong>Please enter or select ending date!</strong></span>
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div class="form-group">
                            <button type="submit" class="btn btn-outline-success"><i class="fas fa-filter" aria-hidden="true">&nbsp;</i>Filter By Date</button>
                        </div>
                    </form>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </div>
    </section>

     <!--Loading Spinner-->
     <div *ngIf="isProcessing">
      <app-spinner></app-spinner>
    </div>

    <!-- /.content-header -->
    <section class="content">
      <div class="container-fluid">
      <div class="card">
          <div class="card-header">
            <h3 class="card-title">Activity Logs</h3>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <table id="example1" class="table table-bordered table-hover">
              <thead>
                <tr>
                    <th>Date</th>
                    <th>Name/Username</th>
                    <th>User Type</th>
                    <th>Module/App</th>
                    <th>Action</th>
                    <th>IP Address</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let activity_log of activity_logs | paginate: { itemsPerPage: 100, currentPage: page, totalItems: count }">
                      <td>{{activity_log.activity_date | date: 'dd-MM-yyyy hh:mm:ss a'}}</td>
                      <td>{{activity_log.name}}</td>
                      <td *ngIf="activity_log.user_type==1">Internal</td>
                      <td *ngIf="activity_log.user_type==2">Agent</td>
                      <td>{{activity_log.module}}</td>
                      <td>{{activity_log.action }}</td>
                      <td>{{activity_log.ip_address}}</td>
                  </tr>
              </tbody>
            </table>
            <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </section>