<!--Loading Spinner-->
<div *ngIf="isInProgress">
    <app-spinner></app-spinner>
  </div>
  
  <!-- Content Header (Page header) -->
  <section class="content-header">
      <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
             
              <div class="col-sm-6">
               
              </div><!-- /.col -->
             
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                  <li class="breadcrumb-item active">Stock at CFS</li>
                </ol>
              </div><!-- /.col -->
            </div><!-- /.row -->
          </div><!-- /.container-fluid -->
      </div><!-- /.content-header -->
      <div class="row">
        <div class="col-md-8">
            <div class="row">
               
            </div>
        </div>
        <div class="col-md-4">
          <div class="row">
              <div class="col">
                  <input type="text" class="form-control" placeholder="Type here to search..." (input)="onSearchTextChange($event)">
              </div>
          </div>
        </div>
      </div>
      </section>
      <section class="content">
        <div class="container-fluid">
        <div class="card">
            <div class="card-header">
              <h3 class="card-title">Stock at CFS&nbsp;&nbsp;&nbsp;Count: {{rowCount}}</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body" style="overflow-x: auto; overflow-y: auto; white-space: nowrap; height: 650px;">
                <!--Error Message-->
                <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                  {{errorMessage}}
                </div>
              <table id="example1" class="table table-bordered table-hover table-striped">
                <thead>
                  <tr>
                      <th>BL No.</th>
                      <th>Entry No.</th>
                      <th>Make</th>
                      <th>Model</th>
                      <th>Chassis No.</th>
                      <th>Reg No.</th>
                      <th>Colour</th>
                      <th>Month Year</th>
                      <th>Duty Paid</th>
                      <th>C & F</th>
                      <th>Others</th>
                      <th>Total</th>
                      <th>Selling Price</th>
                      <th>Agency</th>
                      <th>Duty Pmt. Details</th>
                      <th>Company</th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let vehicle of vehicles | searchStock: searchText">
                      <td>{{vehicle.bl_no}}</td>
                      <td>{{vehicle.entry_no}}</td>
                      <td>{{vehicle.make}}</td>
                      <td>{{vehicle.model}}</td>
                      <td><a routerLink="/view-vehicle/{{vehicle.vehicle_id}}">{{vehicle.chassis_no}}</a></td>
                      <td>{{vehicle.reg_no}}</td>
                      <td>{{vehicle.colour}}</td>
                      <td>{{vehicle.month_yr}}</td>
                      <td>{{vehicle.duty_paid| currency:'Kshs '}}</td>
                      <td>{{vehicle.cnf| currency:'Kshs '}}</td>
                      <td>{{vehicle.other_charges| currency:'Kshs '}}</td>
                      <td>{{vehicle.total| currency:'Kshs '}}</td>
                      <td>{{vehicle.selling_rice| currency:'Kshs '}}</td>
                      <td>{{vehicle.agency}}</td>
                      <td>{{vehicle.duty_pmt_details}}</td>
                      <td>{{vehicle.company}}</td>
                    </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </section>