import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private baseURL = environment.apiURL;

  constructor(
    private httpClient: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }

  /**
   * Get all countries
   * @returns Array
   */
  getAllCountries(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/countries`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }
}
