<!--Loading Spinner-->
<div *ngIf="isLoading">
  <app-spinner></app-spinner>
</div>
 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Enter D.O Payment</h3>

               <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
              </div>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/do-rad-payment/select-bl"><i class="fa fa-list-ul"></i>&nbsp;Select BL</a></li>
               <li class="breadcrumb-item active">Enter D.O Payment</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">Enter D.O Payment</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="handleSubmit()">
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>BL No.:<span style="color:red;">*</span></label>
                           <input type="text" formControlName="bl_no" class="form-control" [(ngModel)]="bl_no" readonly>
                        </div>
                        <span *ngIf="submitted && form.get('bl_no')?.hasError('required')" style="color:red"> <strong>Please select BL number!</strong></span>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>D.O Amount Paid<span style="color:red;">*</span></label>
                            <input type="text" formControlName="do_amount" class="form-control" placeholder="Enter D.O Amount Paid" value="">
                        </div>

                        <span *ngIf="submitted && form.get('do_amount')?.hasError('required')" style="color:red"> <strong>Please enter Delivery Order amount paid!</strong></span>
                    </div>
                </div>
                <button type="submit" class="btn btn-success"><i class="fa fa-arrow-right"
                    aria-hidden="true">&nbsp;</i>Next</button>
            </form>
        </div>
        <!-- /.card-body -->
    </div>
   </div>
   </section>