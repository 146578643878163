 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Refund Commitment Fee</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a href="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a href="/commitment-fee-refunds"><i class="fa fa-undo"></i>&nbsp;View Refundable Commitment Fees</a></li>
               <li class="breadcrumb-item active">Refund Commitment Fee</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->
   <section class="content">
   <div class="container-fluid">
   <div class="card">
        <div class="card-header">
            <h3 class="card-title">Refund Commitment Fee</h3>
            <!--Error messages-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                <span [innerHTML]="errorMessage"></span>
            </div>
        </div>
       <!-- /.card-header -->
        <div class="card-body">
           <form [formGroup]="form" (ngSubmit)="handleSubmit()">
                <div class="row clearfix">
                    <div class="col-md-4">
                       <div class="form-group">
                           <label>Name:</label>
                           <input type="text" name="customer_name" class="form-control" placeholder="Customer Name" value="{{customer_refund.data.first_name}} {{customer_refund.data.middle_name}} {{customer_refund.data.surname}}" readonly>
                       </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Amount to Refund:</label>
                            <input type="text" formControlName="amount_to_refund" [(ngModel)]="amount_to_refund" class="form-control" placeholder="Amount to Refund" value="" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Amount Refunded:<span style="color:red;">*</span></label>
                            <input type="text" formControlName="amount_refunded" class="form-control" placeholder="Amount Refunded" value="" (blur)="showRetainBalanceOption()">

                            <span *ngIf="submitted && form.get('amount_refunded')?.hasError('required')" style="color:red"> <strong>Please enter amount to refund!</strong></span>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                   <div class="col-md-4">
                       <div class="form-group">
                           <label>Date Refunded:<span style="color:red;">*</span></label>
                           <input type="date" formControlName="date_refunded" class="form-control" value="">

                           <span *ngIf="submitted && form.get('date_refunded')?.hasError('required')" style="color:red"> <strong>Please enter or select date payment was refunded!</strong></span>
                       </div>
                   </div>
                   <div class="col-md-4">
                        <div class="form-group">
                            <label>Attachments:</label>
                            <div class="input-group mb-3">
                                <div class="custom-file">
                                <input type="file" formControlName="attachments" accept="application/pdf,image/*" class="custom-file-input" (change)="onFileSelected($event)" multiple>
                                <label class="custom-file-label">{{ fileName }}</label>
                                </div>
                            </div>
                        </div>
                   </div>
                   <div class="col-md-4">
                        <div class="form-group">
                            <label>Comment:</label>
                            <textarea formControlName="comment" class="form-control"></textarea>
                        </div>
                   </div>
                </div>

                <span *ngIf="retain_balance">
                    <input type="checkbox" formControlName="bal_retained">&nbsp;&nbsp;Retain Balance as penalty<br><br>
                </span>

                <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Processing...
                </button>
        
                <ng-template #showSaveBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Save</button>
                </ng-template>
           </form>
       </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>