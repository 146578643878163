<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
            
            <div class="col-sm-6">
                
            </div><!-- /.col -->
            
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/vehicles"><i class="nav-icon fas fa-list-ul"></i>&nbsp;Vehicle List</a></li>
                <li class="breadcrumb-item active">Print QR Codes</li>
                </ol>
            </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
</section>
    <!-- /.content-header -->
<section class="content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <button type="button" id="print-button" class="no-print btn btn-success" (click)="printPage()"><i class='fas fa-print'></i>&nbsp;Print</button>
                    <!--Error message-->
                <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row custom-row">
                    <div class="col-md-4 custom-column">
                        <div class="d-flex flex-column align-items-center">
                        <div>{{ chassis_no }}</div>
                            <qrcode [qrdata]="chassis_no || ''" [width]="230" [errorCorrectionLevel]="'M'"></qrcode>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.card-body -->
        </div>
    </div>
</section>