
<div class="card-body">
    <div class="row">
        <div class="col-md-11">
            <table id="example1" class="table table-bordered custom-table">
                <thead>
                    <tr>
                        <th *ngFor="let column of columns" [hidden]="visibleColumns[column]">
                            {{ column }}
                        </th>
                        <th>Verified</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of data">
                        <td *ngFor="let column of columns" [hidden]="visibleColumns[column]">
                            {{ row[column] }}
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-1 no-print">
            <div style="margin-left: 3px;">
                <app-print-bl-column-toggle [columns]="columns" (columnToggled)="toggleColumn($event)"></app-print-bl-column-toggle>
            </div>
        </div>
    </div>
</div>