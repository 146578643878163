import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute, Params } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { AgentService } from 'src/app/services/agents/agent.service';

@Component({
  selector: 'app-set-agent-password',
  templateUrl: './set-agent-password.component.html',
  styleUrls: ['./set-agent-password.component.css']
})
export class SetAgentPasswordComponent implements OnInit{
  form!: FormGroup;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  isProcessing: boolean = false;
  submitted: boolean = false;
  public agentId: string | null = null;
  public agentName: string | null = null;

  constructor(
    private agentService:AgentService,
    private formBuilder: FormBuilder,
    private router: Router,
    private globalService: GlobalService,
    private activatedRoute: ActivatedRoute,
  ){
    this.form = this.formBuilder.group({
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.agentId = param['get']('agent_id')
      this.agentName = param['get']('agent_name')
    })

    if(!this.agentId){
      //Navigate back to agents list with error
      this.globalService.setGlobalErrorMessage("Unable to select agent for password setup. Please try again!");
      this.router.navigate(['settings/agents-list']);
    }
  }

  handleSubmit():void{
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      if(this.form.value.password.length<8){

        this.isProcessing = false;
        this.errorMessage="Password MUST be atleast 8 characters long!"

      }else if(!this.form.value.password.match(/[a-z]+/)){

        this.isProcessing = false;
        this.errorMessage="Password MUST contain atleast one lowercase letter!"

      }else if(!this.form.value.password.match(/[A-Z]+/)){

        this.isProcessing = false;
        this.errorMessage="Password MUST contain atleast one uppercase letter!"

      }else if(!this.form.value.password.match(/[0-9]+/)){

        this.isProcessing = false;
        this.errorMessage="Password MUST contain atleast one digit!"

      }else if(!this.form.value.password.match(/[$@#&!]+/)){

        this.isProcessing = false;
        this.errorMessage="Password MUST contain atleast one special character e.g $@#&!"

      }else if(this.form.value.password !== this.form.value.password_confirmation){

        this.isProcessing = false;
        this.errorMessage="Password and Password Confirmation DO NOT match!"

      }else{
        const formData = {
          ...this.form.value,
        };
  
        this.agentService.setPassword(formData,this.agentId).subscribe(response => {
          if(response.status==='success'){
  
            this.submitted=false;
            this.isProcessing = false;
            this.globalService.setGlobalSuccessMessage(response.message);
           
            // Redirect back to agents list
            this.router.navigate(['settings/agents-list']);
          }else if(response.status==='error'){
            this.submitted=false;
            this.isProcessing = false;
            this.globalService.setGlobalErrorMessage(response.message);
          }else{
            this.submitted=false;
            this.isProcessing = false;
            this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
          }
        },
        error => {
          this.isProcessing = false;
          this.errorMessage=error;
        });
      }
    }else{
      this.isProcessing = false;
    }
  }
}
