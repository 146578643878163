import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchGrade'
})
export class SearchGradePipe implements PipeTransform {

  transform(grades: any, searchText: string){
    
    if (!searchText) {
      return grades; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return grades.filter((grades: any) => grades.grade.toLowerCase().includes(searchText));
  }

}
