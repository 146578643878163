import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class DoRadiationPaymentsService {

  private baseURL = environment.apiURL;

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, private errorHandler: ConfigErrorHandler) { }

  /**
   * Get all bills of lading pending
   * D.O and radiation payments
   */
  getBLs(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-bls-pending-do-rad-payments`;
    
    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get the vehicles for which radiation & IDF
   * charges have not been paid
   */
  getUnpaidRadiationAndIDF(bl_no: string): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-update-paid-radiation-idf/${bl_no}`;
    
    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  postPayments(payload:any):Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/post-do-rad-idf-payments`;

    return this.http.post<ApiResponse>(apiURL,payload, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

}
