import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CashManagementService } from 'src/app/services//cash-management/cash-management.service';
import { CategoriesService } from 'src/app/services//cash-management/categories.service';
import { GlobalService } from 'src/app/services/shared/global.service';

@Component({
  selector: 'app-new-cash-receipt',
  templateUrl: './new-cash-receipt.component.html',
  styleUrls: ['./new-cash-receipt.component.css']
})
export class NewCashReceiptComponent implements OnInit{
  isProcessing:boolean=false;
  isLoading:boolean=false;
  public errorMessage: string | null = null;
  public balanceData:any;
  form!: FormGroup;
  categories: any[] = [];
  submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private cashManagementService: CashManagementService,
    private globalService: GlobalService,
    private categoriesService: CategoriesService,
    private router: Router,
  ){
    this.form = this.formBuilder.group({
      pmt_date: ['', Validators.required],
      amt_received: ['', Validators.required],
      comment: ['', Validators.required],
      category: ['', Validators.required],
      authorised_by: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.isLoading=true;
    this.openingBalanceStatus();
  }

  //Check if status is open or closed
  openingBalanceStatus(){
    this.cashManagementService.checkOpeningBalanceStatus()
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.balanceData = response;
        
          if(this.balanceData.status==='no_data' || this.balanceData.status.length===0){

            this.isLoading=false;
             //Navigate to opening balance
            this.router.navigate(['cash-mgnt/enter-opening-bal']);
          }else if(this.balanceData.status==='success'){
            this.getCategories();
          }else if(this.balanceData.status==='prev_open'){
            this.globalService.setGlobalWarningMessage(this.balanceData.message);
            //Navigate to close day
            this.router.navigate(['cash-mgnt/close-day']);
          }else{
            this.errorMessage=this.balanceData.message;
            this.isLoading=false;
          }
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.errorMessage=error;
      }
    });
  }

  getCategories(): void {
    this.categoriesService.getAllCategories()
    .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.isLoading = false;
            this.categories = response.data
          }else{
            this.isLoading = false;
            this.errorMessage=response.message;
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.errorMessage=error;
        }
      });
  }

  handleSubmit(){
    this.submitted = true;
    this.isProcessing=true;
   
    if (this.form.valid) {
      this.cashManagementService.receiveCash(this.form.value)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.globalService.setGlobalSuccessMessage(response.message);
            // Redirect to Imported Cars list
            this.router.navigate(['cash-mgnt/transactions']);
          }else{
            this.submitted = false;
            this.isProcessing=false;
            this.errorMessage=response.message;
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isProcessing=false;
          this.errorMessage=error;
        }
      });
    }else{
      this.isProcessing=false;
    }
  }
}
