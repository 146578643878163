import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchUser'
})
export class SearchUserPipe implements PipeTransform {

  transform(users: any, searchText: string){
    
    if (!searchText) {
      return users; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return users.filter((users: any) => users.name.toLowerCase().includes(searchText));
  }

}
