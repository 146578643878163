import { Component, OnInit } from '@angular/core';
import { MakeService } from 'src/app/services/makes/make.service';
import { ApiResponse } from 'src/app/models/IApiResponse';

@Component({
  selector: 'app-makes-list',
  templateUrl: './makes-list.component.html',
  styleUrls: ['./makes-list.component.css']
})
export class MakesListComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  makes: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public isLoading: boolean = false;

  constructor(
    private makeService: MakeService
  ){}

  ngOnInit(): void {
    this.isLoading=true;
    this.fetchVehicleMakes();
  }

  fetchVehicleMakes():void{
    this.makeService.getAllMakes().subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.makes = response.data
          this.isLoading=false;
        }else{
          this.errorMessage=response.message;
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fetchVehicleMakes();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  deleteMake(event: Event,makeId:string){

  }

}
