 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h3>Enter Bill of Lading</h3>
            </div>
            <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/bl-list"><i class="fa fa-list-ul"></i>&nbsp;View Bills of Lading</a></li>
                <li class="breadcrumb-item active">Enter Bill of Lading</li>
            </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
</section>
  <!-- /.content-header -->
<section class="content">
<div class="container-fluid">
<div class="card">
    <div class="card-header">
      <h3 class="card-title">Enter Bill of Lading</h3>
      <!--Error messages-->
      <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
        {{errorMessage}}
      </div>
      <!--Info messages-->
      <div class="alert alert-info" role="alert" *ngIf="infoMessage">
        {{infoMessage}}
      </div>
    </div>

    <!-- /.card-header -->
    <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="handleSubmit()">
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>BL No.:<span style="color:red;">*</span></label>
                        <input type="text" formControlName="bl_no"  class="form-control" placeholder="Enter BL Number" value="">

                        <span *ngIf="submitted && form.get('bl_no')?.hasError('required')" style="color:red"> <strong>Please enter BL number!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>BL Date:<span style="color:red;">*</span></label>
                        <input type="date" formControlName="bl_date" class="form-control" placeholder="BL Date" value="">

                        <span *ngIf="submitted && form.get('bl_date')?.hasError('required')" style="color:red"> <strong>Please enter BL Date!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Consignee:<span style="color:red;">*</span></label>
                        <select formControlName="consignee_id"  class="form-control">
                            <option value="">Select</option>
                            <option [value]="consignee.id" *ngFor="let consignee of consignees">{{ consignee.consignee_name }}</option>
                        </select>

                        <span *ngIf="submitted && form.get('consignee_id')?.hasError('required')" style="color:red"> <strong>Please select Consignee!</strong></span>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>No. of Units:<span style="color:red;">*</span></label>
                        <input type="text" formControlName="no_of_units" class="form-control" placeholder="Number of Units" value="">

                        <span *ngIf="submitted && form.get('no_of_units')?.hasError('required')" style="color:red"> <strong>Please enter number of units on the BL!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>CFS:<span style="color:red;">*</span></label>
                        <select formControlName="cfs_id"  class="form-control">
                            <option value="">Select</option>
                            <option [value]="cfs.id" *ngFor="let cfs of cfses">{{ cfs.name }}</option>
                        </select>

                        <span *ngIf="submitted && form.get('cfs_id')?.hasError('required')" style="color:red"> <strong>Please select CFS!</strong></span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Vessel:<span style="color:red;">*</span></label>
                        <input type="text" formControlName="vessel" class="form-control" placeholder="Vessel Name" value="">

                        <span *ngIf="submitted && form.get('vessel')?.hasError('required')" style="color:red"> <strong>Please enter vessel name!</strong></span>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-md-4">
                    <label>Attachment:<span style="color:red;">*</span></label>
                    <div class="input-group mb-3">
                        <div class="custom-file">
                          <input type="file" formControlName="attachment" accept="application/pdf,image/*" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onFileSelected($event)">
                          <label class="custom-file-label" for="inputGroupFile01">{{ fileName }}</label>
                        </div>
                    </div>
    
                    <span *ngIf="submitted && form.get('attachment')?.hasError('required')" style="color:red"> <strong>Please select BL attachment!</strong></span>
                </div>
            </div>

            <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Processing...
            </button>

            <ng-template #showSaveBtn>
                <button type="submit" class="btn btn-success" [disabled]="!form.valid"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Save</button>
            </ng-template>
        </form>
    </div>
    <!-- /.card-body -->
</div>
</div>
</section>