<!--Loading Spinner-->
  <div *ngIf="isInProgress">
    <app-spinner></app-spinner>
  </div>
  
  <!-- Content Header (Page header) -->
  <section class="content-header">
      <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
             
              <div class="col-sm-6">
               
              </div><!-- /.col -->
             
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                  <li class="breadcrumb-item active">All Stock</li>
                </ol>
              </div><!-- /.col -->
            </div><!-- /.row -->
          </div><!-- /.container-fluid -->
      </div><!-- /.content-header -->
      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-1">
              <label>Location:</label>
            </div>
            <div class="col-md-3">
                <select name="locations" class="form-control" (change)="onSelectChange($event.target,'location')">
                  <option value="">All</option>
                  <option [value]="location.id" *ngFor="let location of locations">{{ location.location_name }}</option>
                </select>
            </div>
            <div class="col-md-1">
              <label>Branch:</label>
            </div>
            <div class="col-md-3">
              <select name="branches" class="form-control" (change)="onSelectChange($event.target,'branch')">
                <option value="">All</option>
                <option [value]="branch.id" *ngFor="let branch of branches">{{ branch.branch_name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row">
              <div class="col">
                  <input type="text" class="form-control" placeholder="Type here to search..." (input)="onSearchTextChange($event)">
              </div>
          </div>
        </div>
      </div>
      </section>
      <section class="content">
        <div class="container-fluid">
        <div class="card">
            <div class="card-header">
              <h3 class="card-title">All Stock&nbsp;&nbsp;&nbsp;Count: {{rowCount}}</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body" style="overflow-x: auto; overflow-y: auto; white-space: nowrap; height: 650px;">
                <!--Error Message-->
                <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                  {{errorMessage}}
                </div>
              <table id="example1" class="table table-bordered table-hover table-striped">
                <thead>
                    <tr>
                        <th *ngIf="userPermissions.includes('view-bl_no')">BL No.</th>
                        <th *ngIf="userPermissions.includes('view-entry_no')">Entry No.</th>
                        <th *ngIf="userPermissions.includes('view-doc_ref_no')">Doc Ref No.</th>
                        <th>Make</th>
                        <th>Model</th>
                        <th>Chassis No.</th>
                        <th *ngIf="userPermissions.includes('view-engine_no')">Engine No.</th>
                        <th *ngIf="userPermissions.includes('view-engine_cc')">Engine CC</th>
                        <th>Transmission</th>
                        <th>Body Type</th>
                        <th>Drive</th>
                        <th>Fuel</th>
                        <th *ngIf="userPermissions.includes('view-mileage')">Mileage</th>
                        <th *ngIf="userPermissions.includes('view-reg_no')">Reg No.</th>
                        <th>Colour</th>
                        <th>Month Year</th>
                        <th *ngIf="userPermissions.includes('view-duty_paid')">Duty Paid</th>
                        <th *ngIf="userPermissions.includes('view-cnf')">C & F</th>
                        <th *ngIf="userPermissions.includes('view-do_charges')">D.O Charges</th>
                        <th *ngIf="userPermissions.includes('view-rad_charges')">Radiation Charges</th>
                        <th *ngIf="userPermissions.includes('view-idf_charges')">IDF Charges</th>
                        <th *ngIf="userPermissions.includes('view-port_charges')">Port Charges</th>
                        <th *ngIf="userPermissions.includes('view-whse_charges')">Warehouse Charges</th>
                        <th *ngIf="userPermissions.includes('view-agency_fees')">Agency Fees</th>
                        <th *ngIf="userPermissions.includes('view-other_charges')">Others</th>
                        <th *ngIf="userPermissions.includes('view-total_expe')">Total</th>
                        <th *ngIf="userPermissions.includes('view-selling_rice')">Selling Price</th>
                        <th *ngIf="userPermissions.includes('view-agency')">Agency</th>
                        <th *ngIf="userPermissions.includes('view-duty_pmt_details')">Duty Pmt. Details</th>
                        <th *ngIf="userPermissions.includes('view-company')">Company</th>
                        <th *ngIf="userPermissions.includes('view-location')">Location</th>
                        <th *ngIf="userPermissions.includes('view-status')">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let vehicle of vehicles | searchStock: searchText" [ngClass]="{'highlight_reserved': vehicle.reserved===1}">
                      <td *ngIf="userPermissions.includes('view-bl_no')">{{vehicle.bl_no}}</td>
                      <td *ngIf="userPermissions.includes('view-entry_no')">{{vehicle.entry_no}}</td>
                      <td *ngIf="userPermissions.includes('view-doc_ref_no')">{{vehicle.doc_ref_no}}</td>
                      <td>{{vehicle.make}}</td>
                      <td *ngIf="vehicle.reserved === 0">{{vehicle.model}}</td>
                      <td *ngIf="vehicle.reserved === 1 && userPermissions.includes('process-sale')"><a routerLink="/view-vehicle-reservations/{{vehicle.vehicle_id}}">{{vehicle.model}}</a></td>
                      <td><a routerLink="/view-vehicle/{{vehicle.vehicle_id}}">{{vehicle.chassis_no}}</a></td>
                      <td *ngIf="userPermissions.includes('view-engine_no')">{{vehicle.engine_no}}</td>
                      <td *ngIf="userPermissions.includes('view-engine_cc')">{{vehicle.engine_cc}}</td>
                      <td>{{vehicle.transmission}}</td>
                      <td>{{vehicle.body_type}}</td>
                      <td>{{vehicle.drive}}</td>
                      <td>{{vehicle.fuel}}</td>
                      <td *ngIf="userPermissions.includes('view-mileage')">{{vehicle.mileage}}</td>
                      <td *ngIf="userPermissions.includes('view-reg_no')">{{vehicle.reg_no}}</td>
                      <td>{{vehicle.colour}}</td>
                      <td>{{vehicle.month_yr}}</td>
                      <td *ngIf="userPermissions.includes('view-duty_paid')">{{vehicle.duty_paid| currency:'Kshs '}}</td>
                      <td *ngIf="userPermissions.includes('view-cnf')">{{vehicle.cnf| currency:'Kshs '}}</td>
                      <td *ngIf="userPermissions.includes('view-do_charges')">{{vehicle.do_chg| currency:'Kshs '}}</td>
                      <td *ngIf="userPermissions.includes('view-rad_charges')">{{vehicle.rad_chg| currency:'Kshs '}}</td>
                      <td *ngIf="userPermissions.includes('view-idf_charges')">{{vehicle.idf_chg| currency:'Kshs '}}</td>
                      <td *ngIf="userPermissions.includes('view-port_charges')">{{vehicle.port_chg| currency:'Kshs '}}</td>
                      <td *ngIf="userPermissions.includes('view-whse_charges')">{{vehicle.whse_chg| currency:'Kshs '}}</td>
                      <td *ngIf="userPermissions.includes('view-agency_fees')">{{vehicle.agcy_fees| currency:'Kshs '}}</td>
                      <td *ngIf="userPermissions.includes('view-other_charges')">{{vehicle.other_charges| currency:'Kshs '}}</td>
                      <td *ngIf="userPermissions.includes('view-total_expe')"> <a routerLink="/vehicle-expe-details/{{vehicle.vehicle_id}}">{{vehicle.total| currency:'Kshs '}}</a></td>
                      <td *ngIf="userPermissions.includes('view-selling_rice')">{{vehicle.selling_rice| currency:'Kshs '}}</td>
                      <td *ngIf="userPermissions.includes('view-agency')">{{vehicle.agency}}</td>
                      <td *ngIf="userPermissions.includes('view-duty_pmt_details')">{{vehicle.duty_pmt_details}}</td>
                      <td *ngIf="userPermissions.includes('view-company')">{{vehicle.company}}</td>
                      <td *ngIf="userPermissions.includes('view-location') && vehicle.status === 0">{{vehicle.cfs_name}} {{vehicle.vessel_eta}}</td> <!--Show CFS Name and ETA on location column if vehicle is En route-->
                      <td *ngIf="userPermissions.includes('view-location') && vehicle.status === 1">{{vehicle.cfs_name}}</td> <!--Show CFS Name on location column if vehicle is at CFS-->
                      <td *ngIf="userPermissions.includes('view-location') && vehicle.status === 2 || vehicle.status === 3 || vehicle.status === 4">{{vehicle.location_name}} - {{vehicle.branch_name}}</td> <!--Show location and branch if vehicle is in stock,being transferred or on road test-->
                      <td *ngIf="vehicle.status === 0 && userPermissions.includes('view-status')">En route</td>
                      <td *ngIf="vehicle.status === 1 && userPermissions.includes('view-status')">At CFS</td>
                      <td *ngIf="vehicle.status === 2 && userPermissions.includes('view-status')">In Stock</td>
                      <td *ngIf="vehicle.status === 3 && userPermissions.includes('view-status')">Being Transferred</td>
                      <td *ngIf="vehicle.status === 4 && userPermissions.includes('view-status')">On Road Test</td>
                    </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </section>