import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }

  /**
   * Get categories
   * @returns Array
   */
  getAllCategories(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/cash-mgnt/get-categories`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Create new category
   * @param formData 
   * @returns array
   */
  store(formData:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/cash-mgnt/create-category`;

    return this.http.post(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get category for editing
   * @returns Array
   */
  edit(id:string){

    let apiURL:string=`${this.baseURL}/v1/cash-mgnt/get-category/${id}`;

    return this.http.get(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Update category
   * @param formData 
   * @param Id 
   * @returns array
   */
  update(formData:any,Id: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/cash-mgnt/update-category/${Id}`;

    return this.http.put(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Delete category
   * @returns Array
   */
  delete(id:string){

    let apiURL:string=`${this.baseURL}/v1/cash-mgnt/delete-category/${id}`;

    return this.http.delete<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }
}
