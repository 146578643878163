import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  public userPermissions: string[] = [];

  constructor(
    private authService: AuthService,
  ) { }

   hasAnyPermission(permissions: string[]): boolean {
    const userPermissions: string[] = this.userPermissions=this.authService.getPermissions();

    if (Array.isArray(permissions)) {
      // Check if any of the provided permissions exist in the userPermissions array
      return permissions.some(permission => userPermissions.includes(permission));
    } else {
      // If permissions is not an array, just check if it's included in userPermissions
      return userPermissions.includes(permissions as string);
    }
  }
}
