import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class BankAccountsService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient, private errorHandler: ConfigErrorHandler) { }

   /**
   * Get All Bank Accounts
   * @returns Array
   */
   public getAllBankAccounts():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/bankaccounts`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Create new bank account
   * @param formData 
   * @returns array
   */
  saveBankAccount(formData:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/bankaccounts`;

    return this.httpClient.post(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  getBankAccountDetails(id:any){
    let apiURL:string=`${this.baseURL}/v1/bankaccounts/${id}/edit`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Update bank account
   * @param formData 
   * @returns array
   */
   updateBankAccount(formData:any,id:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/bankaccounts/${id}`;

    return this.httpClient.put(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Filter Bank Accounts by consignee
   * @returns Array
   */
  public filterBankAccountsByConsignee(consigneeId:any):Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/filter-bank-accounts-by-consignee/${consigneeId}`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }
}
