import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class PortChargesService {

  private baseURL = environment.apiURL;

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, private errorHandler: ConfigErrorHandler) { }

   /**
   * Get Open Invoices
   * @returns Array
   */
   public getOpenInvoices():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/port-charges-open-invoices`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Get port charges invoice attachment URL
   * @param invoice_id 
   */
  getPortChargesInvoiceAttachmentUrl(invoice_id: string){

    let apiURL:string=`${this.baseURL}/v1/port-charges-invoice/attachment-url/${invoice_id}`;

    return this.http.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Get all bills of lading pending
   * Port Charges payments
   */
  getBLs(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-bls-pending-port-charges`;
    
    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Post payments internally (Invoice NOT posted by agent)
   * @param payload 
   * @returns 
   */
  postPayments(payload:any):Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/post-port-charges`;

    return this.http.post<ApiResponse>(apiURL,payload, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

   /**
   * Get open port charges invoice details
   * @param invoice_id 
   */
   getOpenPortInvoiceDetails(invoice_id: string){

    let apiURL:string=`${this.baseURL}/v1/get-open-port-charges-invoice-details/${invoice_id}`;

    return this.http.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Pay port charges (Invoice posted by agent)
   * @param paymentData
   * @returns 
   */
  payPortCharges(paymentData:any,attachment:any): Observable<any> {

    const formData = new FormData();
    formData.append("bl_no", paymentData.bl_no);
    formData.append("no_of_units",paymentData.no_of_units)
    formData.append("invoice_no", paymentData.invoice_no);
    formData.append("amount_kes", paymentData.amt_kes);
    formData.append("amount_usd", paymentData.amt_usd);
    formData.append("currency", paymentData.currency);
    formData.append("ex_rate", paymentData.ex_rate);
    formData.append("pmt_mode", paymentData.pmt_mode);
    formData.append("pmt_ref", paymentData.pmt_ref);
    formData.append("pmt_date", paymentData.pmt_date);
    formData.append("attachment", attachment);

    let apiURL:string=`${this.baseURL}/v1/pay-port-charges`;

    return this.http.post(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Get port charges invoice details
   * for editing
   * @param invoice_id 
   */
   getInvoiceDetails(invoice_id: string){

    let apiURL:string=`${this.baseURL}/v1/edit-port-charges-invoice/${invoice_id}`;

    return this.http.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  updateInvoice(form_data:any,attachment:any,invoice_id: string|null):Observable<ApiResponse>{
    const formData = new FormData();
    formData.append("invoice_no", form_data.invoice_no);
    formData.append("amount_kes", form_data.amount_kes);
    formData.append("amount_usd", form_data.amount_usd);
    formData.append("attachment", attachment);

    let apiURL:string=`${this.baseURL}/v1/update-port-charges-invoice/${invoice_id}`;

    return this.http.post<ApiResponse>(apiURL,formData).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Delete invoice
   * @param invoiceId 
   * @returns array
   */
  deleteInvoice(invoiceId: string|null,invoiceNo: string):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/port-charges-invoice/delete/${invoiceId}/${invoiceNo}`;

    return this.http.delete(apiURL, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
