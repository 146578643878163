import { Component, OnInit } from '@angular/core';
import { UpdateEntryService } from 'src/app/services/entries/update-entry.service';
import { ActivatedRoute, Params,Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { DeleteEntryService } from 'src/app/services/entries/delete-entry.service';

@Component({
  selector: 'app-delete-addtl-duty',
  templateUrl: './delete-addtl-duty.component.html',
  styleUrls: ['./delete-addtl-duty.component.css']
})
export class DeleteAddtlDutyComponent implements OnInit{
  public errorMessage: string | null = null;
  public vehicle_id: string | null = null;
  public entry_id: string | null = null;
  entry: any;
  duties: any[] = [];
  isProcessing: boolean = false;

  constructor(
    private updateEntryService: UpdateEntryService,
    private activatedRoute:ActivatedRoute, 
    private router: Router,
    private globalService: GlobalService,
    private deleteEntryService: DeleteEntryService
    ){
  
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.vehicle_id = param['get']('vehicle_id')
    })

    if(this.vehicle_id){
      this.isProcessing=true;
      this.getEntryData(this.vehicle_id);
    }else{
      const error="Unable to select entry, please try again!";
      this.globalService.setGlobalErrorMessage(error);
      this.router.navigate(['/transactions/delete-entry']);
    }
  }

   /**
   * Get entry details
   * @param vehicle_id 
   */
   getEntryData(vehicle_id: string): void {
    this.updateEntryService.fetchEntryDetails(vehicle_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.isProcessing=false;
          this.errorMessage="Oops! A problem was encountered while loading data. Please refresh the page and try again!";
        }else{
          this.entry = response;
          //Get additional duty
          this.entry_id=this.entry.data.entry_id;
          this.getAdditionalDuties(this.entry_id);

          if(this.entry.status==='no_data' || this.entry.status.length===0){
            this.isProcessing=false;
            this.errorMessage="Oops! A problem was encountered while loading data. Please refresh the page and try again.";
          }
        }
      },
      error: (error) => {
        this.isProcessing=false;
        this.errorMessage=error;
      }
    });
  }

  /**
   * Get additional duties associated with
   * the selected entry
   * @param entry_id 
   */
  getAdditionalDuties(entry_id: string|null): void {
    this.deleteEntryService.fetchAdditionalDuties(entry_id)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.duties = response.data;
          this.isProcessing=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isProcessing=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isProcessing=false;
        }
      },
      error: (error) => {
        this.isProcessing=false;
        this.errorMessage=error;
      }
    });
  }

  /**
   * Delete additional duty
   * @param txn_id
   */
  deleteAdditionalDuty(txn_id: string,doc_ref_no: string,pmt_type:string){
    this.errorMessage=null;
    
    let prompt = "Are you sure you want to delete duty with document reference number "+doc_ref_no+"?";
    if (confirm(prompt) == true) {
      this.isProcessing=true;
      this.deleteEntryService.deleteAdditionalDuty(txn_id,doc_ref_no,pmt_type)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.globalService.setGlobalSuccessMessage(response.message);
            this.getAdditionalDuties(this.entry_id);
          }else{
            this.errorMessage=response.message;
            this.isProcessing=false;
          }
        },
        error: (error) => {
          this.errorMessage=error;
          this.isProcessing=false;
        }
      });
    }
  }
}
