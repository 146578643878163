<section class="content-header">
    <!--Error Message-->
    <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
        {{errorMessage}}
    </div>
    <!--Warning Message-->
    <div class="alert alert-warning" role="alert" *ngIf="warningMessage">
        {{warningMessage}}
    </div>
    <div class="row">
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-1">
                    <label>Location:</label>
                </div>
                <div class="col-md-3">
                    <select name="locations" class="form-control" [(ngModel)]="selectedLocation" (change)="onLocationChange($event.target)">
                    <option value="">Select</option>
                    <option [value]="location.id" *ngFor="let location of locations">{{ location.location_name }}</option>
                    </select>
                </div>
                <div class="col-md-1">
                    <label>Branch:</label>
                </div>
                <div class="col-md-3">
                    <select name="branches" class="form-control" [(ngModel)]="selectedBranch" (change)="onBranchChange($event.target)">
                        <option value="">Select</option>
                        <option [value]="branch.id" *ngFor="let branch of branches">{{ branch.branch_name }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</section>
<app-data-table [data]="data" [columns]="columns"></app-data-table>