<!--Loading Spinner-->
  <div *ngIf="isInProgress">
    <app-spinner></app-spinner>
  </div>
  
  <!-- Content Header (Page header) -->
  <section class="content-header">
      <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
             
              <div class="col-sm-6">
               
              </div><!-- /.col -->
             
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                  <li class="breadcrumb-item active">Sold Cars</li>
                </ol>
              </div><!-- /.col -->
            </div><!-- /.row -->
          </div><!-- /.container-fluid -->
      </div><!-- /.content-header -->
      <div class="row">
        <div class="col-md-8">
            <div class="row">
               
            </div>
        </div>
        <div class="col-md-4">
          <div class="row">
              <div class="col">
                  <input type="text" class="form-control" placeholder="Type here to search..." (input)="onSearchTextChange($event)">
              </div>
          </div>
        </div>
      </div>
      </section>
      <section class="content">
        <div class="container-fluid">
        <div class="card">
            <div class="card-header">
              <h3 class="card-title">Sold Cars&nbsp;&nbsp;&nbsp;Count: {{rowCount}}</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <!--Error Message-->
                <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                  {{errorMessage}}
                </div>
              <table id="example1" class="table table-bordered table-hover table-striped">
                <thead>
                  <tr>
                      <th>Make</th>
                      <th>Model</th>
                      <th>Chassis No.</th>
                      <th>Engine CC</th>
                      <th>Engine No</th>
                      <th>Transmission</th>
                      <th>Body Type</th>
                      <th>Drive</th>
                      <th>Fuel</th>
                      <th>Mileage</th>
                      <th>Colour</th>
                      <th>Month Year</th>
                      <th>Reg No.</th>
                      <th *ngIf="userPermissions.includes('process-sale')"></th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let vehicle of vehicles | searchStock: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                      <td>{{vehicle.make}}</td>
                      <td>{{vehicle.model}}</td>
                      <td>{{vehicle.chassis_no}}</td>
                      <td>{{vehicle.engine_cc}}</td>
                      <td>{{vehicle.engine_no}}</td>
                      <td>{{vehicle.transmission}}</td>
                      <td>{{vehicle.body_type}}</td>
                      <td>{{vehicle.drive}}</td>
                      <td>{{vehicle.fuel}}</td>
                      <td>{{vehicle.mileage}}</td>
                      <td>{{vehicle.colour}}</td>
                      <td>{{vehicle.month_yr}}</td>
                      <td>{{vehicle.reg_no}}</td>
                      <td *ngIf="userPermissions.includes('process-sale') || userPermissions.includes('view-vhcl-atts')">
                        <div class="dropdown">
                            <button class="btn btn-default dropdown-toggle" type="button"
                                data-toggle="dropdown">Action
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li *ngIf="userPermissions.includes('process-sale') && vehicle.reserved === 1"><a class="dropdown-item" routerLink="/view-vehicle-reservations/{{vehicle.vehicle_id}}">View Reservations</a></li>
                                <li *ngIf="userPermissions.includes('view-vhcl-atts')"><a class="dropdown-item" routerLink="/view-vehicle-attachments/{{vehicle.vehicle_id}}">View Attachments</a></li>
                                <li *ngIf="userPermissions.includes('add-vhcl-photos')"><a class="dropdown-item" routerLink="/view-vehicle/{{vehicle.vehicle_id}}">View Photos</a></li>
                                <li *ngIf="userPermissions.includes('print-qr-codes')"><a class="dropdown-item" routerLink="/print-qr-code/{{vehicle.chassis_no}}">Print QR Code</a></li>
                                <li *ngIf="userPermissions.includes('process-sale')"><a class="dropdown-item" (click)="openModal(vehicle.vehicle_id)">Process Return</a></li>
                            </ul>
                        </div>
                      </td>
                    </tr>
                </tbody>
              </table>
              <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </section>

<!-- Modal -->
<div class="modal fade" #ReturnSoldVehicleModal tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Return Sold Vehicle</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="form" (ngSubmit)="returnSold()">
        <div class="modal-body">
          <div class="row clearfix">
            <div class="col-md-6">
                <div class="form-group">
                  <label>Make:</label>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Make" value="{{ vehicle?.make }}" readonly>
                  </div>
                </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Model:</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="Model" value="{{ vehicle?.model }}" readonly>
                </div>
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <div class="col-md-6">
              <div class="form-group">
                <label>Chassis No.:</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="Chassis No." value="{{ vehicle?.chassis_no }}" readonly>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Sold From:</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="Sold From" value="{{ vehicle?.location_name }} - {{ vehicle?.branch_name }}" readonly>
                </div>
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <div class="col-md-6">
              <div class="form-group">
                <label>Returned To:<span style="color:red;">*</span></label>
                <div class="input-group mb-3">
                  <select name="branches" class="form-control" formControlName="returned_to">
                    <option value="">Select</option>
                    <option [value]="branch.branch_id" *ngFor="let branch of branches">{{ branch.location_name }} - {{ branch.branch_name }}</option>
                  </select>
                </div>

                <span *ngIf="submitted && form.get('returned_to')?.hasError('required')" style="color:red"> <strong>This field is required!</strong></span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Date Returned:<span style="color:red;">*</span></label>
                <div class="input-group mb-3">
                  <input type="date" formControlName="date_returned" class="form-control" placeholder="Date Returned">
                </div>

                <span *ngIf="submitted && form.get('date_returned')?.hasError('required')" style="color:red"> <strong>This field is required!</strong></span>
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <div class="col-md-12">
              <div class="form-group">
                <label>Comment:</label>
                <div class="input-group mb-3">
                  <textarea class="form-control" formControlName="comment" rows="4"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button class="btn btn-success" type="button" *ngIf="isInProgress; else showProcessBtn" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Processing...
          </button>
    
          <ng-template #showProcessBtn>
              <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Process</button>
          </ng-template>
        </div>
      </form>
    </div>
  </div>
</div>