<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
           
            <div class="col-sm-6">
              <a routerLink="/enter-bl" class="btn btn-success pull-left" class="btn btn-success"><i
                class="fa fa-plus-circle" aria-hidden="true">&nbsp;</i>Enter BL</a>
            </div><!-- /.col -->
           
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item active">Recently Cleared Vehicles</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
  
    <div class="row">
      <div class="col-md-8">
          <div class="row">
             
          </div>
      </div>
      <div class="col-md-4">
        <div class="row">
            <div class="col">
                <input type="text" class="form-control" placeholder="Type Chassis Number, Doc Ref. Number or Entry Number here to search..." (input)="onSearchTextChange($event)">
            </div>
        </div>
      </div>
    </div>
    </section>
      <!-- /.content-header -->
    <section class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Recently Cleared Vehicles</h3>
            <!--Error Message-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
              {{errorMessage}}
            </div>
            <!--Success Message-->
            <div class="alert alert-success" role="alert" *ngIf="successMessage">
              {{successMessage}}
            </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <table id="example1" class="table table-bordered table-hover">
              <thead>
                <tr>
                    <th>BL No.</th>
                    <th>Entry No.</th>
                    <th>Entry Date</th>
                    <th>Doc Ref. No.</th>
                    <th>Make</th>
                    <th>Model</th>
                    <th>Engine CC</th>
                    <th>Engine No.</th>
                    <th>Chassis No.</th>
                    <th>Colour</th>
                    <th>Body Type</th>
                    <th></th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let vehicle of clearedVehicles | searchEntries: searchText| paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                    <td>{{vehicle.bl_no}}</td>
                    <td>{{vehicle.entry_no}}</td>
                    <td>{{vehicle.entry_date| date: 'dd-MM-yyyy'}}</td>
                    <td>{{vehicle.doc_ref_no}}</td>
                    <td>{{vehicle.make}}</td>
                    <td>{{vehicle.model}}</td>
                    <td>{{vehicle.engine_cc}}</td>
                    <td>{{vehicle.engine_no}}</td>
                    <td>{{vehicle.chassis_no}}</td>
                    <td>{{vehicle.colour}}</td>
                    <td>{{vehicle.body_type}}</td>
                    <td><a routerLink="/update-entry/{{ vehicle.vehicle_id }}" class="btn btn-success btn-sm active" role="button" aria-pressed="true"><i
                      class="far fa-edit" aria-hidden="true">&nbsp;</i>Update Entry</a></td>
                  </tr>
              </tbody>
            </table>
            <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </section>